import React, { Component, Fragment } from 'react';
import {
    Grid, 
    Button,  
    Slide,
} from '@material-ui/core';
import UpTextField from './upTextField';
import * as styles from './styles.js';
import "../../index.css";
import Spinner from '../common/CircularSpinner';
import { withTranslation } from 'react-i18next';
import { DialogModal } from "./dialogModal";
import * as constants from "./constants";
import { DialogAllOptions } from "./dialogAllOptions";

export class ContactComponent  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contact: this.props.contact, 
            show: this.props.show,
            isLoading : false,
            isDelete : false
      }
    }

    async componentDidMount() {
        
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.contact !== this.props.contact) 
        {
            this.setState({
                contact : this.props.contact
            });
        }

        if(prevProps.show !== this.props.show) 
        {
            this.setState({
                show : this.props.show
            });
        }
        
    }

    edit = () => {
        this.setState({
            open: true,
        })
    }

    handleCloseModify = (contact) => {
        this.setState({
            open : false,
            contact : contact
        }, function() {
            this.props.updateContact(contact, "contactId");
        })
    }

    handleBackModify  = () => {
        this.setState({
            open: false,
        })
    }

    delete = () => {
        this.setState({
            isDelete : true,
        })
    }

    handleDelete = async (value) => {
        if (value) {
            if (!this.props.isLoading) {
                this.setState({
                    isLoading: true,
                });
            }
              
            this.setState(prevState => ({
                ...prevState,
                contact: {
                    ...prevState.contact,
                    state : constants.Deleted
                },
            }), async function() {
               
                var flag = false;
                await this.props.actions.updateContact(this.state.contact)
                .then((result) => {
                    flag = true;
                })
                .catch(() => {
                })
                .finally(() =>{
                    this.setState({
                        isLoading: false,
                    });
                
                })
        
                if (flag) {
                    this.setState({
                        isDelete: false,
                        show: false,
                    }, function() {
                        this.props.cleanFilter("contactId");
                    });
                };
            });
        }
        else {
            this.setState({
                isDelete : false,
            })
        }
    }
  
    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    
    render() {
        const { t } = this.props;
        return (
            <Fragment>
                {this.state.show &&
                    <Grid style={styles.ElementWithoutPadding}>
                        <Grid container style={styles.ElementWithoutPadding}>
                            <UpTextField
                                disabled={true}
                                style={styles.inputElements}
                                id="email"
                                label={t("Email")}
                                value={this.state.contact.email}
                                onChange={this.handleChange} 
                            />
                            <UpTextField
                                disabled={true}
                                style={styles.inputElements}
                                id="phone"
                                label={t("Phone")}
                                value={this.state.contact.phone}
                                onChange={this.handleChange} 
                            />
                            <UpTextField
                                disabled={true}
                                style={styles.inputElements}
                                id="title"
                                label={t("Title")}
                                value={this.state.contact.title}
                                onChange={this.handleChange} 
                            /> 
                            <Grid
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="flex-end"
                                style={{ marginBottom : "5px"  }}
                            >
                                <Grid item xs={12} style={{ textAlign : 'end'}}>
                                    <Button id="close" onClick={this.edit} variant="contained" color="primary" style={{ marginRight : '20px'}} >
                                        {t("Edit")}
                                    </Button >
                                    <Button id="save" 
                                        color="primary" onClick={this.delete} variant="contained" style={{ marginRight : '30px'}}>
                                        {t("Delete")}
                                    </Button >  
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                { this.state.open &&
                   <DialogAllOptions 
                        openModify={this.state.open}
                        isAllOptions={true}
                        typeModify={constants.contacts}
                        notNeedsFind={true}
                        contactFound={this.state.contact}
                        handleCloseModify={this.handleCloseModify} 
                        handleBackModify={this.handleBackModify} 
                        stayInMainWindowIsAllOptions={this.props.stayInMainWindowIsAllOptions}
                    >
                            
                    </DialogAllOptions> 
                } 
                {this.state.isLoading &&
                    <Spinner open={true}/>
                }
                { this.state.isDelete &&
                   <DialogModal
                        t={this.props.t}
                        open={this.state.isDelete}
                        questionDialog={t("AreyouSureofDelete")}
                        handleReply={this.handleDelete}
                   >
                   </DialogModal>
                } 
            </Fragment>
        )
    };
}


export default withTranslation('common')(ContactComponent);

