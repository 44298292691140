import localforage from 'localforage'

export const forageStore = localforage.createInstance({
    // List of drivers used
    driver: [
        localforage.LOCALSTORAGE,
    ],
    // Prefix all storage keys to prevent conflicts
    name: 'up-cache'
});

export default forageStore;