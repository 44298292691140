import * as types from "./actionTypes";
import * as productGroupApi from "../../api/productGroupApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";
import * as constants from "../../components/common/constants";

export function readProductGroupsSuccess(productGroups) {
    return { type: types.LOAD_PRODUCTGROUP_SUCCESS, productGroups };
}

export function readProductGroups(filter, sort, page, cancelToken) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return productGroupApi
        .ReadProductGroups(filter, sort, page, cancelToken)  
        .then(productGroups => {
            dispatch(readProductGroupsSuccess(productGroups));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (typeof(error.message) !== "undefined" &&
                error.message !== constants.cancelHttpRequest) {
                dispatch(notifications.notificationError("ErrorloadingProductGroups"));
                throw new Error(error);
            }
        });
    };
} 

export function readProductGroupSuccess(productGroup) {
    return { type: types.READ_PRODUCTGROUP_SUCCESS, productGroup };
}

export function readProductGroup(id) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return productGroupApi
        .ReadProductGroup(id)  
        .then(productGroup => {
            dispatch(readProductGroupSuccess(productGroup));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorloadingProductGroup"));
            throw new Error(error);
        });
    };
} 

export function updateProductGroupSuccess(productGroup) {
    return { type: types.UPDATE_PRODUCTGROUP_SUCCESS, productGroup };
}

export function updateProductGroup(productGroup) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return productGroupApi
        .updateProductGroup(productGroup)  
        .then(productGroup => {
            dispatch(updateProductGroupSuccess(productGroup));
            return productGroup;
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (constants.UniqueKeyConstraintViolation === error ||
                constants.UniqueKeyConstraintViolation === error.message) {
                dispatch(notifications.notificationError("ItemCodeAlreadyExist"));
            }
            else {
                dispatch(notifications.notificationError("ErrorupdatingProductGroup"));
            }
            throw new Error(error);
        });
    };
} 

