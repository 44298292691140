import React, { Component, Fragment } from 'react';
import "../../../index.css";
import { withTranslation } from 'react-i18next';
import * as constants from "../constants";
import FilterHelper from "../../../helpers/filterHelper"; 
import { SearchList } from "../Lists/searchList";
import axios from 'axios';
import Spinner from './../CircularSpinner';
import StatefulSessionHelper from "../../../helpers/statefulSessionHelper";

var isMounted = false;

export class UsersList  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows : [],
            filterOptions : [],
            builderList : [],
            isLoading : false,
            isRowStyle : false,
            expandedSearch: false,
            idSaveLocal: this.props.idSave !== undefined ? this.props.idSave : constants.usersFilter
        }
    }

    componentWillUnmount() {
        isMounted = false;
        this.props.cancelPromises();
    }

    componentDidMount() {
        this.setFilter();
        var searchValue = StatefulSessionHelper.getSearchValue(this.state.idSaveLocal + this.props.user.profile.sub);
        if (searchValue === null) {
            searchValue = "";
        }

        this.setState({
            filter : searchValue
        }, function() {
            this.reloadData();
        })
      
    }

    reloadData = () => {
        var data = FilterHelper.getfilterOptions(this.state.idSaveLocal);
        this.loadRecords(this.state.filter, data.sort,1);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.usersGeneralInfo !== this.props.usersGeneralInfo) {
            this.setState({
                rows : this.props.usersGeneralInfo,
            });
        }

    }
    
    setFilter = () => {
        this.setState({
            idSave : this.props.idSave,
        });

        var options = [
            FilterHelper.createFilterOption("firstName", true, true, 2, constants.textAlign.left, true, "name"),
        ];

        var filterOptions = FilterHelper.setFilter(this.state.idSaveLocal, options, false);
        this.setState({
            filterOptions : filterOptions.filter,
            isRowStyle : filterOptions.isRowStyle
        })
    }

    showFilterFields = (row, rowFilter) => {
        return (
            <Fragment>
                {rowFilter.id === "firstName" &&
                    row.name
                }
            </Fragment>
        )
    }

    loadRecords = async (filter, sort, page) => {
        isMounted = true;

        this.props.cancelPromises();

        var isLoaded = false;
        if (!this.props.isArray) {

            
            if (!this.props.isLoading) {
                this.setState({
                    isLoading : true
                })
            }

            var data = FilterHelper.getfilterOptions(this.state.idSaveLocal);
            this.setState({
                filterOptions : data.filter,
                isRowStyle: data.isRowStyle 
            })

            const cancelToken = axios.CancelToken.source();

            await this.props.cancellablePromise(
               this.props.actions.ReadUsersGeneralInfo(filter, data.sort, page, cancelToken.token), cancelToken
            )
            .then(() => {
                isLoaded = true;
            })
            .catch((error) => {
                if (error.message === constants.cancelHttpRequest || error.message === "[object Object]") {
                    isMounted = false;
                }
            })
            .finally(result =>{
                if (isMounted) {
                    this.setState({
                        isLoading : false
                    })
                }
            })
        }
        else {
            this.props.loadRecords(filter, sort);
        }

        return isLoaded;
    }
    

    render() {
        const { t } = this.props;
        return (
            <Fragment>
                {this.state.isLoadingMassive &&
                    <Spinner open={true}/>
                }
                <SearchList 
                    {...this.props}
                    rows={this.state.rows}
                    showFilterFields={this.showFilterFields}
                    filterOptions={this.state.filterOptions}
                    isRowStyle={this.state.isRowStyle}
                    loadRecords={this.loadRecords}
                   
                    backButton={this.props.backButton !== undefined ? this.props.backButton : false}
                    onlySelect={this.props.onlySelect !== undefined ? this.props.onlySelect :  false}
                    addButton={this.props.addButton !== undefined ? this.props.addButton : true}
                    deleteButton={this.props.deleteButton !== undefined ? this.props.deleteButton  : true}
                    editButton={this.props.editButton !== undefined ? this.props.editButton : true}
                    isToUp={this.props.isToUp !== undefined ? this.props.isToUp : true}
                    title={t(this.props.title !== undefined ? this.props.title : "Users")} 
                    isLoading={this.state.isLoading}
                    handleOneSelect={this.props.handleOneSelect}
                    handleSearchOneSelect={this.props.handleSearchOneSelect}
                    multiSelect={this.props.multiSelect}
                
                    isArray={this.props.isArray}
                    handleAdd={this.props.handleAdd}
                    refreshDetails={this.props.refreshDetails}
                    builderList={this.state.builderList}
                    idSave={this.props.idSave !== undefined ? this.props.idSave : constants.usersFilter  }
                    handleCloseProduct={this.props.handleCloseProduct}
                    history={this.props.history}
                    expandedSearch={this.state.expandedSearch}


                />
            </Fragment>    
        )
    };
}

export default withTranslation('common')(UsersList);
