import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function workSiteReducer(state = initialState.workSite , action) {
    if (action.type === types.READ_WORKSITE_SUCCESS)
    {
        return action.WorkSite;
    }
    else {
        return state;
    }
}
