import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function sugarSessionReducer(state = initialState.sugarSession, action) {
    if (action.type === types.LOAD_LOGGED_IN_SUGAR_INSTANCES_SUCCESS) {
        return {
            ...state,
            loading: false,
            loggedInInstances: action.loggedInInstances,
        };
    }
    else if (action.type === types.SUGAR_LOGIN_SUCCESS) {
        return {
            ...state,
            loggedInInstances: action.loggedInInstances,
        };
    }
    else if (action.type === types.SUGAR_LOGOUT_SUCCESS) {
        return {
            ...state,
            loggedInInstances: action.loggedInInstances,
        };
    }
    else {
        return state;
    }
}
