import React, { Component, Fragment } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Card,
    CardContent,
    Container,
    Grid,
    Typography,
    TextField

} from '@material-ui/core';
import "../../index.css";
import { withTranslation } from 'react-i18next';
import * as styles from '../common/styles.js';
import Spinner from './CircularSpinner';
import *  as usersActions from '../../redux/actions/usersActions';
import * as notifications from "../../redux/actions/notificationsActions";
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

export class PasswordModal  extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userId: this.props.userId,
            password: "",
            repeatPassword: "",
        }
        console.log(this.props.userId);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.userId !== this.props.userId) {
           this.setState({
                userId : this.props.userId,
           });
        }
    }

    handleChange = (event) => {
      
        const { id, value } = event.target;
     
        this.setState(prevState => ({
            ...prevState,
            [id]: value
        }));

    }

    handleClose = () => {
        if (this.props.handleReply) {
            this.props.handleReply();
        }
    }

    handleChangePassword = async () => {
        if (!this.props.isLoading) {
            this.setState({
                isLoading : true
            })
        }

        var record = {
            id : this.props.userId,
            password: this.state.password,
            repeatPassword: this.state.repeatPassword
        }

        var isChanged = false;
        await this.props.actions.resetpasswordUser(this.state.userId, record)
        .then(() => {
            isChanged = true;
        })
        .catch(() => {})
        .finally(() =>{
            this.setState({
                isLoading : false
            })
        })
      
        if (isChanged) {
            if (this.props.handleReply) {
                this.props.handleReply();
            }
            this.props.actions.notificationSuccess(this.props.t("PasswordChanged"), true);
        }
    }


    render() {
        
        const { t } = this.props;
        return (
            <Fragment>
                <Dialog 
                    open={this.props.open}
                    onClose={this.handleCancel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{t("ChangePassword")}</DialogTitle>
                    <DialogContent>
                        <Grid container style={styles.ElementWithoutPadding}>
                            <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                                <Card style={styles.CardWithoutBoxes}>
                                    <CardContent style={styles.ElementWithoutPadding}>
                                        <Grid container >
                                            <Grid style={{ width: "100%" }}>
                                                <TextField
                                                    style={{ width: "100%" }}
                                                    id="password"
                                                    label={t("Password")}
                                                    type="password"
                                                    value={this.state.password}
                                                    onChange={this.handleChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Container>
                            <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                                <Card style={styles.CardWithoutBoxes}>
                                    <CardContent style={styles.ElementWithoutPadding}>
                                        <Grid container >
                                            <Grid style={{ width: "100%" }}>
                                                <TextField
                                                    style={{ width: "100%" }}
                                                    id="repeatPassword"
                                                    label={t("RepeatPassword")}
                                                    type="password"
                                                    value={this.state.RepeatPassword}
                                                    onChange={this.handleChange}
                                                />
                                            </Grid>
                                            <Grid>
                                                <Typography id="password-helper-text" 
                                                    style={(this.state.password !== this.state.repeatPassword) ? 
                                                        { color: "rgba(255, 1, 1, 0.54)" } : {color: "transparent"}
                                                    }>
                                                    {t("PasswordDontMatch")}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Container>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleClose()} >
                            {t("Cancel")}
                        </Button>
                        <Button 
                            disabled={this.state.password.trim() === "" || (this.state.password !== this.state.repeatPassword)}
                            onClick={() => this.handleChangePassword()} color="primary" >
                            {t("Update")}
                        </Button>
                    </DialogActions>
                </Dialog>
                {this.state.isLoading &&
                    <Spinner open={true}/>
                }
            </Fragment>
            
        )
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: {
            resetpasswordUser: bindActionCreators(usersActions.resetpasswordUser, dispatch),
            notificationSuccess: bindActionCreators(notifications.notificationSuccess, dispatch),
        }
    };
  }

export default compose(withTranslation('common'),
connect(
    null,
    mapDispatchToProps
))(withRouter(PasswordModal));

