import React, { Component } from 'react';
import { WorkSiteList } from "../common/Lists/workSiteList";
import { connect } from 'react-redux';
import *  as workSiteActions from '../../redux/actions/workSiteActions';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import CancelPromisesHOC   from "../../helpers/cancelPromisesHOC";
import { withRouter } from "react-router-dom";
import {setCurrentModule} from "../../utils/navigationUtil";
import *  as navigationActions from '../../redux/actions/navigationActions';
import *  as customersActions from '../../redux/actions/customersActions';
import *  as catalogsActions from '../../redux/actions/catalogsActions';

export class WorkSites  extends Component {
    componentDidMount(){
        this.props.actions.setModule(setCurrentModule('/worksites'));
    }

    render() {
        return (
            <WorkSiteList {...this.props} />
        )
    };
}

function mapStateToProps(state) {
    return {
        workSites : state.workSites,
        moduleInfo: state.navigation.module,
        customers : state.customers,
        customer : state.customer,
        catalog : state.catalog
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            readWorkSites: bindActionCreators(workSiteActions.readWorkSites, dispatch),
            updateWorkSite: bindActionCreators(workSiteActions.updateWorkSite, dispatch),  
            readCustomers: bindActionCreators(customersActions.readCustomers, dispatch),
            setModule: bindActionCreators(navigationActions.setModule, dispatch),
            readCatalog: bindActionCreators(catalogsActions.readCatalog, dispatch),
        }
    };
  }

export default compose(withTranslation('common'),
CancelPromisesHOC,
connect(
    mapStateToProps,
    mapDispatchToProps,
))(withRouter(WorkSites));