import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function availableTenantsReducer(state = initialState.availableTenants, action) {
  if (action.type === types.GET_ALL_AVAILABLE_TENANTS_SUCCESS) {
            return action.tenants;
    }
    else {
        return state;
    }
}
