import * as types from "./actionTypes";
import * as CustomerApi from "../../api/customersApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";
import * as constants from "../../components/common/constants";
import * as ContactsApi from "../../api/contactsApi";

export function readCustomersSuccess(customers) {
    return { type: types.LOAD_CUSTOMER_SUCCESS, customers };
}

export function readCustomers(filter, sort, page, paymentType, accountGroup, cancelToken) {
    return async function(dispatch) {
        dispatch(beginApiCall());

        let value = [];
      
        await CustomerApi
        .ReadCustomers(filter, sort, page, paymentType, accountGroup, cancelToken)  
        .then(result => {
            value = result;
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (error.message !== constants.cancelHttpRequest)
            {
                dispatch(notifications.notificationError("ErrorloadingCustomers"));
                throw new Error(error);
            }
        });

        var dict = {};
        value.map(element => {
            if (element.contactId !== "") {
                dict[element.contactId] = "";
            }
            return element;
        })
        var batch = [];
        for(var key in dict) {
            batch.push(key)
        }
        var sendData = {
            batch : batch
        }
        
        await ContactsApi
        .ReadContactBatch(sendData, cancelToken)  
        .then(result => {
            var valueDict = result;
            if (Object.keys(valueDict).length > 0) {
                for (var x = 0; x < value.length; x++) {
                    var name = valueDict[value[x].contactId];
                    if (name) {
                        value[x].contact = name;
                    }
                    else {
                        value[x].contact = "";
                    }
                }
            }
            dispatch(readCustomersSuccess(value));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (error.message !== constants.cancelHttpRequest) {
                dispatch(notifications.notificationError("ErrorloadingContacts"));
                throw new Error(error);
            }
        });

    };
} 


export function readCustomerSuccess(customer) {
    return { type: types.READ_CUSTOMER_SUCCESS, customer };
}

export function readCustomer(id) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return CustomerApi
        .ReadCustomer(id)  
        .then(result => {
            dispatch(readCustomerSuccess(result));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorloadingCustomer"));
            throw new Error(error);
        });
    };
} 

export function updateCustomerSuccess(customer) {
    return { type: types.UPDATE_CUSTOMER_SUCCESS, customer };
}

export function updateCustomer(model) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return CustomerApi
        .updateCustomer(model)  
        .then(result => {
            dispatch(updateCustomerSuccess(result));
            return result;
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorupdatingCustomer"));
            throw new Error(error);
        });
    };
} 

