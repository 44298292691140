import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function navigationReducer(state = initialState.navigation, action) {
    if (action.type === types.SET_MODULE) {
        //console.log(action.modules)
        const navigation = {
            ...state,
            module: action.modules
        }
        return navigation;
    }
    else if(action.type === types.READ_SUGAR_PAGE_HISTORY_SUCCESS){
        const navigation = {
            ...state,
            sugarHistory: action.sugarHistory
        }
        return navigation;
    }    
    else if(action.type === types.SHOW_MODULE_TITLE){
        const navigation = {
            ...state,
            showModuleTitle: action.show
        }
        return navigation;
    }
    if (action.type === types.SET_USERNAME) {
        const navigation = {
            ...state,
            username: action.name
        }
        return navigation;
    }
    else {
        return state;
    }
}
