import React, { Component, Fragment } from 'react';
import "../../../index.css";
import { withTranslation } from 'react-i18next';
import * as constants from "../constants";
import FilterHelper from "../../../helpers/filterHelper"; 
import { SearchList } from "../Lists/searchList";
import axios from 'axios';
import Spinner from './../CircularSpinner';
import { Catalogs } from "../../common/catalogs";
import {
    Container,
    Card,
    CardContent,
    Grid
} from '@material-ui/core';
import * as styles from '../styles';
import StatefulSessionHelper from "../../../helpers/statefulSessionHelper";

var isMounted = false;

export class CustomerList  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows : [],
            filterOptions : [],
            builderList : [],
            isLoading : false,
            isRowStyle : false,
            paymentType : "",
            accountGroup : "",
            filter: "",
            cleanData : false,
            expandedSearch: false,
            idSaveLocal: this.props.idSave !== undefined ? this.props.idSave : constants.customerFilter
        }
    }

    componentWillUnmount() {
        isMounted = false;
        this.props.cancelPromises();
        
    }

    async componentDidMount() {
        this.setFilter();
        var searchValue = StatefulSessionHelper.getSearchValue(this.state.idSaveLocal + this.props.user.profile.sub);
        if (searchValue === null) {
            searchValue = "";
        }
        
        await this.setState({
            filter : searchValue
        })
    
        var dataValues = StatefulSessionHelper.getValues(this.state.idSaveLocal + this.props.user.profile.sub);
       
        if (dataValues !== null) {
            var expand = false;
            if (dataValues.paymentType !== "" || dataValues.accountGroup !== "") {
                expand = true;
            }
            this.setState({
                paymentType : dataValues.paymentType,
                accountGroup : dataValues.accountGroup,
                expandedSearch: expand
           }, function() {
              this.reloadData();
           })
        }
        else {
            this.reloadData();
        }
    }

    reloadData = () => {
        var data = FilterHelper.getfilterOptions(this.state.idSaveLocal);
        this.loadRecords(this.state.filter, data.sort,1);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.customers !== this.props.customers) {
            this.setState({
                rows : this.props.customers,
            });
        }

   
    }
    
    setFilter = () => {
        this.setState({
            idSave : this.props.idSave,
        });

        var options = [
            FilterHelper.createFilterOption("customerNumber", true, true, 2),
            FilterHelper.createFilterOption("name", true, false, 3, constants.textAlign.left, true, null, "Name"),
            FilterHelper.createFilterOption("vatCode", true, false, 2, constants.textAlign.left, true, null, "VatCode"),
            FilterHelper.createFilterOption("eInvoiceAddress", false, false, 3, constants.textAlign.left, true, null, "PaymentInfoModel.EInvoiceAddress"),
            FilterHelper.createFilterOption("eInvoiceOperator", false, false, 3, constants.textAlign.left, true, null, "PaymentInfoModel.EInvoiceOperator"),
            FilterHelper.createFilterOption("paymentType", false, false, 3, constants.textAlign.left, false),
            FilterHelper.createFilterOption("accountGroup", false, false, 3, constants.textAlign.left, false),
            FilterHelper.createFilterOption("address", false, false, 3, constants.textAlign.left, true, null, "InvoiceAddress.Address"),
            FilterHelper.createFilterOption("addressLine2", false, false, 3, constants.textAlign.left, true, null, "InvoiceAddress.AddressLine2"),
            FilterHelper.createFilterOption("postalCode", false, false, 3, constants.textAlign.left, true, null, "InvoiceAddress.PostalCode"),
            FilterHelper.createFilterOption("city", true, false, 2, constants.textAlign.left, true, null, "InvoiceAddress.City"),
            FilterHelper.createFilterOption("country", false, false, 2, constants.textAlign.left, true, null, "InvoiceAddress.Country"),
            FilterHelper.createFilterOption("contact", true, true, 3, constants.textAlign.left, false, "CustomerContact"),
        ];

        var filterOptions = FilterHelper.setFilter(this.state.idSaveLocal, options, false);
        this.setState({
            filterOptions : filterOptions.filter,
            isRowStyle : filterOptions.isRowStyle
        })
    }

    showFilterFields = (row, rowFilter) => {
        return (
            <Fragment>
                {rowFilter.id === "customerNumber" &&
                    row.customerNumber
                }
                {rowFilter.id === "name" &&
                    row.name
                }
                {rowFilter.id === "vatCode" &&
                    row.vatCode
                }
                {rowFilter.id === "eInvoiceAddress" &&
                    row.paymentInfoModel.eInvoiceAddress
                }
                {rowFilter.id === "eInvoiceOperator" &&
                    row.paymentInfoModel.eInvoiceOperator
                }
                {rowFilter.id === "paymentType" &&
                    row.paymentInfoModel.paymentType
                }
                {rowFilter.id === "accountGroup" &&
                    row.paymentInfoModel.accountGroup
                }
                {rowFilter.id === "address" &&
                    row.invoiceAddress.address
                }
                {rowFilter.id === "addressLine2" &&
                    row.invoiceAddress.addressLine2
                }
                {rowFilter.id === "postalCode" &&
                    row.invoiceAddress.postalCode
                }
                {rowFilter.id === "city" &&
                    row.invoiceAddress.city
                }
                {rowFilter.id === "country" &&
                    row.invoiceAddress.country
                }
                {rowFilter.id === "contact" &&
                    row.contact
                }
            </Fragment>
        )
    }

    handleCatalogs = (id, value ) => {
        this.setState({
            [id]: value,
            cleanData: true
        }, function() {
            this.setSession();
        });
    }

    setSession = () => {
        var data = {
            paymentType : this.state.paymentType,
            accountGroup : this.state.accountGroup,
        }
       
       StatefulSessionHelper.setValues(this.state.idSaveLocal + this.props.user.profile.sub, data);
       this.reloadData();
    }

    changeFilter = (filter) => {
        this.setState({
            filter : filter
        })
    }

    loadRecords = async (filter, sort, page) => {
       
        isMounted = true;

        this.props.cancelPromises();

        var isLoaded = false;
        if (!this.props.isArray) {

            
            if (!this.props.isLoading) {
                this.setState({
                    isLoading : true
                })
            }

            var data = FilterHelper.getfilterOptions(this.state.idSaveLocal);
            this.setState({
                filter : filter,
                filterOptions : data.filter,
                isRowStyle: data.isRowStyle 
            })

            const cancelToken = axios.CancelToken.source();

            await this.props.cancellablePromise(
               this.props.actions.readCustomers(
                   filter, data.sort, page, 
                   this.state.paymentType, 
                   this.state.accountGroup, 
                   cancelToken.token), cancelToken
            )
            .then(() => {
                isLoaded = true;
            })
            .catch((error) => {
                if (error.message === constants.cancelHttpRequest || error.message === "[object Object]") {
                    isMounted = false;
                }
            })
            .finally(result =>{
                if (isMounted) {
                    this.setState({
                        isLoading : false,
                        cleanData: false
                    })
                }
            })
        }
        else {
            this.props.loadRecords(filter, sort);
        }

        return isLoaded;
    }
    
    handleDeleteRow = async (record) => {
        if (!this.props.isLoading) {
            this.setState({
                isLoading : true
            })
        }

        record.state = constants.Deleted;
        var data = {...record};

        if (this.props.isTesting) {
            this.setState({
                recordTest : data
            });
        }
      
        var isDeleted = false;
        await this.props.actions.updateCustomer(data)
        .then(() => {
            isDeleted = true;
        })
        .catch(() => {})
        .finally(() =>{
            this.setState({
                isLoading : false
            })
        })
        return isDeleted;
    }

    handleJsonFile = async () => {

    }

    render() {
        const { t } = this.props;
        return (
            <Fragment>
                {this.state.isLoadingMassive &&
                    <Spinner open={true}/>
                }
                <SearchList 
                    {...this.props}
                    rows={this.state.rows}
                    showFilterFields={this.showFilterFields}
                    filterOptions={this.state.filterOptions}
                    isRowStyle={this.state.isRowStyle}
                    handleDeleteRow={this.handleDeleteRow}
                    loadRecords={this.loadRecords}
                    handleJsonFile={this.handleJsonFile}
                    changeFilter={this.changeFilter}
                    cleanData={this.state.cleanData}
                    
                    
                    backButton={this.props.backButton !== undefined ? this.props.backButton : false}
                    onlySelect={this.props.onlySelect !== undefined ? this.props.onlySelect :  false}
                    addButton={this.props.addButton !== undefined ? this.props.addButton : true}
                    deleteButton={this.props.deleteButton !== undefined ? this.props.deleteButton  : true}
                    editButton={this.props.editButton !== undefined ? this.props.editButton : true}
                    isToUp={this.props.isToUp !== undefined ? this.props.isToUp : true}
                    title={t(this.props.title !== undefined ? this.props.title : "Customers")} 
                    pathBackFromAddEdit={this.props.pathBackFromAddEdit !== undefined ? this.props.pathBackFromAddEdit : "/customersList/"}
                    pathAddEdit={this.props.pathAddEdit !== undefined ? this.props.pathAddEdit : "/customers/"}
                    isLoading={this.state.isLoading}
                    handleOneSelect={this.props.handleOneSelect}
                    handleSearchOneSelect={this.props.handleSearchOneSelect}
                    multiSelect={this.props.multiSelect}
                    productGroupFilter={this.props.hideFilters ? 
                        undefined
                        :
                        <Grid container style={styles.ElementWithoutPadding}>
                            <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                                <Card style={styles.CardWithoutBoxes}>
                                    <CardContent style={{...styles.ElementWithoutPadding, ...{padding: "0px"}}}>
                                        <Catalogs {...this.props} catalogConstant={constants.catalogs.PaymentType} catalogId={"paymentType"} 
                                            handleCatalogs={this.handleCatalogs} elementId={this.state.paymentType} canAdd={false} label={t("PaymentType")}
                                        ></Catalogs>
                                    </CardContent>
                                </Card>
                            </Container>
                            <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                                <Card style={styles.CardWithoutBoxes}>
                                    <CardContent style={{...styles.ElementWithoutPadding, ...{padding: "0px"}}}>
                                        <Catalogs {...this.props} catalogConstant={constants.catalogs.AccountGroup} catalogId={"accountGroup"} 
                                            handleCatalogs={this.handleCatalogs} elementId={this.state.accountGroup} canAdd={false} label={t("AccountGroup")}
                                        ></Catalogs>
                                    </CardContent>
                                </Card>
                            </Container>
                        </Grid>
                    }
                
                    isArray={this.props.isArray}
                    handleAdd={this.props.handleAdd}
                    refreshDetails={this.props.refreshDetails}
                    builderList={this.state.builderList}
                    idSave={this.props.idSave !== undefined ? this.props.idSave : constants.customerFilter  }
                    handleCloseProduct={this.props.handleCloseProduct}
                    history={this.props.history}
                    expandedSearch={this.state.expandedSearch}


                />
            </Fragment>    
        )
    };
}

export default withTranslation('common')(CustomerList);
