export const setRedirectUri = (uri) => {
    localStorage.setItem("redirect_uri", uri);
}

export const getRedirectUri = () => {
    const uri = localStorage.getItem("redirect_uri");
    return uri ?? "/";
}

export const clearRedirectUri = (uri) => {
    localStorage.removeItem("redirect_uri");
}