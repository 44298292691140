import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import NumberField from "../../NumberField";
import {
    Card,
    CardContent,
    Container,
} from '@material-ui/core';
import * as styles from "../../styles";

export class ProductRegisterRow  extends Component {

    constructor(props) {
        super(props);
        this.state = {
            row : this.props.row
        }
    }

    componentDidMount() {
        if (this.props.quantityInit) {
            this.setState({
                row: {...this.props.row, quantity : this.props.quantityInit},
            });
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.row !== this.props.row) {
            this.setState({
                row: this.props.row,
            });
        }
   }

    handleChange = (e, id) => {
       
        let { value } = e.target;
        const { idx } = this.props;

        this.setState(prevState => ({
            ...prevState,
            row : {
                ...prevState.row,
                [id]: value
            }
        }), function() {
            this.props.handleRowChange(value, id, idx, this.props.isBuilder);
        });

       
    }

    setValueInit = (value, id) => {
        this.handleChange({
            target : {
                value : value
            }
        }, id)
    }

    render() {
        const {t, i18n} = this.props;
        return (
            <Container maxWidth="sm" style={{...styles.ElementWithoutPadding, ...this.props.style}}>
                <Card style={styles.CardWithoutBoxes}>
                    <CardContent style={styles.ElementWithoutPadding}>
                        <NumberField
                            id={"quantity" + this.props.idx}
                            label={t("Quantity")}
                            value={this.state.row.quantity}
                            onChange={(e) => this.handleChange(e, "quantity")}
                            lang={i18n.language}
                            valueInit={this.props.quantityInit}
                            setValueInit={this.setValueInit}
                            propertyName={"quantity"}
                            isBuilder={this.props.isBuilder}
                        />  
                    </CardContent>
                </Card>
            </Container>
        )
    };
}

export default withTranslation('common')(ProductRegisterRow);
