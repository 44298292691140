export default class FileHelper {

    static createJSON = (result, fileName, document) => {
        const fileData = JSON.stringify(result);
        const blob = new Blob([fileData], {type: "text/plain"});
        const url = URL.createObjectURL(blob);
        window.open(url);
        /*
        const link = document.createElement('a');
        link.download = fileName;
        link.href = url;
        link.click();*/
    }
}