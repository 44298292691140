import React from 'react';
import useCancellablePromise from './cancelPromises';

function CancelPromisesHOC(Component) {  
  function WrappedComponent(props) {
    const  { cancellablePromise, cancelPromises } = useCancellablePromise();
    
    return <Component cancellablePromise={cancellablePromise} cancelPromises={cancelPromises} {...props} />;
  }
    
  return WrappedComponent;
}

export default CancelPromisesHOC;



