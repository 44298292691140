import React from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import * as constants from "../common/constants";
import PreviewModal from "../../components/common/previewModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { Typography, IconButton } from "@material-ui/core";

const styles = (theme) => ({
  navbar: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 100,
    background: "#eeeeee",
    height: (props) => props.height,
  },
  spacer: {
    flex: 1,
  },
  moduletext: {
    fontSize: "1.2rem",
    marginTop: "3px",
    marginLeft: "1rem",
    cursor: "pointer",
    textDecoration: "unset",
  },
  downloadIcon: {
    verticalAlign: "middle",
    marginLeft: "-15px",
  },
  fileText:{
    paddingLeft: '2em',
    textIndent:'-2em'
  }
});
export class UploadPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preview: null,
      imgIndex: 0,
      showModal: false,
      rotation: 0,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.rotateImage = this.rotateImage.bind(this);
  }

  openModal(index) {
    this.setState({
      showModal: true,
      imgIndex: index,
    });
  }

  closeModal() {
    this.setState({
      showModal: false,
      imgIndex: 0,
      rotation: 0,
    });
  }
  rotateImage() {
    this.setState((prevState) => {
      return {
        rotation: prevState.rotation + 90 <= 270 ? prevState.rotation + 90 : 0,
      };
    });
  }
  downloadFile() {
    window.open(this.state.preview);
  }

  componentDidMount() {
    if (!!this.props.newBlob) {
      this.setState({ preview: URL.createObjectURL(this.props.file) });
    } else {
      this.props
        .BlobActions(
          this.props.file.documentId + "." + this.props.file.fileExtension,
          constants.blobPermissions.Read
        )
        .then((blob) => {
          this.setState({ preview: blob });
        });
    }
  }

  render() {
    const {classes, file, blobType} = this.props;
    return blobType ? (
      
      <div className={blobType.toLowerCase() === 'file' ? classes.fileText : ''}>
        
        {!!this.props.showDownload && file.filename !== "-" && (
          
          <a className="donwload-link" 
              download 
              target="_blank" 
              rel="noopener noreferrer" 
              href={this.state.preview}>
            <IconButton className={classes.downloadIcon}>
              <FontAwesomeIcon icon={faIcons["faDownload"]} />
            </IconButton>
          </a>
        )}
        {blobType.toLowerCase() === "video" && (
          <div className="preview-card">
            <video
              controls
              disablePictureInPicture
              controlsList="nodownload noremoteplayback disablePictureInPicture"
              src={this.state.preview}
              style={{ width: "100%", height: "auto" }}
            ></video>
          </div>
        )}
        {blobType.toLowerCase() === "audio" && (
          <div style={{ display: "inline", verticalAlign: "middle" }}>
            <audio
              controlsList="nodownload noremoteplayback"
              controls
              src={this.state.preview}
              className={classes.audio}
            ></audio>
          </div>
        )}
        {blobType.toLowerCase() === "image" && (
          <div className="preview-card">
            <img
              className="preview-thumbnail"
              src={this.state.preview}
              alt=""
              style={{ width: "100%", height: "auto" }}
            />

            <FontAwesomeIcon
              className="preview-card-icon-open"
              onClick={() => this.openModal(1)}
              icon={faIcons["faExpand"]}
            />
          </div>
        )}
        {blobType.toLowerCase() === 'file' &&(

            <Typography style={{ display: "inline", width: "90%" }}>
              {!this.props.newBlob  ? (file.filename !== "-" ? file.filename + "." + this.props.file.fileExtension: "")
                : file.name}
            </Typography>
          )}
        <PreviewModal
          isOpen={this.state.showModal}
          onClick={this.closeModal}
          rotateImage={this.rotateImage}
          rotation={this.state.rotation}
          width={window.innerWidth}
          height={window.innerHeight}
          src={this.state.preview}
        />
      </div>
    ) : (
      ""
    );
  }
}

export default compose(
  withTranslation("common"),
  withStyles(styles)
)(UploadPreview);
