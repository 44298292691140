import React from 'react';
import {
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({

  toggler: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '24px',
    width: '30px',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: '3px',
    boxSizing: ' border-box',
    marginLeft: '10px',
    marginTop: '7px',
    '&:focus': {
      outline: 'none'
    }
  },
  togglerLine: {
    width: '30px',
    height: '2px',
    background: 'black'

  }

}));
export function SideBarToggle(props) {
  const classes = useStyles();
  return (
    <button className={classes.toggler} onClick={props.click}>
      <div className={classes.togglerLine}></div>
      <div className={classes.togglerLine}></div>
      <div className={classes.togglerLine}></div>
    </button>
  );
}

export default (SideBarToggle);
