import * as types from "./actionTypes";
import * as productUnitApi from "../../api/productUnitApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";
import * as constants from "../../components/common/constants";

export function readProductUnitsSuccess(productUnits) {
    return { type: types.LOAD_PRODUCTUNIT_SUCCESS, productUnits };
}

export function readProductUnits(filter, sort, page, cancelToken) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return productUnitApi
        .ReadProductUnits(filter, sort, page, cancelToken)  
        .then(productUnits => {
            dispatch(readProductUnitsSuccess(productUnits));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest) {
                dispatch(notifications.notificationError("ErrorloadingProductUnits"));
                throw new Error(error);
            }
        });
    };
} 

export function readProductUnitSuccess(productUnit) {
    return { type: types.READ_PRODUCTUNIT_SUCCESS, productUnit };
}

export function readProductUnit(id) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return productUnitApi
        .ReadProductUnit(id)  
        .then(productUnit => {
            dispatch(readProductUnitSuccess(productUnit));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorloadingProductUnit"));
            throw new Error(error);
        });
    };
} 

export function updateProductUnitSuccess(productUnit) {
    return { type: types.UPDATE_PRODUCTUNIT_SUCCESS, productUnit };
}

export function updateProductUnit(productUnit) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return productUnitApi
        .updateProductUnit(productUnit)  
        .then(productUnit => {
            dispatch(updateProductUnitSuccess(productUnit));
            return productUnit;
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (constants.UniqueKeyConstraintViolation === error ||
                constants.UniqueKeyConstraintViolation === error.message) {
                dispatch(notifications.notificationError("ItemCodeAlreadyExist"));
            }
            else {
                dispatch(notifications.notificationError("ErrorupdatingProductUnit"));
            }
            throw new Error(error);
        });
    };
} 

