import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function productRegisterReducer(state = initialState.productRegister , action) {
    if (action.type === types.READ_PRODUCTREGISTER_SUCCESS)
    {
        return action.productRegister
    }
    else {
        return state;
    }
}
