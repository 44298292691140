import React, { Component }  from 'react';
import {
    Dialog
} from '@material-ui/core';
import * as constants from "./constants";
import Contact from "../contacts/contact";

export class DialogAllOptions extends Component {
    render() {
        return (
            <Dialog id="dialogQuery" fullScreen open={this.props.openModify} onClose={this.props.handleClose}>
                <div style={{paddingLeft: "32px", paddingRight: "32px"}}>
                    {this.props.typeModify === constants.contacts &&
                        <Contact {...this.props}
                            isAllOptions={this.props.isAllOptions}
                            selectId={this.props.selectId}
                            handleCloseModify={this.props.handleCloseModify} 
                            handleBackModify={this.props.handleBackModify}
                            stayInMainWindowIsAllOptions={this.props.stayInMainWindowIsAllOptions} />
                    }
                </div>
            </Dialog>
        )
    }
}

export default (DialogAllOptions);