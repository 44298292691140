import * as types from "./actionTypes";
import * as productRegisterApi from "../../api/productRegisterApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";
import * as constants from "../../components/common/constants";
import * as productUnitApi from "../../api/productUnitApi";

export function readProductsRegisterSuccess(productsRegister) {
    return { type: types.LOAD_PRODUCTREGISTER_SUCCESS, productsRegister };
}

export function readProductsRegister(filter, sort, page, productGroup, unit, cancelToken) {
    return async function(dispatch) {
        dispatch(beginApiCall());

        let value = [];
      
        await productRegisterApi
        .ReadProductsRegister(filter, sort, page, productGroup, unit, cancelToken) 
        .then(productsRegister => {
            value = productsRegister;
                //dispatch(readProductsRegisterSuccess(value));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (error.message !== constants.cancelHttpRequest)
            {
                dispatch(notifications.notificationError("ErrorloadingProductsRegister"));
                throw new Error(error);
            }
        });

        var dict = {};
        value.map(element => {
            if (element.unit !== "") {
                dict[element.unit] = "";
            }
            return element;
        })
        var batch = [];
        for(var key in dict) {
            batch.push(key)
        }
        var sendData = {
            batch : batch
        }
       
        await productUnitApi
        .ReadProductUnitsBatch(sendData, cancelToken)  
        .then(productUnitDict => {
            var valueDict = productUnitDict;
            if (Object.keys(valueDict).length > 0) {
                for (var x = 0; x < value.length; x++) {
                    var unit = valueDict[value[x].unit];
                    if (unit) {
                        value[x].unitName = unit;
                    }
                    else {
                        value[x].unitName = "";
                    }
                }
            }
            dispatch(readProductsRegisterSuccess(value));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (error.message !== constants.cancelHttpRequest) {
                dispatch(notifications.notificationError("ErrorloadingProductUnits"));
                throw new Error(error);
            }
        });

    }
} 

export function readProductRegisterSuccess(productRegister) {
    return { type: types.READ_PRODUCTREGISTER_SUCCESS, productRegister };
}

export function readProductRegister(id) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return productRegisterApi
        .ReadProductRegister(id)  
        .then(productRegister => {
            dispatch(readProductRegisterSuccess(productRegister));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorloadingProductRegister"));
            throw new Error(error);
        });
    };
} 

export function updateProductRegisterSuccess(productRegister) {
    return { type: types.UPDATE_PRODUCTREGISTER_SUCCESS, productRegister };
}

export function updateProductRegister(productRegister) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return productRegisterApi
        .updateProductRegister(productRegister)  
        .then(productRegister => {
            dispatch(updateProductRegisterSuccess(productRegister));
            return productRegister;
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (constants.UniqueKeyConstraintViolation === error ||
                constants.UniqueKeyConstraintViolation === error.message) {
                dispatch(notifications.notificationError("ItemCodeAlreadyExist"));
            }
            else {
                dispatch(notifications.notificationError("ErrorupdatingProductRegister"));
            }
            throw new Error(error);
        });
    };
} 

