import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import *  as workOrderActions from '../../redux/actions/workOrderActions';
import *  as workSiteActions from '../../redux/actions/workSiteActions';
import *  as contactsActions from '../../redux/actions/contactsActions';
import *  as catalogsActions from '../../redux/actions/catalogsActions';
import *  as productRegisterActions from '../../redux/actions/productRegisterActions';
import *  as productGroupActions from '../../redux/actions/productGroupActions';
import *  as productUnitActions from '../../redux/actions/productUnitActions';
import { bindActionCreators, compose } from 'redux';
import {
    Card,
    CardContent,
    Container,
    Grid,
    Typography,
} from '@material-ui/core';
import UpTextField from '../common/upTextField';
import * as styles from '../common/styles.js';
import "../../index.css";
import Spinner from '../common/CircularSpinner';
import { withTranslation } from 'react-i18next';
import * as constants from "../common/constants";
import initialState from "../../redux/reducers/initialState";
import { Breadcrumb } from "../common/Breadcrumb";
import CancelPromisesHOC   from "../../helpers/cancelPromisesHOC";
import { Cancel } from '@material-ui/icons';
import { Save } from '@material-ui/icons';
import { withRouter } from "react-router-dom";
import { SearchOne } from "../common/SearchOne";
import {ScrollableTabs} from '../common/ScrollableTabs';
import { BottomBar } from "../common/BottomBar";
import NumberField from '../common/NumberField';
import UpDatePicker  from '../common/upDatePicker';
import * as notifications from "../../redux/actions/notificationsActions";
import { Address } from "../common/address";
import ContactsWorkSiteList from "../common/Lists/contactsWorksSiteList";
import { ProductsWorkOrderList } from "../common/Lists/productsWorkOrderList";
import { DiaryEntriesList } from "../common/Lists/diaryEntriesList";
import *  as blobsActions from '../../redux/actions/blobsActions';
import *  as realTimeActions from '../../redux/actions/realTimeActions';
import *  as workOrderDiaryEntryActions from '../../redux/actions/workOrderDiaryEntryActions';
import *  as workQueueActions from '../../redux/actions/workQueueActions';
import axios from 'axios';

var isMounted = false;

export class WorkOrder  extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            workOrderId: this.props.isComponent ? 
                         (this.props.isEditWorkOrder ?  this.props.numberWorkOrder : constants.New) : 
                         this.props.selectId ? this.props.selectId : this.props.match.params.workOrderId,
            isLoading : false,
            workOrder : initialState.workOrder,
            workSite: "",
            workSiteId: constants.EmptyGuid,
            rows: [],
            productRows: [],
            address: initialState.address,
            counterBasket: 0,
            conflict : false,
            propertiesChanged: initialState.workOrder,
            deleteRows: [],
            backroundProcess: false,
            openFileDialog:false,
            saving: false,
            workSiteRow: initialState.workSite,
            etag: this.props.etag,
        }
    }

    componentWillUnmount() {
        isMounted = false;
        this.props.cancelPromises();
    }

    async componentDidMount() {
        
        if (this.props.asignElementId) {
            this.setState({
                itemId : this.state.catalog.items.findIndex(element => element === this.props.elementId)
            });
        }

        if (this.state.workOrderId !== constants.New) {
            let deepClone = JSON.parse(JSON.stringify(this.state.propertiesChanged));
  
            delete deepClone.products;
            delete deepClone.diaryEntries;
            this.propertiesChanged(deepClone);

            this.setState({
                propertiesChanged : deepClone
            }, function() {
                this.loadData();
            })
        }
        else if (this.props.isAllOptions) {
            this.handleOneSelect(this.props.workOrder, "workOrderNumber");
        }
        
    }

    isObject = (val) => {
        if (val === null) { return false;}
        return ( (typeof val === 'function') || (typeof val === 'object') );
    }

    propertiesChanged = (data) => {
        for (const [key, value] of Object.entries(data)) { 
            if (this.isObject(value)) {
                this.propertiesChanged(data[key]);
            }
            else {
                data[key] = false;
            }
        }
    }

    propertiesChagedArray = (temp) => {
        for (var x = 0; x < temp.length; x++) {
            temp[x].activeRowEntry = false;
        }
    }

    loadData = async () => {
        if (!this.props.isTesting) {
            if (!this.state.backroundProcess) {
                this.setState({
                    isLoading : true,
                });
            }
        }
    
        isMounted = true;

        let cancelToken = axios.CancelToken.source();
        
        var promise1 = this.props.actions.readWorkOrder(this.state.workOrderId, cancelToken)
        .then(() => {
            if (this.props.workOrder.workSiteId !== constants.EmptyGuid) {
                this.props.actions.readWorkSite(this.props.workOrder.workSiteId, cancelToken)
                .then(() => {
                    this.handleOneSelect(this.props.workSite, "workSiteId");
                })
                .catch(() => { })
                .finally(result => {
                    if (!this.state.isTesting) {
                        this.setState({
                            backroundProcess: false,
                            isLoading: false,
                        });
                    }
                })
            }
            else {
                if (!this.state.isTesting) {
                    this.setState({
                        backroundProcess: false,
                        isLoading: false,
                    });
                }
            }})
        .catch(() => { })
        .finally(result => {
            if (isMounted) {
                if (!this.state.isTesting) {
                    this.setState({
                        backroundProcess: false,
                        isLoading: false,
                    });
                }
            }
        })
       
        await this.props.cancellablePromise(
            promise1
            , cancelToken
        )
        .then(() => {
        })
        .catch((error) => {
            console.log(error);
            if (error.message === constants.cancelHttpRequest || error.message === "[object Object]") {
                isMounted = false;
            }
         })
        .finally(result =>{
            if (isMounted) {
                this.setState({
                    backroundProcess: false,
                    isLoading : false,
                })
            }
        })
    }
   
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.workOrder !== this.props.workOrder) {

           var arrayProducts = [...this.props.workOrder.products]
           
           this.propertiesChagedArray(arrayProducts);
            
           var deepClone;
           
           if (this.state.conflict) {
               new Promise((resolve, reject) => {
                
                    deepClone = JSON.parse(JSON.stringify(this.props.workOrder));
                    delete deepClone.products;
                    delete deepClone.diaryEntries;
                
                    this.resolveConflictMain(this.state.propertiesChanged, deepClone); 

                    var currentRows = [...this.state.productRows];
                    this.resolveConflictArray(arrayProducts, currentRows);
            
                    this.setState({
                        workOrder : deepClone,
                        productRows : [...arrayProducts],
                        counterBasket: this.props.workOrder.products.length
                    }, function() {
                        if (this.state.saving) {
                            //this.save();
                        }
                        else {
                            this.setState({
                                conflict: false
                            })
                        }
                    });   
                })
                
            } 
            else {
                deepClone = this.props.workOrder
                for (var y = 0; y < arrayProducts.length; y++) {
                    arrayProducts[y].activeRowEntry = false; 
                }
                this.setState({
                    workOrder : deepClone,
                    productRows : [...arrayProducts],
                    counterBasket: this.props.workOrder.products.length
                 });
            }   
        }

        if (prevProps.workSite !== this.props.workSite) {
            this.setState({
                rows: this.props.workSite.contacts
            });
        }

        if (prevProps.realTimeMessage !== this.props.realTimeMessage) {
            if (!this.state.saving &&
                this.state.workOrderId !== constants.New &&
                this.props.realTimeMessage.collectionId === constants.collections.workOrder &&
                this.props.realTimeMessage.documentId === this.state.workOrderId &&
                this.props.realTimeMessage.etag !== this.state.etag)
            {
                this.setState({
                    backroundProcess: true,
                    conflict : true,
                }, function() {
                    this.loadData();
                })
            }
          
        }
    }

    resolveConflictMain = (propertyChanged, temp, parent) => {
        for (const [key, value] of Object.entries(propertyChanged)) { 
            if (this.isObject(value)) {
                this.resolveConflictMain(propertyChanged[key], temp, key);
            }
            else {
                if (value) {
                    if (parent !== undefined) {
                        temp[parent][key] = this.state.workOrder[parent][key];
                    }
                    else {
                        temp[key] = this.state.workOrder[key];
                    }
                }
            }
        }
    }

    resolveConflictArray = (rows, currentRows) => {
        var news= []; 
        var item;
        for (var x = 0; x < rows.length; x++) {
            item = this.findElement(currentRows, rows[x].id);
            if (item) {
                if (item.activeRowEntry) {
                    rows[x] = item
                }
            }
            else {
                news.push(rows[x]);
            }
        }

        for (var y = 0; y < this.state.deleteRows.length; y++) {
            var index = this.findIndexElement(news, this.state.deleteRows[y]);
            if (index > -1) {
                news.splice(index,1);
                index = this.findIndexElement(rows, this.state.deleteRows[y]);
                if (index > -1) {
                    rows.splice(index,1);
                }    
            }
        }

        for (var z = 0; z < currentRows.length; z++) {
            item = this.findElement(rows, currentRows[z].id);
            if (!item) {
                rows.push(currentRows[z]);
            }
        }


    }

    findElement = (rows, id) => {
        return rows.find(i => i.id === id)
    }

    findIndexElement = (rows, value) => {
        return rows.findIndex(e => e.id === value)
    }




    handleOneSelect = (row, id) => {
        if (row.id) {
            if (id === "workSiteId") {
                this.setState({
                    workSite: row.name,
                    workSiteId: row.id,
                    address: row.address,
                    rows: row.contacts,
                    workSiteRow: row
                });
            }
 
            this.handleChange({
                target: {
                    id: id,
                    value: row.id
                }
            });
        }

    }


    cleanFilter = (id) => {
        if (id === "workSiteId") {
            this.setState({
                workSite: "",
                workSiteId: constants.EmptyGuid,
                address : initialState.address,
                rows :[],
                workSiteRow: initialState.workSite
            });
        }
  
        this.setState(prevState => ({
            ...prevState,
            propertiesChanged: {
                ...prevState.propertiesChanged,
                [id]: false,
            },
        }));
  
        this.handleChange({
            target: {
                id: id,
                value: constants.EmptyGuid
            }
        });

    }
    
    handleChange = (event) => {
        const { id, value } = event.target;
      
        this.setState(prevState => ({
            ...prevState,
            workOrder: {
                ...prevState.workOrder,
                [id]: value,
            }
        }));

        if (this.props.workOrder[id] !== value ) {
            this.setState(prevState => ({
                ...prevState,
                propertiesChanged: {
                    ...prevState.propertiesChanged,
                    [id]: true,
                },
            }));
        }
    }

    handleDateTimeChange = (event) => {
      
        const { id, value } = event.target;
        this.setState(prevState => ({
            ...prevState,
            workOrder: {
                ...prevState.workOrder,
                agreedInterval : {
                    ...prevState.workOrder.agreedInterval,
                    [id]: value,
                }
            }
        }));

        if (this.props.workOrder.agreedInterval[id] !== value ) {
            this.setState(prevState => ({
                ...prevState,
                propertiesChanged: {
                    ...prevState.propertiesChanged,
                    agreedInterval : {
                        ...prevState.propertiesChanged.agreedInterval,
                        [id]: true,
                    }
                },
            }));
        }

    }


    back = () => {
        if (this.props.isComponent) {
            this.props.handleCloseWorkOrder();
        }
        else {
            if (this.props.isAllOptions) {
                this.props.handleBackModify();
            }
            else {
                var path = "/workOrders";
    
                if (this.props.isTesting) {
                    this.setState({
                        backPathTest: path,
                    });
                }
        
                this.props.history.push(path);
            }
        }
    }

    save = async () => {

        if (!this.props.isLoading) {
            this.setState({
                isLoading: true,
            });
        }
    
        var data = {...this.state.workOrder};
        if (this.state.workOrderId === constants.New) {
            data.state = constants.Added;
        }
        else {
            data.state = constants.Modified;
      
            this.setState({
                saving: true,
            });
        }

        if (this.props.isTesting) {
            this.setState({
                recordTest: data,
            });
        }
        
        data.products = [...this.state.productRows];
        
        var continueBack = true;
        var conflict = false;
        var id = undefined;
      
        await this.props.actions.updateWorkOrder(data)
        .then((result) => {
            if (this.state.workOrderId === constants.New) {
                id = result;
            }
            else {
                if (result === "PreconditionFailed") {
                    conflict = true;
                }
            }
        })
        .catch(() => {
            continueBack = false;
        })
        .finally(() =>{
            this.setState({
                isLoading: false,
            });
        })

        if (conflict) {
            this.setState({
                conflict : true,
            }, function() {
                this.loadData();
            })
        }
        else {
            if (continueBack) {
                if (id !== undefined) 
                {
                    data.id = id;
                    data.workOrderNumber =  id;
                   
                    this.props.actions.notificationSuccess(this.props.t("workOrderNumber") + ": " + id, true);
                }
                
                if (this.props.isComponent) {
                    this.props.handleAddWorkOrder(data, this.state.workSiteRow);
                }
                else {
                    this.back();
                }
            }
            else {
                this.setState({
                    saving: false,
                });
            }
        }
        
    }

    slider = () => {
        const { t } = this.props;
        var tabs = 
        [
            {title: t("GeneralInfo"), item: this.generalInfo()},
            {title: t("Products"), item: this.productsWorkOrder()},
        ]
        if (this.state.workOrderId !== constants.New) {
            tabs.push(
                {title: t("WorkDiary"), item: this.diaryEntries()},
            )
        }

        return (
            <Fragment>
                {this.props.isAllOptions &&
                    <Breadcrumb 
                        title={t("workOrder")} 
                        backButton={this.props.isAllOptions ? this.props.isAllOptions : false} 
                        isAllOptions={this.props.isAllOptions}
                    />
                }
                <ScrollableTabs
                    style={this.props.isAllOptions ? {marginTop:'0px'} : undefined}
                    tabs={tabs}>
                </ScrollableTabs>
            </Fragment>
           
        )
    };

    refreshDetails = (rows) => {
        this.setState(prevState => ({
            ...prevState,
            rows: rows
        }));
    } 

    refreshProducts = (rows, deleteRows, idInserted) => {
        
        this.setState(prevState => ({
            ...prevState,
            productRows : rows
        }));

        if (deleteRows !== undefined) {
            this.setState(prevState => ({
                ...prevState,
                deleteRows : deleteRows
            }));
        }
        else if (idInserted !== undefined) {
            var index = this.state.deleteRows.findIndex(e => e === idInserted);
            if (index > -1) {
                var deletedTemp = [...this.state.deleteRows];
                deletedTemp.splice(index,1);
                this.setState(prevState => ({
                    ...prevState,
                    deleteRows : deletedTemp
                }));
            }
        }
    }

    handleCatalogs = (id, value ) => {
        var event = {
            target : {
                id : id,
                value : value
            }
        }
        this.handleChange(event);

    }

    handleChangeInvoiceAddress = (event) => {
      
        const { id, value } = event.target;
        
        this.setState(prevState => ({
            ...prevState,
            workOrder: {
                ...prevState.workOrder,
                address : {
                    ...prevState.workOrder.address,
                    [id]: value,
                }
            },
        }));

    }

   
    sendMessage = (message) => {
        this.props.actions.sendMessage(message);
    }

    generalInfo = () => {
        const { t, i18n } = this.props;
        const { workOrder } = this.state;
        return (
            <Fragment>
                <Grid container style={styles.ElementWithoutPadding}>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <Grid container >
                                    <NumberField
                                        disabled={true}
                                        id={"workOrderNumber"}
                                        label={t("WorkOrderNumber")}
                                        value={workOrder.workOrderNumber}
                                        onChange={this.handleChange}
                                        lang={i18n.language}
                                    />
                                </Grid>
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
                <Grid container style={styles.ElementWithoutPadding}>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <UpTextField
                                    style={styles.inputElements}
                                    id="internalDescription"
                                    label={t("InternalDescription")}
                                    value={workOrder.internalDescription}
                                    onChange={this.handleChange} />
                            </CardContent>
                        </Card>
                    </Container>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <UpTextField
                                    i18n={i18n}
                                    style={styles.inputElements}
                                    id="invoiceDescription"
                                    label={t("InvoiceDescription")}
                                    value={workOrder.invoiceDescription}
                                    onChange={this.handleChange} />
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
                <Grid container style={styles.ElementWithoutPadding}>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <UpDatePicker
                                    includeTime={true}
                                    style={styles.inputElements}
                                    id="startTime"
                                    label={t("StartDate")}
                                    value={workOrder.agreedInterval.startTime}
                                    onChange={this.handleDateTimeChange} />
                            </CardContent>
                        </Card>
                    </Container>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <UpDatePicker
                                    style={styles.inputElements}
                                    includeTime={true}
                                    id="endTime"
                                    label={t("EndDate")}
                                    value={workOrder.agreedInterval.endTime}
                                    onChange={this.handleDateTimeChange} />
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
                <Grid container style={styles.ElementWithoutPadding}>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <div style={{marginTop:"8px"}}>
                                    <SearchOne
                                        {...this.props}
                                        id="workSiteId"
                                        label={t("WorkSiteId")}
                                        valueOne={this.state.workSite}
                                        handleOneSelect={(row) => this.handleOneSelect(row, "workSiteId")}
                                        cleanFilter={() => this.cleanFilter("workSiteId")}
                                        typeQuery={constants.workSites}
                                        isFullWidth={true}
                                    />
                                </div>
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
                
                <Address address={this.state.address} disabled={true}  t={this.props.t}></Address> 
                { this.ContactsWorkSite() }
            </Fragment>
        )
    }

    productsWorkOrder = () => {
        return (
            <div style={{marginBottom: "100px"}}>
                <ProductsWorkOrderList
                    {...this.props}
                    showtitle={false}
                    rows={this.state.productRows}
                    refreshDetails={this.refreshProducts}
                    hideSearch={true}
                    counterBasket={this.state.counterBasket}
                />
            </div>
            
        )

    }

    ContactsWorkSite = () => {
        const { t } = this.props;
        return (
            <div style={{marginBottom: "100px"}}>
                <Typography variant="h6" >{t("Contacts")}</Typography>
                
                <ContactsWorkSiteList
                    {...this.props}
                    showtitle={false}
                    rows={this.state.rows}
                    refreshDetails={this.refreshDetails}
                    hideSearch={true}
                    addButton={false}
                    notExpanded={true}
                />
            </div>
        )
    }

    diaryEntries = () => {
        return (
            <div style={{marginBottom: "100px"}}>
                <DiaryEntriesList
                    {...this.props}
                    handleAddFileDialog={this.handleAddFileDialog}
                    handleClose={this.CloseFileDialog}
                    open={this.state.openFileDialog}
                    saveDataEntry={this.saveDataEntry}
                    rows={this.state.filesRows}
                    BlobActions={this.props.actions.Blobs}
                    blobProgress={this.props.blobProgress}
                    workOrderId={this.state.workOrderId}
                    etag={this.state.etag}
                />
            </div>
        )
    }

  
    productsView = () => {
        const { t } = this.props;
       
        return (
            <div style={{marginBottom: "100px"}}>
                <Typography variant="h6" >{t("Products")}</Typography>
                
                <ContactsWorkSiteList
                    {...this.props}
                    showtitle={false}
                    rows={this.state.rows}
                    refreshDetails={this.refreshDetails}
                    hideSearch={true}
                    addButton={false}
                    notExpanded={true}
                />
            </div>
        )
    }

    render() {
        const { t } = this.props;
        const { workOrder } = this.state;
        return (
            <React.Fragment>
                {this.state.isLoading &&
                    <Spinner open={true}/>
                }
                {this.slider()}
                <BottomBar
                    disabler={workOrder.number === 0}
                    title={workOrder.name}
                    placeholder={t('') + " " + t('')}
                    rightButton={{action: this.save, tooltip: t("Save"), disabler: this.state.conflict, icon: <Save />}}
                    leftButton={{action: this.back, tooltip: t("Cancel"), icon: <Cancel/>}}
                />
            </React.Fragment>
        )
    };
}

function mapStateToProps(state) {
    return {
        notification: state.notifications,
        workOrder : state.workOrder,
        workSites: state.workSites,
        workSite: state.workSite,
        contacts: state.contacts,
        catalogs: state.catalogs,
        product: state.productRegister,
        productsRegister: state.productsRegister,
        productGroups: state.productGroups,
        productGroup: state.productGroup,
        productUnits: state.productUnits,
        productUnit: state.productUnit,
        realTimeMessage: state.realTimeMessage,
        workOrderDiaryEntry: state.workOrderDiaryEntry,
        workOrderDiaryEntries: state.workOrderDiaryEntries,
        blobProgress:state.blobProgress,
        user: state.oidc.user,
        catalog : state.catalog,
        workQueue: state.workQueue
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            readWorkOrder: bindActionCreators(workOrderActions.readWorkOrder, dispatch),
            updateWorkOrder: bindActionCreators(workOrderActions.updateWorkOrder, dispatch),
            readWorkSites: bindActionCreators(workSiteActions.readWorkSites, dispatch),
            readWorkSite: bindActionCreators(workSiteActions.readWorkSite, dispatch),
            notificationSuccess: bindActionCreators(notifications.notificationSuccess, dispatch),
            readContacts: bindActionCreators(contactsActions.readContacts, dispatch),
            readCatalog: bindActionCreators(catalogsActions.readCatalog, dispatch),
            readProductsRegister: bindActionCreators(productRegisterActions.readProductsRegister, dispatch),
            readProductRegister: bindActionCreators(productRegisterActions.readProductRegister, dispatch),
            updateProductRegister: bindActionCreators(productRegisterActions.updateProductRegister, dispatch),
            readProductGroups: bindActionCreators(productGroupActions.readProductGroups, dispatch),
            readProductGroup: bindActionCreators(productGroupActions.readProductGroup, dispatch),
            readProductUnits: bindActionCreators(productUnitActions.readProductUnits, dispatch),
            readProductUnit: bindActionCreators(productUnitActions.readProductUnit, dispatch),
            Blobs: bindActionCreators(blobsActions.Blobs, dispatch),
            sendMessage: bindActionCreators(realTimeActions.sendMessage, dispatch),
            readWorkOrderDiaryEntries: bindActionCreators(workOrderDiaryEntryActions.readWorkOrderDiaryEntries, dispatch),
            readworkOrderDiaryEntry: bindActionCreators(workOrderDiaryEntryActions.readWorkOrderDiaryEntry, dispatch),
            updateWorkOrderDiaryEntry: bindActionCreators(workOrderDiaryEntryActions.updateWorkOrderDiaryEntry, dispatch),
            readWorkQueue: bindActionCreators(workQueueActions.readWorkQueue, dispatch),
            updateWorkQueue: bindActionCreators(workQueueActions.updateWorkQueue, dispatch)
          
        
        }
    };
  }

export default compose(withTranslation('common'),
CancelPromisesHOC,
connect(
    mapStateToProps,
    mapDispatchToProps
))(withRouter(WorkOrder));
