import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from './authorizationUtils';
import axios from 'axios';

export function updateReportTemplate(id, template) {
    const options = getRequestOptions();
    return axios.put(
        getApiUrl(serviceKeys.documents) + `/api/reporttemplates/${id}`, template, options)
        .then(handleResponse)
        .catch(handleError);
}

export function previewReportFile(templateId, data) {
    const options = getRequestOptions();
    options.responseType = 'blob';
    return axios.post(
        getApiUrl(serviceKeys.documents) + `/api/reporttemplates/${templateId}/preview`, data, options)
        .then(handleResponse)
        .catch(handleError);
}

export function renderReportDocument(templateId, documentId, data, documentDescription) {
    const options = getRequestOptions();
    const requestData = {
        documentId: documentId,
        data: data,
        description: documentDescription
    };
    return axios.post(
        getApiUrl(serviceKeys.documents) + `/api/reporttemplates/${templateId}/render`, requestData, options)
        .then(handleResponse)
        .catch(handleError);
}

export function loadDocumentFile(documentId) {
    const options = getRequestOptions();
    options.responseType = 'blob';
    return axios.get(
        getApiUrl(serviceKeys.documents) + `/api/documents/${documentId}/file`, options)
        .then(handleResponse)
        .catch(handleError);
}

export function shareDocumentByEmail(documentId, emailTemplateId, toAddress) {
    const options = getRequestOptions();
    const data = {
        documentId: documentId,
        // Share the document for the next full year
        expirationTime: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        recipients: [
            toAddress
        ],
        templateId: emailTemplateId
    };
    return axios.post(
        getApiUrl(serviceKeys.documentSharing) + `/api/documentsharing/share`, data, options)
        .then(handleResponse)
        .catch(handleError);
}
