import React, { Component } from "react";
import {
    Typography,
} from '@material-ui/core';
import * as styles from './styles.js';
import { UpButton } from "./upButton";


export class BottomBar extends Component {
    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0 };
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
      }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }
    
    render(){
       
    const { disabler, title, leftButton, rightButton, placeholder} = this.props;
    
    return (
       
        <div style={styles.BottomButtonsBar}>
        
        <div style={styles.BottomButtonsBarLeft}>
            <UpButton
                id="bottomButton1"
                toolTipMessage={!!leftButton.tooltip ? leftButton.tooltip : ""}
                clickAction={leftButton.action}
                iconButton={leftButton.icon}
                color="primary"
                circleButton={window.innerWidth > 960 ? false : true}
                messageContent={window.innerWidth > 960 ? leftButton.tooltip : false}
            />
            </div>
            <div style={{ display: 'inline', verticalAlign: 'middle'}}>
                <Typography
                    color="primary"
                    id="BottomName"
                    style={styles.BottomBarName}
                >{title ? title : placeholder}</Typography>

            </div>
            <div style={styles.BottomButtonsBarRight}>
            <UpButton
                disabled={disabler}
                id="bottomButton2"
                toolTipMessage={!disabler ? rightButton.tooltip : ""}
                clickAction={rightButton.action}
                color="primary"
                iconButton={rightButton.icon}
                circleButton={window.innerWidth > 960 ? false : true}
                messageContent={window.innerWidth > 960 ? rightButton.tooltip : false}
            />

        </div>
        </div>
    )
    
}
}
export default BottomBar