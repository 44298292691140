import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from './authorizationUtils';
import axios from 'axios';

export function getWorkCards() {
    const options = getRequestOptions();
    return axios.get(getApiUrl(serviceKeys.workCards) + "/api/Aggregate/WorkCards", options)
        .then(handleResponse)
        .catch(handleError);
}
