import * as types from "./actionTypes";
import * as WorkQueuesApi from "../../api/workQueueApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";
import * as constants from "../../components/common/constants";
import * as WorkOrderApi from "../../api/workOrderApi";
import * as WorkSitesApi from "../../api/workSiteApi";
import initialState from "../reducers/initialState";



export function readWorkQueuesSuccess(workQueues) {
    return { type: types.LOAD_WORKQUEUE_SUCCESS, workQueues };
}

export function readWorkQueues(filter, sort, page, ownerId, cancelToken) {
    return async function(dispatch) {

        let workqueues = [];

        await WorkQueuesApi
        .ReadWorkQueues(filter, sort, page, ownerId, cancelToken)  
        .then(result => {
            workqueues = result;
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (error.message !== constants.cancelHttpRequest)
            {
                dispatch(notifications.notificationError("ErrorloadingWorkQueues"));
                throw new Error(error);
            }
        });

        var batch = [];
      
        workqueues.map(element => {
            element.workOrders.map(order => {
                batch.push(order);
                return order;
            })
            return element;
        })

        var sendData = {
            batch : batch
        }

        let workorders = [];
        
        await WorkOrderApi
        .ReadWorkOrderBatch(sendData, cancelToken)  
        .then(result => {
            workorders = result;
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (error.message !== constants.cancelHttpRequest)
            {
                dispatch(notifications.notificationError("ErrorloadingWorkOrders"));
                throw new Error(error);
            }
        });

        var dict = {};
        workorders.map(element => {
            if (element.workSiteId !== "") {
                dict[element.workSiteId] = "";
            }
            return element;
        })

        batch = [];
        for(var key in dict) {
            batch.push(key)
        }
        sendData = {
            batch : batch
        }

        
        let  worksites = [];

        await WorkSitesApi
        .ReadWorkSiteBatch(sendData, cancelToken)  
        .then(result => {
            worksites = result;
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (error.message !== constants.cancelHttpRequest)
            {
                dispatch(notifications.notificationError("ErrorloadingWorkSites"));
                throw new Error(error);
            }
        });
        
        var myMap = {};
        for (var x = 0; x < workqueues.length; x++) {
            var items = [];
            for (var y = 0; y < workqueues[x].workOrders.length; y++) 
            {
                var item = {};

                var workorder = findElement(workorders, workqueues[x].workOrders[y].toString());
                
                item.id = workorder.id.toString();
                item.content = undefined;
                item.show = true;

                delete workorder.Products;
                item.workOrder = workorder;

                var worksite = findElement(worksites, workorder.workSiteId);
                if (worksite) {
                    delete worksite.contacts;
                    item.workSite = worksite;  
                }
                else {
                    item.workSite = initialState.workSite;  
                }
              
                items.push(item);
            }
            
            myMap[workqueues[x].id] = 
            {
                name: workqueues[x].title,
                workQueueSettingsId: workqueues[x].WorkQueueSettingsId,
                ownerId: workqueues[x].ownerId,
                items: items,
                members: workqueues[x].members,
                cardColor: workqueues[x].cardColor,
                allowCreateCards: workqueues[x].allowCreateCards,
            };
            
        }

        dispatch(readWorkQueuesSuccess(myMap));

    };
} 

function findElement(items, item) {
    return items.find(e => e.id === item);
                
}

export function readWorkQueueSuccess(workQueue) {
    return { type: types.READ_WORKQUEUE_SUCCESS, workQueue };
}

export function readWorkQueue(id) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return WorkQueuesApi
        .ReadWorkQueue(id)  
        .then(result => {
            dispatch(readWorkQueueSuccess(result));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorloadingWorkQueue"));
            throw new Error(error);
        });
    };
} 

export function updateWorkQueueSuccess(workQueue) {
    return { type: types.UPDATE_WORKQUEUE_SUCCESS, workQueue };
}

export function updateWorkQueue(model) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return WorkQueuesApi
        .updateWorkQueue(model)  
        .then(result => {
            dispatch(updateWorkQueueSuccess(result));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorupdatingWorkQueue"));
            throw new Error(error);
        });
    };
} 

