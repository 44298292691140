import * as types from "./actionTypes";
import * as documentsApi from "../../api/documentsApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as templateLoader from "../../services/templateLoader";
import * as notifications from "./notificationsActions";

export function updateTemplatesSuccess() {
    return { type: types.UPDATE_TEMPLATES_SUCCESS };
}

export function updateTemplates() {
    return function (dispatch) {
        dispatch(beginApiCall());

        let updateTemplate = (templateId, type) => templateLoader.loadById(templateId, type)
            .then((template) => {
                return documentsApi.updateReportTemplate(templateId, template)
            }).catch(error => {
                dispatch(apiCallError(error));
            });

        let updateWorkCardPdf = updateTemplate(templateLoader.templates.workCard.id, templateLoader.templateTypes.pdf);
        let updateEmailTemplate = updateTemplate(templateLoader.templates.shareWorkCardLink.id, templateLoader.templateTypes.html);

        return Promise.all([updateWorkCardPdf, updateEmailTemplate])
            .then(dispatch(updateTemplatesSuccess()))
            .catch(error => {
                dispatch(apiCallError(error));
                dispatch(notifications.notificationError("ErrorUpdatingWorkCardDocument"));
                throw new Error(error);
            })

    };
}

export function resetPDFWorkCardSuccess() {
    return { type: types.RESET_PDF_WORKCARD_SUCCESS };
}

export function resetPDFWorkCard() {
    return function (dispatch) {
        dispatch(resetPDFWorkCardSuccess());
    };
}

export function previewPDFWorkCardSuccess(pdf) {
    return { type: types.PREVIEW_PDF_WORKCARD_SUCCESS, pdf };
}

export function previewPDFWorkCard(workCardData, tenant) {
    return function (dispatch) {
        dispatch(beginApiCall());

        let templateId = templateLoader.templates.workCard.id;

        return documentsApi
            .previewReportFile(
                templateId,
                {
                    workCard: workCardData,
                    tenant: tenant
                })
            .then((pdf) => dispatch(previewPDFWorkCardSuccess(pdf)))
            .catch(error => {
                dispatch(apiCallError(error));
                dispatch(notifications.notificationError("ErrorPreviewWorkCardDocument"));
                throw new Error(error);
            });
    };
}

export function renderPDFWorkCardSuccess() {
    return { type: types.RENDER_PDF_WORKCARD_DOCUMENT_SUCCESS };
}

export function renderPDFWorkCard(workCardData, tenant) {
    return function(dispatch) {
        dispatch(beginApiCall());

        let templateId = templateLoader.templates.workCard.id;
        let documentId = workCardData.workCard.id;
        let documentDescription = workCardData.workCard.title;

        return documentsApi
            .renderReportDocument(
                templateId,
                documentId,
                {
                    workCard: workCardData,
                    tenant: tenant
                },
                documentDescription)
            .then(dispatch(renderPDFWorkCardSuccess()))
            .catch(error => {
                dispatch(apiCallError(error));
                dispatch(notifications.notificationError("ErrorRenderingWorkCardDocument"));
                throw new Error(error);
            });
    };
}

export function sendPDFWorkCardDocumentLinkSuccess() {
    return { type: types.SEND_PDF_WORKCARD_DOCUMENT_LINK_SUCCESS };
}

export function sendPDFWorkCardDocumentLink(workCardId, toAddress) {
    return function (dispatch) {
        dispatch(beginApiCall());

        return documentsApi
            .shareDocumentByEmail(workCardId, templateLoader.templates.shareWorkCardLink.id, toAddress)
            .catch(error => {
                dispatch(apiCallError(error));
                dispatch(notifications.notificationError("ErrorSendingWorkCardDocumentLink"));
                throw new Error(error);
            });
    };
}
