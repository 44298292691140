import React, { Component, Fragment } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import CancelPromisesHOC   from "../../helpers/cancelPromisesHOC";
import { bindActionCreators, compose } from 'redux';
import { withRouter } from "react-router-dom";
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {
  Grid,
  Button,
  Card,
  CardContent,
  Container,
} from '@material-ui/core';
import UpTextField from '../common/upTextField';
import * as styles from '../common/styles.js';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import *  as workQueueActions from '../../redux/actions/workQueueActions';
import * as notifications from "../../redux/actions/notificationsActions";
import * as constants from "../common/constants";
import axios from 'axios';
import Spinner from '../common/CircularSpinner';
import *  as realTimeActions from '../../redux/actions/realTimeActions';
import Search from "../common/search";
import { BottomBar } from "../common/BottomBar";
import { Cancel } from '@material-ui/icons';
import { Save } from '@material-ui/icons';
import initialState from "../../redux/reducers/initialState";
import *  as workQueueSettingsActions from '../../redux/actions/workQueueSettingsActions';
import WorkOrder from "../workOrder/workOrder";
import uuid from "uuid/v4";
import *  as workOrderActions from '../../redux/actions/workOrderActions';
import *  as workSiteActions from '../../redux/actions/workSiteActions';

//var isMounted = false;

export class WorkQueue extends Component {
    
  constructor(props) {
    super(props);
    this.state = { 
      workQueueSettingsId : this.props.match.params.workQueueSettingsId,
      columns : {},
      openNewColumn: false,
      columName: "",
      isLoading: false,
      workQueueSettings: initialState.workQueueSettings,
      openWorkOrder : false,
      currentWorkQueue: -1,
      filter:"",
      isEditWorkOrder: false,
      numberWorkOrder: 0,
      currentKey: "",
      currenPosition: 0,
      workQueuesAvailable: [],
      etag: uuid(),
    };
  }

  async componentDidMount() {
    var message = {
      collectionId : constants.collections.workQueuesSettings,
      id : this.state.workQueueSettingsId,
      etag: this.state.etag
    }
    this.props.actions.sendMessage(message);
    
    if (this.state.workQueueSettingsId !== constants.New) {
        this.reload();         
    }
  }

  reload = async () => {
    if (!this.props.isTesting) {
        this.setState({
          isLoading : true,
      });
    }

    let cancelToken = axios.CancelToken.source();

    await this.props.actions.readWorkQueueSettings(this.state.workQueueSettingsId, cancelToken.token)
    .then(() => {
      this.loadRecords(this.state.filter);
    })
    .catch(() => { })
    .finally(result => {
    })
    if (!this.state.isTesting) {
      this.setState({
          isLoading: false,
      });
    }
  }

  componentWillUnmount() {
    //isMounted = false;
    this.props.cancelPromises();
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.workQueueSettings !== this.props.workQueueSettings) {
      this.setState({
        workQueueSettings : this.props.workQueueSettings
      })
    }
    
    if (prevProps.workQueues !== this.props.workQueues) {
      var myMap = {...this.props.workQueues};  
      
      var keys = Object.keys(myMap);
      for (var x = 0; x < keys.length; x++) {
        var key = keys[x];
        for (var y = 0; y < myMap[key].items.length; y++) {
          myMap[key].items[y].content = this.workQueueCard(myMap[key].items[y], key, y); 
        };
      };

      this.setState({
        columns : myMap
      });
    }

    if (prevProps.realTimeMessage !== this.props.realTimeMessage) 
    {
      if (this.props.realTimeMessage.etag !== this.state.etag &&
          this.props.realTimeMessage.id === this.state.workQueueSettingsId &&      
          this.props.realTimeMessage.collectionId === constants.collections.workQueuesSettings) 
      {
        this.reload();
      }
    }
  }

  handleRealtimeWorkOrder = async (message) => {
    /*
    isMounted = true;

    let cancelToken = axios.CancelToken.source();
    
    var promise1 = this.props.actions.readWorkOrder(message.id, cancelToken)
    .then(() => {
      if (this.props.workOrder.workSiteId !== constants.EmptyGuid) {
          this.props.actions.readWorkSite(this.props.workOrder.workSiteId, cancelToken)
          .then(() => {
          })
          .catch(() => { })
          .finally(result => {
          })
      }
    })
    .catch(() => { })
    .finally(result => {
    })

    await this.props.cancellablePromise(
        promise1
        , cancelToken
    )
    .then(() => {
      var workSite = this.props.worksite ? this.props.worksite : initialState.workSite;
      this.updateCard({...this.props.workOrder}, {...workSite}, message.workQueueId, 0, message.isEditWorkOrder, false)

    })
    .catch((error) => {
        if (error.message === constants.cancelHttpRequest || error.message === "[object Object]") {
            isMounted = false;
        }
    })
    .finally(result =>{
      if (isMounted) {
      }
    })*/
  }

  loadWorkQueuesAvailable = () => {
    /*
    if (!this.state.isTesting) {
      this.setState({
          isLoading: true,
      });
    }
    await this.props.actions.readWorkQueues("", "", 1, this.props.user.profile.sub,cancelToken.token)
      .then(() => {
      })
      .catch(() => { })
      .finally(result => {

      })

      if (!this.state.isTesting) {
        this.setState({
            isLoading: false,
        });
      }*/
  }

  handleRealtime = async () => {
    /*
    var id = this.props.realTimeMessage.id;

    if (this.props.realTimeMessage.typeOperation !== constants.Deleted) {
      let cancelToken = axios.CancelToken.source();
    
      var flagContinue = false;
      await this.props.actions.readWorkQueue(id, cancelToken)
      .then(() => {
        flagContinue = true;
      })
      .catch(() => { })
      .finally(result => {
      })

      if (flagContinue) {
        var items = [];
        for (var y = 0; y < this.props.workQueue.workOrders.length; y++) {
            var idCard = uuid();
            items.push({
              id: idCard, 
              content: this.workQueueCard(idCard),
              workOrderId: this.props.workQueue.workOrders[y]
            })
          };
          this.setState(prevState => ({
          ...prevState,
          columns: {
            ...prevState.columns,
            [id] : {
              name: this.props.workQueue.title,
              ownerId: this.props.workQueue.ownerId,
              items: items,
              members: this.props.workQueue.members
          }
          },
        }));
        
      }
    }
    else {
      var columns = {...this.state.columns};
      delete columns[id];
  
      this.setState({
        columns: columns
      });
    }
    */
  }

  handleOpenNewColumn = () => {
    this.setState({
      openNewColumn : true
    })
  }

  handleCloaseNewColumn = () => {
    this.setState({
      openNewColumn : false
    });
  }

  handleAdd = () => {
  
    var id = this.state.columName;
  
    this.setState(prevState => ({
      ...prevState,
      columns: Object.assign({
        ...prevState.columns,
        [id] : {
            name: this.state.columName,
            workQueueSettingsId: this.state.workQueueSettingsId,
            ownerId: "",
            items: [],
            members: [],
            cardColor: "color",
            allowCreateCards: true,
        
        }
      }),
      openNewColumn: false
    }), function() {
      this.setState({
        columName: ""
      });
      this.updateWorkQue(id, constants.Added);
    });
    
  }

  handleChange = (e) => {
      const { id, value } = e.target;
      
      this.setState(prevState => ({
        ...prevState,
        [id]: value,
      }));
  }

  updateWorkQue = async (id, typeOpertion) => {
    if (!this.props.isTesting) {
      this.setState({
          //isLoading : true,
      });
    }

    var items = [];
    for (var x = 0; x < this.state.columns[id].items.length; x++) {
      items.push(this.state.columns[id].items[x].workOrder.id);
    }
    var data = {
      id: id,
      title: this.state.columns[id].name,
      workQueueSettingsId: this.state.columns[id].workQueueSettingsId,
      ownerId: this.state.columns[id].ownerId,
      workOrders: items,
      members: this.state.columns[id].members,
      cardColor: this.state.columns[id].cardColor,
      allowCreateCards: this.state.columns[id].allowCreateCards,
      state: typeOpertion
    };

    if (typeOpertion === constants.Added) {
      data.ownerId = this.props.user.profile.sub;
    }

    if (this.props.isTesting) {
      this.setState({
        modelTesting : data
      })
    }

    if (typeOpertion === constants.Deleted) {
      var myMap = {...this.state.columns};
      delete myMap[id];
      
      this.setState({
        columns : myMap
      });
    }
  
    await this.props.actions.updateWorkQueue(data)
    .then(() => {
    })
    .catch(() => {
    })
    .finally(() =>{
      this.setState({
        isLoading : false,
      });
      if (typeOpertion === constants.Added) {
        this.save(id);
      }
      else {
        this.sendMessage();
      }
    });
  }

  save = async (idWorkQueue) => {
    if (!this.props.isTesting) {
      this.setState({
          //isLoading : true,
      });
    }
   
    var data = {...this.state.workQueueSettings};
   
    if (this.state.workQueueSettingsId === constants.New) {
      data.ownerId = this.props.user.profile.sub;
      data.state = "Added";
    }
    else {
      data.state = "Modified";
      if (idWorkQueue) {
        var works = [...data.workQueues];
        works.push(idWorkQueue);
        data.workQueues = [...works];  
      }
    }

    var flagContinue = true;
   
    await this.props.actions.updateworkQueueSettings(data)
    .then(() => {
    })
    .catch(() => {
      flagContinue = false;
    })
    .finally(() =>{
    });

    if (flagContinue) {
      this.setState(prevState => ({
        ...prevState,
        workQueueSettings: {
          ...prevState.workQueueSettings,
          id: this.state.workQueueSettings.name,
          ownerId : data.ownerId,
          workQueues : data.workQueues,
          members: data.members,
        },
        workQueueSettingsId : this.state.workQueueSettings.name,
        isLoading : false,
      }));
      this.sendMessage();
    }
  }

  sendMessage = () => {
    var message = {
      collectionId : constants.collections.workQueuesSettings,
      id : this.state.workQueueSettingsId,
      etag: this.state.etag
    }
    this.props.actions.sendMessage(message);
  }
      
  onDragEnd = (result, columns, setColumns) => {
      if (!result.destination) return;
      const { source, destination } = result;
      var temp;
      if (source.droppableId !== destination.droppableId) {
        const sourceColumn = columns[source.droppableId];
        const destColumn = columns[destination.droppableId];
        const sourceItems = [...sourceColumn.items];
        const destItems = [...destColumn.items];
        const [removed] = sourceItems.splice(source.index, 1);
        temp = {...removed};
        destItems.splice(destination.index, 0, temp);
        temp.content = this.workQueueCard(temp, destination.droppableId, destination.index);
        this.setState(prevState => ({
            ...prevState,
            columns: Object.assign({
              ...prevState.columns,
              [source.droppableId]: {
                ...sourceColumn,
                items: sourceItems
              },
              [destination.droppableId]: {
                ...destColumn,
                items: destItems
              },
            }),
        }), function() {
            this.updateWorkQue(source.droppableId, constants.Modified);
            this.updateWorkQue(destination.droppableId, constants.Modified);
        });
      } else {
        const column = columns[source.droppableId];
        const copiedItems = [...column.items];
        const [removed] = copiedItems.splice(source.index, 1);
        temp = {...removed};
        copiedItems.splice(destination.index, 0, temp);
        temp.content = this.workQueueCard(temp, destination.droppableId, destination.index);
        this.setState(prevState => ({
          ...prevState,
          columns: Object.assign({
            ...prevState.columns,
            [source.droppableId]: {
              ...column,
              items: copiedItems
            }
          }),
        }), async function() {
          this.updateWorkQue(source.droppableId, constants.Modified);
        });
      }
      this.sendMessage();
  };
  
  handleMembers = (id, popupState) => {
    popupState.close();
  }

  workQueueCard = (row, key, position) => {
      return (
        <Fragment>
            <div>
              <div style={{marginBottom:"10px"}}> {row.workOrder.id} </div>
              <div style={{marginBottom:"10px"}}> {row.workOrder.internalDescription} </div>
              <div style={{marginBottom:"10px"}}> {row.workSite.name} </div>
              {
                <div>
                  <Button  color="primary"
                    variant="contained"
                    style={{height:"50px"}}
                    onClick={(e) => this.handleEditWorkOrder(key, position)}
                    >
                    Edit
                  </Button>
                </div>
              }
            </div> 
        </Fragment>
      )
  }

  handleDeleteWorkOrder = (key, position) => {
    var column = {...this.state.columns[key]};
    var items = [...column.items];
    items.splice(position,1);
    column.items = items;

    this.setState(prevState => ({
      ...prevState,
      columns: {
        ...prevState.columns,
        [key]: column
      },
    }), function() {
        this.updateWorkQue(key, constants.Modified);
    });  
  }
  
  handleDeleteWorkQueue = (idColumn) => {
    /*
    this.updateWorkQue(idColumn, constants.Deleted);
    */
  }

  loadRecords = (filter) => {
    this.setState({
      filter : filter
    })
    filter = filter.toUpperCase();
    var columns = {...this.state.columns};
    var keys = Object.keys(columns);
    for (var x = 0; x < keys.length; x++) {
      var key = keys[x];
      var column = {...columns[key]}; 
      var items = [...column.items];
      for (var y = 0; y < items.length; y++) {
        var i = {...items[y]};
        if (i.id.includes(filter) || 
            i.workOrder.internalDescription.toUpperCase().includes(filter) ||
            i.workSite.name.toUpperCase().includes(filter))
        {
          i.show = true;
        }
        else {
          i.show = false;
        }
        i.content = this.workQueueCard(i, key, y);
        items[y] = {...i};
      }
      column.items = [...items];
      columns[key] = {...column};

    };

    this.setState(prevState => ({
      ...prevState,
      columns : {...columns}
    }));
  }

  search = () => {
    return (
      <Search 
          backButton={false}
          addButton={false}
          loadRecords={this.loadRecords}
          isArray={false}
          history={this.props.history}
          hideFilters={true}
          hideFilterOptions={true}
          filter={this.state.filter}
          user={this.props.user}
      />
    )
  }

  handleChangeInfo = (event) => {
    
    const { id, value } = event.target;
    
    this.setState(prevState => ({
        ...prevState,
        workQueueSettings: {
            ...prevState.workQueueSettings,
            [id]: value,
        },
    }));
  }

  back = () => {
      var path = "/workQueuesSettings";

      if (this.props.isTesting) {
          this.setState({
              backPathTest: path,
          });
      }

      this.props.history.push(path);
  }

  handleEditWorkOrder = (key, position) => {
    var numberWorkOrder = this.state.columns[key].items[position].workOrder.id;
    this.setState({
      currentWorkQueue : key,
      currenPosition: position,
      openWorkOrder: true,
      isEditWorkOrder: true,
      numberWorkOrder: numberWorkOrder
    })
  }

  handleWorkOrders = (id, popupState) => {
    popupState.close();
   
    this.setState({
      currentWorkQueue : id,
      openWorkOrder : true,
      isEditWorkOrder: false,
      numberWorkOrder: 0
    })
    
  }
  
  handleAddWorkOrder = (workOrder, worksite) => {
    this.handleCloseWorkOrder();
    var id = this.state.currentWorkQueue;
    var workId = workOrder.id.toString(); 
   
    var data = { 
      id: workId, 
      content: undefined,
      workOrder : workOrder,
      workSite :worksite
    };
    
    data.content = this.workQueueCard(data, id, this.state.currenPosition);

    var temp = [...this.state.columns[id].items];
    if (this.state.isEditWorkOrder) {
      Object.entries(this.state.columns).forEach(([key, value]) => {
        var position = this.state.columns[key].items.findIndex(e => e.id === workId);
        if (position >= 0) {
          temp = [...this.state.columns[key].items];
          temp[position] = {...data};
          id = key;
          return false;
        }
      })
    }
    else {
      temp.push(data)
    }
 
    var column = {...this.state.columns[id], items: temp} 
    
    this.setState(prevState => ({
      ...prevState,
      columns: {
        ...prevState.columns,
        [id]: column
      },
    }), function() {
        this.updateWorkQue(id, constants.Modified);
        this.loadRecords(this.state.filter);
    });
  }

  handleCloseWorkOrder = () => {
    this.setState({
      openWorkOrder : false
    })
  }

  generalInfo = () => {
      const { t } = this.props;
      const { workQueueSettings } = this.state;
    
      return (
          <Grid container style={styles.ElementWithoutPadding}>
              <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                  <Card style={styles.CardWithoutBoxes}>
                      <CardContent style={styles.ElementWithoutPadding}>
                          <Grid container >
                              <Grid style={{ width: "100%" }}>
                                  <UpTextField
                                  disabled={this.state.workQueueSettingsId !== constants.New}
                                  style={styles.inputElements}
                                  id="name"
                                  label={t("Name")}
                                  value={workQueueSettings.name}
                                  onChange={this.handleChangeInfo} />
                              </Grid>
                          </Grid>
                      </CardContent>
                  </Card>
              </Container>
          </Grid>
      )
  }
    
  boardCards = () => {
      const { t } = this.props;
      const { columns } = this.state;
      return (
          <div style={{ display: "flex", justifyContent: "left", height: "100%" }}>
            <DragDropContext
                onDragEnd={result => this.onDragEnd(result, columns)}
            >
                {Object.entries(columns).map(([columnId, column], index) => {
                return (
                    <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                    key={columnId}
                    >
                    <div style={{width:"94%",display: "flex",justifyContent: "center"}}>
                      <div style={{width:"80%"}}>
                        <h2>{column.name}</h2>
                      </div>
                      {/*
                      <div>
                        <Button  color="primary"
                          variant="contained"
                          style={{height:"50px"}}
                          onClick={(e) => this.handleDeleteWorkQueue(columnId)}
                          >
                          Delete
                        </Button>
                      </div>*/
                      }
                      <div>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <React.Fragment>
                              <Button  color="primary"
                                variant="contained"
                                {...bindTrigger(popupState)} 
                                style={{height:"50px"}}>
                                ...
                              </Button>
                              <Menu {...bindMenu(popupState)}>
                                <MenuItem onClick={(e) => this.handleWorkOrders(columnId, popupState)}>{t("WorkOrders")}</MenuItem>
                                <MenuItem onClick={(e) => this.handleMembers(columnId, popupState)}>{t("Members")}</MenuItem>
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>

                      </div>
                    </div>
                    <div style={{ margin: 8 }}>
                        <Droppable droppableId={columnId} key={columnId}>
                        {(provided, snapshot) => {
                            return (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                background: snapshot.isDraggingOver
                                    ? "lightblue"
                                    : "lightgrey",
                                padding: 4,
                                width: 250,
                                minHeight: 500,
                                }}
                            >
                                {column.items.map((item, index) => {
                                return (
                                    <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                    >
                                    {(provided, snapshot) => {
                                        return (
                                        <div 
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                            userSelect: "none",
                                            padding: !item.show ? 0 : 16,
                                            margin: !item.show ? "0 0 0px 0" : "0 0 8px 0",
                                            minHeight: "0px",
                                            backgroundColor: snapshot.isDragging
                                                ? "#263B4A"
                                                : "#456C86",
                                            color: "white",
                                            height: !item.show ? "0px" : "auto",
                                            visibility: !item.show ? "hidden" : "visible",
                                            ...provided.draggableProps.style,
                                            }}
                                        >
                                            {item.content}
                                        </div>
                                        );
                                    }}
                                    </Draggable>
                                );
                                })}
                                {provided.placeholder}
                            </div>
                            );
                        }}
                        </Droppable>
                    </div>
                    </div>
                );
                })}
            </DragDropContext>
              <Grid container>
                {this.state.openNewColumn ? (
                  <Grid item>
                      <UpTextField
                            id="columName"
                            style={styles.inputElements}
                            value={this.state.columName}
                            onChange={this.handleChange} />
                      <Button  color="primary" disabled={this.state.columName.trim() === ""}
                            variant="contained"
                            onClick={() => this.handleAdd()} style={{height:"50px"}}>
                          {t("New")}
                      </Button>
                      <Button  color="primary"
                            variant="contained"
                            onClick={() => this.handleCloseNewColumn()} style={{height:"50px"}}>
                          {t("Cancel")}
                      </Button>
                  </Grid>
                ): (
                  <Grid item>
                      <Button  onClick={() => this.handleOpenNewColumn()} color="primary"
                              variant="contained"
                              style={{height:"50px"}}>
                          {t("NewColumn")}
                      </Button>
                  </Grid>
                )}
              </Grid>
          </div>

      );
  }

  
  render() {
    const { t } = this.props;
    const { workQueueSettings } = this.state;
    return (
      <Fragment>
        {this.props.isConnectionSignalActive ? (
          <Fragment>
            {this.state.openWorkOrder ? (
              <WorkOrder 
                isComponent={true} 
                handleCloseWorkOrder={this.handleCloseWorkOrder}
                handleAddWorkOrder={this.handleAddWorkOrder}
                isEditWorkOrder={this.state.isEditWorkOrder}
                numberWorkOrder={this.state.numberWorkOrder}
              >
              </WorkOrder>
            ) : (
              <Fragment>
                {this.state.isLoading &&
                  <Spinner open={true}/>
                }
                {this.generalInfo()}
                {this.state.workQueueSettingsId !== constants.New &&
                  <Fragment>
                    {this.search()}
                    <div style={{
                      overflowX: "scroll",
                      overflowY: "hidden",
                      whiteSpace: "nowrap"
                    }}>
                      {this.boardCards()}
                    </div>
                   </Fragment> 
                }
                <BottomBar
                    disabler={this.state.workQueueSettingsId !== constants.New || !workQueueSettings.name || workQueueSettings.name.trim() === ""}
                    title={workQueueSettings.name}
                    placeholder={t('Unnamed') + " " + t('workQueueSettings')}
                    rightButton={{action: this.save, tooltip: t("Save"), icon: <Save /> }}
                    leftButton={{action: this.back, tooltip: t("Cancel"), icon: <Cancel/>}}
                />
              </Fragment>
            )
            }    
          </Fragment>
        ) : (
          <div>Offline signalr</div>
        )}
      </Fragment>
    )
  }

}

function mapStateToProps(state) {
  return {
      workQueue: state.workQueue,
      workQueues : state.workQueues,
      realTimeMessage: state.realTimeMessage,
      user: state.oidc.user,
      workQueueSettings: state.workQueueSettings,
      workOrder : state.workOrder,
      workSite: state.workSite,
      isConnectionSignalActive: state.isConnectionSignalActive
   };
}

function mapDispatchToProps(dispatch) {
  return {
      actions: {
        readWorkQueue: bindActionCreators(workQueueActions.readWorkQueue, dispatch),
        readWorkQueues: bindActionCreators(workQueueActions.readWorkQueues, dispatch),
        updateWorkQueue: bindActionCreators(workQueueActions.updateWorkQueue, dispatch),
        notificationSuccess: bindActionCreators(notifications.notificationSuccess, dispatch),
        sendMessage: bindActionCreators(realTimeActions.sendMessage, dispatch),
        readWorkQueueSettings: bindActionCreators(workQueueSettingsActions.readworkQueueSettings, dispatch),
        updateworkQueueSettings: bindActionCreators(workQueueSettingsActions.updateworkQueueSettings, dispatch),
        readWorkOrder: bindActionCreators(workOrderActions.readWorkOrder, dispatch),
        readWorkSite: bindActionCreators(workSiteActions.readWorkSite, dispatch),
      }
  };
}


export default compose(withTranslation('common'),
CancelPromisesHOC,
connect(
    mapStateToProps,
    mapDispatchToProps
))(withRouter(WorkQueue));
