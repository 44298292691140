import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import *  as workCardsActions from '../../redux/actions/workCardsActions';
import Button from '@material-ui/core/Button';
import Spinner from '../common/CircularSpinner';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import *  as navigationActions from '../../redux/actions/navigationActions';
import {setCurrentModule} from "../../utils/navigationUtil";
import { Box } from '@material-ui/core';

const button = {
    background: "none",
    border: "none",
    padding: "0",
    textDecoration: "underline",
    color: "#069",
    boxShadow: "none",
}

export class WorkCardsList extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            workCards : [],
            result: ' ',
            isLoading: false,
            language: "",
            newWorkCard : "CreateNewWorcard"
        };
    }
    
    componentDidMount() {
        this.loadWorkCards();
        this.props.actions.setModule(setCurrentModule('/workcards'));
            
            
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.translations !== this.props.translations)
        {  
            if (this.props.isTesting) {
                this.setState({
                    testingTranslation : true
                });    
            }
            if (this.props.t("CreateNewWorcard")) {
                this.setState({
                    newWorkCard : this.props.t("CreateNewWorcard")
                });
            } 
        }
        else if (this.state.language !== prevProps.i18n.language) {
            if (this.props.isTesting) {
                this.setState({
                    testingTranslation : true
                });    
            }
            if (!this.props.t("CreateNewWorcard")) {
                this.setState({
                    language : prevProps.i18n.language
                });
            } 
            else {
                this.setState({
                    newWorkCard : this.props.t("CreateNewWorcard"),
                    language : prevProps.i18n.language
                });
            }
        }

        if (prevProps.workCards !== this.props.workCards) {
            this.setState({
                workCards: [...this.props.workCards]
            });
        }

        if ((!prevProps.tenant && this.props.tenant) || prevProps.tenant.id !== this.props.tenant.id) {
            this.loadWorkCards();
        }
    }

    loadWorkCards = () => {
        if (!this.props.isTesting) {
            this.setState({
                isLoading: true
            });
        }

        this.props.actions.readWorkCards()
            .then(() => {
                this.setState({
                    isLoading: false
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                });
            });
    }

    goToRecord = (id) => {
        var path = "/workcards/";
        if (this.props.isTesting) {
            this.setState({
                testingpath: path,
                testingid: id
            });
        }

        this.props.history.push(path + id)
    }

    goToNew = () => {
        var path = "/workcards/new";
        if (this.props.isTesting) {
            this.setState({
                testingpath: path
            });
        }
        this.props.history.push(path)
    }
    
    handleCloseSnack = () => {
        this.setState({
            openSnack : false,
            messageSnack : "",
            variantSnack : ""
        });
    }

    renderWorkCardList = (workCards) => {
        const { t } = this.props;
        return (
            <div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>{t("Name")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {workCards.map((workCard, idx) =>
                            <tr key={workCard.id}>
                                <td>
                                    <Button id={"btnrecord" + workCard.id} style={button} onClick={() => this.goToRecord(workCard.id)} variant="contained" >
                                        {workCard.title}
                                    </Button >  
                                 </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    

    render() {
        return (
            <Box>
                {this.state.isLoading &&
                    <Spinner open={this.state.isLoading} />
                }
                <Button id={"btnnewcard"} style={button} onClick={() => this.goToNew()} variant="contained" >
                    { this.state.newWorkCard }
                </Button >  
                { this.state.workCards.length &&
                    this.renderWorkCardList(this.state.workCards)
                }
                <label id="resulttext">{this.state.result}</label>
            </Box>
        );
    }
}


function mapStateToProps(state) {
    return {
        workCards: state.workCards,
        translations: state.translations,
        tenant: state.tenant,
        moduleInfo: state.navigation.module
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            readWorkCards: bindActionCreators(workCardsActions.readWorkCards, dispatch),
            setModule: bindActionCreators(navigationActions.setModule, dispatch)
            
        }
    };
}

export default compose(withTranslation('common'),
connect(
    mapStateToProps,
    mapDispatchToProps
))(withRouter(WorkCardsList));


