import * as types from "./actionTypes";
import * as workOrderApi  from "../../api/workOrderApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";
import * as constants from "../../components/common/constants";

export function readWorkOrdersSuccess(workOrders) {
    return { type: types.LOAD_WORKORDER_SUCCESS, workOrders };
}

export function readWorkOrders(filter, sort, page, workSiteId, cancelToken) {
    return async function(dispatch) {
        dispatch(beginApiCall());
      
        return workOrderApi
        .ReadWorkOrders(filter, sort, page, workSiteId, cancelToken)  
        .then(result => {
            dispatch(readWorkOrdersSuccess(result));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (error.message !== constants.cancelHttpRequest)
            {
                dispatch(notifications.notificationError("ErrorloadingWorkOrders"));
                throw new Error(error);
            }
        });
    };
} 

export function readWorkOrderSuccess(workOrder) {
    return { type: types.READ_WORKORDER_SUCCESS, workOrder };
}

export function readWorkOrder(id) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return workOrderApi
        .ReadWorkOrder(id)  
        .then(result => {
            dispatch(readWorkOrderSuccess(result));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorloadingWorkOrder"));
            throw new Error(error);
        });
    };
} 

export function updateWorkOrderSuccess(workOrder) {
    return { type: types.UPDATE_WORKORDER_SUCCESS, workOrder };
}

export function updateWorkOrder(model) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return workOrderApi
        .updateWorkOrder(model)  
        .then(result => {
            dispatch(updateWorkOrderSuccess(result));
            //if (model.state === constants.Added) {
            //    model.id = result;
            //}
            //dispatch(readWorkOrderSuccess(model));
            return result;
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorupdatingWorkOrder"));
            throw new Error(error);
        });
    };
} 

