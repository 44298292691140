import { store } from "../redux/store";

export default function getRequestOptions(cancelToken, ignoreTenantHeader = false) {
    var options = {
        headers: {
            'Content-Type': 'application/json'
        },
        crossdomain: true,
        cancelToken
    };

    var tenantId = sessionStorage.getItem("tenant_id");
    if (!tenantId) {
        tenantId = localStorage.getItem("tenant_id");
    }
    if (tenantId && !ignoreTenantHeader) {
        options.headers['up-tenant-id'] = tenantId;
    }

    if (store && store.getState().oidc.user) {
        const token = store.getState().oidc.user.access_token;
        options.headers['Authorization'] = `Bearer ${token}`;
    }

    return options;
}