import * as types from "./actionTypes";
import * as sugarApi from "../../api/sugarApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";

export function updateCustomResourceAccessSuccess(customResourceAccess) {
    return { type: types.UPDATE_CUSTOM_RESOURCE_ACCESS_SUCCESS, payload: customResourceAccess };
}

export function updateCustomResourceAccess() {
    return function(dispatch) {
        dispatch(beginApiCall());
        return sugarApi
            .getAccessModuleList()
            .then(accessModuleList => {
                dispatch(updateCustomResourceAccessSuccess(accessModuleList));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                dispatch(notifications.notificationError("Virhe sivun lataamisessa"));
            });
    };
}
