import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { useSpring, animated } from "react-spring";
import Paper from "@material-ui/core/Paper";
import { Typography, Button} from "@material-ui/core";
import React from "react";
import FileDragAndDrop from "../common/FileDragAndDrop";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import TextField from "@material-ui/core/TextField";
import LinearDeterminate from "../common/linearProgress";

const styles = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px",
  },

  modalcontent: {
    backgroundColor: "#eeeeee",
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    overflow: "auto",
    padding: theme.spacing(2, 2, 3),
    textAlign: "center",
    alignItems: "center",
    color: "black",
    minWidth: 300,
    maxWidth: 450,
    maxHeight: 600,
    "div::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0, 0, 0, 0.3)",
      borderRadius: "20px",
      backgroundColor: "rgba(51, 51, 51, 0.2)",
    },
  },
  paper: {
    display: "inline-flex",
    flexWrap: "wrap",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    padding: "3px",
    minHeight: 100,
    height: "100%",
    width: "100%",
    transition: "all 700ms ease-out 30ms",
  },
  title: {
    fontSize: 18,
    padding: "1px 10px 10px 10px",
  },
  center: {
    width: "100%",
    textAlign: "center",
  },
  textfield: {
    margin: '15px auto 10px auto',
    width: "80%",
    textAlign: "center",
  },
  buttons: {
    width: "100%",
    margin: "30px auto -20px auto",
  },
});

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

export class CreateDiaryEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      file: null,
      saving: false,
    };
  }
  componentDidMount(){
    if(!!this.props.newEntry){
        this.setState({description: ""});
    }
    else{
        this.setState({description: this.props.dataEntry.description});
    }
  }
  
  handleChange = (event) => {
    this.setState({ description: event.target.value });
  };
  handleSubmit = () => {
    this.setState({ saving: true });
  };
  handleClose = () => {
    this.setState({ saving: false });
    this.props.handleClose(this.props.abortController)
  }

  render() {
    const { classes, t, open } = this.props;
    return (
      <div>
        <Modal
          id="createDiaryEntry"
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          className={classes.modal}
          open={open}
          disableBackdropClick={this.state.saving}
          onClose={() => this.handleClose()}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.props.open}>
            <div className={classes.modalcontent}>
              <Typography className={classes.title} id="new-modal-title">
                {t("WorkDiaryMarking")}
              </Typography>
              <Paper className={classes.paper}>
                {!this.state.file ? (
                  <FileDragAndDrop
                    t={t}
                    blobProgress={this.props.blobProgress}
                    BlobActions={this.props.BlobActions}
                    close={this.props.handleClose}
                    description={this.state.description}
                    saveDataEntry={this.props.saveDataEntry}
                    abortController={this.props.abortController}
                    saving={this.state.saving}
                    className={classes.center}
                    dataEntryId={this.props.dataEntryId}
                    workOrderId={this.props.workOrderId}
                    user={this.props.user}
                    file={!!this.props.newEntry ? null : this.props.dataEntry}
                  ></FileDragAndDrop>
                ) : (
                  ""
                )}
                <div style={{ width: "100%" }}>
                  {/* <div><Help style={{color:'gray',display:'inline-block'}}/><p>{t('FileUploadInfo')}</p></div> */}
                  <TextField
                    className={classes.textfield}
                    id="outlined-multiline-static"
                    variant="outlined"
                    label={t("Description")}
                    multiline
                    rows={6}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={this.state.description}
                    onChange={this.handleChange}
                  />
                </div>
              </Paper>
              <div className={classes.buttons}>
              {(!!this.state.saving && this.props.blobProgress.size >= 0) && (
                <LinearDeterminate
                  saving={this.state.saving}
                  progress={this.props.blobProgress}
                ></LinearDeterminate>
              )}
                <Button
                  children={null}
                  id="cancelFileUpload"
                  className="upButtonWithText"
                  color="primary"
                  onClick={() => this.handleClose()}
                  variant="contained"
                  style={{ margin: "20px 25px"}}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  children={null}
                  
                  disabled={this.state.saving}
                  id="submitFileUpload"
                  onClick={() => this.handleSubmit()}
                  color="primary"
                  variant="contained"
                  style={{ margin: "20px 25px" }}
                >
                  {t("Save")}
                </Button>
              </div>{" "}
         
            </div>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default compose(
  withTranslation("common"),
  withStyles(styles)
)(CreateDiaryEntry);
