import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import { fade, withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import SearchContainer from './SearchContainer';
import IconButton from '@material-ui/core/IconButton';
import { Transition } from 'react-spring/renderprops.cjs.js';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

const styles = theme => ({
  searchButton: {
    cursor: 'pointer',
    borderRadius: '30px',
    padding: '11px',
    fontSize: 18,
    right: '58px',
    color: 'black',
    position: 'absolute',
    backgroundColor: 'white',
    webkitBoxShadow: "1px 3px 1px #9E9E9E",
    mozBoxShadow: "1px 3px 1px #9E9E9E",
    boxShadow: "1px 3px 1px #9E9E9E",
    marginTop: ' -40px',
  },
  searchIcon:{
    fontSize: 18,
  },
  containerRoot:{
    transition: 'all 700ms cubic-bezier(0.19, 0.59, 0, 1.25) 30ms',
  },
  boxContent:{
    height:'1200px'
  },
  inputRoot: {
    flexGrow: 1,
    color: 'inherit',
    padding: '4px 4px 4px 2px',
    borderBottom: 'black solid 1px',
    transition: 'all 700ms cubic-bezier(0.19, 0.59, 0, 1.25) 30ms',
    backgroundColor: fade(theme.palette.common.white, 0.20),
    '&:focus': {
      backgroundColor: fade(theme.palette.common.white, 0.50),
      transition: 'all 700ms cubic-bezier(0.19, 0.59, 0, 1.25) 30ms;',
    },

    '@media (max-width:1200px)': {
      width: 0,
    }
  },
});


export class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: '',
      opened: false,
      isSearchLoading: false,
      searchBarWidth: 200,
      searchBarPadding: 16
    };

    this.showResults = this.showResults.bind(this);
    this.closeContainer = this.closeContainer.bind(this);
  }
  componentDidMount() {
    if (this.props.width < 768) {
      this.setState({
        searchBarWidth: 0,
        searchBarPadding: 0
      })
    }
  }
  
  closeContainer(){
    // for clicking item in searchcontainer     
    if (this.props.unifiedSearchSettingsOpen === false) {
        this.props.actions.showModuleTitle(true)
    const { width } = this.props;
    if (width < 768) {
      this.setState({
        searchBarWidth: 0,
        searchBarPadding: 0
      })
    } else {
      this.setState({ 
        searchBarWidth: 200 
      });
    }
    this.setState({
      opened: false,
    });
  } 
}

  showResults() {
    const { opened } = this.state;
    const { width } = this.props;
    if (width < 400) {
      this.setState({
        searchBarWidth: 210,
        searchBarPadding: 5
      });
    }
    else if (width < 460) {
      this.setState({
        searchBarWidth: 240,
        searchBarPadding: 10
      });
    }
    else if (width < 768 && width > 459) {

      this.setState({
        searchBarWidth: 300,
        searchBarPadding: 16
      });
    } else if (width < 1200 && width > 768) {
      this.setState({
        searchBarWidth: 400,
        searchBarPadding: 16
      });
    }
    else {
      this.setState({ searchBarWidth: 450 });

    }
    if (!opened) {

      this.setState({opened: true});
    }
  }

  updateSearch(input) {

    this.setState({ searchInput: input });

    if (input.length > 2) {
      this.setState({
        isSearchLoading: true,
      });
      this.props.actions.readQuickSearchItems(input)
        .then(() => {
          this.setState({ isSearchLoading: false })
        })
        .catch(() => { })
    }

    else {
      this.setState({ searchresults: null });
    }
  }

  render() {
    var { searchcontainer, t } = this.props;
    const { opened, searchBarWidth, searchBarPadding } = this.state;
    const { classes, width } = this.props;
    var containermargin = 'translate3d(-1px, 5px, 0px)';
    if (width < 768 && width > 400) {
      containermargin = 'translate3d(-5px, 7px, 0px)';
    }  
    else if (width < 400) {
      containermargin = 'translate3d(-30px, 7px, 0px)';
    }

    if (opened) {
      searchcontainer = <SearchContainer
        searchInput={this.state.searchInput}
        searchItems={this.props.searchItems}
        sugarHistory={this.props.sugarHistory}
        close={this.closeContainer}
        actions={this.props.actions}
        unifiedSearchSettings={this.props.unifiedSearchSettings}
        isSearchLoading={this.state.isSearchLoading}
        width={this.props.width}></SearchContainer>
    } else {
      searchcontainer = null;
    }

    return (
      <div className={classes.containerRoot} style={{width:searchBarWidth}}>
        <form id="navBarUnifiedSearch" name='UnifiedSearchAdvancedMain' action='yerp' method='get' onSubmit={this.closeContainer} >
          <input type='hidden' name='module' value='Home' />
          <input type='hidden' name='query_string' />
          <input type='hidden' name='advanced' value='true' />
          <input type='hidden' name='action' value='UnifiedSearch' />
          <input type='hidden' name='search_form' value='false' />


            

          <div id="TopSearchBar" onClick={this.showResults}>


                    {
                        //this.props.user.profile.name
                    }


            <InputBase
              id="qsInput"
              name='query_string'
              onChange={event => this.updateSearch(event.target.value)}
              placeholder={t('SearchPH')}
              classes={{root: classes.inputRoot}}
              style={{
                width: searchBarWidth,
                paddingRight: searchBarPadding,
              }}
              inputProps={{
                'aria-label': 'search',
                'autoComplete': "off",
                'name': 'query_string',
              }}
            />
          </div>
        </form>
        <IconButton id="QuickSearchIcon" className={classes.searchButton} onClick={this.showResults} >
        <SearchIcon className={classes.searchIcon}/>

        </IconButton>
        {opened && (
          <div className={classes.boxContent}
            >
            <Transition
              items={1}
              from={{ transform: 'translate3d(40px,-5px, 0)' }}
              enter={{ transform: containermargin}}
              leave={{ transform: 'translate3d(40px,-40px,0)' }}>
              {show => show && (props => <div style={props}>{searchcontainer}</div>
              )}
            </Transition>

          </div>
        )}
      </div>
    );
  }
}




export default compose(withTranslation('common'), withStyles(styles))(SearchBar);