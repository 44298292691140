import { useDropzone } from "react-dropzone";
import * as styles from "./styles";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton } from "@material-ui/core";
import UploadPreview from "../common/uploadPreview";
import * as constants from "../common/constants";

export const FileDragAndDrop = (props) => {
  const [file, setFile] = useState();
  const [newBlob, setNewBlob] = useState();
  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    multiple: false,
    maxSize: 1000000000,
    onDropAccepted: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
      setNewBlob(true);
    },

    onDropRejected: (rejectedFiles) => {
      if (rejectedFiles[0].size > 1000000000)
        alert(
          props.t("file ") +
            rejectedFiles[0].name +
            " is too large " +
            rejectedFiles[0].size +
            " / " +
            100000000
        );
    },
  });

  const uploadFiles = async (file) => {
    props.saveDataEntry(createData(file), file ? file : {}, props.abortController)
      .then((result) => {
        props.close();
      });
  };

  useEffect(() => {
    if (props.saving === true) 
      uploadFiles(file);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.saving]);

  useEffect(() => {
    if (props.dataEntryId) {
      setFile(props.file);
      if (props.saving === false) 
      setNewBlob(false);
    }
  }, [props.file,props.dataEntryId,props.saving]);

  const removeFile = () => {
    setFile();
    setNewBlob(true);
  };

  const getBlobType = (file) => {
    var blobType;
    if (file.blobType)
      blobType =
        file.blobType.toLowerCase() in constants.fileType
          ? file.blobType
          : "file";
    else
      blobType =
        file.type.split("/")[0].toLowerCase() in constants.fileType
          ? file.type.split("/")[0]
          : "file";
    return blobType;
  };

  const createData = (file) => {
    var data = {};
    const uploadTime = new Date().toISOString();
    
    if (!props.dataEntryId) {
      data = {
        documentId: uuidv4(),
        blobType: getBlobType(file),
        workOrderId: props.workOrderId,
        filename: file ? file.name.split(".")[0] : "-",
        fileExtension: file ? file.name.split(".")[1] : "",
        mimeType: file ? file.type : "text/application",
        description: props.description,
        timeCreated: uploadTime,
        state: constants.Added,
      };
    } else {
      if (props.file) {
        data = props.file;
        if (file) {
          data = {
            id: props.dataEntryId,
            documentId: file.documentId ? file.documentId : uuidv4(),
            blobType: getBlobType(file),
            workOrderId: props.workOrderId,
            filename: file.filename ? file.filename : file.name.split(".")[0],
            fileExtension:
              file.fileExtension !== undefined
                ? file.fileExtension
                : file.name.split(".")[1],
            mimeType: file.mimeType ? file.mimeType : file.type,
            description: props.description,
            timeCreated: props.file.timeCreated,
            timeModified: uploadTime,
            state: constants.Modified,
          };
        }
      }
    }
    return data;
  }



  return (
    <div
      style={{ cursor: "pointer", width: "80%", margin: "10px auto auto auto" }}
    >
      {file ? (
        <div style={{ width: "100%" }}>
          <UploadPreview
            BlobActions={props.BlobActions}
            saving={props.saving}
            file={file}
            blobType={getBlobType(file)}
            newBlob={newBlob}
            thumbWidth={"80%"}
            thumbHeight={"200px"}
          ></UploadPreview>
          <IconButton
            id="removeFile"
            style={{ display: "inline-flex", top: "-10px" }}
            onClick={() => removeFile()}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ) : (
        <div
          id="emptyDragAndDrop"
          style={styles.dragAndDrop}
          {...getRootProps({ className: "dropzone" })}
        >
          <input {...getInputProps()} />
          <p>{props.t("DragAndDropPH")}</p>
        </div>
      )}
    </div>
  );
};

export default FileDragAndDrop;
