import React, { Component } from 'react';
import { Grid, Box, Modal, Backdrop , Fade, Button    } from '@material-ui/core';
import SignaturePad from 'react-signature-pad-wrapper';
import { Gesture } from '@material-ui/icons';
import * as styles from '../common/styles.js';
import { withTranslation   } from 'react-i18next';


export class Approver extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            open : false,
            width: 0,
            height: 0, 
            signature: false,
            isEmpty : true
        }
    }

    updateDimensions() {
        var aspectRatio;
        if (!window.orientation || window.orientation === 0) {
            aspectRatio = ( window.innerWidth / window.innerHeight );
            if (parseFloat(aspectRatio) > 1.77 && parseFloat(aspectRatio) < 1.78) // ratio 16:9
            {
                this.setState({
                    width: window.innerWidth,
                    height: window.height

                }); 
            }
            else if (parseFloat(aspectRatio) > 1.77 && parseFloat(aspectRatio) < 1.78) // ratio 17:10
            {
                this.setState({
                    width: window.innerWidth,
                    height: window.height

                }); 
            }
            else if (parseFloat(aspectRatio) > 1.6 && parseFloat(aspectRatio) < 1.71) // ratio 16:10
            {
                this.setState({
                    width: window.innerWidth,
                    height: window.height

                }); 
            }
            else if (parseFloat(aspectRatio) >= 1.5 && parseFloat(aspectRatio) < 1.6) // ratio 3:2
            {
                this.setState({
                    width: window.innerWidth,
                    height: window.height

                }); 
        
            }
            else if (parseFloat(aspectRatio) > 1.3 && parseFloat(aspectRatio) < 1.4) // ratio 4:3
            {
                this.setState({
                    width: window.innerWidth,
                    height: window.height

                }); 
               
            }
            else {
                this.setState({
                    width: window.innerWidth - (window.innerWidth * .40),
                    height: window.height  - (window.height * .40)

                }); 
            }
            
        }
        else {
            
            aspectRatio = ( window.innerWidth / window.innerHeight );
            if (parseFloat(aspectRatio) > 0.56 && parseFloat(aspectRatio) < 0.57) // ratio 16:9
            {
                this.setState({
                    width: window.innerWidth + (window.innerWidth * .50 ),
                    height: window.height

                }); 
            }
            else if (parseFloat(aspectRatio) > 0.58 && parseFloat(aspectRatio) < 0.59) // ratio 17:10
            {
                this.setState({
                    width: window.innerWidth,
                    height: window.height

                }); 
            }
            else if (parseFloat(aspectRatio) > 0.5 && parseFloat(aspectRatio) < 0.6) // ratio 16:10
            {
                this.setState({
                    width: window.innerWidth,
                    height: window.height

                }); 
            }
            else if (parseFloat(aspectRatio) > 0.66 && parseFloat(aspectRatio) < 0.7) // ratio 3:2
            {
                this.setState({
                    width: window.innerWidth + (window.innerWidth * .50 ),
                    height: window.height

                }); 
            }
            else if (parseFloat(aspectRatio) >= 0.75 && parseFloat(aspectRatio) < 0.76) // ratio 4:3
            {
                this.setState({
                    width: window.innerWidth  + (window.innerWidth * .30),
                    height: window.height

                }); 
            }
            else {
                this.setState({
                    width: window.innerWidth - (window.innerWidth * .40),
                    height: window.height  - (window.height * .40)

                }); 
            }
        }
   
      }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
       
    }
    
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    /*
    static propTypes = {
        approver: PropTypes.shape({
            name: PropTypes.string,
            email: PropTypes.string,
        })
    }*/

    static defaultProps = {
        approver: ""
    }

    handleBack = () => {
        this.setState({ open : false})
    };

    handleOpen = () => {
        this.setState({ open : true})
    };

    handleClear = () => {
        this.signaturePad.clear();
    };

    handleSave = async () => {
        if (!this.props.isTesting) {
            this.setState({
                isEmpty : await this.signaturePad.isEmpty()
            })
        }
        if (!this.state.isEmpty) {
            this.sendSignature();
            this.sendSignatureDate();
            this.setState({ signature : true})
            this.props.onShowSaveButton(true);
            this.props.saveCard();
            //this.close();
        }
    };
   
    sendSignature() {
        var event = {
            target: {
                id: "signature",
                value: this.signaturePad.toDataURL(),
            }
        }
        if (this.props.isTesting) {
           this.setState({
               eventSignature : event
           })
        }
        this.props.onChange(event);
    }

    sendSignatureDate() {
        const now = new Date();
        var event = {
            target: {
                id: "signatureDate",
                value: now.toISOString()
            }
        }
        if (this.props.isTesting) {
           this.setState({
               eventSignatureDate : event
           })
        }
        this.props.onChange(event);
    }
   
    handleClose = () => {
       this.close();
    };
  
    close = () => {
        this.setState({ open : false})
    };
   
    render() {
        const { t } = this.props; 
        return (
            <div>
                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="flex-end"
                    style={{ marginBottom : "30px",  marginTop : "30px"   }}
                >
                        <Button id="signature" variant="contained" color="primary" onClick={() => this.handleOpen()} disabled={!this.props.approver}>
                            <Gesture style={styles.leftIcon} />
                            {t("Signature")}
                        </Button>
                </Grid>
                <Modal
                    id="ModalSignature"
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={styles.modal}
                    open={this.state.open}
                    onClose={() => this.handleClose()}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={this.state.open}  >
                        <div style={{ ...{ width: this.state.width }, ...styles.modalPaper }}>
                            <Box style={{ margin: "10px" }}>{t("ApprovalClause")}</Box>
                            <Box border={2} borderColor="grey.500" borderRadius="borderRadius" style={{margin: "10px", borderRadius : "5px"}} >
                                    <SignaturePad ref={ref => this.signaturePad = ref} redrawOnResize={true} />
                            </Box>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                style={{ marginBottom : "10px"  }}
                            >
                                <Grid item xs={4} style={{ textAlign : 'center'}}>
                                    <Button id="closeSignature" onClick={() => this.handleBack()} variant="contained" >
                                    {t("Back")}
                                    </Button >  
                                </Grid>
                                <Grid item xs={4} style={{ textAlign : 'center'}}>
                                    <Button id="clearSignature"  color="secondary" onClick={() => this.handleClear()} variant="contained" >
                                        {t("Clear")}
                                    </Button >  
                                </Grid>
                                <Grid item xs={4} style={{ textAlign : 'center'}}>
                                    <Button id="saveSignature"  color="primary" onClick={() => this.handleSave()} variant="contained" >
                                        {t("Approve")}
                                    </Button >  
                                </Grid>
                            </Grid>
                           
                        </div>
                    </Fade>
                   
                </Modal>
            </div>
        )
    }
};

export default withTranslation('common')(Approver);
