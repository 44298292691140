import React, { Component } from 'react';
import { RolesList } from "../common/Lists/rolesList";
import { connect } from 'react-redux';
import *  as rolesActions from '../../redux/actions/rolesActions';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import CancelPromisesHOC   from "../../helpers/cancelPromisesHOC";
import { withRouter } from "react-router-dom";
import *  as navigationActions from '../../redux/actions/navigationActions';
import {setCurrentModule} from "../../utils/navigationUtil";


export class Roles  extends Component {    
    componentDidMount(){
        this.props.actions.setModule(setCurrentModule('/Roles'));
    }
    render() {
        return (
            <RolesList {...this.props} />
        )
    };
}

function mapStateToProps(state) {
    return {
        roles : state.roles,
        moduleInfo: state.navigation.module
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            readRoles: bindActionCreators(rolesActions.readRoles, dispatch),
            updateRole: bindActionCreators(rolesActions.updateRole, dispatch),
            setModule: bindActionCreators(navigationActions.setModule, dispatch)
        }
    };
  }

export default compose(withTranslation('common'),
CancelPromisesHOC,
connect(
    mapStateToProps,
    mapDispatchToProps,
))(withRouter(Roles));