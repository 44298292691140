import React, { Component } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions

} from '@material-ui/core';
import "../../index.css";
import { withTranslation } from 'react-i18next';

export class DialogModal  extends Component {
    render() {
        const { t } = this.props;
        return (
            <Dialog 
                open={this.props.open}
                onClose={this.handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{this.props.questionDialog}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.props.messageDialog}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.handleReply(false)} >
                        {t("Cancel")}
                    </Button>
                    <Button onClick={() => this.props.handleReply(true)} color="primary" >
                        {t("Delete")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    };
}

export default withTranslation('common')(DialogModal);
