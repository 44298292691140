export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";
export const LOAD_LOGIN_PROPERTIES_SUCESS = 'LOAD_LOGIN_PROPERTIES_SUCESS';
export const READ_TENANT_SUCCESS = "READ_TENANT_SUCCESS";
export const GET_ALL_AVAILABLE_TENANTS_SUCCESS = "GET_ALL_AVAILABLE_TENANTS_SUCCESS";
export const SELECT_TENANT_SUCCESS = "SELECT_TENANT_SUCCESS";
export const TENANT_SESSION_ACTIVATED = "TENANT_SESSION_ACTIVATED";
export const TENANT_SESSION_DEACTIVATED = "TENANT_SESSION_DEACTIVATED";
export const LOAD_WORKCARDS_SUCCESS = "LOAD_WORKCARDS_SUCCESS";
export const READ_WORKCARD_SUCCESS = "READ_WORKCARD_SUCCESS";
export const CREATE_WORKCARD_SUCCESS = "CREATE_WORKCARD_SUCCESS";
export const UPDATE_WORKCARD_SUCCESS = "UPDATE_WORKCARD_SUCCESS";
export const DELETE_WORKCARD_SUCCESS = "DELETE_WORKCARD_SUCCESS";
export const NEW_WORKCARD_SUCCESS = "NEW_WORKCARD_SUCCESS";
export const LOAD_TRANSLATIONS_SUCCESS = "LOAD_TRANSLATIONS_SUCCESS";
export const UPDATE_TEMPLATES_SUCCESS = "UPDATE_TEMPLATES_SUCCESS";
export const RESET_PDF_WORKCARD_SUCCESS = "RESET_PDF_WORKCARD_SUCCESS";
export const PREVIEW_PDF_WORKCARD_SUCCESS = "PREVIEW_PDF_WORKCARD_SUCCESS";
export const RENDER_PDF_WORKCARD_DOCUMENT_SUCCESS = "RENDER_PDF_WORKCARD_DOCUMENT_SUCCESS";
export const SEND_PDF_WORKCARD_DOCUMENT_LINK_SUCCESS = "SEND_PDF_WORKCARD_DOCUMENT_LINK_SUCCESS";
export const NOTIFICATION_DEBUG = "NOTIFICATION_DEBUG";
export const NOTIFICATION_INFO = "NOTIFICATION_INFO";
export const NOTIFICATION_ERROR = "NOTIFICATION_ERROR";
export const NOTIFICATION_WARNING = "NOTIFICATION_WARNING";
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";
export const READ_PERSON_SUCCESS = "READ_PERSON_SUCCESS";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const CREATE_PERSON_SUCCESS = "CREATE_PERSON_SUCCESS";
export const UPDATE_PERSON_SUCCESS = "UPDATE_PERSON_SUCCESS";
export const DELETE_PERSON_SUCCESS = "DELETE_PERSON_SUCCESS";
export const LOAD_PRODUCTREGISTER_SUCCESS = "LOAD_PRODUCTREGISTER_SUCCESS";
export const READ_PRODUCTREGISTER_SUCCESS = "READ_PRODUCTREGISTER_SUCCESS";
export const CREATE_PRODUCTREGISTER_SUCCESS = "CREATE_PRODUCTREGISTER_SUCCESS";
export const UPDATE_PRODUCTREGISTER_SUCCESS = "UPDATE_PRODUCTREGISTER_SUCCESS";
export const DELETE_PRODUCTREGISTER_SUCCESS = "DELETE_PRODUCTREGISTER_SUCCESS";
export const LOAD_PRODUCTGROUP_SUCCESS = "LOAD_PRODUCTGROUP_SUCCESS";
export const READ_PRODUCTGROUP_SUCCESS = "READ_PRODUCTGROUP_SUCCESS";
export const CREATE_PRODUCTGROUP_SUCCESS = "CREATE_PRODUCTGROUP_SUCCESS";
export const UPDATE_PRODUCTGROUP_SUCCESS = "UPDATE_PRODUCTGROUP_SUCCESS";
export const DELETE_PRODUCTGROUP_SUCCESS = "DELETE_PRODUCTGROUP_SUCCESS";
export const LOAD_PRODUCTUNIT_SUCCESS = "LOAD_PRODUCTUNIT_SUCCESS";
export const READ_PRODUCTUNIT_SUCCESS = "READ_PRODUCTUNIT_SUCCESS";
export const CREATE_PRODUCTUNIT_SUCCESS = "CREATE_PRODUCTUNIT_SUCCESS";
export const UPDATE_PRODUCTUNIT_SUCCESS = "UPDATE_PRODUCTUNIT_SUCCESS";
export const DELETE_PRODUCTUNIT_SUCCESS = "DELETE_PRODUCTUNIT_SUCCESS";
export const LOAD_PRODUCTUNITBATCH_SUCCESS = "LOAD_PRODUCTUNITBATCH_SUCCESS";
export const UI_RESIZE_WINDOW = "UI_RESIZE_WINDOW";
export const BEGIN_LOGIN = "BEGIN_LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const BEGIN_LOGOUT = "BEGIN_LOGOUT";
export const SILENT_RENEW_SUCCESS = "SILENT_RENEW_SUCCESS";
export const BEGIN_SILENT_RENEW = "BEGIN_SILENT_RENEW";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOAD_LOGGED_IN_SUGAR_INSTANCES_SUCCESS = "LOAD_LOGGED_IN_SUGAR_INSTANCES_SUCCESS";
export const SUGAR_LOGIN_SUCCESS = "SUGAR_LOGIN_SUCCESS";
export const SUGAR_LOGOUT_SUCCESS = "SUGAR_LOGOUT_SUCCESS";
export const UPDATE_CUSTOM_RESOURCE_ACCESS_SUCCESS = "UPDATE_CUSTOM_RESOURCE_ACCESS_SUCCESS";
export const UPDATE_UNIFIED_SEARCH_SETTINGS_SUCCESS = "UPDATE_UNIFIED_SEARCH_SETTINGS_SUCCESS";
export const READ_UNIFIED_SEARCH_SETTINGS_SUCCESS = "READ_UNIFIED_SEARCH_SETTINGS_SUCCESS";
export const READ_QUICK_SEARCH_ITEMS_SUCCESS = "READ_QUICK_SEARCH_ITEMS_SUCCESS";
export const READ_SUGAR_PAGE_HISTORY_SUCCESS = "READ_SUGAR_PAGE_HISTORY_SUCCESS";
export const SET_MODULE = "SET_MODULE";
export const SET_USERNAME = "SET_USERNAME";
export const LOAD_CONTACT_SUCCESS = "LOAD_CONTACT_SUCCESS";
export const READ_CONTACT_SUCCESS = "READ_CONTACT_SUCCESS";
export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const LOAD_CUSTOMER_SUCCESS = "LOAD_CUSTOMER_SUCCESS";
export const READ_CUSTOMER_SUCCESS = "READ_CUSTOMER_SUCCESS";
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const READ_CATALOG_SUCCESS = "READ_CATALOG_SUCCESS";
export const UPDATE_CATALOG_SUCCESS = "UPDATE_CATALOG_SUCCESS";
export const LOAD_WORKSITE_SUCCESS = "LOAD_WORKSITE_SUCCESS";
export const READ_WORKSITE_SUCCESS = "READ_WORKSITE_SUCCESS";
export const CREATE_WORKSITE_SUCCESS = "CREATE_WORKSITE_SUCCESS";
export const UPDATE_WORKSITE_SUCCESS = "UPDATE_WORKSITE_SUCCESS";
export const DELETE_WORKSITE_SUCCESS = "DELETE_WORKSITE_SUCCESS";
export const LOAD_WORKORDER_SUCCESS = "LOAD_WORKORDER_SUCCESS";
export const READ_WORKORDER_SUCCESS = "READ_WORKORDER_SUCCESS";
export const CREATE_WORKORDER_SUCCESS = "CREATE_WORKORDER_SUCCESS";
export const UPDATE_WORKORDER_SUCCESS = "UPDATE_WORKORDER_SUCCESS";
export const DELETE_WORKORDER_SUCCESS = "DELETE_WORKORDER_SUCCESS";
export const READ_RALTIME_MESSAGE_SUCCESS = ".READ_RALTIME_MESSAGE_SUCCESS";
export const LOAD_WORKORDER_DIARYENTRY_SUCCESS = "LOAD_WORKORDER_DIARYENTRY_SUCCESS";
export const READ_WORKORDER_DIARYENTRY_SUCCESS = "READ_WORKORDER_DIARYENTRY_SUCCESS";
export const CREATE_WORKORDER_DIARYENTRY_SUCCESS = "CREATE_WORKORDER_DIARYENTRY_SUCCESS";
export const UPDATE_WORKORDER_DIARYENTRY_SUCCESS = "UPDATE_WORKORDER_DIARYENTRY_SUCCESS";
export const DELETE_WORKORDER_DIARYENTRY_SUCCESS = "DELETE_WORKORDER_DIARYENTRY_SUCCESS";
export const UPDATE_BLOB_PROGRESS = "UPDATE_BLOB_PROGRESS";

export const LOAD_WORKQUEUE_SUCCESS = "LOAD_WORKQUEUE_SUCCESS";
export const READ_WORKQUEUE_SUCCESS = "READ_WORKQUEUE_SUCCESS";
export const CREATE_WORKQUEUE_SUCCESS = "CREATE_WORKQUEUE_SUCCESS";
export const UPDATE_WORKQUEUE_SUCCESS = "UPDATE_WORKQUEUE_SUCCESS";
export const DELETE_WORKQUEUE_SUCCESS = "DELETE_WORKQUEUE_SUCCESS";

export const LOAD_WORKQUEUESETTINGS_SUCCESS = "LOAD_WORKQUEUESETTINGS_SUCCESS";
export const READ_WORKQUEUESETTINGS_SUCCESS = "READ_WORKQUEUESETTINGS_SUCCESS";
export const CREATE_WORKQUEUESETTINGS_SUCCESS = "CREATE_WORKQUEUESETTINGS_SUCCESS";
export const UPDATE_WORKQUEUESETTINGS_SUCCESS = "UPDATE_WORKQUEUESETTINGS_SUCCESS";
export const DELETE_WORKQUEUESETTINGS_SUCCESS = "DELETE_WORKQUEUESETTINGS_SUCCESS";

export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";

export const SHOW_MODULE_TITLE = "SHOW_MODULE_TITLE";
export const SET_SEARCH_SETTINGS_OPEN = "SET_SEARCH_SETTINGS_OPEN";
export const SESSION_ACTIVE = "SESSION_ACTIVE";
export const READ_RALTIME_MESSAGE_SEND = "READ_RALTIME_MESSAGE_SEND";
export const RALTIME_CONNECTION_ACTIVE = "RALTIME_CONNECTION_ACTIVE";
export const RALTIME_CONNECTION_SIGNAL_ACTIVE = "RALTIME_CONNECTION_SIGNAL_ACTIVE";
export const LOAD_DATA_USERS_SUCCESS = "LOAD_DATA_USERS_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const CHECK_USER_NAME_SUCCESS = "CHECK_USER_NAME_SUCCESS";
export const READ_USER_SUCCESS = "READ_USER_SUCCESS";
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
export const LOAD_ROLES_SUCCESS = "LOAD_ROLES_SUCCESS";
export const READ_ROLES_SUCCESS = "READ_ROLES_SUCCESS";
export const UPDATE_ROLES_SUCCESS = "UPDATE_ROLES_SUCCESS";


