import * as types from "./actionTypes";
import * as workOrderDiaryEntryApi from "../../api/workOrderDiaryEntryApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";
import * as constants from "../../components/common/constants";

export function readWorkOrderDiaryEntriesSuccess(workOrderDiaryEntries) {
    return { type: types.LOAD_WORKORDER_DIARYENTRY_SUCCESS, workOrderDiaryEntries };
}

export function readWorkOrderDiaryEntries(sort, page, workOrderId, cancelToken) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return workOrderDiaryEntryApi
        .ReadWorkOrderDiaryEntries(sort, page, workOrderId, cancelToken)  
        .then(workOrderDiaryEntries => {
            dispatch(readWorkOrderDiaryEntriesSuccess(workOrderDiaryEntries));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (typeof(error.message) !== "undefined" &&
                error.message !== constants.cancelHttpRequest) {
                dispatch(notifications.notificationError("ErrorloadingWorkOrderDiaryEntries"));
                throw new Error(error);
            }
        });
    };
} 

export function readWorkOrderDiaryEntrySuccess(workOrderDiaryEntry) {
    return { type: types.READ_WORKORDER_DIARYENTRY_SUCCESS, workOrderDiaryEntry };
}

export function readWorkOrderDiaryEntry(id) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return workOrderDiaryEntryApi
        .ReadWorkOrderDiaryEntry(id)  
        .then(workOrderDiaryEntry => {
            dispatch(readWorkOrderDiaryEntrySuccess(workOrderDiaryEntry));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorloadingWorkOrderDiaryEntry"));
            throw new Error(error);
        });
    };
} 

export function updateWorkOrderDiaryEntrySuccess(workOrderDiaryEntry) {
    return { type: types.UPDATE_WORKORDER_DIARYENTRY_SUCCESS, workOrderDiaryEntry };
}

export function updateWorkOrderDiaryEntry(workOrderDiaryEntry,cancelToken) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return workOrderDiaryEntryApi
        .updateWorkOrderDiaryEntry(workOrderDiaryEntry,cancelToken)  
        .then(workOrderDiaryEntry => {
            dispatch(updateWorkOrderDiaryEntrySuccess(workOrderDiaryEntry));
            return workOrderDiaryEntry;
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorupdatingWorkOrderDiaryEntry"));
            throw new Error(error);
        });
    };
} 

