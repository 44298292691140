import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import *  as workSiteActions from '../../redux/actions/workSiteActions';
import *  as customersActions from '../../redux/actions/customersActions';
import *  as contactsActions from '../../redux/actions/contactsActions';
import *  as workOrdersActions from '../../redux/actions/workOrderActions';
import { bindActionCreators, compose } from 'redux';
import {
    Card,
    CardContent,
    Container,
    Grid,
    Typography
} from '@material-ui/core';
import UpTextField from '../common/upTextField';
import * as styles from '../common/styles.js';
import "../../index.css";
import Spinner from '../common/CircularSpinner';
import { withTranslation } from 'react-i18next';
import * as constants from "../common/constants";
import initialState from "../../redux/reducers/initialState";
import { Breadcrumb } from "../common/Breadcrumb";
import CancelPromisesHOC   from "../../helpers/cancelPromisesHOC";
import { Cancel } from '@material-ui/icons';
import { Save } from '@material-ui/icons';
import { withRouter } from "react-router-dom";
import { SearchOne } from "../common/SearchOne";
import {ScrollableTabs} from '../common/ScrollableTabs';
import { BottomBar } from "../common/BottomBar";
import *  as catalogsActions from '../../redux/actions/catalogsActions';
import NumberField from '../common/NumberField';
import ContactsWorkSiteList from "../common/Lists/contactsWorksSiteList";
import UpDatePicker  from '../common/upDatePicker';
import WorkOrderList from "../common/Lists/workOrderList";

export class WorkSite  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workSiteId: this.props.selectId ? this.props.selectId : this.props.match.params.workSiteId,
            isLoading : false,
            workSite : initialState.workSite,
            Customer: "",
            CustomerId: 0,
            rows: [],
            index: -1,
           
        }
    }

    async componentDidMount() {
        if (this.state.workSiteId !== constants.New) {
            if (!this.props.isTesting) {
                this.setState({
                    isLoading : true,
                });
            }
        
            await this.props.actions.readWorkSite(this.state.workSiteId)
            .then(() => {
            })
            .catch(() => { })
            .finally(result => {
            })

            if (this.props.workSite.customerNumber !== 0) {
                this.setState({
                    CustomerOriginal : this.props.workSite.customerNumber
                })
                await this.props.actions.readCustomer(this.props.workSite.customerNumber)
                .then(() => {
                    this.handleOneSelect(this.props.customer, "customerNumber");
                })
                .catch(() => { })
                .finally(result => {
                    if (!this.state.isTesting) {
                        this.setState({
                            isLoading: false,
                        });
                    }
                })
            }
            else {
                if (!this.state.isTesting) {
                    this.setState({
                        isLoading: false,
                    });
                }
            }
        }
        else if (this.props.isAllOptions) {
            this.handleOneSelect(this.props.workSite, "workSiteNumber");
        }
    }
   
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.workSite !== this.props.workSite) {
            var temp = this.props.workSite;
            if(!temp.hasOwnProperty("startTime")){
                temp.startTime = new Date();
            }
            if(!temp.hasOwnProperty("deliveryTime")){
                temp.deliveryTime = new Date();
            }
           
           this.setState({
               workSite : this.props.workSite,
               rows: this.props.workSite.contacts
              
           });
        }
    }

    handleOneSelect = (row, id) => {
        if (row.id) {
            if (id === "customerNumber") {
                this.setState({
                    Customer: row.name,
                    CustomerId: row.id,
                });
            }
 
            this.handleChange({
                target: {
                    id: id,
                    value: row.id
                }
            });
        }

    }


    cleanFilter = (id) => {
        if (id === "customerNumber") {
            this.setState({
                Customer: "",
                CustomerId: 0,
            });
        }
       
        this.handleChange({
            target: {
                id: id,
                value: 0
            }
        });

    }
    
    handleChange = (event) => {
      
        const { id, value } = event.target;
        this.setState(prevState => ({
            ...prevState,
            workSite: {
                ...prevState.workSite,
                [id]: value,
            },
        }));

    }

    back = () => {
        if (this.props.isAllOptions) {
            this.props.handleBackModify();
        }
        else {
            var path = "/workSites";

            if (this.props.isTesting) {
                this.setState({
                    backPathTest: path,
                });
            }
    
            this.props.history.push(path);
        }
    }

    save = async () => {
        if (!this.props.isLoading) {
            this.setState({
                isLoading: true,
            });
        }

        var data = {...this.state.workSite};
        if (this.state.workSiteId === constants.New) {
            data.state = constants.Added;
        }
        else {
            data.state = constants.Modified;
        }

        if (this.props.isTesting) {
            this.setState({
                recordTest: data,
            });
        }
        
        data.contacts = [...this.state.rows];
       
        var continueBack = true;

        await this.props.actions.updateWorkSite(data)
        .then(() => {
        })
        .catch(() => {
            continueBack = false;
        })
        .finally(() =>{
            this.setState({
                isLoading: false,
            });
        })

        if (continueBack) {
            this.back();
        }
    }

    handleChangeIndex = (index) => {
        this.setState({
            index: index,
        })
        this.slider();
    }

    slider = () => {
        const { t } = this.props;
        var tabs = [
            {title: t("GeneralInfo"), item: this.generalInfo()},
            {title: t("Contacts"), item:this.ContactsWorkSite()}
        ];
        if (this.state.workSiteId !== constants.New) 
        {
            if (this.state.index !== 2) {
                tabs.push({title: t("WorkOrders"), item: <div></div>});
            }
            else {
                tabs.push({title: t("WorkOrders"), item: this.workOrders()});
            }
        }

        return (
            <Fragment>
                {this.props.isAllOptions &&
                    <Breadcrumb 
                        title={t("workSite")} 
                        backButton={this.props.isAllOptions ? this.props.isAllOptions : false} 
                        isAllOptions={this.props.isAllOptions}
                    />
                }
                <ScrollableTabs
                    handleChange={this.handleChangeIndex}
                    style={this.props.isAllOptions ? {marginTop:'0px'} : undefined}
                    tabs={tabs}>
                </ScrollableTabs>
            </Fragment>
           
        )
    };

    refreshDetails = (rows) => {
        this.setState(prevState => ({
            ...prevState,
            rows: rows
        }));
    } 

    handleCatalogs = (id, value ) => {
        var event = {
            target : {
                id : id,
                value : value
            }
        }
        this.handleChange(event);

    }

    handleChangeInvoiceAddress = (event) => {
      
        const { id, value } = event.target;
        
        this.setState(prevState => ({
            ...prevState,
            workSite: {
                ...prevState.workSite,
                address : {
                    ...prevState.workSite.address,
                    [id]: value,
                }
            },
        }));

    }

    handleDateTimeChange = (event) => {
      
        const { id, value } = event.target;
        this.setState(prevState => ({
            ...prevState,
            workSite: {
                ...prevState.workSite,
                [id]: value,
            }
        }));
    }

    generalInfo = () => {
        const { t, i18n } = this.props;
        const { workSite } = this.state;
       
        return (
            <Fragment>
                <Grid container style={styles.ElementWithoutPadding}>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <Grid container >
                                    <NumberField
                                        id={"number"}
                                        label={t("WorksiteNumber")}
                                        value={workSite.number}
                                        onChange={this.handleChange}
                                        lang={i18n.language}
                                    />
                                    <Grid>
                                        {(workSite.number === 0) &&
                                            <Typography id="number-helper-text" style={{ color: "rgba(255, 1, 1, 0.54)" }}>
                                                {t("Required")}
                                            </Typography>
                                        }
                                    </Grid>
                                </Grid>
                                <UpTextField
                                    style={styles.inputElements}
                                    id="name"
                                    label={t("Name")}
                                    value={workSite.name}
                                    onChange={this.handleChange} />
                                <NumberField
                                    id={"worksiteNumber"}
                                    label={t("WorksiteTarget")}
                                    value={workSite.worksiteNumber}
                                    onChange={this.handleChange}
                                    lang={i18n.language}
                                />
                            </CardContent>
                        </Card>
                    </Container>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <UpTextField
                                    style={styles.inputElements}
                                    id="description"
                                    label={t("Description")}
                                    value={workSite.description}
                                    onChange={this.handleChange} />
                                <UpTextField
                                    style={styles.inputElements}
                                    id="descriptionOnInvoice"
                                    label={t("DescriptionOnInvoice")}
                                    value={workSite.descriptionOnInvoice}
                                    onChange={this.handleChange} />
                                <div style={{marginTop:"8px"}}>
                                    <SearchOne
                                        {...this.props}
                                        id="customerId"
                                        label={t("Customer")}
                                        valueOne={this.state.Customer}
                                        handleOneSelect={(row) => this.handleOneSelect(row, "customerNumber")}
                                        cleanFilter={() => this.cleanFilter("customerNumber")}
                                        typeQuery={constants.customers}
                                        isFullWidth={true}
                                        isReadOnly={this.props.isAllOptions}
                                    />
                                </div>
                                
                            </CardContent>
                        </Card>
                    </Container>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <UpDatePicker
                                    includeTime={true}
                                    style={styles.inputElements}
                                    id="startTime"
                                    label={t("StartDate")}
                                    value={workSite.startTime}
                                    onChange={this.handleDateTimeChange} />
                            </CardContent>
                        </Card>
                    </Container>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <UpDatePicker
                                    style={styles.inputElements}
                                    includeTime={true}
                                    id="deliveryTime"
                                    label={t("DeliveryDate")}
                                    value={workSite.deliveryTime}
                                    onChange={this.handleDateTimeChange} />
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
                {this.showAddressInfo()}
            </Fragment>
        )
    }

    showAddressInfo = () => {
        const { t } = this.props;
        const { workSite } = this.state;
        return (
            <Fragment>
                <Typography variant="h6" >{t("Address")}</Typography>
                <Grid container style={{...styles.ElementWithoutPadding, ...{marginBottom: "100px"}}}>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <UpTextField
                                    style={styles.inputElements}
                                    id="address"
                                    label={t("Address")}
                                    value={workSite.address.address}
                                    onChange={this.handleChangeInvoiceAddress} />
                                <UpTextField
                                    style={styles.inputElements}
                                    id="addressLine2"
                                    label={t("AddressLine2")}
                                    value={workSite.address.addressLine2}
                                    onChange={this.handleChangeInvoiceAddress} />
                                <UpTextField
                                    style={styles.inputElements}
                                    id="postalCode"
                                    label={t("PostalCode")}
                                    value={workSite.address.postalCode}
                                    onChange={this.handleChangeInvoiceAddress} />  
                            </CardContent>
                        </Card>
                    </Container>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <UpTextField
                                    style={styles.inputElements}
                                    id="city"
                                    label={t("City")}
                                    value={workSite.address.city}
                                    onChange={this.handleChangeInvoiceAddress} />
                                <UpTextField
                                    style={styles.inputElements}
                                    id="country"
                                    label={t("Country")}
                                    value={workSite.address.country}
                                    onChange={this.handleChangeInvoiceAddress} />  
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
            </Fragment>
        )
    }

    ContactsWorkSite = () => {
        return (
            <div style={{marginBottom: "100px"}}>
                <ContactsWorkSiteList
                    {...this.props}
                    showtitle={false}
                    rows={this.state.rows}
                    refreshDetails={this.refreshDetails}
                    hideSearch={true}
                />
            </div>
        )
    }

    workOrders = () => {
        const { workSite } = this.state;
        return (
            <div style={{marginTop: "20px", paddingLeft: "32px", paddingRight: "32px"}}>
                <WorkOrderList 
                    {...this.props}
                    addButton={false}
                    backButton={false}
                    onlySelect={false}
                    //handleClose={this.handleClose}
                    isToUp={true}
                    multiSelect={false}
                    filterId={workSite.id}
                    isReadOnly={true}
                    isAllOptions={false}
                    stayInMainWindowIsAllOptions={false}
                    insideTab={true}
                    notExpanded={true}
                />   
            </div>
        )
    }


    render() {
        const { t } = this.props;
        const { workSite } = this.state;
        return (
            <React.Fragment>
                {this.state.isLoading &&
                    <Spinner open={true}/>
                }
                {this.slider()}
                <BottomBar
                    disabler={workSite.number === 0}
                    title={workSite.name}
                    placeholder={t('Unnamed') + " " + t('WorkSite')}
                    rightButton={{action: this.save, tooltip: t("Save"), icon: <Save />}}
                    leftButton={{action: this.back, tooltip: t("Cancel"), icon: <Cancel/>}}
                />
            </React.Fragment>
        )
    };
}

function mapStateToProps(state) {
    return {
        notification: state.notifications,
        workSite : state.workSite,
        customers: state.customers,
        customer: state.customer,
        catalog : state.catalog,
        contacts: state.contacts,
        workOrders: state.workOrders
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            readWorkSite: bindActionCreators(workSiteActions.readWorkSite, dispatch),
            updateWorkSite: bindActionCreators(workSiteActions.updateWorkSite, dispatch),
            readCustomers: bindActionCreators(customersActions.readCustomers, dispatch),
            readCustomer: bindActionCreators(customersActions.readCustomer, dispatch),
            updateCustomer: bindActionCreators(customersActions.updateCustomer, dispatch),
            readCatalog: bindActionCreators(catalogsActions.readCatalog, dispatch),
            updateCatalog: bindActionCreators(catalogsActions.updateCatalog, dispatch),
            readContacts: bindActionCreators(contactsActions.readContacts, dispatch),
            readWorkOrders: bindActionCreators(workOrdersActions.readWorkOrders, dispatch),
        }
    };
  }

export default compose(withTranslation('common'),
CancelPromisesHOC,
connect(
    mapStateToProps,
    mapDispatchToProps
))(withRouter(WorkSite));
