import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from './authorizationUtils';
import axios from 'axios';
import * as constants from "../components/common/constants";
import FilterHelper from "../helpers/filterHelper"; 

// azure functions can not handle ambigous routes
// we need explicit defined those

export function ReadWorkOrders(filter, sort, page, workSiteId, cancelToken) {
    const options = getRequestOptions(cancelToken);
    var data = {
        filter : filter,
        sort: sort,
        page: page,
        workSiteId: workSiteId,
        searchFields: FilterHelper.getSearchfilter(constants.customerFilter)
    }
    
    return axios.post(getApiUrl(serviceKeys.products) + `/api/Aggregate/WorkOrder/GetAll`, data, options)
        .then(handleResponse)
        .catch(handleError);
}

export function ReadWorkOrder(id) {
    const options = getRequestOptions();
    return axios.get(getApiUrl(serviceKeys.products) + `/api/Aggregate/WorkOrder/Get/${id}`, options)
        .then(handleResponse)
        .catch(handleError);
}

export function updateWorkOrder(model) {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.products) + `/api/Aggregate/WorkOrder/POST`, model, options)
        .then(handleResponse)
        .catch(handleError);
}

export function ReadWorkOrderBatch(batch, cancelToken) {
    const options = getRequestOptions(cancelToken);
    return axios.post(getApiUrl(serviceKeys.products) + `/api/Aggregate/WorkOrder/GetBatch`, batch, options)
        .then(handleResponse)
        .catch(handleError);
}




