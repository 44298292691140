export default class FilterHelper {

    static createFilterOption = (id, isVisible, isSort, sm, textAlign, canSort = true, overrideLabel = null, nameField = "None", group = 0, groupName = "") => {
        var direction = "NONE";
        if (isSort) {
            direction = "DESC"
        }
        
        return {
            id : id,
            label: !!overrideLabel ? overrideLabel : id.charAt(0).toUpperCase() + id.substring(1),
            isVisible : isVisible,
            isSort : isSort,
            sm: sm,
            direction: direction,
            textAlign: textAlign,
            canSort: canSort,
            nameField: nameField,
            group : group,
            groupName: groupName
        }
    }

    static setFilter = (id, filterOptions, isRowStyle) => {
        var local = JSON.parse(localStorage.getItem(id));
        if (local === null) {
            local = filterOptions;
        }
        else {
            if (local.filterOptions && local.filterOptions !== null) {
                if (local.isRowStyle && local.isRowStyle !== null) {
                    isRowStyle =  local.isRowStyle;
                }
                local = local.filterOptions;
            }
            var options = [];
            var option;
            for (var x = 0; x < local.length; x++) 
            {
                if (local[x] !== null) {
                    option = this.validFilterOptions(filterOptions, local[x].id);
                    if (option) {
                        var nameField = option.nameField;
                        var group = option.group;
                        var groupName = option.groupName;
                        var label = option.label;
                        option = Object.assign(option, local[x]);
                        option.nameField = nameField;
                        options.label = label;
                        if (option.group === undefined && group !== undefined) {
                            option.group = group;
                            option.groupName = groupName;
                        }
                        options.push(option);
                    }
                }
                
            }
            
            for (var y = 0; y < filterOptions.length; y++) 
            {
                option = this.validFilterOptions(local, filterOptions[y].id);
                if (!option) {
                    options.push(filterOptions[y]);
                }
            }
            local = options;
        }
    
        var sort = this.savefilterOptions(id, local, isRowStyle);
       
        return {
            filter : local,
            sort : sort,
            isRowStyle : isRowStyle
        };
    }

    static validFilterOptions = (filter, id) => {
        return filter.find(o => o !== null && o.id === id);
    }

    static savefilterOptions = (id, local, isRowStyle) => {
        var data = {
            filterOptions : local,
            isRowStyle : isRowStyle
        }
 

        localStorage.setItem(id, JSON.stringify(data));
        
        return this.getSort(data.filterOptions);
    }

    static getSort = (local) => {
        var sort = "";
        var direction = "";
        for (var x = 0; x < local.length; x++) {
            if (local[x].isSort) {
                direction = local[x].direction;
                if (direction === undefined || direction === "NONE") {
                    direction = "DESC";
                }
                sort += local[x].id + " " + direction + ",";
                break;
            }
        }

        
        if (local.length > 0) {
            sort = sort.slice(0, -1);
        }

        if (sort === "" && local.length > 1) {
            direction = local[0].direction;
            if (direction === undefined || direction === "NONE") {
                direction = "DESC";
            }
            sort += local[0].id + " " + direction 
        }

        return sort;
    }

    static getfilterOptions = (id) => {
        var local = JSON.parse(localStorage.getItem(id));
        var filter = [];
        var sort = "";
        var isRowStyle = false;
        if (local !== null) {
            if (local.filterOptions && local.filterOptions !== null) {
                filter = local.filterOptions;
                if (local.isRowStyle && local.isRowStyle !== null) {
                    isRowStyle =  local.isRowStyle;
                }
                sort = this.getSort(filter);
            }
            else {
                filter = local;
                sort = this.getSort(local);
            }
        }
        
        return {
            filter : filter,
            sort : sort,
            isRowStyle : isRowStyle
        };
    }

    static getSearchfilter = (id) => {
        var local = JSON.parse(localStorage.getItem(id));
        var filter = [];
        if (local !== null) {
            if (local.filterOptions && local.filterOptions !== null) {
                for (var x = 0; x < local.filterOptions.length; x++) {
                    if (local.filterOptions[x].nameField !== "None") {
                        filter.push(local.filterOptions[x].nameField)
                    }
                }
            }
        }
        
        return filter;
    }
}