import env from "./env";

export var app_url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

// TODO Check what the silent_redirect_uri is all about and configure it to work if necessary
export const IDENTITY_CONFIG = {
    authority: env.openIdConnect.authUrl ? env.openIdConnect.authUrl : "test.authUrl", //(string): The URL of the OIDC provider.
    client_id: env.openIdConnect.clientId ? env.openIdConnect.clientId : "test.clientId", //(string): Your client application's identifier as registered with the OIDC provider.
    client_secret: env.openIdConnect.clientSecret,
    redirect_uri: app_url + "/signin-oidc", //The URI of your client application to receive a response from the OIDC provider.
    login: app_url + "/login",
    automaticSilentRenew: true, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
    loadUserInfo: false, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
    silent_redirect_uri: app_url + "/silent_renew.html", //(string): The URL for the page containing the code handling the silent renew.
    post_logout_redirect_uri: app_url + "", // (string): The OIDC post-logout redirect URI.
    audience: "https://example.com", //is there a way to specific the audience when making the jwt
    response_type: "code", //(string, default: 'id_token'): The type of response desired from the OIDC provider.
    grant_type: "password",
    scope: "openid", //(string, default: 'openid'): The scope being requested from the OIDC provider.
    webAuthResponseType: "id_token token",
    filterProtocolClaims: true
};


//post_logout_redirect_uri: app_url + "/logout/callback", // (string): The OIDC post-logout redirect URI.