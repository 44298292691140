
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useSpring, animated } from 'react-spring';
import Paper from "@material-ui/core/Paper";
import { Typography,Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faSicons from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';

const styles = theme => ({

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '10px',
    },
    modalcontent: {
        backgroundColor: '#eeeeee',
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        overflow: 'auto',
        padding: theme.spacing(2, 2, 3),
        textAlign: 'center',
        alignItems: 'center',
        color: 'black',
        minWidth: 300,
        maxWidth: 600,
        minHeight: 400,
        maxHeight: 600,
        'div::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
            borderRadius: '20px',
            backgroundColor: 'rgba(51, 51, 51, 0.2)',
        },

    },
    paper: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        padding: '3px',
        minHeight: 100,
        height: '100%',
        width: '100%',
        transition: 'all 700ms ease-out 30ms',
    },
    filter: {
        display: 'inline-flex'
    },
    input: {
        width: '80%',
    },
    title: {
        fontSize: 18,
        padding: '1px 10px 10px 10px',
    },
    select: {
        minWidth: "100%",
        minHeight: 300
    },
    options: {
        cursor: 'pointer',
        float: 'left',
        padding: '15px'
    },
    center: {
        width: '100%',
        textAlign: 'center',
    },

});

const Fade = React.forwardRef(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});

export class QuickSearchSettings extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            options: '',
            isLoading: true
        }
    }

    handleOpen = () => {
        //console.log('open')

        this.props.actions.setSearchSettingsOpen(true)
        this.setState({ isLoading: true });
        this.setState({ open: true })
        var settings = {};
        this.props.actions.readUnifiedSearchSettings().then(() => {

            this.props.options.forEach(option => {
                settings[option.module] = option.selected;
            })
            this.setState({ options: settings });
            this.setState({ isLoading: false });

        })
    };
    handleClose = () => {
        
        this.props.actions.setSearchSettingsOpen(false)
        this.setState({ open: false });
    };
    handleChange = name => event => {
        //console.log('change')
        this.setState({ options: { ...this.state.options, [name]: event.target.checked }});
      
    };
    handleSubmit = () => {
        //console.log('submit')
        this.props.actions.postUnifiedSearchSettings(
            this.state.options,
            this.props.t("Saved")
            ).then(() => { 
                this.handleClose() });
    };

    render() {

        const { classes, t } = this.props;
        return (
            <div>
                <div className={classes.options}>
                    <FontAwesomeIcon
                        id="UnifiedSearchSettings"
                        onClick={this.handleOpen}
                        icon={faSicons["faCogs"]} />
                </div>

                <Modal
                    id="quickSearchSettingsModal"
                    aria-labelledby="spring-modal-title"
                    aria-describedby="spring-modal-description"
                    className={classes.modal}
                    open={this.state.open}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.open}>
                        <div className={classes.modalcontent}>
                            <Typography className={classes.title} id="new-modal-title">{t('FilterSearchSettings')}</Typography>
                            <Paper className={classes.paper} >

                                {this.state.isLoading === false && this.state.open ?
                                    <FormControl component="fieldset">
                                        <FormGroup>
                                            {this.props.options.map((name, idx) => (
                                                <FormControlLabel key={name.module} id={name.module + "-select"} onChange={this.handleChange(name.module)} control={
                                                    <Checkbox
                                                        checked={this.state.options[name.module]}
                                                        value={name.module}
                                                        onChange={this.handleChange(name.module)}
                                                    />
                                                }
                                                    label={name.translated}
                                                />))}
                                        </FormGroup>
                                    </FormControl>
                                    : (<div className={classes.center}>
                                        <FontAwesomeIcon icon={faSicons["faSpinner"]} spin /></div>)}
                            </Paper>
                            <Button
                                children={null}
                                id="submitSearchSettings"
                                className="upButtonWithText"
                                onClick={() => this.handleSubmit() }
                                color="primary"
                                variant="contained"
                                style={{marginTop:20}}
                            >{t("Save")}</Button>
                        </div>
                    </Fade>
                </Modal>
            </div >
        );
    }
}




export default compose(withTranslation('common'), withStyles(styles))(QuickSearchSettings);