export const Added = "Added";
export const Modified = "Modified";
export const Deleted = "Deleted";
export const New = "New";
export const UniqueKeyConstraintViolation = "Unique Key Constraint Violation";
export const productRegisterFilter = "productRegisterFilter";
export const productBuilderFilter = "productBuilderFilter";
export const productGroupFilter = "productGroupFilter";
export const productUnitFilter = "productUnitFilter";
export const contactFilter = "contactFilter";
export const contactsWorkSiteFilter = "contactsWorkSiteFilter";
export const contactsCustomerFilter = "contactsCustomerFilter";
export const customerFilter = "customerFilter";
export const isVisible = "isVisible";
export const isSort = "isSort";
export const cancelHttpRequest = "cancelHttpRequest";
export const ProductsRegisterList = "ProductsRegisterList";
export const ProductsBuilderList = "ProductsBuilderList";
export const ContactsWorkSiteList = "ContactsWorkSiteList";
export const productRegister = "productRegister";
export const productGroup = "productGroup";
export const productUnit = "productUnit";
export const customers = "customers";
export const contacts = "contacts";
export const workSites = "workSites";
export const minWidthScreen = 960;
export const catalogs = {
    PaymentType : "PaymentType",
    AccountGroup : "AccountGroup",
    Title: "Title",
    Defaults: "Defaults"
};
export const workSiteFilter = "workSiteFilter";
export const worOrderFilter = "worOrderFilter";
export const workOrders = "workOrders";
export const SystemErrorNotFound = "SystemError400";
export const EmptyGuid = "00000000-0000-0000-0000-000000000000";
export const workOrderProductsFilter = "workOrderProductsFilter";
export const ProductWorkOrderList = "ProductWorkOrderList";
export const ProductsRegisterWorkOrderList = "ProductsRegisterWorkOrderList";
export const AddProductFromWorkOrder = "AddProductFromWorkOrder";
export const EditProductFromWorkOrder = "EditProductFromWorkOrder";
export const fileType = {
    video : "video",
    image : "image",
    audio : "audio",
    file : "file"
};
export const blobPermissions = {
    Read : "Read",
    Write : "Write",
    Delete : "Delete",
};
export const textAlign = {
    left : "left",
    right : "right"
};
export const diaryEntryFilter = "diaryEntryFilter";
export const collections = {
    workOrder : "workOrder",
    workOrderDiaryEntry : "workOrderDiaryEntry",
    workQueue : "workQueue",
    workQueuesSettings : "workQueuesSettings"
};
export const PreconditionFailed = "PreconditionFailed";
export const workQueuesSettingsFilter = "workQueuesSettingsFilter";
export const users = "users";
export const usersFilter = "usersFilter";
export const usersManagementFilter = "usersManagementFilter";
export const roleFilter = "roleFilter"



