import React, { Component } from 'react';
import { ContactList } from "../common/Lists/contactsList";
import { connect } from 'react-redux';
import *  as contactsActions from '../../redux/actions/contactsActions';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import CancelPromisesHOC   from "../../helpers/cancelPromisesHOC";
import { withRouter } from "react-router-dom";
import {setCurrentModule} from "../../utils/navigationUtil";
import *  as navigationActions from '../../redux/actions/navigationActions';
import *  as customersActions from '../../redux/actions/customersActions';
import *  as catalogsActions from '../../redux/actions/catalogsActions';

export class Contacts  extends Component {
    componentDidMount(){
        this.props.actions.setModule(setCurrentModule('/contacts'));
    }

    render() {
        return (
            <ContactList {...this.props} />
        )
    };
}

function mapStateToProps(state) {
    return {
        contacts : state.contacts,
        moduleInfo: state.navigation.module,
        customers : state.customers,
        customer : state.customer,
        catalog : state.catalog
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            readContacts: bindActionCreators(contactsActions.readContacts, dispatch),
            updateContact: bindActionCreators(contactsActions.updateContact, dispatch),  
            readCustomers: bindActionCreators(customersActions.readCustomers, dispatch),
            readCustomer: bindActionCreators(customersActions.readCustomer, dispatch),
            updateCustomer: bindActionCreators(customersActions.updateCustomer, dispatch),
            setModule: bindActionCreators(navigationActions.setModule, dispatch),
            readCatalog: bindActionCreators(catalogsActions.readCatalog, dispatch),
        }
    };
  }

export default compose(withTranslation('common'),
CancelPromisesHOC,
connect(
    mapStateToProps,
    mapDispatchToProps,
))(withRouter(Contacts));