import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function userDataReducer(state = initialState.user , action) {
    if (action.type === types.READ_USER_SUCCESS)
    {
        return action.user;
    }
    else {
        return state;
    }
}
