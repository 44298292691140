import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function productGroupsReducer(state = initialState.productGroups , action) {
    if (action.type === types.LOAD_PRODUCTGROUP_SUCCESS)
    {
        return action.productGroups
    }
    else {
        return state;
    }
}
