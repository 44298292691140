
export const inputElements = {
    marginTop: '1em',
    marginLeft: '0em',
    width: '95%',
    marginBottom: '16px'
};


export const CardWithoutBoxes = {
    boxShadow: "none",
};

export const ElementWithoutPadding = {
    paddingLeft: '0px',
    paddingRight: '0px',
    marginLeft: '0px',
};

export const labelMargins = {
    marginBottom: '16px ',
    marginTop: '16px',
    paddingLeft: '10px'
};

export const headerRowMainWordCard = {
    fontSize: '20px',
    marginBottom : '15px'
};

export const headerRowMainWithoutReportLevel = {
    fontSize: '20px',
    marginTop : '30px',
    marginBottom : '15px'
};

export const subHeaderRowMainWordCard =  {
    fontsize: '16px',
    marginBottom : '15px',
    marginLeft: '15px'
};

export const rowPanel = {
    marginLeft: "15px",
    marginBottom : "15px"
}

export const rowPanelWithoutReportLevel = {
    marginLeft: "15px",
    marginBottom : "0px"
}

export const button = {
    margin: "8px"
}

export const leftIcon = {
    marginRight: "8px"
}

export const modalPaper = {
    backgroundColor: "white",
    borderRadius: "5px"
}

export const progressCircle = {
    margin: "5px",
}

export const modal=  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

export const circleButton = {
    margin: "5px",
    borderRadius: "50px",
    padding: "9px 10px",
    minWidth: "initial",
}

export const floatButton = {
    right: "0px",
    position: "fixed",
    zIndex: "100",
    float: "right",
    bottom: "0"
}

export const floatButtonTop = {
    right: "0px",
    position: "fixed",
    zIndex: "100",
    float: "right",
    top: "0"
}

export const floatButtonCenter = {
    position: "fixed",
    zIndex: "100",
    left: "50%",
    //marginLeft: "-50px",
    top: "50%",
    //marginTop: "-50px"
}


export const endAdormentIput = {
    position: "absolute",
    marginLeft: "-20px",
    marginTop: "34px",
    background: "white"
}

export const floatButtomLeft = {
    left: "auto",
    position: "fixed",
    zIndex: "100",
    bottom: "0"
}
export const BottomButtonsBar = {
    background: 'rgba(85, 100, 107, 0.89)',
    position: "sticky",
    zIndex: "100",
    bottom: "0",
    left: "0",
    width: "auto",
    margin: "0 -1em",
    textAlign: 'center',

}
export const BottomBarName = {
    background: 'none',
    color: 'white',
    display: 'inline-block',
    marginTop:'-5px',
    minWidth: '33%',
    maxWidth: '50%',
    textAlign: 'center'

}
export const BottomButtonsBarLeft = {

    display: 'inline-block',
    borderRight: '1px solid black',
    margin: '5px 10px',
}
export const BottomButtonsBarRight = {
    display: 'inline-block',
    borderLeft: '1px solid black',
    margin: '5px 10px',
}

export const swipePage = { 
    marginTop: "30px"
}

export const tooltip = {
    tooltip: "text-align: center; font-size : 18px"
    
}
export const dragAndDrop = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: '2px',
  borderRadius: '2px',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}
