import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from './authorizationUtils';
import axios from 'axios';

// azure functions can not handle ambigous routes
// we need explicit defined those

export function ReadProductsRegister(filter, sort, page, productGroup, unit, cancelToken) {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.products) + `/api/Aggregate/ProductRegister/GetAll?filter=${filter}&sort=${sort}&page=${page}&productGroup=${productGroup}&productUnit=${unit}`, options)
        .then(handleResponse)
        .catch(handleError);
}

export function ReadProductsMassive(cancelToken) {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.products) + `/api/Aggregate/ProductRegister/GetMass`, options)
        .then(handleResponse)
        .catch(handleError);
}
  

export function ReadProductRegister(id) {
    const options = getRequestOptions();
    return axios.get(getApiUrl(serviceKeys.products) + `/api/Aggregate/ProductRegister/Get/${id}`, options)
        .then(handleResponse)
        .catch(handleError);
  }

export function updateProductRegister(productRegister) {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.products) + `/api/Aggregate/ProductRegister/POST`, productRegister, options)
        .then(handleResponse)
        .catch(handleError);
}
