import React, { Component } from 'react';
import {
    CardContent,
    Grid,
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    Slide,
    Dialog,
    FormControl,
    InputLabel,
    Select,
    Container,
    Card,
} from '@material-ui/core';
import * as styles from '../styles.js';
import "../../../index.css";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { withTranslation } from 'react-i18next';
import { Breadcrumb } from '../Breadcrumb';
import CircleButton from "../CircleButton";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UpButton from "../upButton";
import Collapse from '@material-ui/core/Collapse';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogModal from "../dialogModal";
import UpTextField from '../upTextField';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { faMinusSquare } from '@fortawesome/free-regular-svg-icons';
import { faFolder } from '@fortawesome/free-regular-svg-icons';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { faFile } from '@fortawesome/free-regular-svg-icons';


const marginButton = {
    marginTop : "7px"
}

const WAIT_INTERVAL = 500;

export class EditGroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            widths : [],
            groupOptions: this.props.groupOptions,
            filterOptions : this.props.groupOptions.columns,
            nodes: this.props.nodes,
            isRowStyle : this.props.isRowStyle,
            transitionState: [],
            fromIndex: -1,
            toIndex : -1,
            startTransition: false,
            rowPositon: -1,
            startCompleteTransition: false,
            open: false,
            openTree: false,
            checked: [],
            expanded: [],
        }
    }

    componentDidMount() {
        const widths = [];
        for (var x = 0; x < 12; x++)
        {
            widths[x] = x+1;
        }
        this.setState({
            widths : widths,
        })

        this.state.filterOptions.map((row, i) => (
            this.state.transitionState.push(true)
        ));
    
        this.setState({
            nodes: this.props.nodes
        })

    }

    componentDidUpdate(prevPros, prevState) {
        if (prevPros.nodes !== this.props.nodes) {
            this.setState({
                nodes: this.props.nodes
            })
        }
        
    }
    
    moveRow = (fromIndex, isUp) => {

        var flagContinue = true;
        if (fromIndex === 0 && isUp)
        {
            flagContinue = false;
        }
        else if (!isUp  && fromIndex === this.state.filterOptions.length - 1) 
        {
            flagContinue = false;
        }

        if (flagContinue) {

            this.setState({
                rowPositon : fromIndex,
            });

            var toIndex = 0;
            if (isUp) {
                toIndex = fromIndex - 1;
            }
            else {
                toIndex = fromIndex + 1;
            }

            
            this.setState(prevState => ({
                ...prevState,
                transitionState : Object.values({
                    ...prevState.transitionState,
                    [fromIndex]: false
                }),
                fromIndex: fromIndex,
                toIndex : toIndex,
                startTransition: true,
                startCompleteTransition: true
            }));
        }
    }

    handleCloseGroup = () => {
        var temp = {...this.state.groupOptions, columns : this.state.filterOptions}
        this.setState({
            groupOptions : temp
        }, function() {
            this.props.handleCloseGroup(this.state.groupOptions);
        })
    }

    clicking = () => {
       this.resetColor();
    }

    resetColor = () => {
        this.setState({
            rowPositon : -1
        });
    }

    handleDelete = (e, idx) => {
        this.setState({
            idx: idx,
        });

        if (!this.props.isTesting) {
            this.setState({
                open : true
            });
        }
    }

    handleDeleteRow = async (isOk) => {
        if (isOk) {
            var temp = [...this.state.filterOptions];
            var nodes = [...this.state.nodes]
            this.putTrueElementShowCheckBox(nodes, this.state.idx);
            this.putTrueGroupShowCheckBox(nodes);
            temp.splice(this.state.idx,1)
            this.setState({
                filterOptions : temp,
                open: false 
            })
            this.props.updateNodes(nodes);
        }
        else {
            this.setState({
                open: false,
            });
        }
    }

    putTrueElementShowCheckBox = (nodes, idx) => {
        var id = this.state.filterOptions[idx].nameField;
        var nameField = this.state.filterOptions[idx].nameField;

        for (var x = 0; x < nodes.length; x++) {
            if (nodes[x].value === nameField || nodes[x].value === id || 
                nodes[x].children !== undefined) 
            {
                if (nodes[x].children === undefined) 
                {
                  nodes[x].showCheckbox = true;
                  break;
                }
                else {
                    for (var y = 0; y < nodes[x].children.length; y++) {
                        if (nodes[x].children[y].value === nameField || nodes[x].children[y].value === id) {
                           nodes[x].children[y].showCheckbox = true;
                           break;
                        }
                    }
                   
                }                 
            }
        }
    }

    putTrueGroupShowCheckBox = (nodes) => {
        for (var x = 0; x < nodes.length; x++) {
            if (nodes[x].children !== undefined) 
            {
                var flag = true;
                for (var y = 0; y < nodes[x].children.length; y++) {
                    if (nodes[x].children[y].showCheckbox === true) {
                        flag = true;
                        break;
                    }
                    else {
                        flag = false;
                    }
                }
                if (flag) {
                    nodes[x].showCheckbox = true;
                }
            }                 
        }
    }

    handleEnd = (node, done) => {
        node.addEventListener("transitionend", this.doNext, false);
    };
    
    doNext = () => {
        if (this.state.startTransition) {
            this.setState({
                rowPositon : -1
            });

            var rows = [...this.state.filterOptions];

            var row = rows[this.state.fromIndex];
    
            rows.splice(this.state.fromIndex, 1);
            rows.splice(this.state.toIndex, 0, row);
            
            this.setState(prevState => ({
                ...prevState,
                transitionState : Object.values({
                    ...prevState.transitionState,
                    [this.state.toIndex]: false
                })
            }), function() {
                this.setState({
                    filterOptions : rows,
                    startTransition : false
                }, function() {
                    this.setState(prevState => ({
                        ...prevState,
                        transitionState : Object.values({
                            ...prevState.transitionState,
                            [this.state.fromIndex]: true
                        })
                    }), function() {
                        setTimeout(this.triggerChange, WAIT_INTERVAL);
                    });
                }); 
            });
        }
    };

    triggerChange = () => {
        this.setState({
            rowPositon : this.state.toIndex
        }, function() {
            this.setState(prevState => ({
                ...prevState,
                transitionState : Object.values({
                    ...prevState.transitionState,
                    [this.state.toIndex]: true
                })
            }), function() {
                this.setState({
                    startCompleteTransition : false
                });
            })
        });
    }

    handleChange = (e) => {
        var { id, value } = e.target;
      
        if (id === "outlined-rowWidth") {
            value++;
            id = "sm";
        }

        var row = { ...this.state.groupOptions, [id] : value };

        this.setState({
            groupOptions : row
        });

    }

    handleOpenTree = (e) => {
        this.setState({
            openTree: true
        })
    }
   
    handleCloseTree = () => {
        if (this.state.checked.length > 0) {
            for (var x = 0; x < this.state.checked.length; x++) {
                var element = this.findNode(this.state.checked[x]);
                if (element.showCheckbox === true) {
                    if (element !== undefined)
                    {
                        element.showCheckbox = false;
                        var filter = this.findElementInOptions(this.state.checked[x]);
                        if (filter !== undefined)
                        {
                            this.state.filterOptions.push(filter);
                            this.state.transitionState.push(true)
                        }
                    }
                }
            }
            var nodes = this.state.nodes;
            this.putTrueGroupShowCheckBox(nodes);
            this.props.updateNodes(nodes);

        }
        this.setState({
            checked: [],
            openTree: false
        })

    }

    putTrueGroupShowCheckBox = (nodes) => {
        for (var x = 0; x < nodes.length; x++) {
            if (nodes[x].children !== undefined) 
            {
                var flag = false;
                for (var y = 0; y < nodes[x].children.length; y++) {
                    if (nodes[x].children[y].showCheckbox === true) {
                        flag = true;
                        break;
                    }
                }
                if (!flag) {
                    nodes[x].showCheckbox = false;
                }
                else {
                    nodes[x].showCheckbox = true;
                }
            }                 
        }
    }

    findElementInOptions = (checked) => {
        var filter = this.props.filterOptions.find(e => e.nameField === checked);
        if (filter === undefined)
        {
            filter = this.props.filterOptions.find(e => e.id === checked);
        }
        if (filter !== undefined)
        {
            filter.isVisible = true;
        }

        return filter;
    }

    findNode = (checked) => {
        for (var x = 0; x < this.state.nodes.length; x++) {
            if (this.state.nodes[x].value === checked) {
                return this.state.nodes[x];
            }
            else {
                if (this.state.nodes[x].children !== undefined) {
                    for (var y = 0; y < this.state.nodes[x].children.length; y++) {
                        if (this.state.nodes[x].children[y].value === checked) {
                            return this.state.nodes[x].children[y];
                        }
                    }                
                }
            }
        }

        return undefined;
    } 

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });


    rowDetails = (row, idx, len) => {
        const { t } = this.props;
        return (
            <Collapse in={this.state.transitionState[idx]} key={"collapse" + idx}
                addEndListener={this.handleEnd}
                onExited={this.handleExited} style={{width : "100%"}}>
            <React.Fragment key={"fragment" + idx}>
                <ExpansionPanel expanded={false} 
                    style={idx === this.state.rowPositon ?
                        { backgroundColor: "antiquewhite", marginBottom : "1px", width : "100%"  } :
                        { backgroundColor: this.props.backgroundColor, marginBottom : "1px", width : "100%"   }
                    }
                >
                    <ExpansionPanelSummary >
                        <Grid container style={styles.ElementWithoutPadding} onClick={this.clicking}>
                            <Grid item sm xs={8}>
                                <Typography style={{paddingTop: "17px"}}>
                                    {t(row.label)}
                                </Typography>
                            </Grid>
                            <Grid item sm={9} xs={12}>
                                <Grid container direction="row" justify="flex-end">
                                    <div>
                                        <CircleButton
                                            style={marginButton}
                                            id={"deleteId" + idx}
                                            disabled={idx === 0 || this.state.startCompleteTransition}  
                                            toolTipMessage={t("")}
                                            clickAction={(e) => this.handleDelete(e, idx)}
                                            iconButton={<DeleteIcon style={styles.leftIcon} />}
                                            color="primary" 
                                        />   
                                        <CircleButton
                                            style={marginButton}
                                            id={"updId" + idx}
                                            disabled={idx === 0 || this.state.startCompleteTransition}  
                                            toolTipMessage={t("MoveUp")}
                                            clickAction={() => this.moveRow(idx, true)}
                                            iconButton={<ArrowUpwardIcon style={styles.leftIcon} />}
                                            color="primary" 
                                        />   
                                        <CircleButton
                                            style={marginButton}
                                            id={"downId" + idx}
                                            disabled={(len - 1) === idx || this.state.startCompleteTransition}  
                                            toolTipMessage={t("MoveDown")}
                                            clickAction={() => this.moveRow(idx, false)}
                                            iconButton={<ArrowDownwardIcon style={styles.leftIcon} />}
                                            color="primary" 
                                        />  
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ExpansionPanelSummary>
                </ExpansionPanel>
            </React.Fragment>
            </Collapse>
        );
    }

    headers = () => {
        const { t } = this.props;
        return (
            <ExpansionPanel key={"headers"} expanded={false}
            style={{ backgroundColor: this.props.backgroundColor, marginBottom : "1px", width:"100%"  }}
            >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon style={{ color: "transparent" }}/>}>
                    <Grid container style={styles.ElementWithoutPadding}>
                        <Grid item sm xs={8}>
                            <Typography style={{paddingTop: "17px"}}>
                                {t("Cells")}
                            </Typography>
                        </Grid>
                    </Grid>
                </ExpansionPanelSummary>
            </ExpansionPanel>
        )
    }

    renderTree() {
        const { t } = this.props;
        return (
            <Dialog fullScreen open={this.state.openTree} TransitionComponent={this.Transition}>
                <div style={{paddingLeft: "32px", paddingRight: "32px"}}>
                    <Breadcrumb
                        title={t("Cells")} 
                        backButton={true}
                        handleClose={this.handleCloseTree} 
                        backMessage={t("Back")}
                    ></Breadcrumb>
                    <CardContent style={styles.ElementWithoutPadding}>
                        <CheckboxTree
                        icons={{
                            check: <FontAwesomeIcon className="rct-icon rct-icon-check" icon={faCheckSquare} />,
                            uncheck: <FontAwesomeIcon className="rct-icon rct-icon-uncheck" icon={faSquare} />,
                            halfCheck: <FontAwesomeIcon className="rct-icon rct-icon-half-check" icon={faCheckSquare} />,
                            expandClose: <FontAwesomeIcon className="rct-icon rct-icon-expand-close" icon={faChevronRight} />,
                            expandOpen: <FontAwesomeIcon className="rct-icon rct-icon-expand-open" icon={faChevronDown} />,
                            expandAll: <FontAwesomeIcon className="rct-icon rct-icon-expand-all" icon={faPlusSquare} />,
                            collapseAll: <FontAwesomeIcon className="rct-icon rct-icon-collapse-all" icon={faMinusSquare} />,
                            parentClose: <FontAwesomeIcon className="rct-icon rct-icon-parent-close" icon={faFolder} />,
                            parentOpen: <FontAwesomeIcon className="rct-icon rct-icon-parent-open" icon={faFolderOpen} />,
                            leaf: <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faFile} />
                        }}
                            nodes={this.state.nodes}
                            checked={this.state.checked}
                            expanded={this.state.expanded}
                            onCheck={checked => this.setState({ checked })}
                            onExpand={expanded => this.setState({ expanded })}
                        />
                    </CardContent>
                </div>
            </Dialog>
        )
    };

    render() {
        const { t } = this.props;
        const { widths } = this.state;
        return (
            <Dialog fullScreen open={this.props.openGroup} TransitionComponent={this.Transition}>
                <div style={{paddingLeft: "32px", paddingRight: "32px"}}>
                    <Breadcrumb
                        title={t("Group")} 
                        backButton={true}
                        handleClose={this.handleCloseGroup} 
                        backMessage={t("Back")}
                    ></Breadcrumb>
                    <Grid container>
                        <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                            <Card style={styles.CardWithoutBoxes}>
                                <CardContent style={styles.ElementWithoutPadding}>
                                    <Grid container >
                                        <Grid style={{ width: "100%" }}>
                                            <UpTextField
                                                style={styles.inputElements}
                                                id="groupName"
                                                label={t("Name")}
                                                value={this.state.groupOptions.groupName}
                                                onChange={(e) => this.handleChange(e)} />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card> 
                        </Container>
                        <Container maxWidth="sm" style={styles.ElementWithoutPadding}> 
                            <Card style={styles.CardWithoutBoxes}>
                                <CardContent style={styles.ElementWithoutPadding}>
                                    <FormControl  style={{ width : "100px", paddingRight: "10px", marginTop: "17px"}}>
                                        <InputLabel htmlFor="outlined-rowWidth" 
                                            style={{
                                                background: "white",
                                                paddingRight: "5px",
                                                paddingLeft: "5px",
                                            }}
                                            
                                        >
                                            {t("Width")}
                                        </InputLabel>
                                        <Select
                                            native
                                            value={this.state.groupOptions.sm - 1}
                                            onChange={(e) => this.handleChange(e)}
                                            inputProps={{
                                                name: "rowWidthId",
                                                id: 'outlined-rowWidth',
                                            }}
                                            //onClick={this.clicking}
                                            >
                                            {widths.map((row, idx) => (
                                                <option key={"option" + idx } value={idx}>{row}</option>
                                            ))}   
                                        </Select>
                                    </FormControl>
                                </CardContent>
                            </Card>
                        </Container>    
                    </Grid>
                    <Grid container style={styles.ElementWithoutPadding} >
                        <Grid container direction="row" justify="flex-end">
                            <UpButton
                                id="addId"
                                toolTipMessage={""}
                                messageContent={t("Cell")}
                                clickAction={this.handleOpenTree}
                                iconButton={<AddIcon  style={styles.leftIcon}/>}
                                color="primary" 
                                circleButton={false}
                            />
                        </Grid>    
                    </Grid>
                    <Grid container style={{width:"100%"}}>
                        {this.headers()}
                        {this.state.filterOptions.map((row, i) => (
                            this.rowDetails(row, i, this.state.filterOptions.length)
                        ))}
                    </Grid>    
                </div>
                { this.state.open &&
                    <DialogModal
                        open={this.state.open}
                        questionDialog={t("AreyouSureofDelete")}
                        handleReply={this.handleDeleteRow}
                    >
                    </DialogModal>
                }
                { this.state.openTree &&
                    this.renderTree()
                }
            </Dialog>
            
        )
    };
}

export default withTranslation('common')(EditGroup);
