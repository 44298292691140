import React, { Component, Fragment } from 'react';
import "../../../index.css";
import { withTranslation } from 'react-i18next';
import * as constants from "../constants";
import FilterHelper from "../../../helpers/filterHelper"; 
import { SearchList } from "../Lists/searchList";
import axios from 'axios';
import { SearchOne } from "../SearchOne";
import {
    Container,
    Card,
    CardContent,
    Grid
} from '@material-ui/core';
import * as styles from '../styles';
import Spinner from './../CircularSpinner';
import { Catalogs } from "../../common/catalogs";
import StatefulSessionHelper from "../../../helpers/statefulSessionHelper";

var isMounted = false;

export class ContactList  extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            rows : [],
            filterOptions : [],
            builderList : [],
            isLoading : false,
            isRowStyle: false,
            CustomerId : "",
            Customer : "",
            cleanData : false,
            title: "",
            expandedSearch: false,
            idSaveLocal: this.props.idSave !== undefined ? this.props.idSave : constants.contactFilter
        }
    }

    componentWillUnmount() {
        isMounted = false;
        this.props.cancelPromises();
    }

    componentDidMount() {
        this.setFilter();
        if (this.props.filterId !== undefined) {
            if (this.props.filterId > 0) {
                this.setState({
                    Customer : this.props.filterName,
                    CustomerId : this.props.filterId,
                }, function() {
                    this.loadFilteredRecords();
                });
            }
        }
        else {
            this.loadFilteredRecords();
        }
    }

    loadFilteredRecords = async () => {
        var searchValue = StatefulSessionHelper.getSearchValue(this.state.idSaveLocal + this.props.user.profile.sub);
        if (searchValue === null) {
            searchValue = "";
        }
        
        await this.setState({
            filter : searchValue
        })
    
        var dataValues = StatefulSessionHelper.getValues(this.state.idSaveLocal + this.props.user.profile.sub);
       
        if (dataValues !== null) {
            var expand = false;
          
            if (dataValues.CustomerId !== "" || dataValues.title !== "") {
                expand = true;
            }

            var tempCustomer = dataValues.Customer;
            var tempCustomerId = dataValues.CustomerId;
            
            if (this.props.filterId > 0) {
                tempCustomer = this.state.Customer;
                tempCustomerId = this.state.CustomerId;
            }

            this.setState({
                Customer : tempCustomer,
                CustomerId : tempCustomerId,
                title: dataValues.title,
                expandedSearch: expand
           }, function() {
              this.reloadData();
           })
        }
        else {
            this.reloadData();
        }
    }

    reloadData = () => {
        var data = FilterHelper.getfilterOptions(this.state.idSaveLocal);
        this.loadRecords(this.state.filter, data.sort,1);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.contacts !== this.props.contacts) {
            this.setState({
                rows : this.props.contacts,
            });
        }

        if (prevProps.filterId  !== this.props.filterId) {
            if (this.props.filterId > 0) {
                this.setState({
                    Customer : this.props.filterName,
                    CustomerId : this.props.filterId,
                }, function() {
                    this.loadFilteredRecords();
                });
            }
        }
    }
    
    setFilter = () => {
        this.setState({
            idSave : this.props.idSave,
        });

        var options = [
            FilterHelper.createFilterOption("name", true, true, 3, constants.textAlign.left, true, null, "Name"),
            FilterHelper.createFilterOption("email", true, true, 2, constants.textAlign.left, true, null, "Email"),
            FilterHelper.createFilterOption("phone", true, true, 2, constants.textAlign.left, true, null, "Phone"),
            FilterHelper.createFilterOption("customer", true, true, 3, constants.textAlign.left, false, "ContactCustomer"),
            FilterHelper.createFilterOption("title", true, true, 2, constants.textAlign.left, false, "ContactTitle"),
        ];
      
        var filterOptions = FilterHelper.setFilter(this.state.idSaveLocal, options, false);
        
        this.setState({
            filterOptions : filterOptions.filter,
            isRowStyle : filterOptions.isRowStyle
        })
    }

    showFilterFields = (row, rowFilter) => {
        return (
            <Fragment>
                {rowFilter.id === "name" &&
                    row.name
                }
                {rowFilter.id === "email" &&
                    row.email
                }
                {rowFilter.id === "phone" &&
                    row.phone
                }
                {rowFilter.id === "customer" &&
                    row.customer
                }
                {rowFilter.id === "title" &&
                    row.title
                }
            </Fragment>
        )
    }

    handleClose = () => {
        this.setState({
            cleanData: true
        }, function() {
            this.loadRecords(this.state.filter, "", 1);
        })
    }
    
    loadRecords = async (filter, sort, page) => {
        isMounted = true;

        this.props.cancelPromises();

        var isLoaded = false;
        if (!this.props.isArray) {
            
            if (!this.props.isLoading) {
                this.setState({
                    isLoading : true
                })
            }

            var data = FilterHelper.getfilterOptions(this.state.idSaveLocal);
      
            this.setState({
                filter : filter,
                filterOptions : data.filter,
                isRowStyle: data.isRowStyle
            })

            let cancelToken = axios.CancelToken.source();

            await this.props.cancellablePromise(
               this.props.actions.readContacts(
                    filter, data.sort, page, 
                    this.state.CustomerId, 
                    this.state.title,
                    cancelToken.token), cancelToken
            )
            .then(() => {
                isLoaded = true;
            })
            .catch((error) => {
                if (error.message === constants.cancelHttpRequest || error.message === "[object Object]") {
                    isMounted = false;
                }
             })
            .finally(result =>{
                if (isMounted) {
                    this.setState({
                        isLoading : false,
                        cleanData: false
                    })
                }
                
            })
            
        }
        else {
            this.props.loadRecords();
        }

        return isLoaded;
    }
   
    handleDeleteRow = async (record) => {
        if (!this.props.isLoading) {
            this.setState({
                isLoading : true
            })
        }

        record.state = constants.Deleted;
        var data = {...record};

        if (this.props.isTesting) {
            this.setState({
                recordTest : data
            });
        }

        this.unLinkCustomer(data.customerNumber, data.id);
    
        var isDeleted = false;
        await this.props.actions.updateContact(data)
        .then(() => {
            isDeleted = true;
        })
        .catch(() => {})
        .finally(() =>{
            this.setState({
                isLoading : false
            })
        })
        return isDeleted;
    }

    unLinkCustomer = async (customerId, contactId) => {
        await this.props.actions.readCustomer(customerId)
        .then((result) => {
        })
        .catch(() => { })
        .finally(result => {
        })

        if (this.props.customer && this.props.customer.contactId === contactId) {
            var customerModel = {...this.props.customer};
            customerModel.contactId = constants.EmptyGuid;
            customerModel.state = "Modified";
            await this.props.actions.updateCustomer(customerModel)
            .then(() => {
            })
            .catch(() => { })
            .finally(result => {
            })
        }
    }

    
    handleOneSelect = (row, id) => {
        if (id === "customer") {
            this.setState({
                Customer: row.name,
                CustomerId: row.id,
                cleanData: true
            }, function() {
                this.setSession();
            });
        }
    }

    cleanFilter = (id) => {
        if (id === "customer") {
            this.setState({
                Customer: "",
                CustomerId: "",
                cleanData: true
            }, function() {
                this.setSession();
            });
        }
    }

    setSession = () => {
        var data = {
            Customer : this.state.Customer,
            CustomerId : this.state.CustomerId,
            title: this.state.CatalogId,
       }
       
       StatefulSessionHelper.setValues(this.state.idSaveLocal + this.props.user.profile.sub, data);
       this.reloadData();
    }

    changeFilter = (filter) => {
        this.setState({
            filter : filter
        })
    }

    showBasket = (show) => {
        if (this.props.showBasket) {
            this.props.showBasket(show);
        }
    }

    handleCatalogs = (id, value ) => {
        this.setState({
            [id]: value,
            cleanData: true,
            CatalogId: value
        }, function() {
            this.setSession();
        });
    }

    render() {
        const { t } = this.props;
        return (
            <Fragment>
                {this.state.isLoadingMassive &&
                    <Spinner open={true}/>
                }
                <SearchList 
                    {...this.props}
                    rows={this.state.rows}
                    showtitle={this.props.showtitle}
                    showFilterFields={this.props.showFilterFields ?  this.props.showFilterFields : this.showFilterFields}
                    filterOptions={this.props.filterOptions ? this.props.filterOptions : this.state.filterOptions}
                    isRowStyle={this.props.isRowStyle ? this.props.isRowStyle : this.state.isRowStyle}
                    handleDeleteRow={this.handleDeleteRow}
                    loadRecords={this.loadRecords}
                    //handleJsonFile={this.handleJsonFile}
                    changeFilter={this.changeFilter}
                    cleanData={this.state.cleanData}
                    hideSearch={this.props.hideSearch}
                    //dataRowCaptureIdList={this.props.dataRowCaptureIdList ?  this.props.dataRowCaptureIdList : constants.ContactList}
                    
                    backButton={this.props.backButton !== undefined ? this.props.backButton : false}
                    onlySelect={this.props.onlySelect !== undefined ? this.props.onlySelect :  false}
                    isAllOptions={this.props.isReadOnly}
                    stayInMainWindowIsAllOptions={this.props.stayInMainWindowIsAllOptions}
                    typeModify={constants.contacts}
                    addButton={this.props.addButton !== undefined ? this.props.addButton : true}
                    deleteButton={this.props.deleteButton !== undefined ? this.props.deleteButton  : true}
                    editButton={this.props.editButton !== undefined ? this.props.editButton : true}
                    isToUp={this.props.isToUp !== undefined ? this.props.isToUp : true}
                    title={t(this.props.title !== undefined ? this.props.title : "Contact")} 
                    pathBackFromAddEdit={this.props.pathBackFromAddEdit !== undefined ? this.props.pathBackFromAddEdit : "/contactsList/"}
                    pathAddEdit={this.props.pathAddEdit !== undefined ? this.props.pathAddEdit : "/contacts/"}
                    isLoading={this.state.isLoading}
                    productGroupFilter={this.props.hideFilters ? 
                        undefined
                        :
                        <Grid container style={styles.ElementWithoutPadding}>
                            <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                                <Card style={styles.CardWithoutBoxes}>
                                    <CardContent style={{...styles.ElementWithoutPadding, ...{padding: "0px"}}}>
                                            <SearchOne
                                                {...this.props}
                                                disabled={this.props.disabled}
                                                id="customerNumber"
                                                label={t("Customer")}
                                                valueOne={this.state.Customer}
                                                handleOneSelect={(row) => this.handleOneSelect(row, "customer")}
                                                cleanFilter={() => this.cleanFilter("customer")}
                                                //changeFilter={this.changeFilter}
                                                typeQuery={constants.customers}
                                                showBasket={this.showBasket}
                                                hideSearch={false}
                                                handleClose={this.handleClose}
                                                isReadOnly={this.props.isReadOnly}
                                            />
                                    </CardContent>
                                </Card>
                            </Container>
                            <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                                <Card style={styles.CardWithoutBoxes}>
                                    <CardContent style={{...styles.ElementWithoutPadding, ...{padding: "0px"}}}>
                                        <Catalogs {...this.props} catalogConstant={constants.catalogs.Title} catalogId={"title"} 
                                            handleCatalogs={this.handleCatalogs} elementId={this.state.title} canAdd={false} label={t("ContactTitle")}
                                        ></Catalogs>
                                    </CardContent>
                                </Card>
                            </Container>
                        </Grid>
                    }
                    isArray={this.props.isArray}
                    handleAdd={this.props.handleAdd}
                    refreshDetails={this.props.refreshDetails}
                    builderList={this.props.builderList ? this.props.builderList : this.state.builderList}
                    idSave={this.props.idSave !== undefined ? this.props.idSave : constants.contactFilter  }
                    handleOneSelect={this.props.handleOneSelect}
                    handleSearchOneSelect={this.props.handleSearchOneSelect}
                    handleClose={this.props.handleClose}
                    hideFilters={this.props.hideFilters}
                    cleanReloadRecords={this.handleClose}
                    insideTab={this.props.insideTab}
                    expandedSearch={this.state.expandedSearch}
                                    
                    
                    
                />
            </Fragment>
        )
    };
}

export default withTranslation('common')(ContactList);
