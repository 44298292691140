import React from "react";
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import LegacyIFrame from './legacyIFrame';
import *  as navigationActions from '../../redux/actions/navigationActions';

export class RoutedLegacyIFrame extends React.Component {
    constructor(props) {
        super(props);

        this.onIFrameRouteChange = this.onIFrameRouteChange.bind(this);
    }

    componentDidMount() {
        this.props.actions.scrollToTop();
    }

    componentDidUpdate(prevProps) {
    }

    onIFrameRouteChange(title, location, moduleUrl) {
        this.props.actions.setModule({
            url: moduleUrl,
            name: title
        });

        ////this causes window reload
        //this.props.history.push(location);


        // Update browser url to correspond with the location reported by the IFrame
        window.history.replaceState(null, title, location);
    }

    render() {
        return (
            <LegacyIFrame
                sugarHost={this.props.tenant.host}
                location={this.props.location}
                contentHeight={this.props.contentHeight}
                onIFrameRouteChange={this.onIFrameRouteChange}
                />
        );
    }
}

function mapStateToProps(state) {
    return {
        tenant: state.tenant,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            setModule: bindActionCreators(navigationActions.setModule, dispatch),
            scrollToTop: bindActionCreators(navigationActions.scrollToTop, dispatch),
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RoutedLegacyIFrame));
