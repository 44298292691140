import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from './authorizationUtils';
import axios from 'axios';

// azure functions can not handle ambigous routes
// we need explicit defined those

export function ReadProductGroups(filter, sort, page, cancelToken) {
  const options = getRequestOptions(cancelToken);
  return axios.get(getApiUrl(serviceKeys.products) + `/api/Aggregate/ProductGroup/GetAll?filter=${filter}&sort=${sort}&page=${page}`, options)
      .then(handleResponse)
      .catch(handleError);
}

export function ReadProductGroup(id) {
    const options = getRequestOptions();
    return axios.get(getApiUrl(serviceKeys.products) + `/api/Aggregate/ProductGroup/Get/${id}`, options)
        .then(handleResponse)
        .catch(handleError);
  }

export function updateProductGroup(productGroup) {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.products) + `/api/Aggregate/ProductGroup/POST`, productGroup, options)
        .then(handleResponse)
        .catch(handleError);
}
