import * as types from "./actionTypes";
import * as ContactsApi from "../../api/contactsApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";
import * as constants from "../../components/common/constants";
import * as CustomerApi  from "../../api/customersApi";


export function readContactsSuccess(contacts) {
    return { type: types.LOAD_CONTACT_SUCCESS, contacts };
}

export function readContacts(filter, sort, page, customerId, title, cancelToken) {
    return async function(dispatch) {
        dispatch(beginApiCall());

        let value = [];
      
        await ContactsApi
        .ReadContacts(filter, sort, page, customerId, title, cancelToken)  
        .then(result => {
            value = result;
                //dispatch(readProductsRegisterSuccess(value));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (error.message !== constants.cancelHttpRequest)
            {
                dispatch(notifications.notificationError("ErrorloadingContacts"));
                throw new Error(error);
            }
        });

        var dict = {};
        value.map(element => {
            if (element.customerNumber !== "") {
                dict[element.customerNumber] = "";
            }
            return element;
        })
        var batch = [];
        for(var key in dict) {
            batch.push(key)
        }
        var sendData = {
            batch : batch
        }

        await CustomerApi
        .ReadCustomerBatch(sendData, cancelToken)  
        .then(result => {
            for (var x = 0; x < value.length; x++) {
                var name = result[value[x].customerNumber];
                if (name) {
                    value[x].customer = name;
                }
                else {
                    value[x].customer = "";
                }
            }
            dispatch(readContactsSuccess(value));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (error.message !== constants.cancelHttpRequest) {
                dispatch(notifications.notificationError("ErrorloadingCustomers"));
                throw new Error(error);
            }
        });

    };
} 

export function readContactSuccess(contact) {
    return { type: types.READ_CONTACT_SUCCESS, contact };
}

export function readContact(id) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return ContactsApi
        .ReadContact(id)  
        .then(result => {
            dispatch(readContactSuccess(result));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorloadingContact"));
            throw new Error(error);
        });
    };
} 

export function updateContactSuccess(contact) {
    return { type: types.UPDATE_CONTACT_SUCCESS, contact };
}

export function updateContact(model) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return ContactsApi
        .updateContact(model)  
        .then(result => {
            dispatch(updateContactSuccess(result));
            if (model.state === constants.Added) {
                model.id = result;
            }
            dispatch(readContactSuccess(model));
            return result;
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorupdatingContact"));
            throw new Error(error);
        });
    };
} 

