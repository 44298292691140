import React, { Component } from 'react';
import { ProductGroupsList } from "../common/Lists/productGroupsList";
import { connect } from 'react-redux';
import *  as productGroupActions from '../../redux/actions/productGroupActions';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import CancelPromisesHOC   from "../../helpers/cancelPromisesHOC";
import { withRouter } from "react-router-dom";
import {setCurrentModule} from "../../utils/navigationUtil";
import *  as navigationActions from '../../redux/actions/navigationActions';

export class ProductGroups  extends Component {
    componentDidMount(){
        this.props.actions.setModule(setCurrentModule('/productGroups'));
        }
    render() {
        return (
            <ProductGroupsList {...this.props} />
        )
    };
}

function mapStateToProps(state) {
    return {
        productGroups : state.productGroups,
        moduleInfo: state.navigation.module
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            readProductGroups: bindActionCreators(productGroupActions.readProductGroups, dispatch),
            updateProductGroup: bindActionCreators(productGroupActions.updateProductGroup, dispatch),  
            setModule: bindActionCreators(navigationActions.setModule, dispatch)
        }
    };
  }

export default compose(withTranslation('common'),
CancelPromisesHOC,
connect(
    mapStateToProps,
    mapDispatchToProps,
))(withRouter(ProductGroups));