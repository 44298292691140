import React from "react";
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { useSpring, animated } from "react-spring";
import Backdrop from "@material-ui/core/Backdrop";

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

const PreviewModal = ({
  isOpen,
  onClick,
  onNext,
  onPrev,
  rotateImage,
  rotation,
  width,
  src,
}) => {
  return isOpen ? (
    <Modal
      id="filePreviewModal"
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={isOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className="preview-modal-overlay">
          <div className="preview-modal-body">
            <div className="preview-close-modal">
              <FontAwesomeIcon
                className="preview-modal-close"
                icon={faIcons["faTimes"]}
                onClick={onClick}
              />
            </div>
            <div className="preview-rotate-container">
              <FontAwesomeIcon
                className="preview-rotate-icon"
                icon={faIcons["faSyncAlt"]}
                onClick={rotateImage}
              />
            </div>
            <div className="preview-image-container">
              {onPrev ? (
                <div>
                  <button className="button" onClick={onPrev}>
                    <i className="fas fa-angle-left" />
                  </button>
                </div>
              ) : (
                ""
              )}
              <div>
                <img
                  src={src}
                  alt=""
                  style={{
                    transform: `rotate(${rotation}deg)`,
                  }}
                />
              </div>
              {onNext ? (
                <div>
                  <button  className="button" onClick={onNext}>
                    <i className="fas fa-angle-right" />
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  ) : null;
};

PreviewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  //   onNext: PropTypes.func.isRequired,
  //   onPrev: PropTypes.func.isRequired,
  rotateImage: PropTypes.func.isRequired,
  rotation: PropTypes.number.isRequired,
  src: PropTypes.string,
};

export default PreviewModal;
