import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function NotificationsReducer(state = initialState.notification, action) 
{
  if (action.type === types.NOTIFICATION_DEBUG ||
    action.type === types.NOTIFICATION_ERROR ||
    action.type === types.NOTIFICATION_INFO ||
    action.type === types.NOTIFICATION_SUCCESS ||
    action.type === types.NOTIFICATION_WARNING) 
  {
    return action.notification;
  } else if (action.type === types.CLEAR_NOTIFICATIONS) {
    return initialState.notification;
  }
  return state;
}