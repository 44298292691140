import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
//import { OidcProvider } from 'redux-oidc';
import { store } from '../src/redux/store';
import { BrowserRouter } from 'react-router-dom';
import App  from './App';
import * as serviceWorker from './ServiceWorker';
import { I18nextProvider } from "react-i18next";
import i18n from '../src/i18n/i18n';

const rootElement = document.getElementById('root');

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <I18nextProvider i18n={i18n}>
                <App />
            </I18nextProvider>
        </BrowserRouter>
    </Provider>,
    rootElement);


    serviceWorker.register();
