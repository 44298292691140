import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { CustomerList } from "../Lists/customersList";

export class SearchOneCustomer extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }
    
    render() {
        return (
            <CustomerList 
                {...this.props}
                addButton={false}
                backButton={true}
                onlySelect={true}
                handleClose={this.props.handleClose}
                isToUp={true}
                isModalDialog={true}
                handleSearchOneSelect={this.props.handleSearchOneSelect}
                multiSelect={false}
                filterId={this.props.filterId}
                filterName={this.props.filterName}
            
            />
        )
    };
}

export default withTranslation('common')(SearchOneCustomer);
