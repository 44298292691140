import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
    Card,
    CardContent,
    Container,
} from '@material-ui/core';
import * as styles from "../../styles";
import * as constants from "../../constants";
import Catalogs from "../../catalogs";

export class WorkSiteContactRow  extends Component {

    constructor(props) {
        super(props);
        this.state = {
            row : this.props.row
        }
    }

    componentDidMount() {
        this.setState({
            row: this.props.row,
        });
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.row !== this.props.row) {
            this.setState({
                row: this.props.row,
            });
        }
   }

    handleChange = (e, id) => {
        let { value } = e.target;
        const { idx } = this.props;

        this.setState(prevState => ({
            ...prevState,
            row : {
                ...prevState.row,
                [id]: value
            }
        }), function() {
            this.props.handleRowChange(value, id, idx, this.props.isBuilder);
        });

       
    }

    handleCatalogs = (id, value ) => {
        var event = {
            target : {
                value : value
            }
        }
        this.handleChange(event, id);
    }

    render() {
        return (
            <Container maxWidth="sm" style={{...styles.ElementWithoutPadding, ...this.props.style}}>
                <Card style={styles.CardWithoutBoxes}>
                    <CardContent style={styles.ElementWithoutPadding}>
                        <Catalogs {...this.props} 
                            catalogConstant={constants.catalogs.Title} 
                            catalogId={"title"} 
                            handleCatalogs={this.handleCatalogs} 
                            elementId={this.state.row.title}
                            asignElementId={true}
                        ></Catalogs>
                    </CardContent>
                </Card>
            </Container>
        )
    };
}

export default withTranslation('common')(WorkSiteContactRow);
