import { handleError1, handleError2, handleResponse1, handleResponse2, handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from './authorizationUtils';
import axios from 'axios';


export function getAccessModuleList() {
    const options = getRequestOptions();
    let sugar_user_id = sessionStorage.getItem("sugar_user_id");
    if (!sugar_user_id) {
        sugar_user_id = '';
    }
    return axios.get(getApiUrl(serviceKeys.sugar) + '/token-access/index.php?module=Users&action=JSONAccessModuleList&sugar_user_id=' + sugar_user_id, options)
        .then(handleResponse)
        .catch(handleError);
}

export function getMenuList() {
    const options = getRequestOptions();
    var apiurl = getApiUrl(serviceKeys.sugar);
    if (apiurl) {
        if (apiurl != "https://undefined") {
            //ok
        } else {
            return null;
        }
    } else {
        return null;
    }

    let sugar_user_id = sessionStorage.getItem("sugar_user_id");
    if (!sugar_user_id) {
        sugar_user_id = '';
    }

    var res1 = axios.get(apiurl + '/token-access/index.php?module=Users&action=JSONMenu&sugar_user_id=' + sugar_user_id, options)
        .then(handleResponse1)
        .catch(handleError1);


    var res2 = axios.get(apiurl + '/token-access/index.php?module=Users&action=JSONMenu2&sugar_user_id=' + sugar_user_id, options)
        .then(handleResponse2)
        .catch(handleError2);


    return res1;
}

export function postUnifiedSearchSettings(settings) {
    const formData = new FormData();
    for (var i in settings) {
        formData.append(i, settings[i]);
    }
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.sugar) + '/token-access/index.php?module=Home&action=JSONUnifiedSearchSettings', formData, options)
        .then(handleResponse)
        .catch(handleError);
}
export function readUnifiedSearchSettings() {
    const options = getRequestOptions();
    return axios.get(getApiUrl(serviceKeys.sugar) + '/token-access/index.php?module=Home&action=JSONUnifiedSearchSettings', options)
        .then(handleResponse)
        .catch(handleError);
}

export function getSugarPageHistory() {
    let sugar_user_id = sessionStorage.getItem("sugar_user_id");
    if (!sugar_user_id) {
        sugar_user_id = '';
    }
    const options = getRequestOptions();
    return axios.get(getApiUrl(serviceKeys.sugar) + '/token-access/index.php?module=Home&action=pageHistory&sugar_user_id=' + sugar_user_id, options)
        .then(handleResponse)
        .catch(handleError);
}

export function readQuickSearchItems(input) {
    const options = getRequestOptions();
    options.params = { query_string: input }
    return axios.get(getApiUrl(serviceKeys.sugar) + '/token-access/index.php?module=Home&action=JSONQuickSearchItems', options)
        .then(handleResponse)
        .catch(handleError);
}
