import React, { Component, Fragment } from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    Grid, 
    Modal, 
    Backdrop, 
    Fade, 
    Button,  
    Typography, 
    Slide,
} from '@material-ui/core';
import UpTextField from './upTextField';
import * as styles from './styles.js';
import "../../index.css";
import Spinner from '../common/CircularSpinner';
import { withTranslation } from 'react-i18next';
import { DialogModal } from "./dialogModal";

export class Catalogs  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            catalogId: this.props.catalogId,
            catalogConstant : this.props.catalogConstant,
            itemId : -1,
            catalog : {
                id : "",
                items :[]
            },
            isLoading : false,
            name : "",
            isExist : false,
            isEdit : false,
            isDelete : false,
            canAdd: true
      }
    }

    async componentDidMount() {
        if (!this.props.isTesting) {
            this.setState({
                isLoading : true,
            });
        }
        if (this.props.canAdd !== undefined) {
            this.setState({
                canAdd : this.props.canAdd,
            });
        }
        
        await this.props.actions.readCatalog(this.state.catalogConstant)
        .then(() => {
        })
        .catch(() => { })
        .finally(result => {
            if (!this.state.isTesting) {
                this.setState({
                    isLoading: false,
                });
            }
        })

        if (this.props.asignElementId) {
            this.setState({
                itemId : this.state.catalog.items.findIndex(element => element === this.props.elementId)
            });
        }

    }


    componentDidUpdate(prevProps, prevState) {
        if(prevProps.elementId !== this.props.elementId && this.state.catalog.items.length > 0) 
        {
            this.setState({
                itemId : this.state.catalog.items.findIndex(element => element === this.props.elementId)
            });
        }

        if(prevProps.catalog !== this.props.catalog) {
            if (this.props.catalog.id === this.state.catalogConstant) {
                this.setState({
                    catalog : this.props.catalog,
                }, function() {
                    this.setState(prevState => ({
                        ...prevState,
                        catalog: {
                            ...prevState.catalog,
                            items : this.orderItems([...this.state.catalog.items])
                        },
                    }), function() {
                        if(this.props.elementId) 
                        {
                            this.setState({
                                itemId : this.state.catalog.items.findIndex(element => element === this.props.elementId)
                            });
                        }
                    })
               });
            }
        }
     
       
    }

    handleCatalog = (name) => (event) => {
        const value = parseInt(event.target.value);  
        
        if (value !== -1 && value !== -2) {
            this.props.handleCatalogs(this.state.catalogId, this.state.catalog.items[value]);
        }
        else if (value === -1) {
            this.props.handleCatalogs(this.state.catalogId, "");
        }

        this.setState({
            [name] : value
        })

        if (value === -2) {
            this.setState({
                open : true
            })
        }

    }

    handleChange = (event) => {
        const { value } = event.target;
        
        var item = this.state.catalog.items.find(e => e.toUpperCase() === value.toUpperCase());
        if (item) {
            this.setState({
                isExist : true,
            })
        }
        else {
            this.setState({
                isExist : false,
                name : value
            })
        }
    }

    
    save = async () => {
        if (!this.props.isLoading) {
            this.setState({
                isLoading: true,
            });
        }
        
        var clone = {...this.state.catalog }
        var data =  [...this.state.catalog.items];

        if (this.state.isEdit) {
            data[this.state.itemId] = this.state.name
        }
        else if (this.state.isDelete) {
            data.splice(this.state.itemId, 1);
        }
        else 
        {    
            data.push(this.state.name)
        }
        
        clone.id = this.state.catalogConstant;
        clone.items = this.orderItems(data);
      
        var flag = false;
        await this.props.actions.updateCatalog(clone)
        .then((result) => {
            clone = result; 
            flag = true;
        })
        .catch(() => {
        })
        .finally(() =>{
            this.setState({
                isLoading: false,
            });
           
        })

        if (flag) {
            this.setState(prevState => ({
                ...prevState,
                catalog: {
                    ...prevState.catalog,
                    items : clone.items
                },
            }), function() {
                if (this.state.isDelete) {
                    this.setState({
                        isDelete: false,
                        itemId: -1,
                    }, function() {
                        this.props.handleCatalogs(this.state.catalogId, "");
                    });
                }
                else {
                    this.setState({
                        itemId : this.state.catalog.items.findIndex(element => element === this.state.name)
                    }, function() {
                        this.props.handleCatalogs(this.state.catalogId, this.state.catalog.items[this.state.itemId]);
                        this.closeAll();
                    })
                }
            });
        }
    }

    back = () => {
        if (!this.state.isEdit) {
            this.setState({
                itemId : -1
            }, function() {
                this.props.handleCatalogs(this.state.catalogId, "");
                this.closeAll();
            })
        }
        else {
            this.closeAll();
        }
        
    }

    closeAll = () => {
        this.setState({
            isEdit: false,
            open: false,
            name: ""
        });
    }

    orderItems = (data) => {
        return data.sort(); 

    }

    edit = () => {
        this.setState({
            isEdit : true,
            open: true,
            name : this.state.catalog.items[this.state.itemId]
        })
    }

    delete = () => {
        this.setState({
            isDelete : true,
        })
    }

    handleDelete = (value) => {
        if (value) {
            this.save();
        }
        else {
            this.setState({
                isDelete : false,
            })
        }
    }
  
    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    
    dialogQuery = (idx) => {
        const { t } = this.props;
        const { open } = this.state;
        return (
            <Modal
                id="ModalPerson"
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                style={styles.modal}
                open={open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={open}  >
                    <Grid style={{ ...{ paddingLeft: "15px", minWidth: "400px" }, ...styles.modalPaper }}>
                        <Typography variant="h6">
                            {this.props.label}
                        </Typography>    
                        <Grid container style={styles.ElementWithoutPadding}>
                            <UpTextField
                                style={styles.inputElements}
                                id="name"
                                label={this.props.label}
                                value={this.state.name}
                                onChange={this.handleChange} 
                            />
                        </Grid>
                        {this.state.isExist &&
                            <Grid>
                                <Typography id="name-helper-text" style={{ color: "rgba(255, 1, 1, 0.54)" }}>
                                    {t("AlreadyExist")}
                                </Typography>
                            </Grid>
                        }
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            style={{ marginBottom : "20px", marginTop: "20px"   }}
                        >
                            <Grid item xs={4} style={{ textAlign : 'center'}}>
                                <Button id="close" onClick={this.back} variant="contained" >
                                {t("Back")}
                                </Button >  
                            </Grid>
                            <Grid item xs={4} style={{ textAlign : 'center'}}>
                                <Button id="save" 
                                    disabled={(this.state.name.trim() === "" || this.state.isExist) ? true : false}
                                    color="primary" onClick={this.save} variant="contained" >
                                    {t("Save")}
                                </Button >  
                            </Grid>
                        </Grid>
                    </Grid>
                </Fade>
            </Modal>
        )
    }

    render() {
        const { t } = this.props;
        return (
            <Fragment>
                <FormControl  style={styles.inputElements}>
                    <InputLabel htmlFor="outlined-approver" style={{
                        background: "white",
                        paddingRight: "5px",
                        paddingLeft: "0px",
                        }}>{this.props.label}</InputLabel>
                    <Select
                        native
                        value={this.state.itemId}
                        onChange={this.handleCatalog("itemId")}
                        inputProps={{
                            name: "itemId",
                            id: 'outlined-item',
                        }}
                        >
                        <option key={"option" + -1 } value={-1}></option>
                        {this.state.catalog.items.map((row, idx) => (
                            <option key={"option" + idx } value={idx}>{this.state.catalog.items[idx]}</option>
                        ))}   
                        {this.state.canAdd &&
                            <option key={"option" + -2 } value={-2}>{t("New")}</option>
                        }
                    </Select>
                </FormControl>
                { this.state.open &&
                    this.dialogQuery() 
                } 
                {this.state.isLoading &&
                    <Spinner open={true}/>
                }
                {(this.state.itemId > -1 && this.state.canAdd) &&   
                    <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="flex-end"
                        style={{ marginBottom : "5px"  }}
                    >
                        <Grid item xs={12} style={{ textAlign : 'end'}}>
                            <Button id="close" onClick={this.edit} variant="contained" color="primary" style={{ marginRight : '20px'}} >
                                {t("Edit")}
                            </Button >
                            {/*
                            <Button id="save" 
                                color="primary" onClick={this.delete} variant="contained" style={{ marginRight : '30px'}}>
                                {t("Delete")}
                            </Button >
                            */}
                              
                        </Grid>
                    </Grid>
                }
                { this.state.isDelete &&
                   <DialogModal
                        t={this.props.t}
                        open={this.state.isDelete}
                        questionDialog={t("AreyouSureofDelete")}
                        handleReply={this.handleDelete}
                   >
                   </DialogModal>
                } 
            </Fragment>
        )
    };
}


export default withTranslation('common')(Catalogs);

