import React, { Component, Fragment } from 'react';
import { Typography, Button } from '@material-ui/core';
import { WorkCardRow, WorkCardRowAdditional, WorkCardRowAggregate, WorkCardInfoRow } from './workCardRow';
import * as styles from '../common/styles';
import { withTranslation } from 'react-i18next';
import { AddBox } from '@material-ui/icons';

const colorRows = ['white', 'white'];


export class WorkCardRows extends Component {

    render() {
        const { t, hasReportingLevels, additional, normal, infoRows } = this.props;
        return (
            <Fragment>
                <Typography style={styles.headerRowMainWordCard}>{t("Normalwork")}</Typography>
                {Object.keys(normal).map((key, idx) => (
                    <Fragment key={idx}>
                        {hasReportingLevels && <Typography style={styles.subHeaderRowMainWordCard}  >{key}</Typography>}
                        <div style={hasReportingLevels ? styles.rowPanel : styles.rowPanelWithoutReportLevel }>
                            {normal[key].rows.map((row, i) => (
                                <React.Fragment key={"fragment" + row.id}>
                                    {row.rows.length ? (
                                        <WorkCardRowAggregate key={row.id} backgroundColor={
                                            (i % 2) === 0 ? colorRows[0] : colorRows[1]} row={row} 
                                            onChange={this.props.onChange} 
                                            editWorkCardApproved={this.props.editWorkCardApproved}
                                        />
                                    ) : (
                                        <WorkCardRow key={row.id} row={row} backgroundColor={
                                            (i % 2) === 0 ? colorRows[0] : colorRows[1]} onChange={this.props.onChange}
                                            hasReportingLevels={hasReportingLevels}
                                            editWorkCardApproved={this.props.editWorkCardApproved}
                                        />
                                    )
                                    }
                                </React.Fragment>

                            ))}
                        </div>
                    </Fragment>
                ))}
                <Typography style={hasReportingLevels ? styles.headerRowMainWordCard : styles.headerRowMainWithoutReportLevel} >{t("AdditionalWork")}</Typography>
                {Object.keys(additional).map((key, idx) => (
                    <Fragment key={idx}>
                        {hasReportingLevels && <Typography style={styles.subHeaderRowMainWordCard}  >{key}</Typography>}
                        <div style={hasReportingLevels ? styles.rowPanel : styles.rowPanelWithoutReportLevel }>
                            {additional[key].rows.map((row, i) => (
                                <React.Fragment key={"fragment" + row.id}>
                                    {row.rows.length > 0 ? (
                                       <WorkCardRowAggregate key={row.id} backgroundColor={
                                            (i % 2) === 0 ? colorRows[0] : colorRows[1]} row={row} 
                                            nChange={this.props.onChange} 
                                            editWorkCardApproved={this.props.editWorkCardApproved}
                                        />
                                    ) : (
                                        <WorkCardRowAdditional key={row.id} backgroundColor={
                                            (i % 2) === 0 ? colorRows[0] : colorRows[1]} row={row} onChange={this.props.onChange}
                                            hasReportingLevels={hasReportingLevels}
                                            editWorkCardApproved={this.props.editWorkCardApproved}
                                        />
                                    )
                                    }
                                </React.Fragment>
                            ))}
                        </div>
                    </Fragment>
                ))}
                <Typography style={styles.headerRowMainWordCard} >{t("Additionalinformation")}</Typography>
                {
                infoRows &&
                infoRows.map((row, idx) => (
                    <WorkCardInfoRow 
                    key={"rowinfo" + idx} 
                    row={row} 
                    idx={idx} 
                    onChange={this.props.onChangeAdditional}
                    showAdditionalButton={this.props.showAdditionalButton} />
                ))}
                <div style={{
                    textAlign: "end",
                    width: "100%",
                    marginTop: "25px"
                }}>
                    <Button 
                        id="NewAdditionalInfo" 
                        variant="contained" 
                        color="primary" 
                        style={styles.button} 
                        onClick={this.props.addEmptyInfoRow} 
                        disabled={this.props.showAdditionalButton}
                    >
                        <AddBox style={styles.leftIcon} />
                            {t("NewAdditionalInfo")}
                    </Button>
                </div>
            </Fragment>
        );
    }
}

export default withTranslation('common')(WorkCardRows);