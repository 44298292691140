import React, { Component, Fragment } from 'react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import * as styles from './styles.js';
import "../../index.css";
import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';
import { withTranslation } from 'react-i18next';
import CircleButton from "./CircleButton";


export class BottomButtons  extends Component {

    constructor(props) {
        super(props);
        this.state = {
            objScroll: null,
        }
    }

    componentDidMount() {
        if (this.props.isToUp) {
            if (this.props.isModalDialog) {
             
                var objScroll = document.querySelector("#dialogQuery .MuiDialog-paperFullScreen");
                
                if (!objScroll) {
                    objScroll = document.querySelector(".MuiDialog-paperFullScreen");
                }

                objScroll.addEventListener('scroll', this.handleScroll);
    
                this.setState({
                    objScroll : objScroll
                })
            }
            else {
                window.addEventListener('scroll', this.handleScroll);
            }
        }
    }

    componentWillUnmount() { 
        if (this.props.isToUp) {
            if (this.props.isModalDialog) {
                this.state.objScroll.addEventListener('scroll', this.handleScroll);
            }
            else {
                window.removeEventListener('scroll', this.handleScroll);
            }
        }
    }

    handleScroll = () => {
        var d;
        var offset;
        var height;
        if (this.props.isModalDialog) {
            d = this.state.objScroll;
            offset = d.scrollTop + window.innerHeight;
            height = d.offsetHeight;

            if (offset === height) {
                if (this.state.isBottom) {
                    this.setState({
                        isBottom : false
                    })
                }
            }
            else if(window.pageYOffset === 0) { 
                if (!this.state.isBottom) {
                    this.setState({
                        isBottom : true
                    })
                }
            }
        }
        else {
            d = document.documentElement;
            offset = d.scrollTop + window.innerHeight;
            height = d.offsetHeight;

            if (offset === height) {
                this.setState({
                    isBottom : true
                })
              }
              else if(window.pageYOffset === 0) { 
                  this.setState({
                      isBottom : false
                  })
              }
        }
       
    }
   

    toTop = () => {
        this.setState({
            isBottom: false
        });
        if (this.props.isModalDialog) {
            this.state.objScroll.scrollTo(0,0)
        }
        else {    
            window.scrollTo(0, 0)
        }
    }

    render() {
        const { t } = this.props
        return (
            <Fragment>
                {this.props.isToUp && this.state.isBottom &&
                    <div style={styles.floatButton}>
                        <CircleButton
                            color="primary" 
                            style={styles.floatButton}
                            id="upId"
                            toolTipMessage={t ? t("MoveToTop") : "MoveToTop"}
                            clickAction={this.toTop}
                            iconButton={<ArrowUpwardIcon  />}
                        />  
                    </div> 
                }
                {this.props.isSave && 
                    <div style={styles.floatButton}>
                        <CircleButton
                            color="primary" 
                            style={styles.floatButton}
                            id="saveId"
                            toolTipMessage={t ? t("Save") : "Save"}
                            clickAction={this.props.save}
                            iconButton={<SaveTwoToneIcon  />}
                        />  
                    </div>
                }
               
            </Fragment>
        )
    };
}

export default withTranslation('common')(BottomButtons);
