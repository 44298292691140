import React from "react";
import List from "@material-ui/core/List";
import Tooltip from "@material-ui/core/Tooltip";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import '../../components/Navbar/SideNavBar.css';
import * as icons from "@material-ui/icons";
import * as faSicons from '@fortawesome/free-solid-svg-icons';
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { withStyles } from "@material-ui/core/styles";
import navitems from '../../utils/navItems.json';
import hasAccess from "../../services/accessControl";
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compose } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import NestedMenuButton from '../../components/Buttons/NestedMenuButton';
import env from "../../utils/env";
import * as sugarApi from "../../api/sugarApi";

const styles = theme => ({
  root: {
    '-webkit-box-sizing': 'unset  !important',
    '-moz-box-sizing': 'unset  !important',
    'box-sizing': 'unset !important',
    width: "100%",
    maxWidth: 260,
    colorPrimary: "#D0C8C8",
    colorSecondary: "#D0C8C8",
    fontFamily: "\"Open Sans\", \"Roboto\", \"Oxygen\", sans-serif",
    marginLeft: "-4rem",
    maxHeight: 200,
    fontSize: 16,
    minHeight: '48px',
  },
  nested: {
    paddingLeft: theme.spacing(5),
    fontSize: 14
  },
  listIcon: {
    marginLeft: '-2rem',
    colorPrimary: '#D0C8C8',
  },
  menu: {
    color: '#D0C8C8',
    background: '#333333',
  }
});

export class NestedList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      result: ' ',
      isLoading: false,
      anchorEl: null,
      currentPage: ''

    };
  }

  handleClick = (e) => {
    this.setState({ [e]: !this.state[e] });
  };
  handleRoute = (item) => {
    this.props.actions.setModule({ url: item.Link, name: item.name});
    this.props.history.push(item.Link, {clickIndicator: uuidv4()});
    if(!item.Link)
    this.setState({ [item.name]: !this.state[item.name] });
    this.props.close();
  }

  handleMenuClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };
  filterEntries = (item) => {
    if(item.experimental && !env.experimental){
      return false;
    }
    if(!!item.module && !!hasAccess(this.props.resourceAccess, item.module, item.access)){
        return true;
    }else{
      return false
    }
    }

  getItemName = (item_name) => {
        var result = null;
        Object.keys(this.props.resourceAccess.custom).forEach(key => {
            if (item_name == key){
                Object.keys(this.props.resourceAccess.custom[key]).forEach(key1 => {
                    if (key1 == "custom_name"){
                        result = this.props.resourceAccess.custom[key][key1];
                    }
                });
            }
        });
        return result;
    }

  render() {
    const baseurl = "https://" + (this.props.tenant != null ? this.props.tenant.host : window.location.host);
    const yerp_url=this.props.tenant != null ? this.props.tenant.host : null;
    const { t, classes } = this.props;

    var list = navitems;

    //var menu1=sessionStorage.getItem("menu1");

    //if (!menu1){

        //sessionStorage.removeItem("menu1");

        var list1=sugarApi.getMenuList();
      var  menu1=sessionStorage.getItem("menu1");
    //}

    if (menu1){
        var list3 = JSON.parse(menu1);
            //        alert("1: " + menu1);
        if (list3.id){
            list=list3;
            //          alert("2");
        }
    }

    const anchorEl = this.state.anchorEl;

    return (
      <div>
        <List
          key={list.id}
        >
          {/* Map through the main items of the json */}
          {list.items.filter(item => (this.filterEntries(item))).map(item => {
            return (
              <div key={item.id}>
                {/* Check if item has subitems, then check that user has access to those subitems */}
                {(item.subitems != null) ? (
                  item.subitems.filter(sitem => this.filterEntries(sitem)).length !== 0 ? (
                    <div key={item.id}>
                      {(!this.props.minimized ? (
                          <ListItem
                            button
                            style={{paddingRight:6}}
                            key={item.id}
                            id={'side-nav-' + item.name}
                            aria-haspopup="true"
                            onClick={(this.props.minimized ? this.handleMenuClick : this.handleClick.bind(
                              this,
                              item.name
                            ) )

                            }
                          >
                          {(icons[item.icon] ?
                            <ListItemIcon className="side-nav-bar-icon">
                              {React.createElement((icons[item.icon]), { style: { color: (this.state[item.name] ? "#f16d30" : "#D0C8C8") } })}
                            </ListItemIcon>
                            :
                            <div style={{marginLeft: '3px' ,minWidth: '52px', fontSize: '18px'}} className="side-nav-bar-icon" >
                            <FontAwesomeIcon  icon={faSicons[item.icon]} style={{ color: (this.state[item.name] ? "#f16d30" : "#D0C8C8") }} />
                            </div>
                            )}
                            <ListItemText
                              primary={t(item.name)}
                              style= { {color: (this.state[item.name] ? "#f16d30" : "#D0C8C8") } }
                              className="side-nav-bar-list-parent"
                            />
                            {this.state[item.name] ? (
                              <ArrowDropDown color="primary" className="side-nav-bar-icon arrow" />
                            ) : (
                                <ArrowRight className="side-nav-bar-icon arrow" />
                              )}
                          </ListItem>
                      )
                        :
                        <NestedMenuButton t={t}
                          anchorEl={anchorEl}
                          item={item}
                          items={item.subitems}
                          history={this.props.history}
                          actions={this.props.actions}
                          resourceAccess={this.props.resourceAccess}/>
                      )}
                      <Collapse
                        key={list.items.id}
                        component="li"
                        in={this.state[item.name]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List>
                          {item.subitems.filter(sitem => this.filterEntries(sitem)).map(
                            sitem => {
                            var item_name = this.getItemName(sitem.name);

                              return (
                                <ListItem
                                style={{paddingRight:6}}
                                  button
                                  key={sitem.id}
                                  id={'side-nav-' + sitem.name}
                                  className={classes.nested}
                                  // component="a"
                                  onClick={() => this.handleRoute(sitem)}
                                  // href={!sitem.external ? null : baseurl + sitem.Link}
                                >
                                  <ListItemText
                                    key={sitem.id}
                                    primary={item_name||t(sitem.name)}
                                    style= { {color: (this.state[item.name] ? "#f16d30" : "#D0C8C8") } }
                                  />
                                </ListItem>
                              );
                            }
                          )}
                        </List>
                      </Collapse>

                    </div>
                  ) : (null)
                ) : (

                    // If no subitems, render item as link
                    <Tooltip title={this.props.minimized ?  t(item.name) : ""} placement='right'>

                      <ListItem
                        button
                        style={{paddingRight:6}}
                        id={'side-nav-' + item.name}
                        href={!item.external ? null : baseurl + item.Link}
                        onClick={


                            !item.external ? (() => this.handleRoute(item)) : (this.handleClick.bind(
                          this,
                          item.name,
                        ))}
                        key={item.id}


                      >

                      {(icons[item.icon] ?
                          <ListItemIcon className="side-nav-bar-icon">
                          {React.createElement((icons[item.icon]), { style: {color: "#D0C8C8" }})}
                        </ListItemIcon>
                        :
                        <div style={{marginLeft: '5px' ,minWidth: '50px', fontSize: '20px'}} className="side-nav-bar-icon" >
                            <FontAwesomeIcon  icon={faSicons[item.icon]}/>
                            </div>
                            )}
                        <ListItemText
                          primary={this.getItemName(item.name)||t(item.name)}
                          className="side-nav-bar-list-parent"
                        />
                      </ListItem>
                      </Tooltip>
                  )}
              </div>
            );
          })}
        </List>
      </div>
    );
  }
}

export default compose(withTranslation('common'), withStyles(styles),
)(NestedList);
