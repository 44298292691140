import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function contactsReducer(state = initialState.workQueuesSettings , action) {
    if (action.type === types.LOAD_WORKQUEUESETTINGS_SUCCESS)
    {
        return action.workQueuesSettings
    }
    else {
        return state;
    }
}
