import * as types from "./actionTypes";
import * as workSiteApi from "../../api/workSiteApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";
import * as constants from "../../components/common/constants";
import * as CustomerApi  from "../../api/customersApi";


export function readWorkSitesSuccess(WorkSites) {
    return { type: types.LOAD_WORKSITE_SUCCESS, WorkSites };
}

export function readWorkSites(filter, sort, page, customerId, cancelToken) {
    return async function(dispatch) {
        dispatch(beginApiCall());

        let value = [];
      
        await workSiteApi
        .ReadWorkSites(filter, sort, page, customerId, cancelToken)  
        .then(result => {
            value = result;
                //dispatch(readProductsRegisterSuccess(value));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (error.message !== constants.cancelHttpRequest)
            {
                dispatch(notifications.notificationError("ErrorloadingWorkSites"));
                throw new Error(error);
            }
        });

        var dict = {};
        value.map(element => {
            if (element.customerNumber !== "") {
                dict[element.customerNumber] = "";
            }
            return element;
        })
        var batch = [];
        for(var key in dict) {
            batch.push(key)
        }
        var sendData = {
            batch : batch
        }

        await CustomerApi
        .ReadCustomerBatch(sendData, cancelToken)  
        .then(result => {
            for (var x = 0; x < value.length; x++) {
                var name = result[value[x].customerNumber];
                if (name) {
                    value[x].customer = name;
                }
                else {
                    value[x].customer = "";
                }
            }
            dispatch(readWorkSitesSuccess(value));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (error.message !== constants.cancelHttpRequest) {
                dispatch(notifications.notificationError("ErrorloadingCustomers"));
                throw new Error(error);
            }
        });

    };
} 

export function readWorkSiteSuccess(WorkSite) {
    return { type: types.READ_WORKSITE_SUCCESS, WorkSite };
}

export function readWorkSite(id) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return workSiteApi
        .ReadWorkSite(id)  
        .then(result => {
            dispatch(readWorkSiteSuccess(result));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorloadingWorkSite"));
            throw new Error(error);
        });
    };
} 

export function updateWorkSiteSuccess(WorkSite) {
    return { type: types.UPDATE_WORKSITE_SUCCESS, WorkSite };
}

export function updateWorkSite(model) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return workSiteApi
        .updateWorkSite(model)  
        .then(result => {
            dispatch(updateWorkSiteSuccess(result));
            if (model.state === constants.Added) {
                model.id = result;
            }
            dispatch(readWorkSiteSuccess(model));
            return result;
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorupdatingWorkSite"));
            throw new Error(error);
        });
    };
} 

