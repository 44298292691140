import React from 'react';
import {
    Typography,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Grid,
    Card,
    Container,
    CardContent,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import { UpDatePicker } from './fields/upDatePicker';
import UpTextField from '../common/upTextField';
import * as styles from '../common/styles';
import { useTranslation } from 'react-i18next';
import UpDatePicker from '../common/upDatePicker';
import NumberField from '../common/NumberField'

export function WorkCardRow(props) {
    const { t, i18n } = useTranslation('common');

    const changeNormalRow = (e , id) => {
        e.target.id = id;
        props.onChange(e);
    }
  
    return <ExpansionPanel style={{ backgroundColor: props.backgroundColor, marginBottom : "1px"  }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
            <Grid container style={styles.ElementWithoutPadding}>
                <Grid item sm xs={8}>
                    <Typography>
                        {props.row.itemCode || "TUOTE"}
                    </Typography>
                </Grid>
                <Grid item sm={9} xs={12}>
                    <Typography >
                        {props.row.description}
                    </Typography>
                </Grid>
                <Grid item sm xs={4} style={{ textAlign: "right" }}>
                    <Typography style={{ fontWeight: "bolder" }}>
                        {new Intl.NumberFormat(i18n.language).format(props.row.quantity) + " " + props.row.unit}
                    </Typography>
                </Grid>
            </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ backgroundColor: "white" }}>

            <Grid container style={styles.ElementWithoutPadding}>
                {
                    /*
                <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                    <Card  style={styles.CardWithoutBoxes}>
                        <CardContent style={styles.ElementWithoutPadding}>
                            <UpTextField  style={styles.inputElements}
                            disabled={true}
                            label={translation.Amount} inputProps={{ 'data-record': props.row.id }} id="quantity" value={props.row.quantity} 
                            onChange={props.onChange} />
                            <UpTextField   style={styles.inputElements} 
                            disabled={true}
                            label={translation.Unit} inputProps={{ 'data-record': props.row.id }} id="unit" value={props.row.unit} 
                            onChange={props.onChange} />
                        </CardContent>
                    </Card>
                </Container>*/
                }
                <Container maxWidth={props.hasReportingLevels ? "sm" : "xl"} style={styles.ElementWithoutPadding} >
                    <Card style={styles.CardWithoutBoxes} >
                        <CardContent style={styles.ElementWithoutPadding}>
                            <Grid container direction={props.hasReportingLevels ? "column" : "row"}>
                                <Grid item sm xs={8}>
                                    <UpTextField disabled={true} style={styles.inputElements} label={t('Description')} inputProps={{ 'data-record': props.row.id }}
                                    id={"descriptionNormal" + props.row.id} value={props.row.description} 
                                    onChange={(e) => changeNormalRow(e, "description")} />
                                </Grid>
                                <Grid item sm xs={8}>
                                    <UpTextField disabled={true}
                                        style={styles.inputElements} label={t('Note')} inputProps={{ 'data-record': props.row.id }}
                                        id={"noteNormal" + props.row.id}  value={props.row.note} 
                                        onChange={(e) => changeNormalRow(e, "note")} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Container>
                { props.hasReportingLevels &&
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding} >
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding} >
                                {props.editWorkCardApproved &&
                                    <Grid item sm xs={8}>
                                         <NumberField
                                            id={"quantityNormal" + props.row.id}
                                            label={t("Quantity")}
                                            value={props.row.quantity}
                                            onChange={(e) => changeNormalRow(e, "quantity")}
                                            lang={i18n.language}
                                        />
                                    </Grid>
                                }
                                <Grid item sm xs={8}>
                                    <UpTextField style={styles.inputElements} label={t('Reporting_Level')}
                                        disabled={true}
                                        inputProps={{ 'data-record': props.row.id }} 
                                        id={"reportingLevelNormal" + props.row.id} value={props.row.reportingLevel} 
                                        onChange={(e) => changeNormalRow(e, "reportingLevel")}
                                    />
                                </Grid>
                            </CardContent>
                        </Card>
                    </Container>
                }
            </Grid>
        </ExpansionPanelDetails>
    </ExpansionPanel>
};


export function WorkCardRowAdditional(props) {
    const { t, i18n } = useTranslation('common');

    const changeAdditionalRow = (e , id) => {
        e.target.id = id;
        props.onChange(e);
    }

    return <ExpansionPanel style={{ backgroundColor: props.backgroundColor, marginBottom : "1px" }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container style={styles.ElementWithoutPadding}>
                <Grid container style={styles.ElementWithoutPadding} >
                    <Grid item sm xs={8}>
                        <Typography >
                            {new Intl.DateTimeFormat(i18n.language).format(new Date(props.row.date))}
                        </Typography>
                    </Grid>
                    <Grid item sm={9} xs={12}>
                        <Typography >
                            {props.row.description}
                        </Typography>
                    </Grid>
                    <Grid item sm xs={4} style={{ textAlign: "right" }}>
                        <Typography style={{ fontWeight: "bolder" }}>
                            {new Intl.NumberFormat(i18n.language).format(props.row.quantity) + " " + props.row.unit}
                        </Typography>
                    </Grid>
                </Grid>
                <Typography>
                    {props.row.note}
                </Typography>
            </Grid>

        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ backgroundColor: "white" }}>
            <Grid container style={styles.ElementWithoutPadding}>
                {
                    /*<Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <UpTextField 
                                disabled={true}
                                style={styles.inputElements} 
                                label={translation.Amount} inputProps={{ 'data-record': props.row.id }} id="quantity" value={props.row.quantity} 
                                onChange={props.onChange} />
                                <UpTextField 
                                disabled={true}
                                style={styles.inputElements} 
                                label={translation.Unit} inputProps={{ 'data-record': props.row.id }} id="unit" value={props.row.unit} 
                                onChange={props.onChange} />
                            </CardContent>
                        </Card>
                    </Container>*/
                }
                <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                    <Card style={styles.CardWithoutBoxes}>
                        <CardContent style={styles.ElementWithoutPadding}>
                            <UpTextField style={styles.inputElements}
                                disabled={true}
                                label={t('Description')} inputProps={{ 'data-record': props.row.id }} 
                                id={"descriptionAdditional" + props.row.id } value={props.row.description}
                                onChange={(e) => changeAdditionalRow(e, "description")} />
                            <UpTextField style={styles.inputElements}
                                disabled={true}
                                label={t('ItemCode')} inputProps={{ 'data-record': props.row.id }} 
                                id={"itemCodeAdditional" + props.row.id } value={props.row.itemCode}
                                onChange={(e) => changeAdditionalRow(e, "itemCode")} />
                            {props.editWorkCardApproved &&
                                <NumberField
                                    id={"quantityAdditional" + props.row.id }
                                    label={t("Quantity")}
                                    value={props.row.quantity}
                                    onChange={(e) => changeAdditionalRow(e, "quantity")}
                                    lang={i18n.language}
                                />
                            }
                        </CardContent>
                    </Card>
                </Container>
                <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                    <Card style={styles.CardWithoutBoxes}>
                        <CardContent style={styles.ElementWithoutPadding}>
                            {/*
                                <UpDatePicker
                                    disabled={true}
                                    style={styles.inputElements} 
                                    id="date"
                                    label={translation.Date}
                                    value={props.row.date}
                                    record={props.row.id}
                                    onChange={props.onChange} />*/
                            }
                            {props.hasReportingLevels &&
                                <UpTextField style={styles.inputElements}
                                    disabled={true}
                                    label={t('Reporting_Level')} inputProps={{ 'data-record': props.row.id }} 
                                    id={"reportingLevelAdditional" + props.row.id } value={props.row.reportingLevel}
                                    onChange={(e) => changeAdditionalRow(e, "reportingLevel")} />
                            }
                            <UpTextField
                                style={styles.inputElements}
                                label={t('Note')} inputProps={{ 'data-record': props.row.id }} 
                                id={"noteAdditional" + props.row.id } value={props.row.note} 
                                onChange={(e) => changeAdditionalRow(e, "note")} />
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
        </ExpansionPanelDetails>
    </ExpansionPanel>
};

export function WorkCardRowAggregate(props) {
    const { i18n } = useTranslation('common');
    return <ExpansionPanel style={{ backgroundColor: props.backgroundColor, marginBottom : "1px"  }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
            <Grid container style={styles.ElementWithoutPadding}>
                <Grid item sm xs={8}>
                    <Typography>
                        {props.row.itemCode || "TUOTE"}
                    </Typography>
                </Grid>
                <Grid item sm={9} xs={12}>
                    <Typography >
                        {props.row.description}
                    </Typography>
                </Grid>
                <Grid item sm xs={8} style={{ textAlign: "right" }}>
                    <Typography style={{ fontWeight: "bolder" }}>
                        {new Intl.NumberFormat(i18n.language).format(props.row.quantity) + " " + props.row.unit}
                    </Typography>
                </Grid>
            </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ padding: "8px 0px 24px", backgroundColor: "white" }}>
            <div style={{ width: "100%", paddingLeft: "20px", paddingRight: "60px" }}>
                <Grid container>
                    {props.row.rows.map((row, i) => (
                        <div key={"div" + i} style={{
                            marginBottom: "15px",
                            width: "100%",
                            borderStyle: "solid",
                            borderWidth: "1px",
                            borderTop: "0px",
                            borderRight: "0px",
                            borderLeft: "0px",
                            borderColor: "lightgray"
                        }}>
                            <Grid container style={styles.ElementWithoutPadding}>
                                <Grid item sm xs={8}>
                                    <Typography >
                                        {new Intl.DateTimeFormat(i18n.language).format(new Date(row.date))}
                                    </Typography>
                                </Grid>
                                <Grid item sm={9} xs={12}>
                                    <Typography >
                                        {row.note}
                                    </Typography>
                                </Grid>
                                <Grid item sm xs={8} style={{ textAlign: "right" }}>
                                    <Typography style={{ fontWeight: "bolder" }}>
                                        {new Intl.NumberFormat(i18n.language).format(row.quantity) + " " + row.unit}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    ))}
                </Grid>
            </div>
        </ExpansionPanelDetails>
    </ExpansionPanel>
};


export function WorkCardInfoRow(props) {
    const { t, i18n } = useTranslation('common');
    
    const changeInfoRow = (e , id) => {
        e.target.id = id;
        props.onChange(e);
    }

    return ( 
        <ExpansionPanel defaultExpanded={props.row.source ? false : true } 
            style={{ backgroundColor: props.backgroundColor, marginBottom : "1px"  }}
        >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                <Grid container style={styles.ElementWithoutPadding}>
                    <Grid item sm xs={8}>
                        <Typography>
                            {new Intl.DateTimeFormat(i18n.language).format(new Date(props.row.entryTime))}
                        </Typography>
                    </Grid>
                    <Grid item sm={9} xs={12}>
                        <Typography >
                            {props.row.description}
                        </Typography>
                    </Grid>
                </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ backgroundColor: "white" }}>
            <Grid container style={styles.ElementWithoutPadding}>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <UpDatePicker
                                    inputProps={{ 'data-record': props.idx }}
                                    id={"entryTimeInfo" +  props.idx}
                                    style={styles.inputElements}
                                    label={t("EntryTime")}
                                    value={props.row.entryTime}
                                    disabled={props.row.approved}
                                    onChange={(e) => props.onChange(e, props.idx )} />
                                <UpTextField 
                                    inputProps={{ 'data-record': props.idx }}
                                    id={"descriptionInfo" +  props.idx}
                                    style={styles.inputElements} 
                                    label={t('Description')} 
                                    value={props.row.description}
                                    disabled={props.row.approved} 
                                    onChange={(e) => changeInfoRow(e, "description")} />
                            </CardContent>
                        </Card>
                    </Container>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <UpTextField 
                                    inputProps={{ 'data-record': props.idx }} 
                                    id={"authorNameInfo" +  props.idx}
                                    style={styles.inputElements}
                                    label={t('AuthorName')}
                                    value={props.row.authorName} 
                                    disabled={props.row.approved}
                                    onChange={(e) => changeInfoRow(e, "authorName")}
                                />
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
            
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )
};

