import Resources from "./resources";
import Roles, { roleLevel } from "./Roles";

export const roles = Roles;
export const resources = Resources;

export const parseToken = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    const json = window.atob(base64);
    return JSON.parse(json);
};

const membershipIsImplied = (role, resourceRoles) => {
    if (!resourceRoles || !resourceRoles.roles) {
        return false;
    }

    const requiredLevel = roleLevel(role);
    const maxLevel = Math.max(...resourceRoles.roles.map(x => roleLevel(x)));
    return maxLevel >= requiredLevel || resourceRoles.roles.includes(role);
}


// check if the current user has access to a certain resource with a certain role
export const hasAccess = (resourceAccess, resource, role) => {

    function getPropertyCaseInsensitive(object, key) {
        if (!object) {
            return undefined;
        }

        return object[Object.keys(object)
            .find(k => k.toLowerCase() === key.toLowerCase())
        ];
    }

    const resourceRoles = getPropertyCaseInsensitive(resourceAccess, resource) ?? {};

    const customResourceRoles =  !!resourceAccess ? getPropertyCaseInsensitive(resourceAccess.custom, resource) ?? {} : {};

    return membershipIsImplied(role, resourceRoles) ||
        membershipIsImplied(role, customResourceRoles);
}

export default hasAccess;
