import React, { Component, Fragment } from 'react';
import "../../../index.css";
import { withTranslation } from 'react-i18next';
import * as constants from "../constants";
import FilterHelper from "../../../helpers/filterHelper"; 
import { SearchList } from "../Lists/searchList";
import axios from 'axios';
import initialState from "../../../redux/reducers/initialState";
import { SearchOne } from "../SearchOne";
import {
    Container,
    Card,
    CardContent,
    Grid,
    Dialog
} from '@material-ui/core';
import * as styles from '../styles';
import * as productRegisterApi from "../../../api/productRegisterApi";
import Spinner from './../CircularSpinner';
import FileHelper from "../../../helpers/fileHelper";
import StatefulSessionHelper from "../../../helpers/statefulSessionHelper";
import NumberHelper from "../../../helpers/numberHelper";
import { ProductWorkOrderRow } from "./rows/productWorkOrderRow";
import { ProductRegister } from "../../productsRegister/productRegister";

var isMounted = false;

export class ProductsRegisterList  extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            rows : [],
            filterOptions : [],
            isRowStyle: false,
            builderList : this.props.builderList ? this.props.builderList : [],
            isLoading : false,
            ProductGroup : "",
            ProductGroupId : "",
            ProductUnit : "",
            ProductUnitId : "",
            isLoadingMassive : false,
            filter: "",
            cleanData: false, 
            open: false,
            expandedSearch: false,
            idSaveLocal: this.props.idSave !== undefined ? this.props.idSave : constants.productRegisterFilter
      
        }
    }

    componentWillUnmount() {
        isMounted = false;
        this.props.cancelPromises();
    }

    async componentDidMount() {
        this.setFilter();

        var searchValue = StatefulSessionHelper.getSearchValue(this.state.idSaveLocal + this.props.user.profile.sub);
        if (searchValue === null) {
            searchValue = "";
        }
        
        await this.setState({
            filter : searchValue
        })
    
        var dataValues = StatefulSessionHelper.getValues(this.state.idSaveLocal + this.props.user.profile.sub);
       
        if (dataValues !== null) {
            var expand = false;
            if (dataValues.ProductGroupId !== "" || dataValues.ProductUnit !== "") {
                expand = true;
            }
            this.setState({
                ProductGroup : dataValues.ProductGroup,
                ProductGroupId : dataValues.ProductGroupId,
                ProductUnit : dataValues.ProductUnit,
                ProductUnitId : dataValues.ProductUnitId,
                expandedSearch: expand
           }, function() {
              this.reloadData();
           })
        }
        else {
            this.reloadData();
        }
    }

    reloadData = () => {
        var data = FilterHelper.getfilterOptions(this.state.idSaveLocal);
        this.loadRecords(this.state.filter, data.sort,1);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.productsRegister !== this.props.productsRegister) {
            var rowsProducts;
            var x;
            if (this.props.idSave === constants.workOrderProductsFilter) {
                rowsProducts = [...this.props.productsRegister]
                for (x = 0; x < rowsProducts.length; x++) {
                    rowsProducts[x].plannedQuantity = 1;
                    rowsProducts[x].actualQuantity = 1;
                    rowsProducts[x].installed = false;
                    delete rowsProducts[x].quantity;
                    
                }
                this.setState({
                    rows : rowsProducts
                });
            
            }
            else {
                rowsProducts = [...this.props.productsRegister]
                for (x = 0; x < rowsProducts.length; x++) {
                    rowsProducts[x].quantity = 1;
                }
                
                this.setState({
                    rows : this.props.productsRegister,
                });
            }
        }

        if (prevProps.builderList !== this.props.builderList) {
            this.setState({
                builderList : this.props.builderList,
            });
        }
    }
    
    setFilter = () => {
        
        if (this.state.idSaveLocal === constants.productRegisterFilter) {
            this.setState({
                idSave : this.props.idSave,
            });
    
            var options = [
                FilterHelper.createFilterOption("itemCode", true, true, 2, constants.textAlign.left, true, "ProductCode"),
                FilterHelper.createFilterOption("name", true, false, 1, constants.textAlign.left, true),
                FilterHelper.createFilterOption("description", true, false, 2, constants.textAlign.left, true),
                FilterHelper.createFilterOption("unitName", true, false, 1, constants.textAlign.left, false),
                FilterHelper.createFilterOption("costPrice", true, false, 1, constants.textAlign.right, true),
                FilterHelper.createFilterOption("basicSalePrice", true, false, 1, constants.textAlign.right, true),
                FilterHelper.createFilterOption("productGroup", true, false, 2, constants.textAlign.right, true),
            ];
          
            
            var filterOptions = FilterHelper.setFilter(this.state.idSaveLocal, options, false);
            
            this.setState({
                filterOptions : filterOptions.filter,
                isRowStyle : filterOptions.isRowStyle
            })

           
        }
    }

    captureDataTemplate = (model) => {
        return (
            <ProductWorkOrderRow key={"captureBuilder" + model.idx} t={this.props.t} i18n={this.props.i18n} 
                idx={model.idx} row={model.row} isBuilder={false} style={{paddingRight: "100px"}} 
                handleRowChange={model.handleRowChange}
                handleInsert={model.handleInsert} 
                handleDelete={model.handleDelete} 
                handleEdit={model.handleEdit}
                isListModal={true}
                quantityInit={1}
         
            />
        )
    }


    showFilterFields = (row, rowFilter) => {
        const {i18n} = this.props;
        return (
            <Fragment>
                {rowFilter.id === "itemCode" &&
                    row.itemCode
                }
                {rowFilter.id === "name" &&
                    row.name
                }
                {rowFilter.id === "description" &&
                    row.description
                }
                {rowFilter.id === "unitName" &&
                    row.unitName
                }
                {rowFilter.id === "costPrice" &&
                    NumberHelper.moneyFormat(row.costPrice, i18n.language)
                }
                {rowFilter.id === "basicSalePrice" &&
                    NumberHelper.moneyFormat(row.basicSalePrice, i18n.language)
                }
                {rowFilter.id === "productGroup" &&
                    row.productGroup
                }
            </Fragment>
        )
    }

    handleClose = () => {
        this.setState({
            cleanData: true
        }, function() {
            this.loadRecords(this.state.filter, "", 1);
        })
    }
    
    loadRecords = async (filter, sort, page) => {
        isMounted = true;

        this.props.cancelPromises();

        var isLoaded = false;
        if (!this.props.isArray) {
            
            if (!this.props.isLoading) {
                this.setState({
                    isLoading : true
                })
            }

            var data = FilterHelper.getfilterOptions(this.state.idSaveLocal);
      
            this.setState({
                filter : filter,
                filterOptions : data.filter,
                isRowStyle: data.isRowStyle
            })

            let cancelToken = axios.CancelToken.source();

            await this.props.cancellablePromise(
               this.props.actions.readProductsRegister(
                    filter, data.sort, page, 
                    this.state.ProductGroupId, 
                    this.state.ProductUnitId,
                    cancelToken.token), cancelToken
            )
            .then(() => {
                isLoaded = true;
            })
            .catch((error) => {
                if (error.message === constants.cancelHttpRequest || error.message === "[object Object]") {
                    isMounted = false;
                }
             })
            .finally(result =>{
                if (isMounted) {
                    this.setState({
                        isLoading : false,
                        cleanData: false
                    })
                }
                
            })

             /*
            cancelToken = axios.CancelToken.source();
            var batch = [];
            this.state.rows.map(element => batch.push(element.itemCode))
            var sendData = {
                batch : batch
            }
           
            await this.props.cancellablePromise(
                this.props.actions.readProductUnitsBatch(sendData, cancelToken.token), cancelToken
              )
             .then(() => {
                isLoaded = true;
             })
             .catch(() => {
             })
             .finally(result =>{
                 this.setState({
                     isLoading : false,
                     cleanData: false
                 })
             })
             if (Object.keys(this.props.productUnitDict).length > 0) {
                var temp = [...this.state.rows];
                for (var x = 0; x < temp.length; x++) {
                    //var element = temp[x];
                    var unit = this.props.productUnitDict[temp[x].itemCode];
                    if (unit) {
                        temp[x].name = "aaaa";
                        //element.unitName = unit;
                    }
                    else {
                        //element.unitName = "";
                    }
                }
                this.setState({
                    rows : Object.assign([], temp)
                })
                
             }*/
        }
        else {
            this.props.loadRecords();
        }

        return isLoaded;
    }
    
    handleJsonFile = async () => {

        if (!this.props.isLoading) {
            this.setState({
                isLoadingMassive : true
            })
        }

        if (!this.props.isArray) {

            const cancelToken = axios.CancelToken.source();

            await this.props.cancellablePromise(
                productRegisterApi.ReadProductsMassive(cancelToken.token), cancelToken
            )
            .then((result) => {
                FileHelper.createJSON(result, 'productsRegister.json', document);
             })
            .catch(() => {
            })
            .finally(result =>{
                this.setState({
                    isLoadingMassive : false
                })
            })
        }
        else {
            this.props.handleJsonFile();
            this.setState({
                isLoadingMassive : false
            })
        }

    }

    handleDeleteRow = async (record) => {
        if (!this.props.isLoading) {
            this.setState({
                isLoading : true
            })
        }

        record.state = constants.Deleted;
        
        var data = {...initialState.productRegister};
        data.productRegister = {...record};

        if (this.props.isTesting) {
            this.setState({
                recordTest : data
            });
        }

        var isDeleted = false;
        await this.props.actions.updateProductRegister(data)
        .then(() => {
            isDeleted = true;
        })
        .catch(() => {})
        .finally(() =>{
            this.setState({
                isLoading : false
            })
        })
        return isDeleted;
    }
    
    handleOneSelect = (row, id) => {
        if (id === "productGroup") {
            this.setState({
                ProductGroup: row.name,
                ProductGroupId: row.itemCode,
                cleanData: true
            }, function() {
                this.setSession();
            });
        }

        if (id === "unit") {
            this.setState({
                ProductUnit: row.name,
                ProductUnitId: row.itemCode,
                cleanData: true
            }, function() {
                this.setSession();
            });
        }
    }

    cleanFilter = (id) => {
        if (id === "productGroup") {
            this.setState({
                ProductGroup: "",
                ProductGroupId: "",
                cleanData: true
            }, function() {
                this.setSession();
            });
        }
        if (id === "unit") {
            this.setState({
                ProductUnit: "",
                ProductUnitId: "",
                cleanData: true
            }, function() {
                this.setSession();
            });
        }

    }

    changeFilter = (filter) => {
        this.setState({
            filter : filter
        })
        
    }

    setSession = () => {
        var data = {
            ProductGroup : this.state.ProductGroup,
            ProductGroupId : this.state.ProductGroupId,
            ProductUnit : this.state.ProductUnit,
            ProductUnitId : this.state.ProductUnitId
       }
       
       StatefulSessionHelper.setValues(this.state.idSaveLocal + this.props.user.profile.sub, data);
       this.reloadData();
    }

    showBasket = (show) => {
        if (this.props.showBasket) {
            this.props.showBasket(show);
        }
    }

    handleAddInModal = () => {
        this.props.cancelPromises();
        
        this.setState({
            open: true,
        });
    }

    handleClose = () => {
        this.setState({
            open: false,
        });
    }

    addProduct = (data) => {
        this.props.addProduct(data);
        this.handleClose();
    }

    dialogQuery = () => {
        return (
            <Dialog fullScreen open={this.state.open} onClose={this.handleClose} TransitionComponent={this.Transition}>
                <div style={{paddingLeft: "32px", paddingRight: "32px"}}>
                    <ProductRegister 
                        t={this.props.t} 
                        i18n={this.props.i18n} 
                        actions={this.props.actions} 
                        product={this.props.product}
                        cancelPromises={this.props.cancelPromises} 
                        cancellablePromise={this.props.cancellablePromise}
                        productsRegister ={this.props.productsRegister}
                        productGroups={this.props.productGroups}
                        productGroup={this.props.productGroup}
                        productUnits={this.props.productUnits}
                        productUnit={this.props.productUnit}
                        isCreateNewInModal={this.props.isCreateNewInModal}
                        handleClose={this.handleClose}
                        captureDataTemplate={this.captureDataTemplate}
                        match={{
                            params : {
                                productId : constants.AddProductFromWorkOrder
                            }}
                        }
                        addType={constants.ProductWorkOrderList}
                        addProduct={this.addProduct}
                    />
                </div>
            </Dialog>
        )
    }


    render() {
        const { t } = this.props;
        return (
            <Fragment>
                {this.state.isLoadingMassive &&
                    <Spinner open={true}/>
                }
                <SearchList 
                    {...this.props}
                    rows={this.state.rows}
                    showtitle={this.props.showtitle}
                    showFilterFields={this.props.showFilterFields ?  this.props.showFilterFields : this.showFilterFields}
                    filterOptions={this.props.filterOptions ? this.props.filterOptions : this.state.filterOptions}
                    isRowStyle={this.props.isRowStyle ? this.props.isRowStyle : this.state.isRowStyle}
                    handleDeleteRow={this.handleDeleteRow}
                    loadRecords={this.loadRecords}
                    handleJsonFile={this.handleJsonFile}
                    changeFilter={this.changeFilter}
                    cleanData={this.state.cleanData}
                    hideSearch={this.props.hideSearch}
                    dataRowCaptureIdList={this.props.dataRowCaptureIdList ?  this.props.dataRowCaptureIdList : constants.ProductsRegisterList}
                    
                    backButton={this.props.backButton !== undefined ? this.props.backButton : false}
                    onlySelect={this.props.onlySelect !== undefined ? this.props.onlySelect :  false}
                    addButton={this.props.addButton !== undefined ? this.props.addButton : true}
                    deleteButton={this.props.deleteButton !== undefined ? this.props.deleteButton  : true}
                    editButton={this.props.editButton !== undefined ? this.props.editButton : true}
                    isToUp={this.props.isToUp !== undefined ? this.props.isToUp : true}
                    title={t(this.props.title !== undefined ? this.props.title : "ProductsRegister")} 
                    pathBackFromAddEdit={this.props.pathBackFromAddEdit !== undefined ? this.props.pathBackFromAddEdit : "/productsRegisterList/"}
                    pathAddEdit={this.props.pathAddEdit !== undefined ? this.props.pathAddEdit : "/productsRegister/"}
                    isLoading={this.state.isLoading}
                    productGroupFilter={this.props.hideFilters ? 
                        undefined
                        :
                        <Grid container style={styles.ElementWithoutPadding}>
                            <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                                <Card style={styles.CardWithoutBoxes}>
                                    <CardContent style={{...styles.ElementWithoutPadding, ...{padding: "0px"}}}>
                                            <SearchOne
                                                {...this.props}
                                                id="productGroupId"
                                                label={t("ProductGroup")}
                                                valueOne={this.state.ProductGroup}
                                                handleOneSelect={(row) => this.handleOneSelect(row, "productGroup")}
                                                cleanFilter={() => this.cleanFilter("productGroup")}
                                                //changeFilter={this.changeFilter}
                                                typeQuery={constants.productGroup}
                                                isBasket={this.props.isBasket}
                                                showBasket={this.showBasket}
                                                hideSearch={false}
                                                handleClose={this.handleClose}
                                                isNotLegacy={false}
                   
                                            />
                                    </CardContent>
                                </Card>
                            </Container>
                            <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                                <Card style={styles.CardWithoutBoxes}>
                                    <CardContent style={{...styles.ElementWithoutPadding, ...{padding: "0px"}}}>
                                            <SearchOne
                                                {...this.props}
                                                id="productUnitId"
                                                label={t("Unit")}
                                                valueOne={this.state.ProductUnit}
                                                handleOneSelect={(row) => this.handleOneSelect(row, "unit")}
                                                cleanFilter={() => this.cleanFilter("unit")}
                                                //changeFilter={this.changeFilter}
                                                typeQuery={constants.productUnit}
                                                isBasket={this.props.isBasket}
                                                showBasket={this.showBasket}
                                                hideSearch={false}
                                                handleClose={this.handleClose}
                                                isNotLegacy={false}
                   
                                            />
                                    </CardContent>
                                </Card>
                            </Container>
                        </Grid>
                    }
                    isArray={this.props.isArray}
                    handleAdd={this.props.handleAdd}
                    refreshDetails={this.props.refreshDetails}
                    builderList={this.props.builderList ? this.props.builderList : this.state.builderList}
                    idSave={this.props.idSave !== undefined ? this.props.idSave : constants.productRegisterFilter  }
                    handleOneSelect={this.props.handleOneSelect}
                    handleSearchOneSelect={this.props.handleSearchOneSelect}
                    handleClose={this.props.handleClose}
                    hideFilters={this.props.hideFilters}
                    isBasket={this.props.isBasket}
                    addBasket={this.props.addBasket}
                    counterBasket={this.props.counterBasket}
                    setCounterBasket={this.props.setCounterBasket}
                    overrideAddButtonLabel={this.props.overrideAddButtonLabel}
                    captureDataTemplate={this.props.captureDataTemplate ? this.props.captureDataTemplate : this.captureDataTemplate}
                    isNotLegacy={this.props.isNotLegacy}
                    isCreateNewInModal={this.props.isCreateNewInModal}
                    handleAddInModal={this.handleAddInModal}
                    handleEdit={this.props.handleEdit}
                    expandedSearch={this.state.expandedSearch}
                />
                { this.state.open &&
                  this.dialogQuery() 
                }
            </Fragment>
        )
    };
}

export default withTranslation('common')(ProductsRegisterList);
