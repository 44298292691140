/* /src/routes/privateRoute.js */
import React from "react";
import { connect } from "react-redux";
import { Route } from 'react-router-dom';
import Spinner from '../components/common/CircularSpinner';
import LoginInitiator from '../components/auth/loginInitiator';

const PrivateRouteComponent = ({ component, ...rest }) => {
    const renderFn = (Component, props) => () => {
        const { user } = props;
        if (!!Component && !!user && !user.expired) {
            return <div>
                <Component {...props} />
                <LoginInitiator />
            </div>;
        } else {
            return <div>
                <Spinner open={true} />
                <LoginInitiator />
            </div>;
        }
    };
    return <Route {...rest} render={renderFn(component, rest)} />;
};

function mapStateToProps(state) {
    return {
        user: state.oidc.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export const PrivateRoute = connect(mapStateToProps, mapDispatchToProps)(PrivateRouteComponent);