import * as types from "./actionTypes";
import * as sugarApi from "../../api/sugarApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";

export function scrollToTop() {
  return function(dispatch) {
    window.scrollTo(0, 0);
  }
}

export function setModule(modules) {

  return {
      type: types.SET_MODULE,
      modules
  };
}

export function showModuleTitle(show) {

  return {
      type: types.SHOW_MODULE_TITLE,
      show
  };
}

export function readSugarPageHistorySuccess(sugarHistory) {
  return {
    type: types.READ_SUGAR_PAGE_HISTORY_SUCCESS,
    sugarHistory
  };
}

export function readSugarPageHistory() {
  return function(dispatch) {
      dispatch(beginApiCall());
      return sugarApi
          .getSugarPageHistory()
          .then(history => {
              dispatch(readSugarPageHistorySuccess(history));
          })
          .catch(error => {
              dispatch(apiCallError(error));
              dispatch(notifications.notificationError("ErrorReadingPageHistory"));
          });
  };
}
