import navitems from './navItems';

export const setCurrentModule = (key) => {
    var parentModule = navitems.items.filter(navitems => (
        !!navitems.Link && navitems.Link.toLowerCase() === key.toLowerCase()))
    //if module wasn't found, check subitems
    if(parentModule.length < 1){
        var subitems = [];
        navitems.items.filter(item => item.subitems).map(item => {
        return item.subitems.map(subitem => {
            return subitems.push(subitem)
            });
        })
        parentModule = subitems.filter(navitems => (
            !!navitems.Link && navitems.Link.toLowerCase() === key.toLowerCase()))
        
    }
    return {url: parentModule[0].Link, name: parentModule[0].name};
}