import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function workOrdersReducer(state = initialState.workOrders , action) {
    if (action.type === types.LOAD_WORKORDER_SUCCESS)
    {
        return action.workOrders
    }
    else {
        return state;
    }
}
