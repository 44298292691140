import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from './authorizationUtils';
import axios from 'axios';
import * as constants from "../components/common/constants";
import FilterHelper from "../helpers/filterHelper"; 

// azure functions can not handle ambigous routes
// we need explicit defined those

export function ReadCustomers(filter, sort, page, paymentType, accountGroup, cancelToken) {
    const options = getRequestOptions(cancelToken);
    
    var data = {
        filter : filter,
        sort: sort,
        page: page,
        paymentType: paymentType,
        accountGroup: accountGroup,
        searchFields: FilterHelper.getSearchfilter(constants.customerFilter)
    }
    return axios.post(getApiUrl(serviceKeys.products) + `/api/Aggregate/Customer/GetAll`, data, options)
        .then(handleResponse)
        .catch(handleError);
}

export function ReadCustomer(id) {
    const options = getRequestOptions();
    return axios.get(getApiUrl(serviceKeys.products) + `/api/Aggregate/Customer/Get/${id}`, options)
        .then(handleResponse)
        .catch(handleError);
}

export function ReadCustomerBatch(batch, cancelToken) {
    const options = getRequestOptions(cancelToken);
    return axios.post(getApiUrl(serviceKeys.products) + `/api/Aggregate/Customer/GetBatch`, batch, options)
        .then(handleResponse)
        .catch(handleError);
}

export function updateCustomer(model) {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.products) + `/api/Aggregate/Customer/POST`, model, options)
        .then(handleResponse)
        .catch(handleError);
}



