import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from './authorizationUtils';
import axios from 'axios';
import * as constants from "../components/common/constants";
import FilterHelper from "../helpers/filterHelper"; 

// azure functions can not handle ambigous routes
// we need explicit defined those

export function ReadWorkSites(filter, sort, page, customerId, cancelToken) {
    const options = getRequestOptions(cancelToken);

    if (customerId === "") {
        customerId = 0;
    }
   
    var data = {
        filter : filter,
        sort: sort,
        page: page,
        customerId: customerId,
        searchFields: FilterHelper.getSearchfilter(constants.workSiteFilter)
    };
 
    return axios.post(getApiUrl(serviceKeys.products) +  `/api/Aggregate/WorkSite/GetAll`, data, options)
        .then(handleResponse)
        .catch(handleError);
    
}

export function ReadWorkSite(id) {
    const options = getRequestOptions();
    return axios.get(getApiUrl(serviceKeys.products) + `/api/Aggregate/WorkSite/Get/${id}`, options)
        .then(handleResponse)
        .catch(handleError);
}

export function updateWorkSite(model) {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.products) + `/api/Aggregate/WorkSite/POST`, model, options)
        .then(handleResponse)
        .catch(handleError);
}


export function ReadWorkSiteBatch(batch, cancelToken) {
    const options = getRequestOptions(cancelToken);
    return axios.post(getApiUrl(serviceKeys.products) + `/api/Aggregate/WorkSite/GetBatch`, batch, options)
        .then(handleResponse)
        .catch(handleError);
}




