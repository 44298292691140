import * as types from "./actionTypes";

export function clearNotifications() {
  return { 
      type: types.CLEAR_NOTIFICATIONS,
      notification: {}
  };
}

export function notificationSuccess(message, isTranslated) {
  var notification = {
    message : message,
    variant : "success",
    isTranslated : isTranslated
  }

  return { 
      type: types.NOTIFICATION_SUCCESS,
      notification
  };
}

export function notificationWarning(message, isTranslated) {
    var notification = {
      message : message,
      variant : "warning",
      isTranslated : isTranslated
    }
  
    return { 
        type: types.NOTIFICATION_WARNING,
        notification
    };
}

export function notificationError(message, isTranslated) {
    var notification = {
      message : message,
      variant : "error",
      isTranslated : isTranslated
    }
  
    return { 
        type: types.NOTIFICATION_ERROR,
        notification
    };
}

export function notificationInfo(message, isTranslated) {
    var notification = {
      message : message,
      variant : "info",
      isTranslated : isTranslated
    }
  
    return { 
        type: types.NOTIFICATION_INFO,
        notification
    };
}

export function notificationDebug(message, isTranslated) {
    var notification = {
      message : message,
      variant : "debug",
      isTranslated : isTranslated
    }
  
    return { 
        type: types.NOTIFICATION_DEBUG,
        notification
    };
}
