import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from './authorizationUtils';
import axios from 'axios';

export function readWorkCard(workCardId) {
    const options = getRequestOptions();
    return axios.get(getApiUrl(serviceKeys.workCards) + `/api/Aggregate/WorkCards/${workCardId}`, options)
        .then(handleResponse)
        .catch(handleError);
}

export function updateWorkCard(workCardData) {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.workCards) + `/api/Aggregate/WorkCards`, workCardData, options)
        .then(handleResponse)
        .catch(handleError);
}


export function createWorkCard(workCardData) {
    //const options = getRequestOptions();
    return null;
    //return axios.post(getApiUrl(serviceKeys.workCards) + `/api/workcards/`, workCardData)
    //    .then(handleResponse)
    //    .catch(handleError);
}

/*
export function updateWorkCard(workCardId, workCardData) {
    const options = getRequestOptions();
    return null;
    return axios.put(getApiUrl(serviceKeys.workCards) + `/api/workcards/${workCardId}`, workCardData, options)
        .then(handleResponse)
        .catch(handleError);
}
*/

export function deleteWorkCard(workCardId) {
    //const options = getRequestOptions();
    return null;
    //return axios.delete(getApiUrl(serviceKeys.workCards) + `/api/workcards/${workCardId}`)
    //    .then(handleResponse)
    //    .catch(handleError);
}

