
import * as types from "./actionTypes";
import * as workCardsApi from "../../api/workCardsApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";

export function loadWorkcardsSuccess(workCards) {
    return { type: types.LOAD_WORKCARDS_SUCCESS, workCards };
}

export function readWorkCards() {
    return function(dispatch) {
        dispatch(beginApiCall());
        return workCardsApi
        .getWorkCards()  
        .then(workCards => {
            dispatch(loadWorkcardsSuccess(workCards.value));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorloadingworkCards"));
            throw new Error(error);
        });
    };
} 



