import React, { Component, Fragment } from "react";
import { withStyles } from '@material-ui/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { LocaleDate }  from '../../i18n/locales';
import { compose } from 'redux';
import { withTranslation   } from 'react-i18next';

const styles = {
    root: {
        margin: '1em 1em',
    },
};

export class UpDatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(this.props.value),
        };
       

        this.handleDateChange = this.handleDateChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                date: new Date(this.props.value),
            });
    
        }
      }
   

    handleDateChange(value) {
        this.setState(prevState => ({
            date: value
        }));
        const event = {
            target: {
                id: this.props.id,
                value: value,
                dataset: {
                    record: this.props.record || ''
                }
            }
        };
        this.props.onChange(event);
    }

    render() {
        const { i18n } = this.props;
        return (
            <Fragment>
                <KeyboardDatePicker
                    style={{styles, ...this.props.style}}
                    disabled={this.props.disabled}
                    disableToolbar
                    variant="inline"
                    format={LocaleDate[i18n.language]}
                    margin="normal"
                    id={this.props.id}
                    label={this.props.label}
                    value={this.state.date}
                    onChange={this.handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                {this.props.includeTime &&
                    <KeyboardTimePicker
                        style={{styles, ...this.props.style}}
                        disabled={this.props.disabled}
                        margin="normal"
                        id={"time" + this.props.id}
                        label=""
                        value={this.state.date}
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                        }}
                    />
                }
          </Fragment>
        );
    }
}

export default compose(withTranslation('common'),withStyles(styles))(UpDatePicker);
