import React, { Component, Fragment } from 'react';
import {
    Grid,
    Dialog,
    Slide,
} from '@material-ui/core';
import UpTextField from './upTextField';
import * as styles from './styles.js';
import "../../index.css";
import { withTranslation } from 'react-i18next';
import ClearTwoToneIcon from '@material-ui/icons/ClearTwoTone';
import { SearchOneProductGroup } from "./searchOnes/searchOneProductGroup";
import { SearchOneProductUnit } from "./searchOnes/searchOneProductUnit";
import { SearchOneCustomer } from "./searchOnes/searchOneCustomer";
import { SearchOneContact } from "./searchOnes/searchOneContact";
import { SearchOneWorkSite } from "./searchOnes/serachOneWorkSite";
import { SearchOneUser } from "./searchOnes/searchOneUser";
import * as constants from "./constants";

export class SearchOne  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valueOne : "",
            open : false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.valueOne !== this.props.valueOne) {
            this.setState({
                valueOne : this.props.valueOne,
            });
        }
        
    }

    handleSearch = (event) => {
        this.setState({
            scrollLeft : window.pageXOffset,
            scrollTop : window.pageYOffset,
            open: true,
        });
    }

    handleClose = (data) => {
        this.closeAll();
        this.back(data);
    }

    closeAll = () => {
        this.props.cancelPromises();
        this.setState({
            open: false,
        }, function() {
            window.scrollTo(this.state.scrollLeft, this.state.scrollTop);
        });

        if (this.props.showBasket) {
            this.props.showBasket(true);
        }
    }

    back = (data) => {
        if (this.props.handleClose && this.props.isCreateNewInModal === undefined) {
            this.props.handleClose(data);
        }

    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    handleSearchOneSelect = () => {
        this.closeAll();
    }

    dialogQuery = () => {
        return (
            <Dialog id="dialogQuery" fullScreen open={this.state.open} onClose={this.handleClose} TransitionComponent={this.Transition}>
                <div style={{paddingLeft: "32px", paddingRight: "32px"}}>
                    {this.props.typeQuery === constants.productGroup &&
                        <SearchOneProductGroup {...this.props} handleClose={this.handleClose} handleSearchOneSelect={this.handleSearchOneSelect} ></SearchOneProductGroup>
                    }
                    {this.props.typeQuery === constants.productUnit &&
                        <SearchOneProductUnit  {...this.props} handleClose={this.handleClose} handleSearchOneSelect={this.handleSearchOneSelect} ></SearchOneProductUnit>
                    }
                    {this.props.typeQuery === constants.customers &&
                        <SearchOneCustomer {...this.props} handleClose={this.handleClose} handleSearchOneSelect={this.handleSearchOneSelect} ></SearchOneCustomer>
                    }
                    {this.props.typeQuery === constants.contacts &&
                        <SearchOneContact {...this.props} handleClose={this.handleClose} handleSearchOneSelect={this.handleSearchOneSelect} 
                            isFilterDisabled={true}
                        >
                        </SearchOneContact>
                    }
                    {this.props.typeQuery === constants.workSites &&
                        <SearchOneWorkSite {...this.props} handleClose={this.handleClose} handleSearchOneSelect={this.handleSearchOneSelect} >
                        </SearchOneWorkSite>
                    }
                    {this.props.typeQuery === constants.users &&
                        <SearchOneUser {...this.props} handleClose={this.handleClose} handleSearchOneSelect={this.handleSearchOneSelect} 
                        >
                        </SearchOneUser>
                    }
                </div>
            </Dialog>
        )
    }

    render() {
        //const { t } = this.props;
        return (
            <Grid container style={styles.ElementWithoutPadding}>
                <Grid container direction="row" justify="space-between">
                    <div style={this.props.isFullWidth ? {width:"100%"} : {width:"100%"} }>
                        <UpTextField
                            label={this.props.label}
                            value={this.props.valueOne}
                            isAdorment={true}
                            Adorment={<Fragment></Fragment>}
                            handleSearch={this.props.isReadOnly ? null : this.handleSearch}
                            readOnly={true}
                            AdormentRight={
                                this.props.isReadOnly ? null :
                                <ClearTwoToneIcon  onClick={this.props.cleanFilter}/>
                            }
                            showBasket={this.props.showBasket}
                        />
                        
                    </div>
                    {/*
                    <div>
                        <CircleButton
                            id="cleanFilterId"
                            toolTipMessage={t("Cleanfilter")}
                            clickAction={this.props.cleanFilter}
                            iconButton={<ClearTwoToneIcon  />}
                            color="primary" 
                        />
                        <CircleButton
                            id="searchId"
                            toolTipMessage={t("Search")}
                            clickAction={this.handleSearch}
                            iconButton={<SearchIcon  />}
                            color="primary" 
                        />
                    </div>*/
                    }
                </Grid>
                { this.state.open &&
                    this.dialogQuery() 
                } 
            </Grid>
        )
    };
}

export default withTranslation('common')(SearchOne);
