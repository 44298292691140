import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function workQueueSettingsReducer(state = initialState.workQueueSettings , action) {
    if (action.type === types.READ_WORKQUEUESETTINGS_SUCCESS)
    {
        return action.workQueueSettings;
    }
    else {
        return state;
    }
}
