import { BEGIN_LOGIN, BEGIN_LOGOUT, BEGIN_SILENT_RENEW, SESSION_ACTIVE } from "./actionTypes";
import { loadUser } from "redux-oidc";
import { store } from '../../redux/store';
import userManager from "../../services/userManager";
import { setRedirectUri } from "../../routes/redirectStorage";

export function login() {
    return function (dispatch) {
        const action = { type: BEGIN_LOGIN };
        dispatch(action);
        const mylocation = window.location.pathname + window.location.search;
        setRedirectUri(mylocation);
        userManager.signinRedirect();
        return action;
    };
}

export function silentRenew() {
    return function (dispatch) {
        const action = { type: BEGIN_SILENT_RENEW };
        dispatch(action);
        userManager.signinSilent().then((user) => {
            loadUser(store, userManager);
        },
        () => {
            console.log("Silent renew failed.")
            dispatch(login());
        });
    };
}

export function logout() {
    return function (dispatch) {
        const action = { type: BEGIN_LOGOUT };
        dispatch(action);
        userManager.signoutRedirect();
    };
}

export function logoutSso(issuerUrl, redirectUrl) {
    return function (dispatch) {
        window.location.href = issuerUrl + 
            'protocol/openid-connect/logout?redirect_uri=' +
            encodeURI(redirectUrl);
    };
}

export function sessionActivated() {
    return function (dispatch) {
        const action = { type: SESSION_ACTIVE, isSessionActive: true };
        dispatch(action);
        console.log("Session activated.");
    };
}

export function sessionDeactivated() {
    return function (dispatch) {
        const action = { type: SESSION_ACTIVE, isSessionActive: false };
        dispatch(action);
        console.log("Session deactivated.");
    };
}

