import roles from '../../utils/roles.json';

export default {
    oidc: null,
    apiCallsInProgress: 0,
    tenant: {
        id: null,
    },
    availableTenants:[],
    workCards: [],
    blobProgress:{
                size: 0,
                progress:0
    },
    workCard : {
        workCard: {
            id: "",
            number: 0,
            title: "",
            description: "",
            workSiteNumber: 0,
            projectId: "",
            startDate: "2019-03-04T00:00:00",
            endDate: "2019-07-28T00:00:00",
            employeeName: "",
            sendAsEmail: false,
            signature: "",
            state :  "Unchanged",
            hasReportingLevels: false
        },
        additional :{},
        normal: {},
        infoRows: [],
        approver: null,
        state :  "Unchanged",
        customer : {
            name: "",
            phone: "",
            email: "",
            address : "",
            reference : ""
        },
        contact :{
            name: "",
            phone: "",
            email: "",
        },
    },
    workCardDocument: {
        templateLoaded: false,
        blob: null,
        rendered: false,
        sent: false,
    },
    translationLoaded : null,
    notification : null,
    profile: {
        roles: ['invoicer'],
        expired: false
    },
    resourceAccess: {
        custom: {}
    },
    sessionMetadata: {
        logoutInProgress: false,
    },
    isSessionActive: false,
    sugarSession: {
        loading: true,
        loggedInInstances: [],
    },
    persons : [],
    productsRegister : [],
    productRegister : {
        productRegister : {
            itemCode : "",
            name : "",
            description : "",
            unit : "",
            costPrice : 0,
            basicSalePrice : 0,
            productGroup : "",
            quantity: 0
        },
        productsBuilderDetails : []
    },
    productGroups : [],
    productGroup : {
        itemCode : "",
        name : ""
    },
    productUnits : [],
    productUnit : {
        itemCode : "",
        name : ""
    },
    navigation: {
        module: {
            name: "",
            url: ""
        },
        showModuleTitle: true,
        sugarHistory: []
    },
    unifiedSearch:{
        searchItems: [],
        input:"",
        settings:[],
        settingsOpen: false
    },
    ui : {
        width: 0,
        height: 0,
        bodyHeight: 0,
        topNavBarHeight: 0
    },
    contacts : [],
    contact : {
        id : "00000000-0000-0000-0000-000000000000",
        name : "",
        email : "",
        phone : "",
        customerNumber : 0,
        title : ""
    },
    customers : [],
    customer : {
        id : 0,
        customerNumber : 0,
        name : "",
        vatCode : "",
        paymentInfoModel : {
            eInvoiceAddress : "",
            eInvoiceOperator : "",
            paymentType : "",
            accountGroup : ""
        },
        invoiceAddress : {
            address : "",
            addressLine2 : "",
            postalCode : "",
            city : "",
            country : "",
        },
        contactId : "00000000-0000-0000-0000-000000000000"
    },
    catalog : {
        id: "",
        items: []
    },
    workSite: {
        name : "",
        number : 0,
        key : "",
        address : {
            address : "",
            addressLine2 : "",
            postalCode : "",
            city : "",
            country : "",
        },
        contacts : [],
        description: "",
        descriptionOnInvoice: "",
        customerNumber: 0,
        worksiteNumber: 0,
        startTime : "2019-03-04T00:00:00",
        deliveryTime : "2019-03-04T00:00:00"

    },
    workSites: [],
    workOrder: {
        workOrderNumber: 0,
        workSiteId : "00000000-0000-0000-0000-000000000000",
        internalDescription : "",
        invoiceDescription : "",
        agreedInterval : {
            startTime : "2019-03-04T00:00:00",
            endTime : "2019-03-04T00:00:00"
        },
        products: [],
    },
    workOrders: [],
    address : {
        address : "",
        addressLine2 : "",
        postalCode : "",
        city : "",
        country : "",
    },
    realTimeMessage :{
        collectionId: "",
        documentId: "",
    },
    realTimeSendMessage : {},
    isConnectionActive: false,
    isConnectionSignalActive: false,
    workOrderDiaryEntry : {
        id : "00000000-0000-0000-0000-000000000000",
        documentId : "00000000-0000-0000-0000-000000000000",
        workOrderId : "",
        blobType :  "",
        filename : "",
        fileExtension : "",
        mimeType : "",
        description : "",
        createdBy : "00000000-0000-0000-0000-000000000000",
        timeCreated : "2019-03-04T00:00:00",
        ModifiedBy: "00000000-0000-0000-0000-000000000000",
        TimeModified: "2019-03-04T00:00:00",
    },
    workOrderDiaryEntries: [],
    workQueue: {
        title: "",
        workQueueSettingsId : "",
        ownerId: "",
        members: [],
        workOrders: []
    },
    workQueues: {},
    workQueueSettings: {
        name: "",
        ownerId: "",
        members: [],
        workQueues: []
    },
    workQueuesSettings:[],
    usersGeneralInfo: [],
    users:[],
    user: {
        username: "",
        enabled: false,
        email: "",
        emailVerified: false,
        firstName: "",
        lastName: "",
        resourceAccess: []
    },
    role: {
        id: "",
        permissions : {}
    },
    roles: roles
    
};