import React, { Component, Fragment } from 'react';
import "../../../index.css";
import { withTranslation } from 'react-i18next';
import Search from "../search";
import { Breadcrumb } from '../Breadcrumb';
import { RowList } from "../rowList";
import { BottomButtons } from "../BottomButons";
import FilterHelper from "../../../helpers/filterHelper";

export class SearchList  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            rows : [],
            filterOptions : [],
            isRowStyle: false,
            filter : "", 
            sort : "", 
            cleanData : false,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.rows !== this.props.rows) {
            this.setState({
                rows : this.props.rows,
            });
        }
        if (prevProps.sort !== this.props.sort) {
            this.setState({
                sort : this.props.sort,
            });
        }
        if (prevProps.filterOptions !== this.props.filterOptions) {
            this.setState({
                filterOptions : this.props.filterOptions,
            });
            
        }
        if (prevProps.isRowStyle !== this.props.isRowStyle) {
            this.setState({
                isRowStyle : this.props.isRowStyle,
            });
            
        }
        
        if (prevProps.isLoading !== this.props.isLoading) {
            this.setState({
                isLoading : this.props.isLoading,
            });
           
        }

        if (prevProps.cleanData !== this.props.cleanData) {
            this.setState({
                cleanData : this.props.cleanData,
            });
        }

        if (prevProps.loadData !== this.props.loadData) {
            if (!this.props.loadData) {
                this.setState({
                    cleanData: true,
                })
            }
        } 
    }
   
    moreRecords = (page) => {
        const { filter, sort } = this.state;
        this.loadRecords(filter, sort, page);
    }

    sortHeadersRecords = (filterOptions, sort) => {
        const { filter, isRowStyle } = this.state;

        FilterHelper.savefilterOptions( this.props.idSave, filterOptions, isRowStyle);
        
        this.loadRecords(filter, sort, 1);
    }

    loadRecords = async (filter, sort, page) => {
        if (!this.state.isLoading) {
            if (page === 1) {
                this.setState({
                    filter : filter,
                    //filterOptions : filterOptions,
                    sort : sort,
                    cleanData : true
                })
            }

            this.setState({
                sort : sort,
            })

            var flagContinue = false;
            if (!this.props.isArray) {
                flagContinue = await this.props.loadRecords(filter, sort, page) 
            }
            else {
                flagContinue = true;
                this.props.loadRecords(filter, sort)
            }
            
            if (flagContinue) {
                this.setState({
                    page : page + 1,
                    cleanData : false
                })
            }
        }
    }

    handleJsonFile = async () => {
        if (!this.state.isLoading) {
            if (!this.props.isArray) {
                await this.props.handleJsonFile() 
            }
            else {
                this.props.handleJsonFile()
            }
        }
    }

    handleDeleteRow = async (data) => {
        if (!this.props.isArray) {
            return await this.props.handleDeleteRow(data);
        }
    }

   

    render() {
        const { t } = this.props;
        return (
            <Fragment>
                <Breadcrumb 
                    idBreadCrumb={this.props.idBreadCrumb}
                    title={this.props.title} 
                    backMessage={t("Back")}
                    backButton={this.props.backButton} 
                    handleClose={this.props.handleClose}
                    showBasket={this.props.showBasket}
                />
                <Search 
                    filterOptions={this.state.filterOptions}
                    isRowStyle={this.state.isRowStyle}
                    backButton={this.props.backButton}
                    addButton={this.props.addButton}
                    loadRecords={this.loadRecords}
                    handleJsonFile={this.handleJsonFile}
                    pathBackFromAddEdit={this.props.pathBackFromAddEdit}
                    pathAddEdit={this.props.pathAddEdit}
                    isArray={this.props.isArray}
                    history={this.props.history}
                    handleAdd={this.props.handleAdd} 
                    idSave={this.props.idSave}
                    productGroupFilter={this.props.productGroupFilter}
                    hideFilters={this.props.hideFilters}
                    changeFilter={this.props.changeFilter}
                    hideSearch={this.props.hideSearch}
                    addBasket={this.props.addBasket}
                    overrideAddButtonLabel={this.props.overrideAddButtonLabel}
                    counterBasket={this.props.counterBasket}
                    isAllOptions={this.props.isAllOptions}
                    stayInMainWindowIsAllOptions={this.props.stayInMainWindowIsAllOptions}
                    typeModify={this.props.typeModify}
                    handleClose={this.props.handleClose}
                    cleanReloadRecords={this.props.cleanReloadRecords}
                    filterGroups={this.props.filterGroups}
                    isCreateNewInModal={this.props.isCreateNewInModal}
                    handleAddInModal={this.props.handleAddInModal}
                    insideTab={this.props.insideTab}
                    user={this.props.user}
                    expandedSearch={this.props.expandedSearch}
               
                />
                <RowList
                    {...this.props}
                    rows={this.state.rows}
                    moreRecords={this.moreRecords}
                    filterOptions={this.state.filterOptions}
                    isRowStyle={this.state.isRowStyle}
                    showFilterFields={this.props.showFilterFields}
                    fieldsAsDivs={this.props.fieldsAsDivs}
                    isLoading={this.state.isLoading}
                    cleanData={this.state.cleanData}
                    questionDialog={t("AreyouSureofDelete")}
                    handleDeleteRow={this.handleDeleteRow}
                    deleteButton={true} 
                    editButton={true}
                    onlySelect={this.props.onlySelect}
                    isAllOptions={this.props.isAllOptions}
                    typeModify={this.props.typeModify}

                    builderList={[...this.props.builderList]}
                    handleInsert={this.props.handleInsert}
                    isArray={this.props.isArray}
                    refreshDetails={this.props.refreshDetails}
                    history={this.props.history}
                    pathAddEdit={this.props.pathAddEdit}
                    multiSelect={this.props.multiSelect}
                    handleOneSelect={this.props.handleOneSelect}
                    handleSearchOneSelect={this.props.handleSearchOneSelect}
                    t={this.props.t}
                    i18n={this.props.i18n}
                    sortHeadersRecords={this.sortHeadersRecords}
                    backButton={this.props.backButton}
                    isBasket={this.props.isBasket}
                    handleClose={this.props.handleClose}
                    dataRowCaptureIdList={this.props.dataRowCaptureIdList}
                    counterBasket={this.props.counterBasket}
                    setCounterBasket={this.props.setCounterBasket}
                    stayInMainWindowIsAllOptions={this.props.stayInMainWindowIsAllOptions}
                    cleanReloadRecords={this.props.cleanReloadRecords}
                    notExpanded={this.props.notExpanded}
                    captureDataTemplate={this.props.captureDataTemplate}
                    isNotLegacy={this.props.isNotLegacy}
                    handleEdit={this.props.handleEdit}
                    passwordEvent={this.props.passwordEvent}
                
                    
                    
                />
                <BottomButtons
                    isToUp={this.props.isToUp}
                    isModalDialog={this.props.isModalDialog}
                />
            </Fragment>
        )
    };
}

export default withTranslation('common')(SearchList);
