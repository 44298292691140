import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function realTimeConnectionReducer(state = initialState.isConnectionActive , action) {
    
    if (action.type === types.RALTIME_CONNECTION_ACTIVE)
    {
        return action.isConnectionActive;
    }
    else {
        return state;
    }
}
