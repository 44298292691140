export const invoicer = "invoicer";

export const roles = {
    admin: "admin",
    superuser: "superuser",
    contributor: "contributor",
    reader: "reader"
};

export const roleLevel = (role) => {
    if (role === roles.admin) {
        return 4;
    } else if (role === roles.superuser) {
        return 3;
    } else if (role === roles.contributor) {
        return 2;
    } else if (role === roles.reader) {
        return 1;
    } else {
        return 0;
    }
};

export default roles;