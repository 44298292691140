import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function uiReducer(state = initialState.ui, action) {

    if (action.type === types.UI_RESIZE_WINDOW)
    {
        return action.ui;
    }
    else {
        return state;
    }
}
