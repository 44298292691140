
import React, { Component } from "react";
import { withStyles } from '@material-ui/styles';
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import { compose } from 'redux';
const styles = theme => ({

    tooltip:{
        textAlign: 'center',
        fontSize: 18 ,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: 'pre-line',
        lineHeight: '1.2',
    }, 

});
export class InfoTooltip extends Component{

    render(){
    const {classes, info, bg,  placement, icon} = this.props;
    return(
        <SpeedDialAction
            open={true}
            key="key"
            icon={icon}
            className={bg}
            tooltipTitle={info}
            tooltipPlacement={placement}
            TooltipClasses={classes}
        />
    )
    }

}
export default compose(withStyles(styles))(InfoTooltip); 