import React, { Component } from 'react';
import {
    CardContent,
    Grid,
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    Slide,
    Dialog,
} from '@material-ui/core';
import * as styles from '../styles.js';
import "../../../index.css";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { withTranslation } from 'react-i18next';
import { Breadcrumb } from '../Breadcrumb';
import CircleButton from "../CircleButton";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UpButton from "../upButton";
import Collapse from '@material-ui/core/Collapse';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogModal from "../dialogModal";
import EditGroup from "./editGroup";
import FilterHelper from "../../../helpers/filterHelper";


const marginButton = {
    marginTop : "7px"
}

const WAIT_INTERVAL = 500;

export class FilterGroups extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filterOptions : [],
            isRowStyle : this.props.isRowStyle,
            transitionState: [],
            fromIndex: -1,
            toIndex : -1,
            startTransition: false,
            rowPositon: -1,
            startCompleteTransition: false,
            open: false,
            openTree: false,
            nodes : [],
            checked: [],
            expanded: [],
            openGroup: false,
            idxGroup: -1,
        }
    }

    componentDidMount() {
        var dict = [];
        this.props.filterOptions.map((row, i) => (this.recursiveNodes(row, 0, undefined, false, dict)));
        this.reorderColums(dict);

        this.generateGroups();
    }

    reorderColums = (dict) => {
        for(var key in dict) {
            this.state.nodes.push(dict[key]);
        }
    }
   
    generateGroups = () => {
        var sortField = [];
        sortField.push("group");
        var options = this.props.filterOptions.sort(this.fieldSorter(sortField));

        var x = 0;
        var groups = [];
        while (x < options.length) {
            var group = options[x].group;
            if (group > 0) {
                var parent = {
                    group : group,
                    groupName : options[x].groupName,
                    sm: options[x].sm,
                    columns : [] 
                } 
                
                while (x < options.length && group === options[x].group ) {
                    var child = {
                        id : options[x].id,
                        isVisible : options[x].isVisible,
                        isSort : options[x].isSort,
                        direction: options[x].direction,
                        textAlign: options[x].textAlign,
                        canSort: options[x].canSort,
                        nameField: options[x].nameField,
                        label: options[x].label
                    } 
                    
                    parent.columns.push(child);
                    this.findElementInOptions(options[x].nameField, options[x].id);
                    x++;
                }  
                
                groups.push(parent);
                this.state.transitionState.push(true);
            } 
            else  {
                x++;
            }
        }
    
        this.setState({
            filterOptions : groups
        })

        this.putFalseToShowCheckBox();

    }

    findElementInOptions = (nameField, id) => {
        for (var x = 0; x < this.state.nodes.length; x++) {
            if (this.state.nodes[x].value === nameField || this.state.nodes[x].value === id || 
                this.state.nodes[x].children !== undefined) 
            {
                if (this.state.nodes[x].children === undefined) 
                {
                  this.updateParent(x);
                  break;
                }
                else {
                    for (var y = 0; y < this.state.nodes[x].children.length; y++) {
                        if (this.state.nodes[x].children[y].value === nameField || this.state.nodes[x].children[y].value === id) {
                           this.updateChild(x, y);
                           break;
                        }
                    }
                   
                }                 
            }
        }
    }
    
    putFalseToShowCheckBox = () => {

        for (var x = 0; x < this.state.nodes.length; x++) {
            if (this.state.nodes[x].children !== undefined) 
            {
                var flag = false;
                for (var y = 0; y < this.state.nodes[x].children.length; y++) {
                    if (this.state.nodes[x].children[y].showCheckbox === true) {
                        flag = false;
                        break;
                    }
                    else {
                        flag = true;
                    }
                }
                if (flag) {
                    this.updateParent(x);
                }
            }                 
        }
    }

    updateParent = (x) => {
        var row = {...this.state.nodes[x], showCheckbox : false}
        this.setState(prevState => ({
            ...prevState,
            nodes : Object.values({
                ...prevState.nodes,
                [x] : row
            })
        }));
    }

    updateChild = (x, y) => {
        var row = {...this.state.nodes[x] }
        row.children[y].showCheckbox = false;

        this.setState(prevState => ({
            ...prevState,
            nodes : Object.values({
                 ...prevState.nodes,
                [x] : row
            })
        }));
    }

    recursiveNodes = (row, pointer, children, isChildren, dict) => {
        var idx = row.nameField.indexOf(".", pointer);
        var labelText = this.props.t(row.label);
        if (idx === -1) {
            var nameCell = row.nameField;
            if (nameCell === "None") {
                nameCell = row.id;
            }
           
            if (isChildren) {
                children.children.push({ 
                    value: nameCell, 
                    label: labelText,
                    showCheckbox: true,
                });
            }
            else {
                dict[row.nameField] = {
                    value: nameCell, 
                    label: labelText, 
                    showCheckbox: true,
                };
            }
        }
        else {
        
            var parent = row.nameField.substring(0, idx);
            var name = row.nameField.substring(pointer, idx);
            
            children = {};
            if (dict[name] === undefined) {
                if (dict[parent] !== undefined)
                {
                    children = { 
                        value: parent, 
                        label: labelText,
                        showCheckbox: true,
                        children: []
                    }
                    dict[parent] = children
                }
                else {
                    children = { 
                        value: parent, 
                        label: labelText,
                        showCheckbox: true,
                        children: []
                    }
                    dict[name] = children
                
                }
            }
            else {
                children = dict[name];
            }
            this.recursiveNodes(row, idx + 1, children, true, dict);
            
            
            
        }
    }

    moveRow = (fromIndex, isUp) => {

        var flagContinue = true;
        if (fromIndex === 0 && isUp)
        {
            flagContinue = false;
        }
        else if (!isUp  && fromIndex === this.state.filterOptions.length - 1) 
        {
            flagContinue = false;
        }

        if (flagContinue) {

            this.setState({
                rowPositon : fromIndex,
            });

            var toIndex = 0;
            if (isUp) {
                toIndex = fromIndex - 1;
            }
            else {
                toIndex = fromIndex + 1;
            }

            
            this.setState(prevState => ({
                ...prevState,
                transitionState : Object.values({
                    ...prevState.transitionState,
                    [fromIndex]: false
                }),
                fromIndex: fromIndex,
                toIndex : toIndex,
                startTransition: true,
                startCompleteTransition: true
            }));
        }
    }

    handleFilterOptions = () => {
        var options = [];
        
        for (var x = 0; x < this.state.filterOptions.length; x++) {
            var group = this.state.filterOptions[x].group;
            var groupName = this.state.filterOptions[x].groupName;
            var sm = this.state.filterOptions[x].sm;
            
            for (var r = 0; r < this.state.filterOptions[x].columns.length; r++ ) {
                var child = {
                    id : this.state.filterOptions[x].columns[r].id,
                    isVisible : this.state.filterOptions[x].columns[r].isVisible,
                    isSort : this.state.filterOptions[x].columns[r].isSort,
                    sm : sm,
                    direction: this.state.filterOptions[x].columns[r].direction,
                    textAlign: this.state.filterOptions[x].columns[r].textAlign,
                    canSort: this.state.filterOptions[x].columns[r].canSort,
                    nameField: this.state.filterOptions[x].columns[r].nameField,
                    group : group,
                    groupName: groupName
                   
                } 
                options.push(child);
            }  
        }
        
        var notGroup = [];
        for (var y = 0; y < this.props.filterOptions.length; y++) {
            var element = this.findElement(options, this.props.filterOptions[y].id);
            if (element === undefined) {
                var temp = {...this.props.filterOptions[y]}; 
                temp.isVisible = false;
                temp.group = -1;
                notGroup.push(temp)
            } 
        }

        for (var z = 0; z < notGroup.length; z++) {
            options.splice(0, 0, notGroup[z]);
        }

        var sortField = [];
        sortField.push("group");
        options = options.sort(this.fieldSorter(sortField));

        var idx = -1;
        for (var c = 0; c < options.length; c++) {
            if (options[c].group < 1) {
                options[c].isSort = false;
            }
            else {
                if (options[c].isSort === true) {
                    idx = -1;
                    break;
                }
                else if (idx === -1) {
                    idx = c;
                }
            }
        }
        if (idx > -1) {
            options[idx].isSort = true;
        }
        
        var sort = FilterHelper.savefilterOptions(this.props.idSave, options, this.state.isRowStyle, true);

        if (this.props.isTesting) {
            this.setState({
                sortTest : sort,
                optionsTest: options
            });
        }
        
        this.props.handleFilterOptions([...options], sort);

    }

    findElement = (options, id) => {
        
        return options.find(e => e.id === id)
    }

    fieldSorter = (fields) => (a, b) => fields.map(o => {
        let dir = 1;
        if (o[0] === '-') { dir = -1; o=o.substring(1); }
        return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
    }).reduce((p, n) => p ? p : n, 0);

    clicking = () => {
       this.resetColor();
    }

    resetColor = () => {
        this.setState({
            rowPositon : -1
        });
    }

    handleAdd = () => {
        this.state.transitionState.push(true);

        var options = [...this.state.filterOptions];
        
        var group = {
            group : options.length + 1,
            groupName : "group",
            sm : 1,
            columns : [] 
        } 

        options.push(group);

        this.setState({
            filterOptions : options
        })
    }

    handleDelete = (e, idx) => {
          
        this.setState({
            idx: idx,
        });

        if (!this.props.isTesting) {
            this.setState({
                open : true
            });
        }
    }

    handleDeleteRow = async (isOk) => {
        if (isOk) {
       
            var temp = [...this.state.filterOptions];
            var nodes = [...this.state.nodes];
            this.putElementTrueShowCheckBox(nodes, this.state.idx);
            this.putTrueGroupShowCheckBox(nodes);
            temp.splice(this.state.idx,1)
            this.reorderGroups(temp);

            this.setState({
                nodes : nodes,
                filterOptions : temp,
                open: false 
            });
        }
        else {
            this.setState({
                open: false,
            });
        }
    }

    putElementTrueShowCheckBox = (nodes, idx) => {
        for (var z = 0; z < this.state.filterOptions[idx].columns.length; z++) 
        {
            var nameField = this.state.filterOptions[idx].columns[z].nameField;
            var id = this.state.filterOptions[idx].columns[z].id;

            for (var x = 0; x < nodes.length; x++) {
                if (nodes[x].value === nameField || nodes[x].value === id || 
                    nodes[x].children !== undefined) 
                {
                    if (nodes[x].children === undefined) 
                    {
                      nodes[x].showCheckbox = true;
                      break;
                    }
                    else {
                        for (var y = 0; y < nodes[x].children.length; y++) {
                            if (nodes[x].children[y].value === nameField || nodes[x].children[y].value === id) {
                                nodes[x].children[y].showCheckbox = true;
                                break;
                            }
                        }
                       
                    }                 
                }
            }
                
        }
    }

    putTrueGroupShowCheckBox = (nodes) => {
        for (var x = 0; x < nodes.length; x++) {
            if (nodes[x].children !== undefined) 
            {
                var flag = false;
                for (var y = 0; y < nodes[x].children.length; y++) {
                    if (nodes[x].children[y].showCheckbox === true) {
                        flag = true;
                        break;
                    }
                }
                if (!flag) {
                    nodes[x].showCheckbox = false;
                }
                else {
                    nodes[x].showCheckbox = true;
                }
            }                 
        }
    }

    HandleEdit = (idxGroup) => {
        this.setState({
            openGroup: true,
            idxGroup: idxGroup
        });
    }

    handleCloseGroup = (group) => {
        this.setState(prevState => ({
            ...prevState,
            filterOptions : Object.values({
                ...prevState.filterOptions,
                [this.state.idxGroup] : group
            }),
            openGroup: false,
        })); 
    }

    reorderGroups = (rows) => {
        for (var x = 0; x < rows.length; x++) 
        {
            if (rows[x].group > 0) {
                rows[x].group = x + 1;
            }
        }

        var sortField = [];
        sortField.push("group");
        rows = rows.sort(this.fieldSorter(sortField));

    }

    updateNodes = (nodes) => {
        this.setState({
            nodes : nodes
        })
    }

    handleEnd = (node, done) => {
        node.addEventListener("transitionend", this.doNext, false);
    };
    
    doNext = () => {
        if (this.state.startTransition) {
            this.setState({
                rowPositon : -1
            });

            var rows = [...this.state.filterOptions];

            var row = rows[this.state.fromIndex];
    
            rows.splice(this.state.fromIndex, 1);
            rows.splice(this.state.toIndex, 0, row);
            
            this.reorderGroups(rows);
                    
            this.setState(prevState => ({
                ...prevState,
                transitionState : Object.values({
                    ...prevState.transitionState,
                    [this.state.toIndex]: false
                })
            }), function() {
                this.setState({
                    filterOptions : rows,
                    startTransition : false
                }, function() {
                    this.setState(prevState => ({
                        ...prevState,
                        transitionState : Object.values({
                            ...prevState.transitionState,
                            [this.state.fromIndex]: true
                        })
                    }), function() {
                        setTimeout(this.triggerChange, WAIT_INTERVAL);
                    });
                }); 
            });
        }
    };

    triggerChange = () => {
        this.setState({
            rowPositon : this.state.toIndex
        }, function() {
            this.setState(prevState => ({
                ...prevState,
                transitionState : Object.values({
                    ...prevState.transitionState,
                    [this.state.toIndex]: true
                })
            }), function() {
                this.setState({
                    startCompleteTransition : false
                });
            })
        });
    }
    
    rowDetails = (row, idx, len) => {
        const { t } = this.props;
        return (
            <Collapse in={this.state.transitionState[idx]} key={"collapse" + idx}
                addEndListener={this.handleEnd}
                onExited={this.handleExited}>
            <React.Fragment key={"fragment" + idx}>
                <ExpansionPanel expanded={false} 
                    style={idx === this.state.rowPositon ?
                        { backgroundColor: "antiquewhite", marginBottom : "1px"  } :
                        { backgroundColor: this.props.backgroundColor, marginBottom : "1px"  }
                    }
                >
                    <ExpansionPanelSummary >
                        <Grid container style={styles.ElementWithoutPadding} onClick={this.clicking}>
                            <Grid item sm xs={8}>
                                <Typography style={{paddingTop: "17px"}}>
                                    {row.groupName}
                                </Typography>
                            </Grid>
                            <Grid item sm={9} xs={12}>
                                <Grid container direction="row" justify="flex-end">
                                    <div>
                                        <CircleButton
                                            style={marginButton}
                                            id={"deleteId" + idx}
                                            disabled={idx === 0 || this.state.startCompleteTransition}  
                                            toolTipMessage={t("")}
                                            clickAction={(e) => this.handleDelete(e, idx)}
                                            iconButton={<DeleteIcon style={styles.leftIcon} />}
                                            color="primary" 
                                        />   
                                        <CircleButton
                                            style={marginButton}
                                            id={"editId" + idx}
                                            disabled={idx === 0 || this.state.startCompleteTransition}  
                                            toolTipMessage={t("")}
                                            clickAction={() => this.HandleEdit(idx)}
                                            iconButton={<EditIcon style={styles.leftIcon} />}
                                            color="primary" 
                                        />   
                                        <CircleButton
                                            style={marginButton}
                                            id={"updId" + idx}
                                            disabled={idx === 0 || this.state.startCompleteTransition}  
                                            toolTipMessage={t("MoveUp")}
                                            clickAction={() => this.moveRow(idx, true)}
                                            iconButton={<ArrowUpwardIcon style={styles.leftIcon} />}
                                            color="primary" 
                                        />   
                                        <CircleButton
                                            style={marginButton}
                                            id={"downId" + idx}
                                            disabled={(len - 1) === idx || this.state.startCompleteTransition}  
                                            toolTipMessage={t("MoveDown")}
                                            clickAction={() => this.moveRow(idx, false)}
                                            iconButton={<ArrowDownwardIcon style={styles.leftIcon} />}
                                            color="primary" 
                                        />  
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ExpansionPanelSummary>
                </ExpansionPanel>
            </React.Fragment>
            </Collapse>
        );
    }

    headers = () => {
        const { t } = this.props;
        return (
            <ExpansionPanel key={"headers"} expanded={false}
            style={{ backgroundColor: this.props.backgroundColor, marginBottom : "1px"  }}
            >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon style={{ color: "transparent" }}/>}>
                    <Grid container style={styles.ElementWithoutPadding}>
                        <Grid item sm xs={8}>
                            <Typography style={{paddingTop: "17px"}}>
                                {t("Groups")}
                            </Typography>
                        </Grid>
                    </Grid>
                </ExpansionPanelSummary>
            </ExpansionPanel>
        )
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    render() {
        const { t } = this.props;
        return (
            <Dialog fullScreen open={this.props.open} TransitionComponent={this.Transition}>
                <div style={{paddingLeft: "32px", paddingRight: "32px"}}>
                    <Breadcrumb
                        title={t("ListSettings")} 
                        backButton={true}
                        handleClose={this.handleFilterOptions} 
                        backMessage={t("Back")}
                    ></Breadcrumb>
                    <Grid container style={styles.ElementWithoutPadding} >
                        <Grid container direction="row" justify="flex-end">
                            <UpButton
                                id="addId"
                                toolTipMessage={""}
                                messageContent={t("Group")}
                                clickAction={this.handleAdd}
                                iconButton={<AddIcon  style={styles.leftIcon}/>}
                                color="primary" 
                                circleButton={false}
                            />
                        </Grid>    
                    </Grid>
                    <CardContent style={styles.ElementWithoutPadding}>
                        {this.headers()}
                        {this.state.filterOptions.map((row, i) => (
                            this.rowDetails(row, i, this.state.filterOptions.length)
                        ))}
                    </CardContent>
                </div>
                { this.state.open &&
                    <DialogModal
                        open={this.state.open}
                        questionDialog={t("AreyouSureofDelete")}
                        handleReply={this.handleDeleteRow}
                    >
                    </DialogModal>
                }
                { this.state.openGroup &&
                    <EditGroup
                        openGroup={this.state.openGroup}
                        handleCloseGroup={this.handleCloseGroup}
                        groupOptions={this.state.filterOptions[this.state.idxGroup]}
                        filterOptions={this.props.filterOptions}
                        nodes={this.state.nodes}
                        updateNodes={this.updateNodes}
                    >
                    </EditGroup>
                }
            </Dialog>
            
        )
    };
}

export default withTranslation('common')(FilterGroups);
