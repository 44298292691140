import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from './authorizationUtils';
import axios from 'axios';

// azure functions can not handle ambigous routes
// we need explicit defined those

export function ReadWorkQueues(filter, sort, page, userId, cancelToken) {
    const options = getRequestOptions(cancelToken);
    
    return axios.get(getApiUrl(serviceKeys.products) + 
        `/api/Aggregate/WorkQueue/GetAll?filter=${filter}&sort=${sort}&page=${page}&ownerId=${userId}`, options)
        .then(handleResponse)
        .catch(handleError);
}

export function ReadWorkQueue(id) {
    const options = getRequestOptions();
    return axios.get(getApiUrl(serviceKeys.products) + `/api/Aggregate/WorkQueue/Get/${id}`, options)
        .then(handleResponse)
        .catch(handleError);
}

export function updateWorkQueue(model) {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.products) + `/api/Aggregate/WorkQueue/POST`, model, options)
        .then(handleResponse)
        .catch(handleError);
}

export function ReadWorkQueueBatch(batch, cancelToken) {
    const options = getRequestOptions(cancelToken);
    return axios.post(getApiUrl(serviceKeys.products) + `/api/Aggregate/WorkQueue/GetBatch`, batch, options)
        .then(handleResponse)
        .catch(handleError);
}



