import { useRef, useEffect, useState } from 'react';
import * as constants from "../components/common/constants";

export function makeCancelable(promise) {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then(val => (isCanceled ? reject(new Error({ isCanceled })) : resolve(val)))
      .catch(error => (isCanceled ? reject(new Error({ isCanceled })) : reject(error)));
  });

  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true;
    },
  };
}

function useCancellablePromise(cancelable = makeCancelable) {
  const emptyPromise = Promise.resolve(true);

  if (cancelable(emptyPromise).cancel === undefined) {
    throw new Error('promise wrapper argument must provide a cancel() function')
  }

  const promises = useRef();
  const [tokens, setTokens] = useState([]);

  useEffect(
    () => {
      promises.current = promises.current || [];
      return function cancel() {
        cleanPromises();
      };
    }, []
  );

  function cancelPromises() {
    cleanTokens();
    promises.current = promises.current || [];
    cleanPromises();
  }

  function cleanPromises() {
    promises.current.forEach(p => { 
      p.cancel();
    });
    promises.current = [];
  }

  function cleanTokens() {
    tokens.map(t =>
      t.cancel(constants.cancelHttpRequest)
    );
    setTokens([]);
  }

  function cancellablePromise(p, cancelToken) {
    setTokens(t => [...t, cancelToken]);
    const cPromise = cancelable(p);
    promises.current.push(cPromise);
    return cPromise.promise;
  }

  return { cancellablePromise, cancelPromises };
}

export default useCancellablePromise;
