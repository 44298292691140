import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sessionActions from '../../redux/actions/sessionActions';
import * as localizationActions from '../../redux/actions/localizationActions';
import { withTranslation } from 'react-i18next';

const tokenExpirationTimeSec = 60;

export class LoginInitiator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loginInitiated: false,
            expired: false,
            expiresIn: 0
        };
    }

    componentDidMount() {
        this.updateExpirationState();

        this.interval = setInterval(() => this.updateExpirationState(), 15000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    hasUserPropsChanged(prevProps) {
        const user = this.props.oidc.user;
        const prevUser = prevProps.oidc.user;
        return (!!user !== !!prevUser) ||
            (!!user && user.expires_at !== prevUser.expires_at);
    }

    updateExpirationState() {
        const expired = !!this.props.oidc.user && this.props.oidc.user.expired;
        const expiresIn = !!this.props.oidc.user ? this.props.oidc.user.expires_in: 0;
        this.setState({
            expired,
            expiresIn
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.hasUserPropsChanged(prevProps)) {
            this.updateExpirationState();
        }

        if (prevState.expiresIn < this.state.expiresIn) {
            this.setState(
                {
                    loginInitiated: false
                }
            );
        }
        if (!this.props.oidc.isLoadingUser && !this.state.loginInitiated) {
            
             if (!this.props.oidc.user) {
                this.loginIfRequired();
            } else if ((this.state.expiresIn < tokenExpirationTimeSec && prevState.expiresIn >= tokenExpirationTimeSec)
                || this.state.expired) {
                this.trySilentRenew();
            }
            else if(this.props.oidc.user !== null && prevProps.oidc.user){
                if(prevProps.oidc.user.profile.locale !== this.props.oidc.user.profile.locale){
                        this.props.actions.selectLanguage(this.props.oidc.user.profile.locale);
                }
            }
            
        }  
    }

    trySilentRenew() {
        if (!this.props.oidc.isLoadingUser && !this.state.loginInitiated) {
            if (!!this.props.oidc.user && this.state.expiresIn < tokenExpirationTimeSec) {
                this.setState(
                    {
                        loginInitiated: true
                    }
                );
                
                this.props.actions.silentRenew();
            }
        }
    }

    loginIfRequired() {
        if (!this.props.oidc.isLoadingUser && !this.state.loginInitiated) {
            if (!this.props.oidc.user || (this.props.oidc.user && this.props.oidc.user.expired)) {
                this.setState(
                    {
                        loginInitiated: true
                    }
                );

                this.props.actions.login();
            }
        }
    }

    render() {
        return <div></div>;
    }
}

function mapStateToProps(state) {
    return {
        oidc: state.oidc,
        locale: state.locale
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            login: bindActionCreators(sessionActions.login, dispatch),
            silentRenew: bindActionCreators(sessionActions.silentRenew, dispatch),
            selectLanguage: bindActionCreators(localizationActions.selectLanguage, dispatch),
        }
    };
}

export default compose(withTranslation('common'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(LoginInitiator);
