import * as types from "./actionTypes";
import * as personsApi from "../../api/personsApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";

export function readPersonsSuccess(persons) {
    return { type: types.READ_PERSON_SUCCESS, persons };
}

export function readPersons(projectId) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return personsApi
        .ReadPersons(projectId)  
        .then(persons => {
            dispatch(readPersonsSuccess(persons.value));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorloadingApprovers"));
            throw new Error(error);
        });
    };
} 

export function updatePersonSuccess(person) {
    return { type: types.UPDATE_PERSON_SUCCESS, person };
}

export function updatePerson(person) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return personsApi
        .updatePerson(person)  
        .then(person => {
            dispatch(updatePersonSuccess(person));
            return person;
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorupdatingApprovers"));
            throw new Error(error);
        });
    };
} 

