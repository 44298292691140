import React, {useEffect} from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
//import Typography from '@material-ui/core/Typography';
//import Box from '@material-ui/core/Box';
//import { useTheme } from '@material-ui/core/styles';
//import SwipeableViews from 'react-swipeable-views';
import Swiper from "../common/swiper";


/*
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
*/


function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}



 export function ScrollableTabs(props) {
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (props.index !== undefined && props.index >= 0) {
      setValue(props.index);
    }
    
  },[props.index]);

  

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (props.handleChange) {
        props.handleChange(newValue);
    }
  };
  
  const handleChangeIndex = index => {
    setValue(index);
  };
  
  return (
    <div>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
          style={props.style ? props.style : {marginTop:'20px'}}
        >
          {props.tabs.map((tab,idx) => {
              return(<Tab label={tab.title} key={idx} {...a11yProps(idx)} />)
          })}
        </Tabs>
        <Swiper 
          onIndexChange={handleChangeIndex} 
          headers={false} 
          selectedIndex={value}
        >
          {props.tabs.map((tab,idx) => {
              return(
                  <div key={"tab" + idx}>{tab.item}</div>
              )
          })}
        </Swiper>
        {/*
          <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
          >
          {props.tabs.map((tab,idx) => {
              return(
                  <TabPanel value={value} index={idx}>
                  <div>{tab.item}</div>
                  </TabPanel>)
          })}
          </SwipeableViews>*/
        }
       
    </div>
  );
}
export default ScrollableTabs;