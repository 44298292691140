import React from 'react';
import './SideNavBar.css';
import NestedList from './NestedList';
import CreateNewModal from './CreateNewModal';
import Logo from '../../components/Navbar/Logo';
import MinimizeSideButton from '../../components/Buttons/MinimizeSideButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faSicons from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import *  as navigationActions from '../../redux/actions/navigationActions';

export const minimizedWidth = '58px';
export const maximizedWidth = '212px';

export class SideNavBar extends React.Component {

    constructor(props) {
        super(props);
        this.handleOpen = this.handleOpen.bind(this);

        this.state = {
            wideNav: true,
            navWidth: maximizedWidth
        };
    }

    handleOpen = () => {
        this.setState({ wideNav: true });
        this.setState({ navWidth: maximizedWidth });
    };

    handleMinimize = () => {
        this.setState({ wideNav: false });
        this.setState({ navWidth: minimizedWidth });
    };

    render() {
        const { t, resourceAccess, minimized, show } = this.props;
        let width = (minimized ? minimizedWidth : maximizedWidth);
        let sideBarClasses = 'side-nav-bar';
        let nestedClasses = 'side-nav-bar-list'
        if (show) {
            sideBarClasses = 'side-nav-bar open';
        } else {
            if (minimized) {
                nestedClasses += ' side-nav-minimized';

            }
            else {

            }
        }

        return (
            <nav className={sideBarClasses} style={{ width: width }}>
                <div>
                    <Logo tenant={this.props.tenant} 
                        history={this.props.history} 
                        actions={this.props.actions}
                        close={this.props.close}
                        minimized={minimized}></Logo></div>
                <CreateNewModal
                    width={this.props.width}
                    tenant={this.props.tenant}
                    minimized={minimized}
                    actions={this.props.actions}
                    resourceAccess={resourceAccess}
                    close={this.props.close}
                    history={this.props.history}>
                </CreateNewModal>
                <div id="main-menu" style={{height:window.innerHeight - (this.props.show ? 0 : 242)}} className={nestedClasses}>
                    <NestedList
                        close={this.props.close}
                        t={t}
                        actions={this.props.actions}
                        minimized={minimized}
                        history={this.props.history}
                        resourceAccess={resourceAccess}>
                    </NestedList>
                </div>
                {!show ? (
                    <div className="side-nav-minimize-btn">
                        {!minimized ? (
                            <MinimizeSideButton 
                            id="minimizeSideNav" onClick={this.props.sideNavMinimizeHandler}>
                                <FontAwesomeIcon icon={faSicons["faAngleDoubleLeft"]} />

                            </MinimizeSideButton>) : (
                                <MinimizeSideButton id="openSideNav" onClick={this.props.sideNavMinimizeHandler}>
                                    <FontAwesomeIcon icon={faSicons["faAngleDoubleRight"]} />

                                </MinimizeSideButton>)}

                    </div>) : ("")}

            </nav>
        );
    }
};

function mapStateToProps(state) {
    return {
        tenant: state.tenant,
        open: state.open,
        resourceAccess: state.resourceAccess
    };
}
function mapDispatchToProps(dispatch) {
    return {
        actions: {
            setModule: bindActionCreators(navigationActions.setModule, dispatch)
        }
    };
}
export default compose(withTranslation('common'), connect(
    mapStateToProps,
    mapDispatchToProps
))(withRouter(SideNavBar));
