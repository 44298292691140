import React, { Component, Fragment } from 'react';
import {
    Dialog,
    Slide,
} from '@material-ui/core';
import "../../../index.css";
import { withTranslation } from 'react-i18next';
import { ProductsRegisterList } from "./productsRegisterList";
import * as constants from "../constants";
import FilterHelper from "../../../helpers/filterHelper"; 
import NumberHelper from "../../../helpers/numberHelper";
import CheckTwoToneIcon from '@material-ui/icons/CheckTwoTone';
import { ProductWorkOrderRow } from "./rows/productWorkOrderRow";
import { ProductRegister } from "../../productsRegister/productRegister";

export class ProductsWorkOrderList  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows : this.props.rows,
            open : false,
            openProduct : false,
            scrollLeft : 0,
            scrollTop : 0,
            filterOptions : [],
            isRowStyle: false,
            counterBasket: 0,
            show: true,
            productEditable : {},
            idxProduct: -1,
            
            //rowsBackup : this.props.rows,
        }
    }

    componentDidMount() {
        this.setFilter();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.rows !== this.props.rows) {
            this.setState({
                rows: this.props.rows,
                //rowsBackup : this.props.rows,
            }, function() {
                this.loadRecords();
            });
        }
        if (prevProps.counterBasket !== this.props.counterBasket) {
            this.setState({
                counterBasket: this.props.counterBasket,
            });
        }
    }

    setFilter = () => {
        this.setState({
            idSave : this.props.idSave,
        });

        var options = [
            FilterHelper.createFilterOption("itemCode", true, true, 1, constants.textAlign.left),
            FilterHelper.createFilterOption("description", true, false, 3, constants.textAlign.left),
            FilterHelper.createFilterOption("unitName", true, false, 2, constants.textAlign.left,false),
            FilterHelper.createFilterOption("name", false, false, 1, constants.textAlign.left),
            FilterHelper.createFilterOption("costPrice", false, false, 1, constants.textAlign.right),
            FilterHelper.createFilterOption("basicSalePrice", false, false, 1, constants.textAlign.right),
            FilterHelper.createFilterOption("plannedQuantity", true, false, 2, constants.textAlign.right),
            FilterHelper.createFilterOption("actualQuantity", true, false, 3, constants.textAlign.right),
            FilterHelper.createFilterOption("installed", true, false, 1, constants.textAlign.left)
        ];

        var id = this.props.idSave !== undefined ? this.props.idSave : constants.workOrderProductsFilter;
        
        var filterOptions = FilterHelper.setFilter( id, options, false);
        
        this.setState({
            filterOptions : filterOptions.filter,
            isRowStyle : filterOptions.isRowStyle
        })
    }

    captureDataTemplate = (model) => {
        return (
            <ProductWorkOrderRow key={"captureBuilder" + model.idx} t={this.props.t} i18n={this.props.i18n} 
                idx={model.idx} row={model.row} isBuilder={true} style={{paddingRight: "100px"}} 
                handleRowChange={model.handleRowChange}
                handleInsert={model.handleInsert} 
                handleDelete={model.handleDelete} 
                handleEdit={model.handleEdit}
                isListModal={true}
                quantityInit={1}
            />
        )
    }

    showFilterFields = (row, rowFilter) => {
        const {i18n} = this.props;
        return (
            <Fragment>
                {rowFilter.id === "plannedQuantity" &&
                    NumberHelper.numberFormat(row.plannedQuantity, i18n.language)
                }
                {rowFilter.id === "actualQuantity" &&
                    NumberHelper.numberFormat(row.actualQuantity, i18n.language)
                }
                {rowFilter.id === "installed" &&
                    <Fragment>
                        {row.installed &&
                          <CheckTwoToneIcon/>
                        }
                    </Fragment>
                }
                {rowFilter.id === "name" &&
                    row.name
                }
                {rowFilter.id === "itemCode" &&
                    row.itemCode
                }
                {rowFilter.id === "unitName" &&
                    row.unitName
                }
                {rowFilter.id === "description" &&
                    row.description
                }
                {rowFilter.id === "unit" &&
                    row.unit
                }
                {rowFilter.id === "costPrice" &&
                    NumberHelper.moneyFormat(row.costPrice, i18n.language)
                }
                {rowFilter.id === "basicSalePrice" &&
                    NumberHelper.moneyFormat(row.basicSalePrice, i18n.language)
                }
            </Fragment>
        )
    }

    handleAdd = () => {
        this.props.cancelPromises();
        
        this.setState({
            scrollLeft : window.pageXOffset,
            scrollTop : window.pageYOffset,
            open: true,
        });
    }

    handleClose = () => {
        this.props.cancelPromises();
        
        this.setState({
            open: false,
        }, function() {
            window.scrollTo(this.state.scrollLeft, this.state.scrollTop);
        });
    }

    handleEditProduct = (e, idx) => {
        this.props.cancelPromises();
        
        this.setState({
            openProduct: true,
            idxProduct: idx,
            productEditable: this.state.rows[idx]
        });
    }

    saveEdit = (data, idx) => {
        var temp = [...this.state.rows];
        temp[idx] = data;
        this.refreshData(temp);
        this.props.refreshDetails(temp);
        this.handleCloseProduct();
    }

    handleCloseProduct = () => {
        this.props.cancelPromises();
        
        this.setState({
            openProduct: false,
            idxProduct: -1,
            productEditable: {}
        });
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
   
    addProduct = (data) => {
        var tempRow = {...data};
        tempRow.activeRowEntry = true;

        this.handleClose();
        var temp = [...this.state.rows, tempRow]
        this.refreshData(temp);
        this.props.refreshDetails(temp);
        this.handleAdd();
    }

    refreshDetails = (data, deleteRows, idInserted) => {
        this.refreshData(data);
        this.props.refreshDetails(data, deleteRows, idInserted);
    } 

    refreshData = (data) => {
        this.setState(prevState => ({
            ...prevState,
            rows: data
        }));
        this.setCounterBasket(data.length);
    }

    fieldSorter = (fields) => (a, b) => fields.map(o => {
        let dir = 1;
        if (o[0] === '-') { dir = -1; o=o.substring(1); }
        return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
    }).reduce((p, n) => p ? p : n, 0);

    sortAscedent = (data) => {
        var temp = [];
        for (var x = data.length - 1; 0 <= x; x--) {
          temp.push(data[x]);
        }
        return temp;
    }

    loadRecords = () => {

        
        var data = FilterHelper.getfilterOptions(constants.workOrderProductsFilter, this.state.isRowStyle);
        this.setState({
            filterOptions : data.filter,
            isRowStyle: data.isRowStyle
        })

        var rows = [...this.state.rows];
        
        var sortData = data.sort.split(" "); 
        var sortField = [];
        sortField.push(sortData[0]);
        rows = rows.sort(this.fieldSorter(sortField));
        
        if (sortData[1] === "DESC") {
            rows = this.sortAscedent(rows);
        }

        this.setState({
            rows : rows
        });
        
        
    }

    setCounterBasket = (counter) => {
        this.setState({
            counterBasket : counter
        })
    }

    showBasket = (show) => {
        this.setState({
            show : show
        })
    }
    
    dialogQuery = () => {
        return (
            <Dialog fullScreen open={this.state.open} onClose={this.handleClose} TransitionComponent={this.Transition}>
                <div style={{paddingLeft: "32px", paddingRight: "32px"}}>
                    <ProductsRegisterList 
                        {...this.props}
                        addButton={true}
                        backButton={false}
                        onlySelect={true}
                        handleClose={this.handleClose}
                        builderList={this.state.rows}
                        isToUp={true}
                        isModalDialog={true}
                        refreshDetails={this.refreshDetails} 
                        multiSelect={true}
                        searchWithFilter={false}
                        hideSearch={true}
                        isBasket={this.state.show}
                        showBasket={this.showBasket}
                        dataRowCaptureIdList={constants.ProductsRegisterWorkOrderList}
                        counterBasket={this.state.counterBasket}
                        setCounterBasket={this.setCounterBasket}
                        showtitle={true}
                        isNotLegacy={true}
                        isCreateNewInModal={true}
                        addProduct={this.addProduct}
                    />
                </div>
            </Dialog>
        )
    }

    dialogProduct = () => {
        return (
            <Dialog fullScreen open={this.state.openProduct} onClose={this.handleClose} TransitionComponent={this.Transition}>
                <div style={{paddingLeft: "32px", paddingRight: "32px"}}>
                    <ProductRegister 
                        t={this.props.t} 
                        i18n={this.props.i18n} 
                        actions={this.props.actions} 
                        product={this.props.product}
                        cancelPromises={this.props.cancelPromises} 
                        cancellablePromise={this.props.cancellablePromise}
                        productsRegister ={this.props.productsRegister}
                        productGroups={this.props.productGroups}
                        productGroup={this.props.productGroup}
                        productUnits={this.props.productUnits}
                        productUnit={this.props.productUnit}
                        isCreateNewInModal={true}
                        handleClose={this.handleCloseProduct}
                        captureDataTemplate={this.captureDataTemplate}
                        match={{
                            params : {
                                productId : constants.EditProductFromWorkOrder
                            }}
                        }
                        addType={constants.ProductWorkOrderList}
                        saveEdit={this.saveEdit}
                        productEditable={this.state.productEditable}
                        idxProduct={this.state.idxProduct}
                    />
                </div>
            </Dialog>
        )
    }


    render() {
        return (
            <Fragment>
                <ProductsRegisterList  
                    {...this.props} 
                    title={""} 
                    addButton={false} 
                    editButton={false} 
                    onlySelect={false} 
                    backButton={false} 
                    isArray={true} 
                    loadRecords={this.loadRecords} 
                    builderList={this.state.rows} 
                    handleAdd={this.handleAdd}  
                    refreshDetails={this.refreshDetails}  
                    idSave={constants.workOrderProductsFilter} 
                    hideFilters={true}
                    isBasket={false}
                    addBasket={true}
                    overrideAddButtonLabel="AddProducts"
                    dataRowCaptureIdList={constants.ProductWorkOrderList}
                    filterOptions={this.state.filterOptions}
                    isRowStyle={this.state.isRowStyle}
                    showFilterFields={this.showFilterFields}
                    counterBasket={this.state.counterBasket}
                    captureDataTemplate={this.captureDataTemplate}
                    isNotLegacy={true}
                    handleEdit={this.handleEditProduct}
                /> 
                { this.state.open &&
                    this.dialogQuery() 
                }
                { this.state.openProduct &&
                    this.dialogProduct() 
                } 
            </Fragment>
            
        )
    };
}

export default withTranslation('common')(ProductsWorkOrderList);
