import * as constants from "../components/common/constants";

export const defaultDecimalSeparator = ".";
export const defaultLanguage = "fi";

export const Locales = [];
Locales["us"] = "en-US";
Locales["gb"] = "en-GB";
Locales["en"] = "en-GB";
Locales["fi"] = "fi-FI";
Locales["sv"] = "sv-SE";
Locales["se"] = "sv-SE";

export const Countries = [];
Countries["en-US"] = "us";
Countries["en-GB"] = "gb";
Countries["fi-FI"] = "fi";
Countries["sv-SE"] = "se";

export const LocaleDate = [];
LocaleDate["en-US"] = "MM/dd/yyyy";
LocaleDate["en-GB"] = "dd/MM/yyyy";
LocaleDate["fi-FI"] = "dd.MM.yyyy";
LocaleDate["sv-SE"] = "yyyy-MM-dd";

export const LocaleDecimalSeparator = [];
LocaleDecimalSeparator["en-US"] = ".";
LocaleDecimalSeparator["en-GB"] = ".";
LocaleDecimalSeparator["fi-FI"] = ",";
LocaleDecimalSeparator["sv-SE"] = ",";

export const LocaleCurrencySymbol = [];
LocaleCurrencySymbol["en-US"] = "$";
LocaleCurrencySymbol["en-GB"] = "€";
LocaleCurrencySymbol["fi-FI"] = "€";
LocaleCurrencySymbol["sv-SE"] = "€";

export const PositionLocaleCurrencySymbol = [];
PositionLocaleCurrencySymbol["en-US"] = constants.textAlign.left;
PositionLocaleCurrencySymbol["en-GB"] = constants.textAlign.left;
PositionLocaleCurrencySymbol["fi-FI"] = constants.textAlign.right;
PositionLocaleCurrencySymbol["sv-SE"] = constants.textAlign.left;

export const Flags = [
    { country : "us" },
    { country : "gb" },
    { country : "fi" },
    { country : "se" },
]

export const DateNotations = [];
DateNotations["en-US"] = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: false,
};
DateNotations["en-GB"] = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: false,
};
DateNotations["fi-FI"] = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: false,
};
DateNotations["sv-SE"] = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: false,
};
