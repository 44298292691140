import React, { Component, Fragment } from 'react';
import "../../../index.css";
import { withTranslation } from 'react-i18next';
import * as constants from "../constants";
import FilterHelper from "../../../helpers/filterHelper"; 
import { SearchList } from "../Lists/searchList";
import axios from 'axios';
import Spinner from './../CircularSpinner';
import {
    Container,
    Card,
    CardContent,
    Grid
} from '@material-ui/core';
import * as styles from '../styles';
import { SearchOne } from "../SearchOne";
import StatefulSessionHelper from "../../../helpers/statefulSessionHelper";


var isMounted = false;

export class WorkQueueSettingsList  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows : [],
            filterOptions : [],
            builderList : [],
            isLoading : false,
            isRowStyle : false,
            User : "",
            UserId : "",
            filter: "",
            cleanData: false, 
            expandedSearch: false,
            idSaveLocal: this.props.idSave !== undefined ? this.props.idSave : constants.workQueuesSettingsFilter
        }
    }

    componentWillUnmount() {
        isMounted = false;
        this.props.cancelPromises();
    }

    async componentDidMount() {
        this.setFilter();
        var searchValue = StatefulSessionHelper.getSearchValue(this.state.idSaveLocal + this.props.user.profile.sub);
        if (searchValue === null) {
            searchValue = "";
        }
        
        await this.setState({
            filter : searchValue
        })
    
        var dataValues = StatefulSessionHelper.getValues(this.state.idSaveLocal + this.props.user.profile.sub);
       
        if (dataValues !== null) {
            var expand = false;
            if (dataValues.UserId !== "") {
                expand = true;
            }
            this.setState({
                User : dataValues.User,
                UserId : dataValues.UserId,
                expandedSearch: expand
           }, function() {
              this.reloadData();
           })
        }
        else {
            this.reloadData();
        }
    }

    reloadData = () => {
        var data = FilterHelper.getfilterOptions(this.state.idSaveLocal);
        this.loadRecords(this.state.filter, data.sort,1);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.workQueuesSettings !== this.props.workQueuesSettings) {
            this.setState({
                rows : this.props.workQueuesSettings,
            });
        }

    }
    
    setFilter = () => {
        this.setState({
            idSave : this.props.idSave,
        });

        var options = [
            FilterHelper.createFilterOption("name", true, true, 3),
            FilterHelper.createFilterOption("ownerNameTmp", true, false, 3, constants.textAlign.left, false, "user"),
        ];
 
        var filterOptions = FilterHelper.setFilter( this.state.idSaveLocal, options, false);
        this.setState({
            filterOptions : filterOptions.filter,
            isRowStyle : filterOptions.isRowStyle
        })
    }

    showFilterFields = (row, rowFilter) => {
        return (
            <Fragment>
                {rowFilter.id === "name" &&
                    row.name
                }
                {rowFilter.id === "ownerNameTmp" &&
                    row.ownerNameTmp
                }
            </Fragment>
        )
    }

    loadRecords = async (filter, sort, page) => {
        isMounted = true;

        this.props.cancelPromises();

        var isLoaded = false;
        if (!this.props.isArray) {

            
            if (!this.props.isLoading) {
                this.setState({
                    isLoading : true
                })
            }

            var data = FilterHelper.getfilterOptions(this.state.idSaveLocal);
            this.setState({
                filterOptions : data.filter,
                isRowStyle: data.isRowStyle 
            })

            const cancelToken = axios.CancelToken.source();

            var ownerId = this.props.user.profile.sub
            if (this.state.UserId !== "") {
                ownerId = this.state.UserId;
            }

            await this.props.cancellablePromise(
               this.props.actions.readworkQueuesSettings(filter, data.sort, page, ownerId, cancelToken.token), cancelToken
            )
            .then(() => {
                isLoaded = true;
            })
            .catch((error) => {
                if (error.message === constants.cancelHttpRequest || error.message === "[object Object]") {
                    isMounted = false;
                }
            })
            .finally(result =>{
                if (isMounted) {
                    this.setState({
                        isLoading : false,
                        cleanData: false
                    })
                }
            })
        }
        else {
            this.props.loadRecords(filter, sort);
        }

        return isLoaded;
    }
    
    handleOneSelect = (row, id) => {
        if (id === "user") {
            this.setState({
                User: row.name,
                UserId: row.id,
                cleanData: true
            }, function() {
                this.setSession();
            });
        }
    }

    cleanFilter = (id) => {
        if (id === "user") {
            this.setState({
                User: "",
                UserId: "",
                cleanData: true
            }, function() {
                this.setSession();
            });
        }
    }

    setSession = () => {
        var data = {
            User : this.state.User,
            UserId : this.state.UserId,
        }
       
       StatefulSessionHelper.setValues(this.state.idSaveLocal + this.props.user.profile.sub, data);
       this.reloadData();
    }

    handleClose = () => {
        this.setState({
            cleanData: true
        }, function() {
            this.loadRecords(this.state.filter, "", 1);
        })
    }

   
    render() {
        const { t } = this.props;
        return (
            <Fragment>
                {this.state.isLoadingMassive &&
                    <Spinner open={true}/>
                }
                <SearchList 
                    {...this.props}
                    rows={this.state.rows}
                    showFilterFields={this.showFilterFields}
                    filterOptions={this.state.filterOptions}
                    isRowStyle={this.state.isRowStyle}
                    loadRecords={this.loadRecords}
                    cleanData={this.state.cleanData}
                    
                   
                    backButton={this.props.backButton !== undefined ? this.props.backButton : false}
                    onlySelect={this.props.onlySelect !== undefined ? this.props.onlySelect :  false}
                    addButton={this.props.addButton !== undefined ? this.props.addButton : true}
                    deleteButton={this.props.deleteButton !== undefined ? this.props.deleteButton  : true}
                    editButton={this.props.editButton !== undefined ? this.props.editButton : true}
                    isToUp={this.props.isToUp !== undefined ? this.props.isToUp : true}
                    title={t(this.props.title !== undefined ? this.props.title : "workQueuesSettings")} 
                    pathBackFromAddEdit={this.props.pathBackFromAddEdit !== undefined ? this.props.pathBackFromAddEdit : "/workQueuesSettingsList/"}
                    pathAddEdit={this.props.pathAddEdit !== undefined ? this.props.pathAddEdit : "/workQueuesSettings/"}
                    isLoading={this.state.isLoading}
                    handleOneSelect={this.props.handleOneSelect}
                    handleSearchOneSelect={this.props.handleSearchOneSelect}
                    multiSelect={this.props.multiSelect}
                    productGroupFilter={this.props.hideFilters ? 
                        undefined
                        :
                        <Grid container style={styles.ElementWithoutPadding}>
                            <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                                <Card style={styles.CardWithoutBoxes}>
                                    <CardContent style={{...styles.ElementWithoutPadding, ...{padding: "0px"}}}>
                                            <SearchOne
                                                {...this.props}
                                                id="user"
                                                label={t("User")}
                                                valueOne={this.state.User}
                                                handleOneSelect={(row) => this.handleOneSelect(row, "user")}
                                                cleanFilter={() => this.cleanFilter("user")}
                                                typeQuery={constants.users}
                                                hideSearch={false}
                                                handleClose={this.handleClose}
                                            />
                                    </CardContent>
                                </Card>
                            </Container>
                        </Grid>
                    }
                    
                    isArray={this.props.isArray}
                    handleAdd={this.props.handleAdd}
                    refreshDetails={this.props.refreshDetails}
                    builderList={this.state.builderList}
                    idSave={this.props.idSave !== undefined ? this.props.idSave : constants.workQueuesSettingsFilter  }
                    handleCloseProduct={this.props.handleCloseProduct}
                    history={this.props.history}
                    expandedSearch={this.state.expandedSearch}

                />
            </Fragment>    
        )
    };
}

export default withTranslation('common')(WorkQueueSettingsList);
