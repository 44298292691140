import React from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faicons from '@fortawesome/free-regular-svg-icons';
import * as faSicons from '@fortawesome/free-solid-svg-icons';
import '../../components/Navbar/SideNavBar.css';
import { withStyles } from "@material-ui/core/styles";
import InfoTooltip from "../common/InfoTooltip";
import QuickSearchSettings from './QuickSearchSettings'
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Transition } from 'react-spring/renderprops.cjs.js';
import { Help } from '@material-ui/icons';
import { store } from "../../redux/store";

const styles = theme => ({
    root: {
        colorPrimary: "#D0C8C8",
        colorSecondary: "#D0C8C8",
        fontFamily: "\"Open Sans\", \"Roboto\", \"Oxygen\", sans-serif",
        width: 520,
        borderRadius: '0px 0px 10px 10px',
        boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px',
        alignItems: 'flex-start',
        background: '#fff',
        marginRight: '-60px',
        display: 'inline-grid',
        border: '1px solid black',
        borderTop: 'none',
        '@media (max-width:1200px)': {
            width: '450px',
            minWidth: '400px',
        },
        '@media (max-width:768px)': {
            display: 'table-cell',
            width: '340px',
            minWidth: '340px',
        },
        '@media (max-width:600px)': {
            display: 'table-cell',
            width: '300px',
            minWidth: '300px',
        },
        '@media (max-width:450px)': {
            display: 'table-cell',
            width: '300px',
            minWidth: '300px',
            maxHeight: '680px',
        }, 

    },
    moreResults: {
        fontFamily: "\"Open Sans\", \"Roboto\", \"Oxygen\", sans-serif",
        background: 'transparent',
        border: 'none',
        padding: '16px',
        float: 'right',
        cursor: 'pointer'
    },
    searchFooter: {
        display: 'flow-root',
        borderTop: 'solid grey 1px',
        width: '100%',
    },
    row: { gridRow: 1 },
    list: {
        'transition-duration': '0.1s',
        'transition-timing-function': 'ease-in',
        display: 'grid',
        paddingBottom: '0px'
    },
    emptyList: {
        float: 'left',
        width: '100%',
        textAlign: 'center',
        paddingBottom: '0px',
    },
    searchItem: {
        borderBottom: '1px solid #e9e9e9',
        paddingLeft: 40,
        color: 'black !important',
    },
    searchModule: {
        borderBottom: '1px solid #e9e9e9',
        paddingLeft: 4,
        color: 'black !important',
        textDecoration: "underline",
    },
    moreResultsIcon: {
        marginLeft: '5px',
        colorPrimary: true,
    },
    center: {
        width: '100%',
        textAlign: 'center',
        margin: '-5px 0px 5px 0px',
    },
    historyIcon: {
        marginRight: '10px',
    },
    historyItem: {
        borderTop: "1px solid #e9e9e9"
    },
    headText: {
        textAlign: 'center',
        display: 'block',
        fontSize: '1.17em',
        marginBlockStart: 10,
        marginBlockEnd: 10,
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        fontWeight: 'bold',
    },
    info: {
        background: 'transparent',
        boxShadow: 'none', 
        margin:1
    }

});


export class SearchContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            sideDrawerOpen: false,
            isHistoryLoading: false,
            isSearchLoading:false,
            result: [],
        };

      this.handleClickOutside = this.handleClickOutside.bind(this);
    }
      
    componentDidMount(){
        document.addEventListener('click', this.handleClickOutside);
    if(this.props.width < 1200){
        this.props.actions.showModuleTitle(false)
      }
      else{
      this.props.actions.showModuleTitle(true)
      }
        
    this.pageHistory();
    }
    componentWillUnmount(){
    
    document.removeEventListener('click', this.handleClickOutside);
}
    handleRoute = (path) => {
        //matti 12.2.2021
        //this.props.history.push(path)
        //this.props.close();


        window.location.href = path;
    }

    pageHistory = async () => {
        this.setState({
          isHistoryLoading: true,
        });
        this.props.actions.getSugarPageHistory()
          .then((res) => {
            this.setState({
              isHistoryLoading: false,
            });
          })
          .catch(() => { })
      }
      handleClickOutside(event) {
          const domNode = ReactDOM.findDOMNode(this);

          if (!event.target.id) {
              this.props.close();
          }
          else if (event.target.id !== 'qsInput' && !domNode.contains(event.target) ) {
            this.props.close();
        }
      }
    filterResults(results) {
        if (results) {
            const { classes, t } = this.props;
            var keys = Object.keys(results)
            var result = [];
            var item;
            // show less objects on narrow screen
            var itemcount = (this.props.width < 768) ?  5 : 0;
            var modulecount = 0;
            for (let key in keys) {

                modulecount++;
                item = results[keys[key]].items.slice(0, 5).map((d,idx) =>
                    <ListItem
                        className={classes.searchItem}
                        button
                        onClick={() => this.handleRoute("/yerp?module="
                            + keys[key]
                            + "&offset=1"
                            + "&action=DetailView&record=" + d.id
                            +"&tenant=" + store.getState().tenant.id
                        )}
                        key={key+idx}>{t(d.name)}
                    </ListItem>);
                itemcount += !!item && item.length ? item.length : 0;

                // remove items after hitting 10 in total
                if (itemcount > 9) {
                    item.splice(9- (itemcount))
                }

                // Push all items of a module after that module
                result.push(
                    <div key={99} className={classes.column}>
                        <ListItem
                            className={classes.searchModule}
                            button
                            onClick={() => this.handleRoute("/yerp?module=" + keys[key])}
                            key={keys[key]+itemcount}>
                            <FontAwesomeIcon
                                className={classes.historyIcon}
                                icon={!results[keys[key]].image.solid ?
                                    (faicons[results[keys[key]].image.icon]) :
                                    (faSicons[results[keys[key]].image.icon])} />
                            {t(keys[key])}
                        </ListItem>
                        {item}
                    </div>
                );
                if (modulecount > 2 || itemcount > 9)
                    break;
            }
            if (result.length < 1  && !this.props.isSearchLoading) {
                return <div className={classes.center}><p>{t('NoResults')}.</p><p>{t("FixSearchOrEnter")}</p></div>
            } else {
                return <Transition

                    items={results}
                    trail={50}
                    from={{ transform: 'translate3d(0,-10px,0)' }}
                    enter={{ transform: 'translate3d(0, 0px,0)' }}
                    update={{ transform: 'translate3d(0,-5px,0)' }}
                    leave={{ transform: 'translate3d(0,-30px,0)' }}>
                    {show => show && (props => <div style={props}
                        className="quickSearchBox" id="quickSearchBox">{
                            result}</div>
                    )}
                </Transition>;
            }
        }
    }

    showPageHistory(items) {
        if (items.length > 0 && typeof items === 'object') {
            const { classes, width } = this.props;
            // show less objects on narrow screen
            var filterfunc = (width < 768 ? ((idx) => idx < 4) : ((idx) => idx < 6) )
            var key = (items).filter((item,idx) => filterfunc(idx)).map(item =>
                <ListItem className={classes.historyItem} button
                    onClick={() => this.handleRoute(
                        "/yerp?module=" + item.module_name
                        + "&offset=1&action=DetailView&record=" + item.item_id +"&tenant=" + store.getState().tenant.id)}
                    key={item.item_id}>
                    <FontAwesomeIcon
                        className={classes.historyIcon}
                        icon={!item.image.solid ? (faicons[item.image.icon]) : (faSicons[item.image.icon])} />
                    {item.item_summary}
                </ListItem>);

            return <Transition
                items={1}
                from={{ transform: 'translate3d(0,-10px,0)' }}
                enter={{ transform: 'translate3d(0,-5px,0)' }}
                leave={{ transform: 'translate3d(0,-10px,0)' }}>
                {show => show && (props => <div style={props}
                    className="RecentItemsBox" id="RecentItemsBox">{
                        key}</div>
                )}
            </Transition>;
        }else{
            return <div/>
        }
    }




    render() {
        const { classes, searchItems, searchInput, sugarHistory, t} = this.props;

        return (
            <div className={classes.root}>
                <p className={classes.headText}>{t("SearchUPlus")}
                <InfoTooltip icon={<Help/>} info={t('SearchHelp')} placement="bottom" bg={classes.info}></InfoTooltip></p>

                <List className={classes.list}>
                    {this.props.isSearchLoading ? (
                        <div className={classes.center}>
                            <FontAwesomeIcon icon={faSicons["faSpinner"]} spin /></div>) : ('')
                    }
                    {searchInput.length > 2 ? (
                        (this.filterResults(searchItems))

                    ) : (<div className={classes.emptyList}>
                        <p className={classes.center}>
                            {t('NoResultsEmpty')}</p>
                    </div>
                        )}
                </List>


                <div>
                    <p className={classes.headText}>{t('RecentlyOpened')}</p>
                    <List className={classes.emptyList}>
                    {this.state.isHistoryLoading ? (
                        <div className={classes.center}>
                            <FontAwesomeIcon icon={faSicons["faSpinner"]} spin /></div>
                        ) : this.showPageHistory(sugarHistory)
                    }
                    </List>
                </div>
                <div className={classes.searchFooter}>
                    <QuickSearchSettings
                        isLoading={this.props.isSettingsLoading} 
                        actions={this.props.actions}
                        options={this.props.unifiedSearchSettings} >
                    </QuickSearchSettings>                    
                    <button id="moreResults"
                        form="navBarUnifiedSearch"
                        type="submit"
                        className={classes.moreResults}
                        >
                        {t('MoreResults')}
                        <FontAwesomeIcon className={classes.moreResultsIcon} icon={faSicons["faArrowRight"]} />
                    </button>
                </div>

            </div>
        );
    }
}





export default compose(withTranslation('common'),
    withStyles(styles),
    )
    (withRouter(SearchContainer));
