import React, { Component } from 'react';
import { connect } from 'react-redux';
import *  as workQueueSettingsActions from '../../redux/actions/workQueueSettingsActions';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import CancelPromisesHOC   from "../../helpers/cancelPromisesHOC";
import { withRouter } from "react-router-dom";
import *  as navigationActions from '../../redux/actions/navigationActions';
import {setCurrentModule} from "../../utils/navigationUtil";
import WorkQueueSettingsList from "../common/Lists/workQueueSettingsList";
import * as UsersActions from "../../redux/actions/usersActions";


export class WorkQueueSettings extends Component {    
    componentDidMount(){
        this.props.actions.setModule(setCurrentModule('/workQueuesSettings'));
    }
    render() {
        return (
            <WorkQueueSettingsList {...this.props} />
        )
    };
}

function mapStateToProps(state) {
    return {
        workQueuesSettings : state.workQueuesSettings,
        moduleInfo: state.navigation.module,
        usersGeneralInfo: state.usersGeneralInfo,
        user: state.oidc.user
   
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            readworkQueuesSettings: bindActionCreators(workQueueSettingsActions.readworkQueuesSettings, dispatch),
            updateworkQueueSettings: bindActionCreators(workQueueSettingsActions.updateworkQueueSettings, dispatch),
            setModule: bindActionCreators(navigationActions.setModule, dispatch),
            ReadUsersGeneralInfo: bindActionCreators(UsersActions.ReadUsersGeneralInfo, dispatch),
            
        }
    };
  }

export default compose(withTranslation('common'),
CancelPromisesHOC,
connect(
    mapStateToProps,
    mapDispatchToProps,
))(withRouter(WorkQueueSettings));