import React, { Component, Fragment } from 'react';
import {
    Dialog,
    Slide,
} from '@material-ui/core';
import "../../../index.css";
import { withTranslation } from 'react-i18next';
import { ProductsRegisterList } from "./productsRegisterList";
import * as constants from "../constants";
import FilterHelper from "../../../helpers/filterHelper"; 
import NumberHelper from "../../../helpers/numberHelper";

export class ProductsBuilderList  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows : this.props.rows,
            open : false,
            scrollLeft : 0,
            scrollTop : 0,
            filterOptions : [],
            isRowStyle: false,
            counterBasket: 0,
            show: true
        
            //rowsBackup : this.props.rows,
        }
    }

    componentDidMount() {
        this.setFilter();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.rows !== this.props.rows) {
            this.setState({
                rows: this.props.rows,
                //rowsBackup : this.props.rows,
            }, function() {
                this.loadRecords();
            });
        }
        if (prevProps.counterBasket !== this.props.counterBasket) {
            this.setState({
                counterBasket: this.props.counterBasket,
            });
        }
    }

    setFilter = () => {
        this.setState({
            idSave : this.props.idSave,
        });

        var options = [
            FilterHelper.createFilterOption("quantity", true, false, 1, constants.textAlign.right),
            FilterHelper.createFilterOption("itemCode", true, true, 1, constants.textAlign.left),
            FilterHelper.createFilterOption("description", true, false, 8, constants.textAlign.left),
            FilterHelper.createFilterOption("unitName", true, false, 1, constants.textAlign.left,false),
            FilterHelper.createFilterOption("name", false, false, 1, constants.textAlign.left),
            FilterHelper.createFilterOption("costPrice", false, false, 1, constants.textAlign.right),
            FilterHelper.createFilterOption("basicSalePrice", false, false, 1, constants.textAlign.right),
            FilterHelper.createFilterOption("productGroup", true, false, 2, constants.textAlign.right),
        ];

        var id = this.props.idSave !== undefined ? this.props.idSave : constants.productBuilderFilter;
        
        var filterOptions = FilterHelper.setFilter( id, options, false);
        
        this.setState({
            filterOptions : filterOptions.filter,
            isRowStyle : filterOptions.isRowStyle
        })
    }

    showFilterFields = (row, rowFilter) => {
        const {i18n} = this.props;
        return (
            <Fragment>
                 {rowFilter.id === "quantity" &&
                    NumberHelper.numberFormat(row.quantity, i18n.language)
                }
                {rowFilter.id === "itemCode" &&
                    row.itemCode
                }
                {rowFilter.id === "unitName" &&
                    row.unitName
                }
                {rowFilter.id === "description" &&
                    row.description
                }
                {rowFilter.id === "unit" &&
                    row.unit
                }
                {rowFilter.id === "costPrice" &&
                    NumberHelper.moneyFormat(row.costPrice, i18n.language)
                }
                {rowFilter.id === "basicSalePrice" &&
                    NumberHelper.moneyFormat(row.basicSalePrice, i18n.language)
                }
                {rowFilter.id === "productGroup" &&
                    row.productGroup
                }
            </Fragment>
        )
    }

    handleAdd = () => {
        this.setState({
            scrollLeft : window.pageXOffset,
            scrollTop : window.pageYOffset,
            open: true,
        });
    }

    handleClose = () => {
        this.props.cancelPromises();
        
        this.setState({
            open: false,
        }, function() {
            window.scrollTo(this.state.scrollLeft, this.state.scrollTop);
        });
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
   
    refreshDetails = (data) => {
        this.setState(prevState => ({
            ...prevState,
            rows: data,
            counterBasket: data.length
        }));
    
        this.props.refreshDetails(data);

    } 

    fieldSorter = (fields) => (a, b) => fields.map(o => {
        let dir = 1;
        if (o[0] === '-') { dir = -1; o=o.substring(1); }
        return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
    }).reduce((p, n) => p ? p : n, 0);

    sortAscedent = (data) => {
        var temp = [];
        for (var x = data.length - 1; 0 <= x; x--) {
          temp.push(data[x]);
        }
        return temp;
    }

    loadRecords = () => {

        var data = FilterHelper.getfilterOptions(constants.productBuilderFilter, this.state.isRowStyle);
        this.setState({
            filterOptions : data.filter,
            isRowStyle: data.isRowStyle
        })

        var rows = [...this.state.rows];
        
        var sortData = data.sort.split(" "); 
        var sortField = [];
        sortField.push(sortData[0]);
        rows = rows.sort(this.fieldSorter(sortField));
        
        if (sortData[1] === "DESC") {
            rows = this.sortAscedent(rows);
        }

        this.setState({
            rows : rows
        });

        /* old way remains to study
      
        var data = FilterHelper.getfilterOptions(constants.productBuilderFilter);
        var filterOptions = data.filter;
        
        var sortOptions = [];
        
        
        for (var x= 0; x < filterOptions.length; x++) {
            if (filterOptions[x].isSort) {
                sortOptions.push(filterOptions[x].id);
            }
        }
        */
        
        /*
        if (filter.trim() !== "") 
        {
            rows = rows.filter(r => r.itemCode.includes(filter) || 
                                    r.name.includes(filter) ||
                                    r.unit.includes(filter) ||
                                    r.description.includes(filter));
        }*/

        //rows = rows.sort(this.fieldSorter(sortOptions));
        
       

       
    }

    setCounterBasket = (counter) => {
        this.setState({
            counterBasket : counter
        })
    }

    showBasket = (show) => {
        this.setState({
            show : show
        })
    }
    
    dialogQuery = () => {
        return (
            <Dialog fullScreen open={this.state.open} onClose={this.handleClose} TransitionComponent={this.Transition}>
                <div style={{paddingLeft: "32px", paddingRight: "32px"}}>
                    <ProductsRegisterList 
                        {...this.props}
                        addButton={false}
                        backButton={false}
                        onlySelect={true}
                        handleClose={this.handleClose}
                        builderList={this.state.rows}
                        isToUp={true}
                        isModalDialog={true}
                        refreshDetails={this.refreshDetails} 
                        multiSelect={true}
                        searchWithFilter={false}
                        hideSearch={true}
                        isBasket={this.state.show}
                        showBasket={this.showBasket}
                        dataRowCaptureIdList={constants.ProductsRegisterList}
                        counterBasket={this.state.counterBasket}
                        setCounterBasket={this.setCounterBasket}
                        showtitle={true}

                    />
                </div>
            </Dialog>
        )
    }

    render() {
        return (
            <Fragment>
                <ProductsRegisterList  
                    {...this.props} 
                    title={""} 
                    addButton={false} 
                    editButton={false} 
                    onlySelect={false} 
                    backButton={false} 
                    isArray={true} 
                    loadRecords={this.loadRecords} 
                    builderList={this.state.rows} 
                    handleAdd={this.handleAdd}  
                    refreshDetails={this.refreshDetails}  
                    idSave={constants.productBuilderFilter} 
                    hideFilters={true}
                    isBasket={false}
                    addBasket={true}
                    overrideAddButtonLabel="AddSubproducts"
                    dataRowCaptureIdList={constants.ProductsBuilderList}
                    filterOptions={this.state.filterOptions}
                    isRowStyle={this.state.isRowStyle}
                    showFilterFields={this.showFilterFields}
                    counterBasket={this.state.counterBasket}
                    
                /> 
                { this.state.open &&
                    this.dialogQuery() 
                } 
            </Fragment>
            
        )
    };
}

export default withTranslation('common')(ProductsBuilderList);
