import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from "@material-ui/core/styles";
import * as icons from "@material-ui/icons";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { withTranslation } from 'react-i18next';
import hasAccess from "../../services/accessControl";
import { compose } from 'redux';
import Tooltip from "@material-ui/core/Tooltip";
import ListItem from "@material-ui/core/ListItem";
import Divider from '@material-ui/core/Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faSicons from '@fortawesome/free-solid-svg-icons';

const styles = theme => ({
    menu:{ 
        color: '#D0C8C8',
        background: '#333333',
        },
        
    nested: {
        paddingLeft: theme.spacing(5),
        fontSize: 14
    },
  
    listIcon: {
        marginLeft: '-2rem',
        fontSize: '16px',
        colorPrimary: true,
    },
});
export class NestedMenuButton extends React.Component {

    state = {
        anchorEl: null
    };
    handleRoute = (item) => {
        this.props.actions.setModule({ url: item.Link, name: item.name}) 
        this.handleClose()
        this.props.history.push(item.Link)
      }

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
        event.currentTarget.getElementsByTagName('svg')[0].style.color = "#f16d30";
    }

    handleClose = () => {
        this.state.anchorEl.getElementsByTagName('svg')[0].style.color = "#D0C8C8";
        this.setState({ anchorEl: null });
    };

    render() {
        const { t, classes, resourceAccess} = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const listItems = this.props.items.filter((sitem) => !!sitem.module && hasAccess(resourceAccess, sitem.module, "reader")).map(
            sitem => <MenuItem button
                className={classes.menu} 
                key={sitem.name}
                id={'side-nav-' + sitem.name}
                onClick={() => this.handleRoute(sitem)}

            >{t(sitem.name)}
           <Divider/> </MenuItem>
        );

        return (
            <div>

                <Tooltip placement='right' title={t(this.props.item.name)}>
                    <ListItem
                        id={'side-nav-' + this.props.item.name + '-min'}
                        button
                        onClick={this.handleMenu}>
                        {(icons[this.props.item.icon] ?      
                        <ListItemIcon className="side-nav-bar-icon"
                            aria-owns={open ? 'menu-appbar' : null}
                            aria-haspopup="true">
                            {React.createElement((icons[this.props.item.icon]),{style:{color:"#D0C8C8"}})}
                        </ListItemIcon> : 
                         <div style={{marginLeft: '3px' ,minWidth: '50px'}} className="side-nav-bar-icon" >
                        <FontAwesomeIcon  className="side-nav-bar-icon"
                        aria-owns={open ? 'menu-appbar' : null}
                        aria-haspopup="true"
                        icon={faSicons[this.props.item.icon]}>
                        </FontAwesomeIcon>
                        </div>
                            )}
                  
                    </ListItem>
                </Tooltip>
                <Menu 
                    style={{marginLeft: '2.6em' }}
                    id="menu-appbar"
                    key={this.props.items.name}
                    anchorEl={anchorEl} 
                    PaperProps={{
                        style: {
                          backgroundColor: '#333333',
                        },
                      }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={open}
                    onClose={this.handleClose}
                >
                    {listItems}


                </Menu>
            </div>
        );
    }

}

export default compose(withTranslation('common'),withStyles(styles))(NestedMenuButton);