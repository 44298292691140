import React from 'react';
import SnackbarContentWrapper from "../components/common/SnackBar";
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators, compose } from 'redux';
import * as notificationActions from "../redux/actions/notificationsActions";

const styles = ({
    success: {
        position: "fixed",
        zIndex: "1400",
        width: "100%",
        bottom: "5px",
        right: "10px"
    },
});



export class Notifications extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            notifications: []
        };
    }
    
    componentDidUpdate = (prevProps) => {
        this.addNotification(this.props.notifications);
    }

    addNotification = (notification) => {
        if (!!notification && this.state.notifications.filter(n => n.message === notification.message).length === 0) {
            this.props.actions.clearNotifications();

            const newArr = this.state.notifications;
            newArr.push(notification);
            this.setState({
                notifications: newArr
            });
        }
    }

    handleCloseSnack = (idx) => {
        const newArr = this.state.notifications;
        newArr.splice(idx, 1);
        this.setState({
            notifications: newArr
        });
    }
    
    render = () => {
        const {t} = this.props;
        return (
            <div  className={clsx(this.props.classes.success)}>
                { (this.state.notifications || []).map((key, idx) => (
                    <div style={{marginBottom : "5px"}} key={"divsnack" + idx} >
                        <SnackbarContentWrapper key={"snack" + idx}
                            onClose={() => this.handleCloseSnack(idx)}
                            open={true}
                            message={
                                this.state.notifications[idx].isTranslated ? this.state.notifications[idx].message :
                                t(this.state.notifications[idx].message)
                            }
                            variant={this.state.notifications[idx].variant}
                        />
                    </div>
                    
                ))}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        notifications: state.notifications
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            clearNotifications:  bindActionCreators(notificationActions.clearNotifications, dispatch),
        }
    };
  }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withTranslation('common'), withStyles(styles))(Notifications));

