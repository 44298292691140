
import {Locales}  from '../../i18n/locales';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import *  as localizationActions from '../../redux/actions/localizationActions';
import { withRouter } from 'react-router-dom';

export class AutoLanguageSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            targetLanguage: localStorage.getItem("i18nextLng"),
        };
    }

    componentDidMount() {
        this.props.actions.loadTranslations();
        this.props.actions.selectDefaultLanguage(this.state.targetLanguage);
        this.componentDidUpdate(this.props, this.state);
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.user !== null) {
            const targetLanguage = Locales[this.props.user.profile.locale];
            if (!!this.props.user.profile.locale && this.state.targetLanguage !== targetLanguage) {
                this.setState({
                    targetLanguage
                });
            }
        }

        if (this.state.targetLanguage !== prevState.targetLanguage) {
            if (!this.state.targetLanguage) {
                this.props.actions.selectDefaultLanguage();
            } else {
                this.props.actions.selectDefaultLanguage(this.state.targetLanguage);
            }
        }
    }
    render() {
        return (
            <div />
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.oidc.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
          selectDefaultLanguage: bindActionCreators(localizationActions.selectDefaultLanguage, dispatch),
          selectLanguage: bindActionCreators(localizationActions.selectLanguage, dispatch),
          loadTranslations: bindActionCreators(localizationActions.loadTranslations, dispatch),
        }
    };
}

export default compose(withTranslation('common'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(withRouter(AutoLanguageSelector));