import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { CallbackComponent } from "redux-oidc";
import userManager from "../../services/userManager";
import { getRedirectUri, clearRedirectUri } from "../../routes/redirectStorage";
import { store } from '../../redux/store';
import { loadUser } from "redux-oidc";
import * as notifications from "../../redux/actions/notificationsActions";
import * as sessionActions from "../../redux/actions/sessionActions";

export class CallbackPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectUri: null
        };
    }

    componentDidMount() {
        this.setState({
            redirectUri: getRedirectUri()
        });
    }

    render() {
        return (
            <CallbackComponent
                userManager={userManager}
                successCallback={(user) => {
                    loadUser(store, userManager);
                    clearRedirectUri();
                    this.props.actions.sessionActivated();
                    this.props.history.replace(this.state.redirectUri);
                }}
                errorCallback={error => {
                    if (!!this.props.oidc && !!this.props.oidc.user && !this.props.oidc.user.expired) {
                        this.props.history.replace(this.state.redirectUri);
                    }
                    else {
                        this.props.actions.notificationError(error);
                        this.props.history.replace("/error");
                    }
                }}
            >
                <div />
            </CallbackComponent>
        );
    }
}

function mapStateToProps(state) {
    return {
        oidc: state.oidc
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            notificationError: bindActionCreators(notifications.notificationError, dispatch),
            sessionActivated: bindActionCreators(sessionActions.sessionActivated, dispatch),
        }
    };
}

export const Callback = connect(mapStateToProps, mapDispatchToProps)(CallbackPage);