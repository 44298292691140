import React , { Fragment, Component } from 'react';
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Button,
    Grid,
    Typography,
    CircularProgress,
    Container,
    Card,
    CardContent
} from '@material-ui/core';
import * as styles from './styles.js';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckTwoToneIcon from '@material-ui/icons/CheckTwoTone';
import Spinner from './CircularSpinner';
import DialogModal from "../common/dialogModal";
import { withTranslation } from 'react-i18next';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import UpButton from "./upButton";
import AddIcon from '@material-ui/icons/Add';
import {Animate} from 'react-rebound';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as constants from "./constants";
import { ProductRegisterRow } from './Lists/rows/productRegisterRow.js';
import { WorkSiteContactRow } from "./Lists/rows/workSiteContactRow";
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import DialogAllOptions from "./dialogAllOptions";

const rowsByPage = 20;

export class RowList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded : false,
            next : false,
            page : 1,
            rowList : [],
            open: false,
            isDeleting: false,
            builderList : [],
            deletedList : [],
            //rowsBackup : [],
            order : -1,
            directions : [],
            skipDirections : 0,
            counter: this.props.counterBasket,
            toggled: false,
            startExpand : 1.4,
            endExpand : 1,
            startTransition: false,
            isRowStyle : false,
            openModify : false,
            id : undefined
        }
    }
    
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions();
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        if (window.innerWidth <= constants.minWidthScreen) {
            this.setState({
                isRowStyle : true
            })
        
        }
        else {
            this.setState({
                isRowStyle : false
            })
        }
    } 

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.cleanData !== this.props.cleanData) {
            if (this.props.cleanData)
            {
                this.setState({
                    rowList : [],
                    page : 1
                });
            }
        }

        if (prevState.idExpand !== this.state.idExpand) {
            if (this.state.idExpand !== -1)
            {
                this.setState({
                    expanded : false
                });
            }
        }

        if(prevProps.builderList !== this.props.builderList) {
            this.setState({
                builderList : this.props.builderList,
            });
        }
        
        if(prevProps.counter !== this.props.counter) {
            this.setState({
                counter : this.props.counter,
            });
        }
        
        if(prevProps.rows !== this.props.rows) {
            if (this.props.rows.length !== 0) {
                if (this.props.onlySelect) {
                    var copy = [];
                    for (var x = 0; x < this.props.rows.length; x++)
                    {
                        if (this.checkRow(this.props.rows[x].id)) 
                        {
                            copy.push(this.props.rows[x]);
                        }
                    }
                    this.setState({
                        rowList : this.state.rowList.concat(copy),
                    });
                }
                else {
                    if (!this.props.isArray) {
                        this.setState({
                            rowList : this.state.rowList.concat(this.props.rows),
                        });
                    }
                    else {
                        this.setState({
                            rowList : this.props.rows,
                        });
                    }
                }
                this.setState({
                    page : this.state.page + 1,
                    next : true
                });

            }
            else {
                this.setState({
                    next : false
                });
            }
        }

        if (prevProps.filterOptions !== this.props.filterOptions) {
            this.fillDirections();
        }
    }

    fillDirections = () => {
        var directions = [];
        var onlyone = false;
        var skip = 0;
        for (var x = 0; x < this.props.filterOptions.length; x++) {
            if (this.props.filterOptions[x].group < 1) {
                skip++;
            }
            if (this.props.filterOptions[x].isSort && !onlyone) {
                var direction = this.props.filterOptions[x].direction;
                if (direction === undefined || direction.toUpperCase() === "NONE") {
                    direction = "DESC";
                }
                directions.push(direction);
                onlyone = true;
            }
            else {
                directions.push("NONE");
            }
        }

        this.setState({
            directions : directions
        })

        this.setState({
            skipDirections : skip
        })

    }

    handleChange = (idx) => (event, isExpanded) => {
        this.setState({
            expanded : isExpanded ? idx : false
        });
    };

    handleCloseModify = (data) => {
        this.handleBackModify();
        this.props.handleClose(data);
    }

    handleBackModify = () => {
        this.setState({
            openModify : false
        }, function() {
            if (this.props.stayInMainWindowIsAllOptions) {
                this.props.cleanReloadRecords();
            }
        });
    }

    handleEdit = (e, idx) => {
        if (this.props.isAllOptions) {
            this.setState({
                openModify : true,
                id : this.state.rowList[idx].id
            })
        }
        else {
            if (this.props.handleEdit) {
               this.props.handleEdit(e, idx);     
            }
            else  {
                var path = this.props.pathAddEdit +  this.state.rowList[idx].id;
                this.pushLocation(path);
            }
        }
    }

    pushLocation = (path) => {
        if (this.props.isTesting) {
            this.setState({
                testingpath: path
            });
        }
        this.props.history.push(path)
    }

    handleDelete = (e, idx) => {
        this.setState({
            idx: idx,
        });

        if (!this.props.isTesting) {
            this.setState({
                open : true
            });
        }
    }

    handleDeleteRow = async (isOk) => {
        if (isOk) {
            if (this.props.isArray) {
                this.setState({
                    open : false,
                    expanded : false
                });

                this.deleteRow();
            }
            else {
                if (!this.state.isDeleting) {
                    if (!this.props.isTesting) {
                        this.setState({
                            isDeleting: true,
                        });
                    }
            
                    var record = {...this.state.rowList[this.state.idx]};
                    const isDeleted = await this.props.handleDeleteRow(record);
                    if (isDeleted) {
                        this.deleteRow();
                    }
                    else {
                        this.setState({
                            open: false,
                            isDeleting: false
                        });
                    }
                }
            }
        }
        else {
            this.setState({
                open: false,
            });
        }
        
    }

    deleteRow = () => {
        var copy;
        if (!this.props.isArray) {
            copy = [...this.state.rowList];
            copy.splice(this.state.idx,1);
            this.setState({
                rowList: copy,
            });
        }
        else {
            var idDeleted = this.state.builderList[this.state.idx].id; 
            var deletes = [...this.state.deletedList];
            deletes.push(idDeleted);
            this.setState({
                deletedList: deletes
            }, function() {
                this.state.builderList.splice(this.state.idx,1);
                this.props.refreshDetails([...this.state.builderList], [...this.state.deletedList]);
            });

        }

        this.setState({
            open: false,
            idExpand : this.state.idx,
            isDeleting: false
        });

    }

    checkRow = (id) => {
        if (!this.state.builderList.find(p => p.id === id)) {
            return true;    
        }

        return false;
    }

    handleInsert = (e, row, idx) => {
        if (this.props.multiSelect) {
            var copy = [...this.state.rowList];
            copy.splice(idx,1);
    
            this.setState({
                expanded : false,
                counter: this.state.counter + 1,
                toggled: true,
                startTransition: true,
            }, function() {
                if (this.props.setCounterBasket) {
                    this.props.setCounterBasket(this.state.counter);
                }
            });
          
            this.setState({
                rowList : copy,
            });
            
            var tempRow = {...row};
            tempRow.activeRowEntry = true;
           
            this.state.builderList.push({...tempRow});

            this.props.refreshDetails([...this.state.builderList], undefined, row.id);
        }
        else 
        {
            this.props.handleOneSelect(row);
            this.props.handleSearchOneSelect();
                
        }
    }

    onExpandEnd = () => {
        if (this.state.startExpand === 1.4) {
            this.setState({
                startExpand : 1,
                endExpand : 1.4,
            });
        }
        else {
            this.setState({
                startExpand : 1.4,
                endExpand : 1,
                toggled : false,
                startTransition: false
            });
        }
    }

    handleRowChange = (value, id, idx, isBuilder) => {
        if (!isBuilder) {
            const row = {...this.state.rowList[idx], [id]: value };
        
            this.setState(prevState => ({
                ...prevState,
                rowList : Object.values({
                    ...prevState.rowList,
                    [idx]: row
                })
            }));
        }
        else {
           const rowBuilder = {...this.state.builderList[idx], [id]: value, activeRowEntry : true };
           this.setState(prevState => ({
                ...prevState,
                builderList : Object.values({
                    ...prevState.builderList,
                    [idx]: rowBuilder
                })
            }), function() {
                this.props.refreshDetails([...this.state.builderList]);
            });
        }
    
    }
   
    headerButton = (i, text, isSort) => {
        if (isSort === undefined) {
            isSort = this.props.filterOptions[i].isSort;
        }
        else {
            i = i + this.state.skipDirections;
        }
        const { t } = this.props;
        return (
            <Button 
                id={"headerButton" + i}
                onClick={() => this.clickHeader(i)} 
                style={{padding: "0px", justifyContent: "inherit"}}
            >
                {t(text)} 
                { isSort &&
                    <Fragment>
                        {this.state.directions[i] === "DESC" ? (
                            <ArrowDownwardIcon/>
                        ) : (
                            <Fragment>
                                {this.state.directions[i] === "NONE" ? (
                                    <Fragment/>
                                ) : (
                                    <ArrowUpwardIcon/>
                                )
                                }
                            </Fragment>
                        )
                        }
                     </Fragment>    
                }
            </Button>
        )

    }

    clickHeader = (i) => {
        if (!this.props.filterOptions[i].canSort) {
            return false;
        }

        if (this.state.order !== i) {

            this.setState(prevState => ({
                ...prevState,
                directions: {
                    ...prevState.directions,
                    [this.state.order]: "NONE",
                },
            }));
        }
        
        var id = this.props.filterOptions[i].id;
        var direction = "";
        if (this.state.directions[i] === "DESC") {
            direction = "ASC"
        }
        else {
            direction = "DESC"
        }

        this.setState(prevState => ({
            ...prevState,
            directions: {
                ...prevState.directions,
                [i]: direction,
            },
        }));

        var filterOptions = [...this.props.filterOptions];
        for (var x = 0; x < filterOptions.length; x++) {
            filterOptions[x].isSort = false;
            filterOptions[x].direction = "NONE";
        }
        filterOptions[i].isSort = true;
        filterOptions[i].direction = direction;
        
        this.props.sortHeadersRecords(filterOptions, id + " " + direction);
     
       
    }

    
    moreRecords = () => {
        this.props.moreRecords(this.state.page);
    }

    renderDataCaptureLegacy = (row, idx) => {
        return (
            <Fragment>
                { (this.props.dataRowCaptureIdList === constants.ProductsRegisterList && this.props.onlySelect) &&
                    <ProductRegisterRow key={"capture" + idx} t={this.props.t} i18n={this.props.i18n} idx={idx} row={row}
                        style={{paddingRight: "100px"}} handleRowChange={this.handleRowChange} quantityInit={1}
                    />
                }
                { (this.props.dataRowCaptureIdList === constants.ProductsBuilderList) &&
                    <ProductRegisterRow key={"captureBuilder" + idx} t={this.props.t} i18n={this.props.i18n} 
                        idx={idx} row={row} isBuilder={true}
                        style={{paddingRight: "100px"}} handleRowChange={this.handleRowChange} 
                    />
                }
                { (this.props.dataRowCaptureIdList === constants.ContactsWorkSiteList) &&
                    <WorkSiteContactRow {...this.props} key={"captureBuilder" + idx} t={this.props.t} i18n={this.props.i18n} 
                        idx={idx} row={row} isBuilder={true}
                        handleRowChange={this.handleRowChange} 
                    />
                }
            </Fragment>
        )
    }

    renderExpandDataCaptureLegacy = (row, idx) => {
        return (
            <ExpansionPanelDetails style={{ backgroundColor: "white" }}>
                <Grid container style={styles.ElementWithoutPadding}>
                    <Grid container direction="row" 
                        justify={this.props.dataRowCaptureIdList !== constants.ContactsWorkSiteList ? 
                            "flex-end" : "flex-start"}>
                        
                        {this.props.onlySelect ? (
                            <div>
                                {this.props.multiSelect &&
                                        this.renderDataCaptureLegacy(row, idx)
                                }
                                {this.props.isAllOptions && this.operationButtons(idx)}
                                <Button id="selectId" variant="contained" color="primary" className="circleButton" 
                                    style={this.props.multiSelect ?
                                        (this.props.dataRowCaptureIdList === constants.ProductsRegisterList ?
                                        {...styles.circleButton, ...{bottom: "95px", right: "-180px", position : "relative"}} :
                                        styles.circleButton) :
                                        styles.circleButton
                                    } 
                                    onClick={(e) => this.handleInsert(e, row, idx)} >
                                    <CheckTwoToneIcon  style={styles.leftIcon}/>
                                </Button>
                            </div>
                        ) : (
                            <Fragment>
                                {this.props.dataRowCaptureIdList === constants.ProductsBuilderList ? (
                                    <div>
                                        {this.renderDataCaptureLegacy(row, idx)}
                                        {this.operationButtons(idx)}
                                    </div>
                                ) :( 
                                    <Fragment>
                                        {this.renderDataCaptureLegacy(row, idx)}
                                        {this.props.dataRowCaptureIdList !== constants.ContactsWorkSiteList &&
                                        this.operationButtons(idx)
                                        }
                                    </Fragment>
                                )
                                }
                    
                            </Fragment>
                        )}
                    </Grid>
                </Grid>
                {this.props.dataRowCaptureIdList === constants.ContactsWorkSiteList &&
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <Grid container direction="row" justify={"flex-end"}>
                                    {this.operationButtons(idx)}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Container>
                }
            </ExpansionPanelDetails>
        )
    }

    renderDataCapture = (row, idx) => {
        var model = {
            row : row,
            idx : idx,
            handleRowChange : this.handleRowChange,
            handleInsert: this.handleInsert,
            handleDelete: this.handleDelete,
            handleEdit: this.handleEdit
        }
        return (
            <Fragment>
                {this.props.captureDataTemplate(model)}
            </Fragment>
        )
    }

    renderExpandDataCapture = (row, idx) => {
        return (
            <ExpansionPanelDetails style={{ backgroundColor: "white" }}>
                <Grid container style={styles.ElementWithoutPadding}>
                    <Grid container direction="row" 
                        justify={this.props.dataRowCaptureIdList !== constants.ContactsWorkSiteList ? 
                            "flex-end" : "flex-start"}>
                        {this.renderDataCapture(row, idx)}
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
        )
    }

    renderSummary = (row, rowGroup, idx) => {
        const {t} = this.props
       
        return (
            <ExpansionPanelSummary expandIcon={this.props.notExpanded ? null : <ExpandMoreIcon />} >
                <Grid container style={styles.ElementWithoutPadding}>
                    {this.props.filterOptions.map((rowFilter, i) => (
                        <React.Fragment key={"fieldRow" + i} >
                            {rowFilter.isVisible &&
                                <Fragment>
                                    {this.state.isRowStyle ? (
                                        <Grid container style={{...styles.ElementWithoutPadding, 
                                            ...{wordBreak: "break-all",
                                            whiteSpace: "normal"
                                        }}}>
                                            <Typography style={{flexShrink: 1,  fontWeight: "bold"}}>
                                                {
                                                    t(rowFilter.label) + ":"
                                                }
                                            </Typography>
                                            {!!this.props.fieldAsDivs ? 
                                            <div style={{marginLeft: "10px", marginBottom: "10px", flexShrink: 1 }}>
                                                {
                                                    this.props.showFilterFields(row, rowFilter)
                                                }
                                            </div>
                                            :
                                            <Typography style={{marginLeft: "10px", marginBottom: "10px", flexShrink: 1 }}>
                                                {
                                                    this.props.showFilterFields(row, rowFilter)
                                                }
                                            </Typography>
    }
                                        </Grid>  
                                    ):(
                                        <Fragment>
                                            {rowFilter.group === 0 &&
                                                <Grid item sm={rowFilter.sm} style={{paddingRight: "10px", textAlign: rowFilter.textAlign, 
                                                    wordBreak: "break-all",
                                                    whiteSpace: "normal"
                                                }}>
                                                  
                                            {!!this.props.fieldsAsDivs ? 
                                            
                                            <div style={{ flexShrink: 1 }}>
                                            {
                                                this.props.showFilterFields(row, rowFilter)
                                            }
                                        </div> :
                                                    <Typography style={{ flexShrink: 1 }}>
                                                        {
                                                            this.props.showFilterFields(row, rowFilter)
                                                        }
                                                    </Typography>
    }
                                                </Grid>
                                            }
                                            {rowFilter.group > 0 && rowGroup !== rowFilter.group &&
                                                <Fragment>
                                                    <div style={{color:"transparent", width: "0px"}}>
                                                        {rowGroup = rowFilter.group}    
                                                    </div>
                                                    <Grid item sm={rowFilter.sm} style={{paddingRight: "10px", textAlign: rowFilter.textAlign, 
                                                        wordBreak: "break-all",
                                                        whiteSpace: "normal",
                                                        paddingBottom: "20px"
                                                    
                                                    }}>
                                                        <div>
                                                            {this.props.filterOptions.filter(m => m.group === rowGroup).map((rowFilter, i) => (
                                                                <Grid key={"row" + rowFilter.group + " " + i}  item style={{paddingRight: "10px", textAlign: rowFilter.textAlign, 
                                                                    wordBreak: "break-all",
                                                                    whiteSpace: "normal"
                                                                }}>
                                                                  
                                            {!!this.props.fieldsAsDivs ? 
                                            <div style={{ flexShrink: 1 }}>
                                            { this.props.showFilterFields(row, rowFilter) }
                                        </div>
                                                                    : <Typography style={{ flexShrink: 1 }}>
                                                                        { this.props.showFilterFields(row, rowFilter) }
                                                                    </Typography>}
                                                                </Grid>
                                                            ))}
                                                        </div>
                                                    </Grid>
                                                </Fragment>
                                            }
                                        </Fragment>
                                            
                                    )}
                                </Fragment>
                            }
                        </React.Fragment>
                    ))}
                    {this.props.showFilterFields(null, {id : "buttons"}, idx)}
                       
                </Grid>
            </ExpansionPanelSummary>
        )
    }

    renderRow = (row, idx) => {
        let rowGroup = 0;
        return (
            <ExpansionPanel key={"expand" + idx} id={"expand" + idx} 
                style={{ backgroundColor: this.props.backgroundColor, marginBottom : "1px"  }}
                expanded={this.props.notExpanded ? false : this.state.expanded === idx} onChange={this.handleChange(idx)}
            >
               {this.renderSummary(row, rowGroup, idx)}
               {this.props.isNotLegacy ? (
                    this.renderExpandDataCapture(row, idx)
                ) : (
                    this.renderExpandDataCaptureLegacy(row, idx)
                )
                } 
            </ExpansionPanel>
       )
    }

    operationButtons = (idx) => {
        return (
            <Fragment>
                {(this.props.deleteButton || this.props.isAllOptions) &&
                    <Button id="deleteId" variant="contained"  className="circleButton" 
                        style={this.props.isArray ? 
                            (this.props.dataRowCaptureIdList === constants.ProductsBuilderList ?
                            {...styles.circleButton, ...{bottom: "95px", right: "-180px", position : "relative"}} :
                            {...styles.circleButton, ...{marginTop:"70px"}} ) :
                            styles.circleButton
                        } 
                        onClick={(e) => this.handleDelete(e,idx)} >
                        <DeleteIcon style={styles.leftIcon} />
                    </Button>
                }
                 {this.props.passwordEvent &&
                    <Button id="passwordId" variant="contained" color="primary" className="circleButton" style={styles.circleButton} 
                        onClick={(e) => this.props.passwordEvent(e,idx)} >
                        <VpnKeyIcon  style={styles.leftIcon}/>
                    </Button>
                }
                {(this.props.editButton || this.props.isAllOptions) && !this.props.isArray &&
                    <Button id="editId" variant="contained" color="primary" className="circleButton" style={styles.circleButton} 
                        onClick={(e) => this.handleEdit(e,idx)} >
                        <EditIcon  style={styles.leftIcon}/>
                    </Button>
                }
            </Fragment>
        )
    }

    headers = () => {
        let rowGroup = 0;
        let rowPosition = 0;
        return (
            <ExpansionPanel key={"headers"} expanded={false}
            style={{ backgroundColor: this.props.backgroundColor, marginBottom : "1px"  }}
            >
                <ExpansionPanelSummary expandIcon={this.props.notExpanded ? null : <ExpandMoreIcon />}>
                    <Grid container style={styles.ElementWithoutPadding}>
                        {this.props.filterOptions.map((rowFilter, i) => (
                            <React.Fragment key={"fieldRow" + i} >
                                {rowFilter.isVisible &&
                                    <Fragment>
                                        {this.state.isRowStyle ? (
                                           <Grid container style={{...styles.ElementWithoutPadding, 
                                                ...{marginButtom: "10px", wordBreak: "break-all", whiteSpace: "normal"}}} >
                                                { this.headerButton(i,rowFilter.label)}
                                            </Grid>  
                                        ):(
                                           <Fragment>
                                               {rowFilter.group === 0 &&
                                                    <Grid item sm={rowFilter.sm} style={{paddingRight: "10px", textAlign: rowFilter.textAlign,
                                                        wordBreak: "break-all", whiteSpace: "normal"}}>
                                                        { this.headerButton(i,rowFilter.label)}
                                                    </Grid>
                                                }
                                                {rowFilter.group > 0 && rowGroup !== rowFilter.group &&
                                                    <Fragment>
                                                        <div style={{color:"transparent", width:"0px"}}>
                                                            {rowGroup = rowFilter.group}    
                                                        </div>
                                                        <Grid item sm={rowFilter.sm} style={{paddingRight: "10px", textAlign: rowFilter.textAlign, 
                                                            wordBreak: "break-all",
                                                            whiteSpace: "normal",
                                                            paddingBottom: "20px"
                                                        }}>
                                                            <div>
                                                                {this.props.filterOptions.filter(m => m.group === rowGroup).map((rowFilter, i) => (
                                                                    <Grid key={"row" + rowFilter.group + " " + i}  item style={{paddingRight: "10px", textAlign: rowFilter.textAlign, 
                                                                        wordBreak: "break-all",
                                                                        whiteSpace: "normal"
                                                                    }}>
                                                                        { this.headerButton(rowPosition, rowFilter.label, rowFilter.isSort)}
                                                                        <div style={{color:"transparent", height : "0px", width:"0px"}}>
                                                                            {rowPosition = rowPosition + 1}    
                                                                        </div>
                                                                    </Grid>
                                                                ))}
                                                            </div>
                                                        </Grid>
                                                    </Fragment>
                                                }
                                            </Fragment> 
                                              
                                        )}
                                    </Fragment>
                                }
                            </React.Fragment>
                        ))}
                    </Grid>
                </ExpansionPanelSummary>
            </ExpansionPanel>
        )
    }

    render() {
        const {t} = this.props;
        return (
            <Fragment>
                {this.headers()}
                {!this.props.isArray ? (
                    <Fragment>
                        {this.state.rowList.map((row, idx) => (
                            this.renderRow(row, idx)
                        ))}
                    </Fragment>
                ) : (
                    <Fragment>
                        {this.state.builderList.map((row, idx) => (
                            this.renderRow(row, idx)
                        ))}
                    </Fragment>
                )
                }
                {this.state.isDeleting &&
                    <Spinner open={true}/>
                }
                {this.props.isLoading && !this.props.isArray &&
                    <div style={{width : "100%", margin: "0", textAlign: "center"}}>
                        <CircularProgress style={styles.progressCircle} />
                    </div>
                }
                {this.props.isBasket &&
                    <Animate 
                        scaleX={this.state.toggled ? this.state.startExpand : this.state.endExpand} 
                        scaleY={this.state.toggled ? this.state.startExpand : this.state.endExpand}
                        onEnd={this.onExpandEnd}
                    >
                        <div style={this.state.startTransition ? styles.floatButtonCenter : styles.floatButtonTop}>
                            <UpButton
                                id="backId"
                                toolTipMessage={""}
                                messageContent={this.state.startTransition ? "" : t("Back") + " (" + this.state.counter + ")"}
                                iconButton={this.state.startTransition ?
                                    <AddIcon /> :
                                    <ArrowBackIcon />
                                }
                                color="primary" 
                                circleButton={this.state.startTransition ? true : false}
                                clickAction={this.props.handleClose}
                            />
                        </div>
                    </Animate>
                }
                {!this.props.isLoading && this.state.next && !this.props.isArray && this.state.rowList.length >= rowsByPage &&
                    <div style={{width : "100%", margin: "0", textAlign: "center"}}>
                        <Button id="nextId" variant="contained" color="primary" className="circleButton" 
                            style={styles.circleButton} onClick={this.moreRecords} 
                        >
                            <ExpandMoreIcon  />
                        </Button>
                    </div>
                }
                { this.state.open &&
                   <DialogModal
                        open={this.state.open}
                        questionDialog={this.props.questionDialog}
                        //messageDialog={this.state.rowList[this.state.idx].id}
                        handleReply={this.handleDeleteRow}
                   >
                   </DialogModal>
                } 
                { this.state.openModify &&
                    <DialogAllOptions {...this.props}
                        typeModify={this.props.typeModify}
                        isAllOptions={this.props.isAllOptions}
                        selectId={this.state.id}
                        handleCloseModify={this.handleCloseModify} 
                        handleBackModify={this.handleBackModify} 
                        openModify={this.state.openModify}
                        stayInMainWindowIsAllOptions={this.props.stayInMainWindowIsAllOptions}
                   
                    />
                } 
            </Fragment>
        )
    }
}

export default withTranslation('common')(RowList);