import React, { Component } from 'react';
import { CustomerList } from "../common/Lists/customersList";
import { connect } from 'react-redux';
import *  as customersActions from '../../redux/actions/customersActions';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import CancelPromisesHOC   from "../../helpers/cancelPromisesHOC";
import { withRouter } from "react-router-dom";
import {setCurrentModule} from "../../utils/navigationUtil";
import *  as navigationActions from '../../redux/actions/navigationActions';
import *  as catalogsActions from '../../redux/actions/catalogsActions';

export class Customers  extends Component {
    componentDidMount(){
        this.props.actions.setModule(setCurrentModule('/customers'));
    }

    render() {
        return (
            <CustomerList {...this.props} />
        )
    };
}

function mapStateToProps(state) {
    return {
        customers : state.customers,
        moduleInfo: state.navigation.module,
        catalog : state.catalog
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            readCustomers: bindActionCreators(customersActions.readCustomers, dispatch),
            updateCustomer: bindActionCreators(customersActions.updateCustomer, dispatch),  
            setModule: bindActionCreators(navigationActions.setModule, dispatch),
            readCatalog: bindActionCreators(catalogsActions.readCatalog, dispatch),
           
        }
    };
  }

export default compose(withTranslation('common'),
CancelPromisesHOC,
connect(
    mapStateToProps,
    mapDispatchToProps,
))(withRouter(Customers));