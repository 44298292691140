import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { serviceKeys, getApiUrl } from "../api/apiUtils";
import getRequestOptions from "../api/authorizationUtils";
import { compose } from 'redux';
import { store } from "../redux/store";
import *  as realTimeActions from '../redux/actions/realTimeActions';

// documentation https://pypi.org/project/signalrcore/

const signalR = require('@aspnet/signalr');

var connected = false;
var countConnected = 0;
var tryTime = 0;
var connection;
var tryConnected = false;

export class RealTimeMessages extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            messages: [],
            isConnectionActive : false,
            isSessionActive : false,
            tenant: undefined,
        };
    }
  
    componentDidUpdate = (prevProps) => {
        if (prevProps.isConnectionActive !== this.props.isConnectionActive) {
            this.setState({
                isConnectionActive : this.props.isConnectionActive
            }, function() {
                this.tryConnectToServer();
            })
        }
        if (prevProps.isSessionActive !== this.props.isSessionActive) {
            this.setState({
                isSessionActive : this.props.isSessionActive
            }, function() {
                this.tryConnectToServer();
            })
        }
        if (prevProps.tenant !== this.props.tenant) {
            this.setState({
                tenant : this.props.tenant,
                messages : []
            }, function() {
                connected = false;
                this.tryConnectToServer();
            })
        }
        if (prevProps.realTimeSendMessage !== this.props.realTimeSendMessage) {
            var temp = [...this.state.messages];
            temp.push(this.props.realTimeSendMessage);
            this.setState({
                messages : temp
            }, function() {
                this.sendMessage();
            })
        }
    }

    tryConnectToServer = () => {
        if (!tryConnected && 
            ((!connected && this.state.isConnectionActive && this.state.isSessionActive && this.props.accessToken && this.state.tenant) ||
            (!this.props.userExpired && !connected))) 
        {
            tryConnected = true;
            this.Connect();
        }
    }

    Connect = () => {
        tryConnected = false; return;//MATTI
        var options = getRequestOptions();
        
        var token = this.props.accessToken;
        var tenantid = options.headers['up-tenant-id'];
        var group = "demo";
        var userid = "";
       
        const apiBaseUrl = getApiUrl(serviceKeys.realTime);
        const query = "/?up-tenant-id=" + tenantid + "&upgroup=" + group + "&userid=" + userid;
        connection = new signalR.HubConnectionBuilder()
        .withUrl(`${apiBaseUrl}/api/${query}`, {
            accessTokenFactory: () => token
        })
        .build();
        
        connection.start()
        .then(() => {
            store.dispatch(realTimeActions.realTimeConnectionSingalSuccess(true));
            connected = true;
            countConnected = 0;
            tryTime = 0;
            tryConnected = false;
        })
        .catch(() => {
            store.dispatch(realTimeActions.realTimeConnectionSingalSuccess(false));
            connected = false;
            if (countConnected < 3) {
                countConnected++;
                tryTime += 5000;
                setTimeout(() => {
                    this.Connect(token);
                }, tryTime);
            }
            else {
                tryConnected = false;
            }
        
        });
    
        connection.on('newMessage', newMessage);
    
        connection.onclose(() => {
            store.dispatch(realTimeActions.realTimeConnectionSingalSuccess(false));
            connected = false;
            if (countConnected < 3) {
                countConnected++;
                tryTime += 5000;
                setTimeout(() => {
                    this.Connect(token);
                }, tryTime);
            }
            else {
                tryConnected = false;
            }
        });
    }

    render = () => {
        return (
            <div>
            </div>
        );
    }
}

function newMessage(message) {
    store.dispatch(realTimeActions.realTimeMessageSuccess(message));
}

function mapStateToProps(state) {
    return {
        isConnectionActive : state.isConnectionActive,
        isSessionActive : state.isSessionActive,
        tenant: state.tenant,
        realTimeSendMessage: state.realTimeSendMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            
        }
    };
  }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withTranslation('common'))(RealTimeMessages));

