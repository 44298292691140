import React, { Component, Fragment } from 'react';
import {
    CardHeader,
    Grid,
} from '@material-ui/core';
import * as styles from './styles.js';
import "../../index.css";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withTranslation } from 'react-i18next';
import UpButton from "./upButton";

export class Breadcrumb  extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <Fragment>
                {this.props.backButton ? (
                    <Grid container direction="row" justify="space-between" style={styles.ElementWithoutPadding}>
                        <CardHeader
                            title={this.props.title}
                        />
                        {!this.props.isAllOptions &&
                            <UpButton
                                id="backId"
                                toolTipMessage={""}
                                messageContent={this.props.backMessage}
                                clickAction={this.props.handleClose}
                                iconButton={<ArrowBackIcon  style={styles.leftIcon}/>}
                                color="primary" 
                                circleButton={false}
                                showBasket={this.props.showBasket}
                            />
                        }
                    </Grid>
                ): (
                    <Fragment>
                        {this.props.showBasket ?  (
                            <Grid container direction="row" justify="space-between" style={styles.ElementWithoutPadding}>
                                <CardHeader
                                    title={this.props.title}
                                />
                            </Grid>
                        ) : (
                            <Fragment/>
                        )
                        }
                    </Fragment> 
                    
                    
                )}
            </Fragment>
        )
    };
}

export default withTranslation('common')(Breadcrumb);
