import * as types from "./actionTypes";
import * as rolesApi from "../../api/rolesApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";
import * as constants from "../../components/common/constants";

export function readRolesSuccess(roles) {
    return { type: types.LOAD_ROLES_SUCCESS, roles };
}

export function readRoles(filter, sort, page, cancelToken) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return rolesApi
        .ReadRoles(filter, sort, page, cancelToken)  
        .then(roles => {
            dispatch(readRolesSuccess(roles));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (typeof(error.message) !== "undefined" && error.message !== constants.cancelHttpRequest) {
                dispatch(notifications.notificationError("ErrorloadingRoles"));
                throw new Error(error);
            }
        });
    };
} 

export function readRoleSuccess(role) {
    return { type: types.READ_ROLES_SUCCESS, role };
}

export function readRole(id) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return rolesApi
        .ReadRole(id)  
        .then(role => {
            dispatch(readRoleSuccess(role));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorloadingRole"));
            throw new Error(error);
        });
    };
} 

export function updateRoleSuccess(role) {
    return { type: types.UPDATE_ROLES_SUCCESS, role };
}

export function updateRole(role) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return rolesApi
        .updateRole(role)  
        .then(role => {
            dispatch(updateRoleSuccess(role));
            return role;
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (constants.UniqueKeyConstraintViolation === error ||
                constants.UniqueKeyConstraintViolation === error.message) {
                dispatch(notifications.notificationError("IdAlreadyExist"));
            }
            else {
                dispatch(notifications.notificationError("ErrorupdatingRole"));
            }
            throw new Error(error);
        });
    };
} 

