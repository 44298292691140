export const templates = {
    workCard: {
        id: "a6e71f2c-06c1-47ec-924d-604522520f5e",
        name: "workCard"
    },
    shareWorkCardLink: {
        id: "fd16d69f-6cd5-41d3-83d0-a8e637851b56",
        name: "shareWorkCardLink"
    }
};

export const templateTypes = {
    pdf: "Pdf",
    html: "Html"
};

export function loadById(templateId, type) {
    for (const templatePopertyName in templates) {
        let template = templates[templatePopertyName];

        if (template.id === templateId) {
            return load(template.name, type);
        }
    }

    throw new Error("Template not defined with id " + templateId);
}

export function load(templateName, type) {
    let contentTemplate = loadTemplate(
            templateName,
            type
        );

    let headerTemplate = loadTemplate(
            "header",
            type
        );

    let footerTemplate = loadTemplate(
            "footer",
            type
        );

    let templatePromise = Promise.all(
            [contentTemplate, headerTemplate, footerTemplate]
        ).then(templateArr => {
            return {
                contentTemplate: templateArr[0],
                headerTemplate: templateArr[1],
                footerTemplate: templateArr[2],
                reportContentType: type,
            };
        });

    return templatePromise;
}

function loadTemplate(templateName, type) {
    return fetch(`assets/templates/${type}/${templateName}.html`)
        .then((response) => {
            return response.text();
        });
}