import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import *  as tenantActions from '../../redux/actions/tenantActions';
import * as resourceAccessActions from '../../redux/actions/resourceAccessActions';
import { withRouter } from 'react-router-dom';

export class AutoTenantSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestedTenantId: this.useQuery().get("tenant")
        };
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }

    componentDidMount() {
        if (!!this.props.user && !this.props.user.expired) {
            this.props.actions.getAllAvailableTenants();
        }
    }

    componentDidUpdate(prevProps) {
        if (!!this.props.user && (!prevProps.user || this.props.user.sub !== prevProps.user.sub)) {
            this.props.actions.getAllAvailableTenants();
        }

        if (this.props.availableTenants.length > prevProps.availableTenants.length
            && (this.props.tenant.id !== this.state.requestedTenantId
            || !this.props.tenant.id)) {
                this.props.actions.selectTenant(this.state.requestedTenantId, 
                    this.props.availableTenants);
        }

        if (this.props.tenant.id !== prevProps.tenant.id) {
            this.setState({
                requestedTenantId: this.props.tenant.id
            });
        }

        if (this.props.tenant.host !== prevProps.tenant.host) {
            this.props.actions.updateCustomResourceAccess();
            if (!!prevProps.tenant && !!prevProps.tenant.id) {
                this.props.actions.tenantSessionDeactivated(prevProps.tenant.id);
            }

            if (this.props.tenant.id) {
                this.props.actions.tenantSessionActivated(this.props.tenant.id);
            }
        }

    }

    render() {
        return (
            <div />
        );
    }
}

function mapStateToProps(state) {
    return {
        availableTenants: state.availableTenants,
        tenant: state.tenant,
        user: state.oidc.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            readTenant: bindActionCreators(tenantActions.readTenant, dispatch),
            selectTenant: bindActionCreators(tenantActions.selectTenant, dispatch),
            selectDefaultTenant: bindActionCreators(tenantActions.selectDefaultTenant, dispatch),
            updateCustomResourceAccess: bindActionCreators(resourceAccessActions.updateCustomResourceAccess, dispatch),
            getAllAvailableTenants: bindActionCreators(tenantActions.getAllAvailableTenants, dispatch),
            tenantSessionActivated: bindActionCreators(tenantActions.tenantSessionActivated, dispatch),
            tenantSessionDeactivated: bindActionCreators(tenantActions.tenantSessionDeactivated, dispatch),
        }
    };
}

export default compose(withTranslation('common'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(withRouter(AutoTenantSelector));
