import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function workOrderDiaryEntriesReducer(state = initialState.workOrderDiaryEntries , action) {
    if (action.type === types.LOAD_WORKORDER_DIARYENTRY_SUCCESS)
    {
        return action.workOrderDiaryEntries
    }
    else {
        return state;
    }
}
