import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { UsersList } from "../Lists/usersList";

export class SearchOneUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }
    
    render() {
        return (
            <UsersList 
                {...this.props}
                addButton={false}
                backButton={true}
                onlySelect={true}
                handleClose={this.props.handleClose}
                isToUp={true}
                isModalDialog={true}
                handleSearchOneSelect={this.props.handleSearchOneSelect}
                multiSelect={false}
                isReadOnly={this.props.isFilterDisabled}
                propsstayInMainWindowIsAllOptions={this.props.propsstayInMainWindowIsAllOptions}
            />
        )
    };
}

export default withTranslation('common')(SearchOneUser);
