import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function unifiedSearchReducer(state = initialState.unifiedSearch, action) {
   
    switch(action.type){
        case types.UPDATE_UNIFIED_SEARCH_SETTINGS_SUCCESS : {
            const unifiedSearch = {
                ...state,
                settings: action.settings
            }
            return unifiedSearch;
        }        
        case types.READ_UNIFIED_SEARCH_SETTINGS_SUCCESS : {
            const unifiedSearch = {
                ...state,
                settings: action.settings
            }
            return unifiedSearch;
        }
        case types.READ_QUICK_SEARCH_ITEMS_SUCCESS : { 
            const unifiedSearch = {
                ...state,
                searchItems: action.payload.searchItems,
                input: action.payload.input
            }
            return unifiedSearch;
        }        
        case types.SET_SEARCH_SETTINGS_OPEN : {
            const unifiedSearch = {
                ...state,
                settingsOpen: action.open
            }
            return unifiedSearch;
        }    
        default: {
        return state;
        }
    }
}
