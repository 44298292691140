import * as types from "./actionTypes";
import * as sugarApi from "../../api/sugarApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";

export function updateUnifiedSearchSettingsSuccess(settings) {
    return { type: types.UPDATE_UNIFIED_SEARCH_SETTINGS_SUCCESS, settings };
}
export function readUnifiedSearchSettingsSuccess(settings) {
    return { type: types.READ_UNIFIED_SEARCH_SETTINGS_SUCCESS, settings};
}
export function readQuickSearchItemsSuccess(items,input) {
    return { type: types.READ_QUICK_SEARCH_ITEMS_SUCCESS, payload: {searchItems:items, input:input}};
}
export function setSearchSettingsOpen(open) {
    return { type: types.SET_SEARCH_SETTINGS_OPEN, open};
}
export function updateUnifiedSearchSettings(settings,message) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return sugarApi
            .postUnifiedSearchSettings(settings,message)
            .then(settings => {
                dispatch(updateUnifiedSearchSettingsSuccess(settings));
                dispatch(notifications.notificationSuccess(message));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                dispatch(notifications.notificationError("ErrorPostingUnifiedSearchSettings"));
            });
    };
} 

export function readUnifiedSearchSettings() {
    return function(dispatch) {
        dispatch(beginApiCall());
        return sugarApi
            .readUnifiedSearchSettings()
            .then(settings => {
                dispatch(readUnifiedSearchSettingsSuccess(settings));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                dispatch(notifications.notificationError("ErrorReadingUnifiedSearchSettings"));
            });
    };
} 
export function readQuickSearchItems(input) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return sugarApi
            .readQuickSearchItems(input)
            .then(items => {
                dispatch(readQuickSearchItemsSuccess(items,input));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                dispatch(notifications.notificationError("ErrorGettingQuickSearch"));
            });
    };
} 
