import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Typography} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
      margin: '-20px auto auto auto',
      width: "100%",
      height: 10,
      paddingBottom:10
  },
 bar:{
     height:14,
     backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
});

export const LinearDeterminate = (props) => {
  const classes = useStyles();
  const [completed, setCompleted] = React.useState(0);

  React.useEffect(() => {
    function progress() {

      if (props.progress) {
        setCompleted((oldCompleted) => {
          if (oldCompleted > props.progress.size) {
            return 100;
          }
          if (props.progress.size === 0) {
            return 0;
          }
          return Math.min(props.progress.progress + 1, props.progress.progress / props.progress.size * 100);
        });
      }
    }
    console.log(progress)
    const timer = setInterval(progress, 20);
    return () => {
      clearInterval(timer);
    };
  }, [props.progress]);

  return (
    <div className={classes.root}>
      <LinearProgress className={classes.bar} variant="determinate" value={completed} />
        <Typography>{props.progress.progress <= props.progress.size && completed.toFixed(0)}%</Typography>
    </div>
  );
};
export default LinearDeterminate;
