import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import '../../components/Navbar/SideNavBar.css';
import PersonIcon from "@material-ui/icons/Person";
import hasAccess from "../../services/accessControl";
import { Link } from 'react-router-dom';
import TenantSelector from '../tenants/TenantSelector';
import { withStyles } from '@material-ui/styles';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import PasswordModal from "../common/passwordModal";

const styles = theme => ({
  tooltip:{
    textAlign: 'center',
    fontSize: 18 ,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: 'pre-line',
    lineHeight: '1.6',
}, 
});
export class TopNavMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state= {
      anchorEl: null,
      openPassword: false
    }
  }

  handleClick = event => {
    this.setState({anchorEl: event.currentTarget});
  };
  handleRoute = (item) => {
    this.props.history.push(item.Link)
    this.handleClose()
  }
  handleClose = () => {
    this.setState({anchorEl:null});
  };

  handlePassword = (open) => {
    if (open) {
      this.setState({
        openPassword: true
      })
    }
    else {
      this.setState({
        openPassword: false
      })
    }
    this.handleClose()
 
  }

  render(){
    
  const { t, classes, user, tenantInfo, availableTenants } = this.props;
    return (
        <div id="TopNavMenu" style={{marginBottom: "1px"}}>
            {
                //user.profile.name
            }
            <SpeedDialAction

               


          open={true}
          tooltipTitle={user.profile.name + "\n" + tenantInfo.name}
          tooltipPlacement={'left'}
          FabProps={{style:{background: 'transparent',
          boxShadow: 'none', 
          margin: "2px 2px 0px 4px",
          padding:0}}}
          TooltipClasses={classes}
          placement="bottom" 
          icon={
                <PersonIcon
                  style={{   background: 'transparent',
                  boxShadow: 'none', 
                  margin: "2px 2px 0px 4px",
                  padding:0}}
                  aria-controls="profile-menu"
                  aria-haspopup="true"
                  onClick={this.handleClick}
              >
                </PersonIcon>
              }           

            />
        <Menu
          id="profile-menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem id="top-menu-tenant" style={{background:"rgba(238, 238, 238, 0.5)"}}>
          {availableTenants.length > 1 ? 
            <TenantSelector tenants={availableTenants} 
                            tenantInfo={tenantInfo} 
                            actions={this.props.actions}></TenantSelector> 
            : tenantInfo.name}
          </MenuItem>
          <MenuItem
            button 
            component={this.props.isTesting ? "a" : ""}
            id="top-menu-personal-info"
            onClick={() => this.handleRoute({name:'Users', Link:"/yerp?module=Users&action=DetailView&record=" + this.props.resourceAccess.sugarUser})}>
            {t('OwnInfo')}
          </MenuItem>
                <MenuItem
                    button component={this.props.isTesting ? "a" : ""}
                    id="top-menu-change-password"
                    onClick={() => this.handlePassword(true)}>
                    {t('ChangePassword')}</MenuItem>
          {hasAccess(this.props.accessControl, 'Administration', 'contributor') ? (
            <MenuItem
              button component={this.props.isTesting ? "a" : ""}
              id="top-menu-administration"
              onClick={() => this.handleRoute({name:'Administration',Link:"/yerp?module=Administration"})}>
              {t('Administration')}</MenuItem>
          ) : (null)}          
          
          <MenuItem onClick={this.handleClose}
            button component='a'
            id="top-menu-what-is-new"
            href="https://forums.urakointiplus.fi/julkaisut/?pwd=snpjm05xb2r">
            {t('WhatsNew')}
            {/* <Badge className="what-new-badge" badgeContent={'!'}color="primary"></Badge> */}
          </MenuItem>
                <MenuItem component={this.props.isTesting ? "a" : Link} to="/yerp?module=Users&action=Logout" onClick={this.handleClose} button id="top-menu-logout">{t('Logout')}</MenuItem>

        </Menu>
        { this.state.openPassword &&
          <PasswordModal
              open={this.state.openPassword}
              userId={ this.props.user.profile.sub}
              handleReply={this.handlePassword}
              t={this.props.t}
          >
          </PasswordModal>
        } 
      </div>
    );
        }
}

export default withStyles(styles)(TopNavMenu);

/*
         
 
 */