import * as locales from "../i18n/locales"
import * as constants from "../components/common/constants";

export default class NumberHelper {

    static moneyFormat = (value, language) => {
        if (locales.PositionLocaleCurrencySymbol[language] === constants.textAlign.left) {
            return locales.LocaleCurrencySymbol[language] + " " + new Intl.NumberFormat(language).format(value)
        }
        
        return   new Intl.NumberFormat(language).format(value) + " " + locales.LocaleCurrencySymbol[language]
    }

    static numberFormat = (value, language) => {
        return new Intl.NumberFormat(language).format(value)
    }
}