import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from './authorizationUtils';
import axios from 'axios';

// azure functions can not handle ambigous routes
// we need explicit defined those

export function ReadRoles(filter, sort, page, cancelToken) {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.products) + `/api/Aggregate/Roles/GetAll?filter=${filter}&sort=${sort}&page=${page}`, options)
        .then(handleResponse)
        .catch(handleError);
}

export function ReadRolesBatch(batch, cancelToken) {
    const options = getRequestOptions(cancelToken);
    return axios.post(getApiUrl(serviceKeys.products) + `/api/Aggregate/Roles/GetBatch`, batch, options)
        .then(handleResponse)
        .catch(handleError);
}

export function ReadRole(id) {
    const options = getRequestOptions();
    return axios.get(getApiUrl(serviceKeys.products) + `/api/Aggregate/Roles/Get/${id}`, options)
        .then(handleResponse)
        .catch(handleError);
}

export function updateRole(role) {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.products) + `/api/Aggregate/Roles/POST`, role, options)
        .then(handleResponse)
        .catch(handleError);
}
