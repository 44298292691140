import React, { Component, Fragment } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import *  as workCardActions from '../../redux/actions/workCardActions';
import *  as workCardPDFActions from '../../redux/actions/workCardPDFActions';
import *  as personsActions from '../../redux/actions/personsActions';
import UpTextField from '../common/upTextField';
import { UpPanel } from '../upPanel'
import UpDatePicker from '../common/upDatePicker';
import { 
    Card, 
    CardContent, 
    Grid, 
    Container, 
    Switch, 
    Button, 
    Typography, 
    FormControl, 
    InputLabel, 
    Select, 
} from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import WorkCardRows from './workCardRows';
import Approver from './approver';
import * as styles from '../common/styles.js';
import Spinner from '../common/CircularSpinner';
import { withTranslation } from 'react-i18next';
import { PictureAsPdf } from '@material-ui/icons';
import { Save } from '@material-ui/icons';
import * as roles from "../../services/Roles";
import { Person } from "./person";
import { withRouter } from 'react-router-dom';
import { BottomBar } from "../common/BottomBar";
import {ScrollableTabs} from '../common/ScrollableTabs';
import { hasAccess } from '../../services/accessControl';


const marginMainCards = {
    marginBottom: "23px"
}

export class WorkCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workCardId: this.props.match.params.workCardId,
            callback: this.useQuery().get("callback"),
            tenant: this.props.tenant,
            workCardData: this.props.workCardData,
            workCardDocument: this.props.workCardDocument,
            isLoading: false,
            signature: false,
            isNotValidValue: false,
            number: 0,
            businessLine : "",
            stateRecord : "Unchanged",
            editWorkCardApproved: false,
            approverId: -1,
            person : {
                name: "",
                email: "",
                phone: "",
                state: "Added"

            },
            openPerson : false,
            persons: this.props.persons,
            isEditing: false,
            email : "",
            canEditApprover: false
        };
    }
   
    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }

    componentDidMount() {
        if (this.state.workCardId !== 'new') {
            if (!this.props.isTesting) {
                this.setState({
                    isLoading: true,
                });
            }
            this.setState({
                stateRecord : "Modified"
            });

            if (this.state.tenant && this.state.tenant.id) {
                this.readWorkCard();
            }
        }
        else {
            if (!this.props.isTesting) {
                this.setState({
                    stateRecord : "Added"
                });
            }
            this.props.actions.newWorkCard();
            this.props.actions.resetPDFWorkCard();
        }

        if (!this.props.workCardDocument.templateLoaded) {
            this.props.actions.updateTemplates();
        }
    }

    readWorkCard = () => {
        this.props.actions.readWorkCard(this.state.workCardId)
            .then(() => {

                this.props.actions.readPersons(this.state.workCardData.workCard.projectId)
                    .then(() => {
                        this.searchAndSortApprovers();
                        this.setState({
                            isLoading: false,
                        });
                    })
                    .catch(() => {
                        this.setState({
                            isLoading: false,
                        });
                    })
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                });
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.workCardData !== prevProps.workCardData) {
            var editing = false;
            if (this.props.workCardData.workCard.approved &&
                !this.props.workCardData.workCard.invoiced &&
                hasAccess(this.props.resourceAccess, "workcards", roles.invoicer)) 
            {
                editing = true;
            }

            this.setState({
                workCardData: this.props.workCardData,
                editWorkCardApproved : editing,
                canEditApprover: this.props.workCardData.workCard.approved
            });

         }
        if (this.props.tenant !== prevProps.tenant) {
            this.setState({
                tenant: this.props.tenant,
            });
            if (!this.props.workCardDocument.templateLoaded) {
                this.props.actions.updateTemplates();
            }

            if (this.state.workCardId !== 'new') {
                this.readWorkCard();
            }
        }
        if (this.props.workCardDocument !== prevProps.workCardDocument) {
            if (this.props.workCardDocument.blob !== prevProps.workCardDocument.blob && this.props.workCardDocument.blob) {
                if (!this.props.isTesting) {
                    this.setState({
                        isLoading: false,
                    });
                }
                const file = new Blob(
                    [this.props.workCardDocument.blob],
                    { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            }
            this.setState({
                workCardDocument: this.props.workCardDocument,
            });
        }
        if (this.props.workCardData.number !== prevProps.workCardData.number) {
            this.setState({
                number: this.props.workCardData.number
            });
        }

        if (this.props.workCardData.workCard.businessLine !== prevProps.workCardData.workCard.businessLine) {
            this.setState({
                businessLine: this.props.workCardData.workCard.businessLine
            });
        }

        if (this.props.persons !== prevProps.persons) {
            this.setState({
                persons : this.props.persons
            });
            
        }
       
    }

    addEmptyInfoRow = () => {
        var rows = [...this.state.workCardData.infoRows];
        rows.push({
            entryTime : new Date().toISOString(),
            description : "",
            authorName : "",
            source : undefined
        });
     
        this.setState(prevState => ({
            ...prevState,
            workCardData: {
                ...prevState.workCardData,
                infoRows: rows
            }
        }));

        this.setState({
            showAdditionalButton : true
        });
    }

    setInfoRowApproved = () => {
        var rows = [...this.state.workCardData.infoRows];
        for (var x = 0; x < rows.length; x++) {
            rows[x].approved = true;
        }
        rows = rows.filter(row => row.source !== undefined); 
        
        this.setState(prevState => ({
            ...prevState,
            workCardData: {
                ...prevState.workCardData,
                infoRows: rows
            }
        }));
    }

    returnToCallback = () => {
        var path = decodeURIComponent(this.state.callback);
        window.location.href = path;
    }

    handleChange = (event) => {
        
        const { id, value } = event.target;
        var data = { ...this.state.workCardData.workCard,  [id]: value, state : this.state.stateRecord };

        this.setState(prevState => ({
            ...prevState,
            workCardData: {
                ...prevState.workCardData,
                workCard: data,
            },
        }));
    }

    onShowSaveButton = () => {
        this.setState({ signature: true })
    }

    onChangeApprover = (event) => {
        const { id, value } = event.target;
        var data = { ...this.state.workCardData.approver, [id]: value, state : this.state.stateRecord };

        this.setState(prevState => ({
            ...prevState,
            workCardData: {
                ...prevState.workCardData,
                approver: data
            }
        }));
    }

    onChangeSwitch = (event) => {
        const { id, checked } = event.target;
        var data = { ...this.state.workCardData.workCard, [id]: checked, state : this.state.stateRecord };

        this.setState(prevState => ({
            ...prevState,
            workCardData: {
                ...prevState.workCardData,
                workCard: data
            }
        }));
    }

    handleRowChange = (event) => {
        var continueSearch = this.searchRows(event, false);
        if (continueSearch) {
            this.searchRows(event, true);
        }
    }

    handleAdditionalRowChange = (event, idx) => {
        const { id, value } = event.target;
        var record = event.target.dataset.record;
        
        if (idx !== undefined) {
           record = idx;
        }

        var valueData = true;
        if (id === "entryTime") {
            valueData = Date.parse(value);
        }
        
        if (!isNaN(valueData)) {
            var row = {...this.state.workCardData.infoRows[record], [id]: value };
          
            var source = "";
            if (!this.validAdditionalRow(row))
            {
                if (row.source !== "Manual" && row.source !== "ChangeReason") {
                    if (row.source === undefined) {
                        source = "Manual";
                    }
                    else if (row.source !== "Manual") {
                        source = "ChangeReason";
                    }
                    row.source = source;
                }
            }
            else {
                if (row.source === "Manual" || row.source === "ChangeReason") {
                    if (row.source === "Manual") {
                        source = "undefined";
                    }
                    else if (row.source === "ChangeReason") {
                        source = "Aggregate";
                    }
                    row.source = source;
                }
            }
           
            this.setState(prevState => ({
                ...prevState,
                workCardData: {
                    ...prevState.workCardData,
                    infoRows : Object.values({
                        ...prevState.workCardData.infoRows, 
                        [record] : row
                    })
                }
            }));
        }
        else {
            this.setState({
                showAdditionalButton : true
            });
        }
    }

    validAdditionalRow = (row) => {
        var disabled = true;
        if (row.description.trim() !== "" ) {
            if (row.authorName.trim() !== "" ) {
                disabled = false;
            }
            else {
                disabled = true;
            }
        }
        else {
            disabled = true;
        }
        this.setState({
            showAdditionalButton : disabled
        });

        return disabled;
    }
 
    searchRows = (event, normalRows) => {
        var continueSearch = true;
        
        const { id, value } = event.target;
        const { record } = event.target.dataset;

        var property = normalRows ? "normal" : "additional";   

        var rowsLevel = this.state.workCardData[property];

        var level = "";
        var idx = -1;
        var subIdx = -1;
        var row = 0;
        var state = this.state.stateRecord;
        Object.keys(rowsLevel).forEach(function (key) {
            var rows = rowsLevel[key].rows;
            
            for (var x = 0; x < rows.length; x++) {
                if (rows[x].id === record) {
                    level = key;
                    idx = x;
                    row = { ...rows[x], [id]: value, state : state };
                    continueSearch = false;
                    break;
                }
                else {
                    for (var y = 0; y < rows[x].rows.length; y++) {
                        if (rows[x].rows[y].id === record) {
                            level = key;
                            idx = x;
                            subIdx = y;
                            row = { ...rows[x].rows[y], [id]: value, state : state } ;
                            continueSearch = false;
                            break;
                        }
                    }
                }
            }  
        });

        if (!continueSearch) {
            if (subIdx === -1) {
                this.setState(prevState => ({
                    ...prevState,
                    workCardData: {
                        ...prevState.workCardData,
                        [property] : {
                            ...prevState.workCardData[property], 
                            [level] : {
                                ...prevState.workCardData[property][level],
                                rows : Object.values({
                                    ...prevState.workCardData[property][level].rows, 
                                    [idx] : row
                                })
                            }
                        }
                    }
                }));
            }
            else {
                this.setState(prevState => ({
                    ...prevState,
                    workCardData: {
                        ...prevState.workCardData,
                        [property] : {
                            ...prevState.workCardData[property], 
                            [level] : {
                                ...prevState.workCardData[property][level],
                                rows : {
                                    ...prevState.workCardData[property][level].rows[idx], 
                                    rows : Object.values({
                                        ...prevState.workCardData[property][level].rows[idx], 
                                        [subIdx] : row
                                    })
                                }
                            }
                        }
                    }
                }));
            }
        }

        return continueSearch;
    }

    handleSubmit = async () => {
    

        if (!this.props.isTesting) {
            this.setState({
                isLoading: true,
                counterAPICalls : 0
            });
        }

        this.setInfoRowApproved();
        
        let createOrUpdatePromise;

        if (this.state.workCardId === "new") {
            this.setState(prevState => ({
                ...prevState,
                workCardData: {
                    ...prevState.workCardData,
                    state : this.state.stateRecord
                }
            }));
            
            createOrUpdatePromise = this.props.actions.createWorkCard(this.state.workCardId, this.state.workCardData);

        } else {
            this.setState(prevState => ({
                ...prevState,
                workCardData: {
                    ...prevState.workCardData,
                    workCard : {
                        ...prevState.workCardData.workCard,
                        Approved : true
                    }
                }
            }));

            createOrUpdatePromise = this.props.actions.updateWorkCard(this.state.workCardData);
        }

        var flagContinue = true
        await createOrUpdatePromise
            .catch((error) => {
                flagContinue = false;      
            });

        if (flagContinue) {
            await this.props.actions.renderPDFWorkCard(this.state.workCardData, this.state.tenant)
            .catch((error) => {
                flagContinue = false;      
            });
        } 

        if (flagContinue) {
            if (this.state.workCardData.workCard.sendAsEmail) {
                await this.props.actions.sendPDFWorkCardDocumentLink(this.state.workCardId, this.state.email)
                .catch((error) => {
                    flagContinue = false;      
                });
            }
        }

        if (this.props.isTesting) {
            this.setState({
                continueAPICalls : flagContinue
            });
        }

        if (flagContinue) {
            this.closeCard();
        }
        else {
            this.setState({
                isLoading: false
            });
        }
    }

    /*
    handleDelete = (event) => {
        event.preventDefault();
        this.props.actions.deleteWorkCard(this.state.workCardId);
    }*/

    saveCard = () => {
        this.setState({
            isSaving: true
        });
        this.handleSubmit();
    }

    openPDF = () => {
        if (!this.props.isTesting) {
            this.setState({
                isLoading: true,
            });
        }
        this.props.actions.previewPDFWorkCard(this.state.workCardData, this.state.tenant)
        .catch((error) => {
            this.setState({
                isLoading: false
            });
        });
    }

    closeCard = () => {
        var path;
        if (this.state.callback !== null && this.state.callback !== undefined && this.state.callback !== "") {
            this.returnToCallback();
        }
        else {
            this.setState({
                isLoading: false
            });

            this.props.history.push("/workcards")
        }

     

        if (this.props.isTesting) {
            this.setState({
                path : path
            });
        }
    }

    handleCloseSnack = () => {
        this.setState({
            openSnack : false,
            messageSnack : "",
            variantSnack : ""
        });
    }

    handleApproverChange = (name) => (event) => {

        const value = parseInt(event.target.value);
         var email = "";
         if (value !== -1 && value !== -2) {
            email = this.state.persons[value].email;
         }
         this.handleChange({
            target :{
                id : "sendAsEmail",
                value : email !== ""
            }
        });
         
         this.setState({
            [name] : value,
            email : email
          });
           
          if (value === -2) {
            this.setState({
                openPerson : true,
            });
          }
    }

    handlePersonBack = () => {
        if (this.state.isEditing) {
            this.setState({
                isEditing: false
            });
        }
        else {
            this.setState({
                approverId : -1 
            });
        }
        this.cleanPerson();
       
    }

    cleanPerson = () => {
        this.setState({
            openPerson : false,
            person : {
                name: "",
                email: "",
                phone: "",
                state: "Added"
            },
        });
    }

    handlePersonSave = async () => {
       
        if (!this.props.isTesting) {
            this.setState({
                isLoading : true
            })
        }

        var person = { ...this.state.person };

        if (person.state === "Added") {
            if (person.id !== undefined && person.id !== "") {
                person.state = "Modified";
            }
            else {
                person.projectId = this.state.workCardData.workCard.projectId;
            }
        }
        else {
            person.state = "Modified";
        }

        
        await this.props.actions.updatePerson(person)
        .then((result) => {
            if (person.state === "Added") {
                person.id = result.id
                
                this.setState({
                    persons: [ ...this.state.persons, person ],
                });
            }
            else {
                this.setState(prevState => ({
                    ...prevState,
                    persons: Object.values({
                        ...prevState.persons, 
                        [this.state.approverId] : person
                    }),
                }));
            }

            this.handleChange({
                target :{
                    id : "approver",
                    value : person.id
                }
            });

            if (person.email.trim() !== "") {
                this.handleChange({
                    target :{
                        id : "sendAsEmail",
                        value : true
                    }
                });
                
                this.setState({
                    email : person.email
                });
            }
           
            this.cleanPerson();

            this.searchAndSortApprovers();
        })
        .catch ((error) => {})

        this.setState({
            isLoading : false
        })

    }

    onChangePerson = (event) => {
        const { id, value } = event.target;

        var data = { ...this.state.person, [id]: value };

        this.setState({
            person: data
        });
    }


    editApprover = () => {
        this.setState({
            person : { ...this.state.persons[this.state.approverId] },
            openPerson : true,
            isEditing: true
        });
        
        
    }

    compare = (a, b) => {
        const bandA = a.name.toUpperCase();
        const bandB = b.name.toUpperCase();
      
        let comparison = 0;
        if (bandA > bandB) {
          comparison = 1;
        } else if (bandA < bandB) {
          comparison = -1;
        }
        return comparison;
      }

    searchAndSortApprovers = () => {
        var persons = [...this.state.persons];
        persons.sort(this.compare);

        this.setState({
            persons: persons
        });

        for (var x = 0; x < persons.length; x++) {
            if (persons[x].id === this.state.workCardData.workCard.approver) {
                this.setState({
                    approverId: x
                });
                break;
            }
        }

        this.setState({
            email : this.state.persons[this.state.approverId].email
        });
    }


    workCardInfo = () => {
        const { t } = this.props;
        const workCard = this.state.workCardData.workCard;
        
        return (

            <Grid container style={styles.ElementWithoutPadding}>
                <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                    <Card style={styles.CardWithoutBoxes}>
                        <CardContent style={styles.ElementWithoutPadding}>
                            <div style={marginMainCards}>
                            </div>
                            {/*
                            <NumberField
                                disabled={true}
                                id="number"
                                label={t("Number")}
                                value={this.state.number}
                                onChange={this.handleChange}
                                lang={i18n.language}
                            />*/
                            }
                            
                            <UpTextField
                                style={styles.inputElements}
                                id="title"
                                label={t("Title")}
                                value={workCard.title}
                                onChange={this.handleChange} />
                            <UpTextField
                                style={styles.inputElements}
                                id="description"
                                label={t("Description")}
                                value={workCard.description}
                                onChange={this.handleChange} />
                            <UpTextField
                                disabled={true}
                                style={styles.inputElements}
                                id="workSiteNumber"
                                label={t("workSiteNumber")}
                                value={workCard.workSiteNumber}
                                onChange={this.handleChange} />
                            <UpTextField
                                disabled={true}
                                style={styles.inputElements}
                                id="businessLine"
                                label={t("BusinessLine")}
                                value={this.state.businessLine}
                                onChange={this.handleChange} />
                            
                        </CardContent>
                    </Card>
                </Container>
                <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                    <Card style={styles.CardWithoutBoxes}>
                        <CardContent style={styles.ElementWithoutPadding}>
                            <div style={marginMainCards}>
                            </div>
                            <UpDatePicker
                                disabled={true}
                                style={styles.inputElements}
                                id="startDate"
                                label={t("startDate")}
                                value={workCard.startDate}
                                onChange={this.handleChange} />
                            <UpDatePicker
                                disabled={true}
                                style={styles.inputElements}
                                id="endDate"
                                label={t("endDate")}
                                value={workCard.endDate}
                                onChange={this.handleChange} />
                            <UpTextField
                                style={styles.inputElements}
                                id="employeeName"
                                label={t("employeeName")}
                                value={workCard.employeeName}
                                onChange={this.handleChange} />
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
        )
    }

    workCardExtraInfo = () => {
        const { t, i18n } = this.props;
        const customer = this.state.workCardData.customer;
        const contact = this.state.workCardData.contact;
        return (
            <div>
            <Grid container style={styles.ElementWithoutPadding}>
                <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                    <Card style={styles.CardWithoutBoxes}>
                        <CardContent style={styles.ElementWithoutPadding}>
                            <Typography variant="h6">
                                {t("Customer")}
                            </Typography>
                            <UpTextField
                                disabled={true}
                                id="name"
                                label={t("Name")}
                                value={customer.name}
                                onChange={this.handleChange}
                                lang={i18n.language}
                            />
                            <UpTextField
                                disabled={true}
                                style={styles.inputElements}
                                id="phone"
                                label={t("Phone")}
                                value={customer.phone}
                                onChange={this.handleChange} />
                            <UpTextField
                                disabled={true}
                                style={styles.inputElements}
                                id="email"
                                label={t("EmailAddress")}
                                value={customer.email}
                                onChange={this.handleChange} />
                            <UpTextField
                                disabled={true}
                                style={styles.inputElements}
                                id="address"
                                label={t("Address")}
                                value={customer.address}
                                onChange={this.handleChange} />
                        </CardContent>
                    </Card>
                </Container>
                <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                    <Card style={styles.CardWithoutBoxes}>
                        <CardContent style={styles.ElementWithoutPadding}>
                            <Typography variant="h6">
                                {t("ContactPerson")}
                            </Typography>
                            <UpTextField
                                disabled={true}
                                id="nameContact"
                                label={t("Name")}
                                value={contact.name}
                                onChange={this.handleChange}
                                lang={i18n.language}
                            />
                            <UpTextField
                                disabled={true}
                                style={styles.inputElements}
                                id="phoneContact"
                                label={t("Phone")}
                                value={contact.phone}
                                onChange={this.handleChange} />
                            <UpTextField
                                disabled={true}
                                style={styles.inputElements}
                                id="emailContact"
                                label={t("EmailAddress")}
                                value={contact.email}
                                onChange={this.handleChange} />
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid container style={styles.ElementWithoutPadding}>
             <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                 <Card style={styles.CardWithoutBoxes}>
                     <CardContent style={styles.ElementWithoutPadding}>
                         <Typography variant="h6">
                             {t("CustomerReference")}
                         </Typography>
                         <UpTextField
                             disabled={true}
                             id="reference"
                             label={t("CustomerReference")}
                             value={customer.reference}
                             onChange={this.handleChange}
                             lang={i18n.language}
                         />
                     </CardContent>
                 </Card>
             </Container>
         </Grid>
         </div>
        )
    }

    workCardApproverInfo = () => {
        const { t } = this.props;
        const workCard = this.state.workCardData.workCard;
        const { persons, canEditApprover } = this.state;
        return (
            <Grid container style={styles.ElementWithoutPadding}>
                <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                    <Card style={styles.CardWithoutBoxes}>
                        <CardContent style={styles.ElementWithoutPadding}>
                            <Typography variant="h6">
                                {t("Approver_Information")}
                            </Typography>
                            <FormControl style={styles.inputElements}>
                                <InputLabel htmlFor="outlined-approver" style={{
                                    background: "white",
                                    paddingRight: "5px",
                                    paddingLeft: "5px",
                                    }}>{t("Name")}</InputLabel>
                                <Select
                                    disabled={canEditApprover}
                                    native
                                    value={this.state.approverId}
                                    onChange={this.handleApproverChange("approverId")}
                                    inputProps={{
                                        name: "approverId",
                                        id: 'outlined-approver',
                                    }}
                                    >
                                    <option key={"option" + -1 } value={-1}></option>
                                    {persons.map((row, idx) => (
                                        <option key={"option" + idx } value={idx}>{persons[idx].name}</option>
                                    ))}   
                                    <option key={"option" + -2 } value={-2}>{t("New")}</option>
                                </Select>
                            </FormControl>
                            { (this.state.approverId !== -1 && this.state.approverId !== -2) &&
                                <div>     
                                    <Grid container >
                                        <Grid style={{ width: "100%" }}>
                                            <UpTextField
                                                disabled={canEditApprover}
                                                style={styles.inputElements}
                                                id="email"
                                                label={t("EmailAddress")}
                                                value={this.state.email}
                                                onChange={this.onChangeApprover} />
                                        </Grid>
                                        <Grid>
                                            {workCard.sendAsEmail && !this.state.email &&
                                                <Typography id="email-helper-text" style={{ color: "rgba(255, 1, 1, 0.54)" }}>
                                                    {t("RequireEmail")}
                                                </Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ marginTop: "24px" }}>
                                        <Grid style={{ paddingTop: "8px", width: "49px" }}>
                                            <Switch
                                                disabled={canEditApprover}
                                                id="sendAsEmail"
                                                label={t("sendAsEmail")}
                                                checked={workCard.sendAsEmail}
                                                color="primary"
                                                onChange={this.onChangeSwitch}
                                            />
                                        </Grid>
                                        <Grid>
                                            <Typography style={styles.labelMargins}  >
                                                {t("Send_email_copy")}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                 
                                </div>
                            }
                            {workCard.approved ? (
                                <img id="imagsignature" src={workCard.signature} alt="" width="100%" height="200"/>
                            ) : (
                                    <Approver approver={workCard.approver} onChange={this.handleChange} saveCard={this.saveCard} onShowSaveButton={this.onShowSaveButton} />
                            )
                            }
                        </CardContent>
                    </Card>
                </Container>
                <Person handlePersonBack={this.handlePersonBack} t={t} person={this.state.person} open={this.state.openPerson} handlePersonSave={this.handlePersonSave} onChangePerson={this.onChangePerson}/>
            </Grid>
        )
    }

    
    workCardButtons = () => {
        const { t } = this.props;
        return (
            <div>
                <Grid container direction="row" justify="space-between">
                    <Grid item>
                        <Button id="cancelWorcardId" variant="contained" style={styles.button} onClick={this.closeCard} >
                            <Cancel style={styles.leftIcon} />
                            {t("Cancel")}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button id="openPDFId" variant="contained" color="primary" style={styles.button} onClick={this.openPDF} >
                            <PictureAsPdf style={styles.leftIcon} />
                            {t("Open_PDF")}
                        </Button>
                    </Grid> 
                    {this.state.workCardData.workCard.approved &&
                        <Grid item>
                            <Button id="saveWorcardId" variant="contained" color="primary" style={styles.button} onClick={this.handleSubmit} >
                                <Save style={styles.leftIcon} />
                                {t("Save")}
                            </Button>
                        </Grid>
                    }
                </Grid>
            </div>
        )
    }

    workCardrows = () => {
        const workCard = this.state.workCardData;
        return(
        <WorkCardRows 
                additional={workCard.additional} 
                normal={workCard.normal} 
                infoRows={workCard.infoRows} 
                onChange={this.handleRowChange} 
                onChangeAdditional={this.handleAdditionalRowChange}
                hasReportingLevels={workCard.workCard.hasReportingLevels} 
                showAdditionalButton={this.state.showAdditionalButton}
                addEmptyInfoRow={this.addEmptyInfoRow}
                editWorkCardApproved={this.state.editWorkCardApproved}
            />
        )
    }
    slider = () => {
        const { t, isSnapshot } = this.props;
        return (
            <Fragment>
                {isSnapshot ? (
                    this.workCardInfo(),
                    this.workCardExtraInfo(),
                    this.workCardrows(),
                    this.workCardApproverInfo()
                ) : ( 
                    <ScrollableTabs
                        tabs={[
                        {title: t("GeneralInfo"), item: this.workCardInfo()},
                        {title: t("ContactPerson"), item: this.workCardExtraInfo()},
                        {title: t("WorkItems"), item: this.workCardrows()},
                        {title: t("Approver_Information"), item: this.workCardApproverInfo()},]}>
                        {/* <div title={t("ProductRegister")}>
                            {this.generalInfo()}
                            {this.buttonsBottom()} 
                        </div>
                        <div title={t("ProductsBuilder")}>
                            {this.builderList()}
                        </div>
                        <div title={t("ProductsBuilder")}>
                            {this.builderList()}
                        </div>  */}
                    </ScrollableTabs>
                )
            }
</Fragment>
            

        )
    }
    render() {
        return (
            <UpPanel>
                <div>
                    {this.state.isLoading &&
                        <Spinner open={this.state.isLoading} />
                    }
                </div>
                
                {this.slider()}
                {/* {this.workCardInfo()}
                {this.workCardExtraInfo()}
                <WorkCardRows 
                    additional={workCard.additional} 
                    normal={workCard.normal} 
                    infoRows={workCard.infoRows} 
                    onChange={this.handleRowChange} 
                    onChangeAdditional={this.handleAdditionalRowChange}
                    hasReportingLevels={workCard.workCard.hasReportingLevels} 
                    showAdditionalButton={this.state.showAdditionalButton}
                    addEmptyInfoRow={this.addEmptyInfoRow}
                    editWorkCardApproved={this.state.editWorkCardApproved}
                />
                {this.workCardApproverInfo()} */}
                {/* {this.workCardButtons()} */}
                <BottomBar
                title={this.state.workCardData.workCard.title}
                disabler={this.state.workCardData.workCard.approved}
                placeholder={this.props.t('Unnamed') + ' ' + this.props.t('WorkCard')}
                leftButton={{action: this.closeCard, tooltip: this.props.t("Cancel"), icon: <Cancel />}}
                rightButton={{action: this.openPDF, tooltip: this.props.t("Open_PDF"), icon: <PictureAsPdf/>}}
                >
                </BottomBar>

            </UpPanel>
        );
    }
}

function mapStateToProps(state) {
    return {
        resourceAccess: state.resourceAccess,
        workCardData: state.workCard,
        workCardDocument: state.workCardDocument,
        tenant: state.tenant,
        user: state.user,
        persons: state.persons
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            readWorkCard: bindActionCreators(workCardActions.readWorkCard, dispatch),
            createWorkCard: bindActionCreators(workCardActions.createWorkCard, dispatch),
            updateWorkCard: bindActionCreators(workCardActions.updateWorkCard, dispatch),
            deleteWorkCard: bindActionCreators(workCardActions.deleteWorkCard, dispatch),
            newWorkCard: bindActionCreators(workCardActions.newWorkCard, dispatch),
            updateTemplates: bindActionCreators(workCardPDFActions.updateTemplates, dispatch),
            previewPDFWorkCard: bindActionCreators(workCardPDFActions.previewPDFWorkCard, dispatch),
            renderPDFWorkCard: bindActionCreators(workCardPDFActions.renderPDFWorkCard, dispatch),
            resetPDFWorkCard: bindActionCreators(workCardPDFActions.resetPDFWorkCard, dispatch),
            sendPDFWorkCardDocumentLink: bindActionCreators(workCardPDFActions.sendPDFWorkCardDocumentLink, dispatch),
            readPersons: bindActionCreators(personsActions.readPersons, dispatch),
            updatePerson: bindActionCreators(personsActions.updatePerson, dispatch),
        }
    };
}

export default compose(withTranslation('common'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(withRouter(WorkCard));
