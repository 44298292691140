import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from './authorizationUtils';
import axios from 'axios';

// azure functions can not handle ambigous routes
// we need explicit defined those

export function ReadCatalog(id) {
    const options = getRequestOptions();
    return axios.get(getApiUrl(serviceKeys.products) + `/api/Aggregate/GeneralCatalogs/Get/${id}`, options)
        .then(handleResponse)
        .catch(handleError);
}

export function updateCatalog(model) {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.products) + `/api/Aggregate/GeneralCatalogs/POST`, model, options)
        .then(handleResponse)
        .catch(handleError);
}



