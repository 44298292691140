import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { ProductGroupsList } from "../Lists/productGroupsList";

export class SearchOneProductGroup  extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }
    
    render() {
        return (
            <ProductGroupsList 
                {...this.props}
                addButton={false}
                backButton={true}
                onlySelect={true}
                handleClose={this.props.handleClose}
                isToUp={true}
                isModalDialog={true}
                handleSearchOneSelect={this.props.handleSearchOneSelect}
                multiSelect={false}
            
            />
        )
    };
}

export default withTranslation('common')(SearchOneProductGroup);
