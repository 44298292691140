import React, { Component } from 'react';
import { ProductsRegisterList } from "../common/Lists/productsRegisterList";
import { connect } from 'react-redux';
import *  as productRegisterActions from '../../redux/actions/productRegisterActions';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import CancelPromisesHOC   from "../../helpers/cancelPromisesHOC";
import *  as productGroupActions from '../../redux/actions/productGroupActions';
import *  as productUnitActions from '../../redux/actions/productUnitActions';
import *  as navigationActions from '../../redux/actions/navigationActions';
import { withRouter } from "react-router-dom";
import {setCurrentModule} from "../../utils/navigationUtil";

export class ProductsRegister  extends Component {
    componentDidMount(){
        
    this.props.actions.setModule(setCurrentModule('/productsRegister'));
   
    }
    render() {
        return (
            <ProductsRegisterList {...this.props} />
        )
    };
}

function mapStateToProps(state) {
    return {
        productsRegister : state.productsRegister,
        productGroups : state.productGroups,
        productGroup : state.productGroup,
        productUnits : state.productUnits,
        productUnit : state.productUnit,
        moduleInfo: state.navigation.module
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            readProductsRegister: bindActionCreators(productRegisterActions.readProductsRegister, dispatch),
            updateProductRegister: bindActionCreators(productRegisterActions.updateProductRegister, dispatch),
            readProductGroups: bindActionCreators(productGroupActions.readProductGroups, dispatch),
            readProductGroup: bindActionCreators(productGroupActions.readProductGroup, dispatch),
            readProductUnits: bindActionCreators(productUnitActions.readProductUnits, dispatch),
            readProductUnit: bindActionCreators(productUnitActions.readProductUnit, dispatch),
            setModule: bindActionCreators(navigationActions.setModule, dispatch)
        
        }
    };
  }

export default compose(withTranslation('common'),
CancelPromisesHOC,
connect(
    mapStateToProps,
    mapDispatchToProps,
))(withRouter(ProductsRegister));