import * as types from "./actionTypes";
import * as tenantApi from "../../api/tenantApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";

export function readTenantSuccess(tenant) {
    return { type: types.READ_TENANT_SUCCESS, tenant };
}
export function getAllAvailableTenantsSuccess(tenants) {
    return { type: types.GET_ALL_AVAILABLE_TENANTS_SUCCESS, tenants };
}

export function readTenant(tenantId) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return tenantApi
            .readTenant(tenantId)
            .then(tenant => {
                sessionStorage.setItem("tenant_id", tenantId);
                localStorage.setItem("tenant_id", tenantId);
                dispatch(readTenantSuccess(tenant));
            })
            .catch(error => {
                sessionStorage.removeItem("tenant_id");
                localStorage.removeItem("tenant_id");
                dispatch(apiCallError(error));
                dispatch(notifications.notificationError("ErrorGettingTenantId"));
            });
    };
} 
export function getAllAvailableTenants() {
    return function(dispatch) {
        dispatch(beginApiCall());
        return tenantApi
            .getAllAvailableTenants()
            .then(tenant => {
                dispatch(getAllAvailableTenantsSuccess(tenant));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                dispatch(notifications.notificationError("ErrorGettingTenants"));
            });
    };
} 


export function selectTenantSuccess(tenantId) {
    return function(dispatch) {
        const action = { type: types.SELECT_TENANT_SUCCESS, tenantId };
        dispatch(action);
    }
}

export function selectTenant(tenantId, availableTenants) {
    return function (dispatch) {
        if (!!tenantId) {
            dispatch(readTenant(tenantId));
            dispatch(selectTenantSuccess(tenantId));
        } else {
            dispatch(selectDefaultTenant(availableTenants));
        }
    };
} 

export function selectDefaultTenant(availableTenants) {
    return function (dispatch) {
        if (!availableTenants || availableTenants.length === 0) {
            dispatch(notifications.notificationError("ErrorGettingTenants"));
            return;
        }

        // Select the previously selected tenant if set in localstorage
        var tenantId = sessionStorage.getItem("tenant_id");
        if (!tenantId) {
            tenantId =  localStorage.getItem("tenant_id");
        }
        if (!tenantId) {
            // Fist available tenant is selected as the default tenant
            tenantId = availableTenants[0].id;
        } else if (!!tenantId && 
            !availableTenants.find(tenant => tenant.id === tenantId)) {
                // If the previously selected tenant is not available any more
                // select the first available tenant.
                tenantId = availableTenants[0].id;
        }

        dispatch(readTenant(tenantId));
    };
}

export function tenantSessionActivated(tenantId) {
    return function(dispatch) {
        const action = { type: types.TENANT_SESSION_ACTIVATED, tenantId };
        dispatch(action);

    }
}

export function tenantSessionDeactivated(tenantId) {
    return function(dispatch) {
        const action = { type: types.TENANT_SESSION_DEACTIVATED, tenantId };
        dispatch(action);
        
    }
}

