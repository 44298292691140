/* /src/routes/privateRoute.js */
import React from "react";
import { connect } from "react-redux";
import hasAccess from "../../services/accessControl";

export class ProtectedContentComponent extends React.Component {
    render = () => {
        const { user, sessionMetadata } = this.props;
        if (!!user && !sessionMetadata.logoutInProgress
            && ((!this.props.resource && !this.props.role) || 
                hasAccess(this.props.resourceAccess, this.props.resource, this.props.role))) {
            return <div>{this.props.children}</div>;
        } else {
            return <div />;
        }
    }
};

function mapStateToProps(state) {
    return {
        sessionMetadata: state.sessionMetadata,
        user: state.oidc.user,
        resourceAccess: state.resourceAccess,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export const ProtectedContent = connect(mapStateToProps, mapDispatchToProps)(ProtectedContentComponent);