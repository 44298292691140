
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useSpring, animated } from 'react-spring';
import Paper from "@material-ui/core/Paper";
import { Typography,Button } from '@material-ui/core';
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';

const styles = theme => ({

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '10px',
    },
    modalcontent: {
        backgroundColor: '#eeeeee',
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        overflow: 'auto',
        padding: theme.spacing(2, 2, 3),
        textAlign: 'center',
        alignItems: 'center',
        color: 'black',
        minWidth: 300,
        maxWidth: 600,
        minHeight: 400,
        maxHeight: 600,
        'div::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
            borderRadius: '20px',
            backgroundColor: 'rgba(51, 51, 51, 0.2)',
        },

    },
    paper: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        padding: '3px',
        minHeight: 100,
        height: '100%',
        width: '100%',
        transition: 'all 700ms ease-out 30ms',
    },
    filter: {
        display: 'inline-flex'
    },
    input: {
        width: '80%',
    },
    title: {
        fontSize: 18,
        padding: '1px 10px 10px 10px',
    },
    select: {
        minWidth: "100%",
        minHeight: 300
    },
    options: {
        cursor: 'pointer',
        float: 'left',
        padding: '15px'
    },
    center: {
        width: '100%',
        textAlign: 'center',
    },

});

const Fade = React.forwardRef(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});

export class TenantSelector extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            selected: props.tenantInfo.id,
        }
    }

    handleOpen = () => {
        this.setState({ open: true })
    };
    handleClose = () => {
        this.setState({ open: false });
    };
    handleChange = event => {
        //this.setState({selected: event.target.value});
        //matti 12.2.2021
        window.location.href = "/yerp?tenant=" + event.target.value;
    };
    handleSubmit = () => {
        this.props.actions.selectTenant(this.state.selected)
        this.handleClose()
    };

    render() {

        const { classes, t, tenantInfo, tenants } = this.props;
        return (
            <div>
                    <div
                        id="TenantSelector"
                        onClick={this.handleOpen}>
                            {tenantInfo.name}
                        </div>

                <Modal
                    id="quickSearchSettingsModal"
                    aria-labelledby="spring-modal-title"
                    aria-describedby="spring-modal-description"
                    className={classes.modal}
                    open={this.state.open}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.open}>
                        <div className={classes.modalcontent}>
                            <Typography className={classes.title} id="new-modal-title">{t('Valitse yritys')}</Typography>
                            <Paper className={classes.paper} >
           
                                    <FormControl component="fieldset">
                                        <RadioGroup value={this.state.selected} onChange={this.handleChange}>
                                        {tenants.map((tenant) => (
                                                <FormControlLabel value={tenant.id} key={tenant.id}  label={tenant.name} control={
                                                    <Radio/>
                                                }
                                                />))}
                                        </RadioGroup>
                                    </FormControl>
                            </Paper>
                            <Button
                                children={null}
                                id="submitSearchSettings"
                                className="upButtonWithText"
                                onClick={() => this.handleSubmit() }
                                color="primary"
                                variant="contained"
                                style={{marginTop:20}}
                            >{t("Save")}</Button>
                        </div>
                    </Fade>
                </Modal>
            </div >
        );
    }
}




export default compose(withTranslation('common'), withStyles(styles))(TenantSelector);