import React, { Component } from 'react';
import * as styles from './styles.js';
import "../../index.css";
import {
    Button,
    Tooltip
} from '@material-ui/core';


export class UpButton  extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    onClick = () => {
        this.props.clickAction();
        if (this.props.showBasket) {
            this.props.showBasket(true);
        }
    }

    render() {
        return (
            <Tooltip title={this.props.toolTipMessage ? this.props.toolTipMessage : ""} >
                <Button 
                    id={this.props.id ? this.props.id : "searchId" }
                    variant="contained"
                    className=".upButtonWithText"
                    style={this.props.circleButton ? {...styles.circleButton, ...this.props.style} : {...styles.button, ...this.props.styleNoCircle}}
                    onClick={this.onClick} 
                    disabled={this.props.disabled}
                    color={this.props.color} 
                    >
                    {this.props.iconButton}
                    {this.props.messageContent}
                </Button>
            </Tooltip>
        )
    };
}

export default (UpButton);
