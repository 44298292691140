import React, { Component } from 'react';
import { Grid, Modal, Backdrop , Fade, Button,  Typography } from '@material-ui/core';
import * as styles from '../common/styles.js';
import UpTextField from '../common/upTextField';
import { withTranslation   } from 'react-i18next';



export class Person extends Component {

    constructor(props) {
        super(props);
        this.state = {
           
        }
    }
   
    render() {
        const { t, person, open } = this.props; 
        return (
            <Modal
                id="ModalPerson"
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                style={styles.modal}
                open={open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={open}  >
                    <div style={{ ...{ paddingLeft: "15px" }, ...styles.modalPaper }}>
                            <Typography variant="h6">
                                {t("Approver_Information")}
                            </Typography>    <Grid container style={styles.ElementWithoutPadding}>
                            <UpTextField
                                style={styles.inputElements}
                                id="name"
                                label={t("Name")}
                                value={person.name}
                                onChange={this.props.onChangePerson} 
                            />
                            <UpTextField
                                style={styles.inputElements}
                                id="email"
                                label={t("EmailAddress")}
                                value={person.email}
                                onChange={this.props.onChangePerson} 
                            />
                            <UpTextField
                                style={styles.inputElements}
                                id="phone"
                                label={t("Phone")}
                                value={person.phone}
                                onChange={this.props.onChangePerson} 
                            />
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            style={{ marginBottom : "10px"  }}
                        >
                            <Grid item xs={4} style={{ textAlign : 'center'}}>
                                <Button id="close" onClick={this.props.handlePersonBack} variant="contained" >
                                {t("Back")}
                                </Button >  
                            </Grid>
                            <Grid item xs={4} style={{ textAlign : 'center'}}>
                                <Button id="save" 
                                    disabled={(person.name.trim() === "" || person.email.trim() === "") ? true : false}
                                    color="primary" onClick={this.props.handlePersonSave} variant="contained" >
                                    {t("Save")}
                                </Button >  
                            </Grid>
                        </Grid>
                    
                    </div>
                </Fade>
            
            </Modal>
        )
    }
};

export default withTranslation('common')(Person);
