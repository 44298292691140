import React, { Component, Fragment } from 'react';
import "../../../index.css";
import { withTranslation } from 'react-i18next';
import * as constants from "../constants";
import FilterHelper from "../../../helpers/filterHelper"; 
import { SearchList } from "../Lists/searchList";
import Spinner from './../CircularSpinner';
import * as locales from "../../../i18n/locales";
import axios from 'axios';
import {
    Typography
} from '@material-ui/core';
import UploadPreview from '../uploadPreview';  
import CreateDiaryEntry from "../../workOrder/createDiaryEntry";
import { AbortController } from "@azure/abort-controller";

var isMounted = true;

export class DiaryEntriesList  extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            rows : [],
            filterOptions : [],
            builderList : [],
            isLoading : false,
            isRowStyle: false,
            cleanData : false,
            openFileDialog: false,
            dataEntryId: undefined,
            open: false,
            newEntry:true,
            saving:false,
            isDeleting: false,
            
        }
    }

    componentWillUnmount() {
        isMounted = false;
        this.props.cancelPromises();
    }

    componentDidMount() {
        this.setFilter();
       this.setState({abortController : new AbortController()});
        this.loadRecords("", "",1);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.rows !== this.props.rows) {
            this.setState({
                rows : this.props.rows
            })
        }

        if (prevProps.workOrderDiaryEntries !== this.props.workOrderDiaryEntries) {
            this.setState({
                rows : this.props.workOrderDiaryEntries
            })
        }

        if (prevProps.realTimeMessage !== this.props.realTimeMessage) {
            if (this.props.realTimeMessage.collectionId === constants.collections.workOrderDiaryEntries &&
                this.props.realTimeMessage.etag !== this.props.etag)
            {
                this.loadRecords("", "",1);
            }
        }
    }
    
    setFilter = () => {
        var options = [
            FilterHelper.createFilterOption("attachment", true, false, 5, constants.textAlign.left, false, null),
            FilterHelper.createFilterOption("description", true, false, 3, constants.textAlign.left, true, null, "Description"),
            FilterHelper.createFilterOption("timeModified", true, false, 2, constants.textAlign.left, true, null, "TimeModified"),
            FilterHelper.createFilterOption("timeCreated", true, true, 2, constants.textAlign.left, true, null, "TimeCreated"),
            
        ];
      
        var id = constants.diaryEntryFilter;
    
        var filterOptions = FilterHelper.setFilter( id, options, false);
        
        this.setState({
            filterOptions : filterOptions.filter,
            isRowStyle : filterOptions.isRowStyle
        })
    }

    showFilterFields = (row, rowFilter, idx) => {
        const { i18n } = this.props;
        return (
            <Fragment>
           
                {rowFilter.id === "attachment" &&
                    <div>
                        <UploadPreview showDownload={true} BlobActions={this.props.BlobActions} newBlob={false} blobType={row.blobType} file={row} thumbWidth={'80%'} thumbHeight={'100px'}/>
                    </div>
                }
                {rowFilter.id === "description" &&
                    <Typography>{row.description}</Typography>
                }

                {rowFilter.id === "timeCreated" &&
                <Typography>
                   {new Intl.DateTimeFormat(i18n.language, locales.DateNotations[i18n.language])
                     .format(Date.parse(row.timeCreated))}
                     </Typography>
                }
                {rowFilter.id === "timeModified" &&
                <Typography>
                   {new Intl.DateTimeFormat(i18n.language, locales.DateNotations[i18n.language])
                    .format(Date.parse(row.timeModified ? row.timeModified : row.timeCreated))}
                    </Typography>
                }  
            </Fragment>
        )
    }

    handleClose = () => {
        this.setState({
            cleanData: true
        }, function() {
            this.loadRecords("", "", 1);
        })
    }

    loadRecords= async (filter, sort, page) => {
       
        isMounted = true;

        this.props.cancelPromises();

        var isLoaded = false;
            
        if (!this.props.isLoading) {
            this.setState({
                isLoading : true
            })
        }

        var data = FilterHelper.getfilterOptions(constants.diaryEntryFilter);
        this.setState({
            filter : filter,
            filterOptions : data.filter,
            isRowStyle: data.isRowStyle 
        })

        const cancelToken = axios.CancelToken.source();
        
        await this.props.cancellablePromise(
            this.props.actions.readWorkOrderDiaryEntries(
                data.sort, page, 
                this.props.workOrderId, 
                cancelToken.token), cancelToken
        )
        .then(() => {
            isLoaded = true;
        })
        .catch((error) => {
            if (error.message === constants.cancelHttpRequest || error.message === "[object Object]") {
                isMounted = false;
            }
        })
        .finally(result =>{
            if (isMounted) {
                this.setState({
                    isLoading : false,
                    cleanData: false
                })
            }
        })

        return isLoaded;
        
    }

    handleAdd = () => {
        this.setState({
            newEntry:true,
            dataEntryId: undefined,
            openFileDialog: true
        })
    }

    handleEdit = (e, idx) => {
        this.setState({
            idx:idx,
            newEntry:false,
            dataEntryId: this.state.rows[idx].id,
            openFileDialog: true
        }, function() {
        })
    }

    CloseFileDialog = () => {
        this.state.abortController.abort();
        this.setState({
            openFileDialog: false
        });
        this.setState({
            abortController:new AbortController()
        });
    }

    saveDataEntry = async (data, file, abortController) => {
        if(file.path){
        await this.props.actions.Blobs(data.documentId + "." + data.fileExtension, 
            constants.blobPermissions.Write,
            file,
            this.state.abortController.signal)
        .then(() => this.setState({saving: true}))
        .catch(() =>
            this.setState({saving: false})
        );
        }else{
            await this.setState({saving: true})
        }
        if (this.state.saving) {
            
        const cancelToken = axios.CancelToken.source();
            this.props.cancellablePromise(
                await this.props.actions.updateWorkOrderDiaryEntry(data, cancelToken.token)
                .then(() => this.setState({saving: true}))
                .catch(() => this.setState({saving: false})
            )
            , cancelToken
        ).then(() => {
            this.setState({saving:true})
        })
        .catch((error) => {
            if (error.message === constants.cancelHttpRequest || error.message === "[object Object]") {
                isMounted = false;
            }
        })

        }
        if (this.state.saving) {
            
            this.setState({
                cleanData : true,
                openFileDialog : false,
            }, async function() {
                if(this.state.saving)
                this.loadRecords("", "",1);

                this.sendMessage({
                    collectionId : constants.collections.workOrderDiaryEntry,
                    etag : this.props.etag
                });
            });
        }
    }

    sendMessage = (message) => {
        this.props.actions.sendMessage(message);
    }

   
    refreshDetails = (data) => {
        this.setState(prevState => ({
            ...prevState,
            rows: data,
        }));
    
    } 

    handleDelete = (e, idx) => {
        this.setState({
            idx: idx,
        });

        if (!this.props.isTesting) {
            this.setState({
                open : true
            });
        }
    }

    handleDeleteRow = async (record) => {
        if (record) {
            if (!this.state.isDeleting) {
                if (!this.props.isTesting) {
                    this.setState({
                        isDeleting: true,
                    });
                }
            
                var continueDeleting = true
                await this.props.actions.readworkOrderDiaryEntry(record.id)
                .then(() => null)
                .catch(() =>
                    continueDeleting = false 
                );

                var data = this.props.workOrderDiaryEntry;
                if(data.documentId){
                await this.props.actions.Blobs(data.documentId + "." + data.fileExtension, constants.blobPermissions.Delete, null)
                .then(() => null)
                .catch(() => null);
                }
               
                if (continueDeleting) {
                    var temp = {...data}
                    temp.state = constants.Deleted;
                    await this.props.actions.updateWorkOrderDiaryEntry(temp)
                    .then(() => null)
                    .catch(() =>
                        continueDeleting = false 
                    );
                }

                if (continueDeleting) {
                    this.setState({
                        open: false,
                        isDeleting: false,
                        cleanData: true
                    }, function() {
                        this.loadRecords("", "",1);
                    });
                }
            }
        }
        else {
            this.setState({
                open: false,
            });
        }
    }

    fileDialog = () => {
        const abortController = new AbortController();
        return ( <CreateDiaryEntry 
            user={this.props.user}
            abortController={abortController}
            dataEntry={this.state.rows[this.state.idx]}
            dataEntryId={this.state.dataEntryId}
            BlobActions={this.props.BlobActions}
            newEntry={this.state.newEntry}
            blobProgress={this.props.blobProgress} workOrderId={this.props.workOrderId} open={this.state.openFileDialog} 
            saveDataEntry={this.saveDataEntry} handleClose={this.CloseFileDialog} >
                    </CreateDiaryEntry>
        )
    }

    render() {
        return (
            <Fragment>
                {this.state.isLoadingMassive &&
                    <Spinner open={true}/>
                }
                <SearchList 
                    {...this.props}
                    title={""} 
                    addButton={true}
                    hideSearch={true}
                    isCreateNewInModal={true}
                    handleAddInModal={this.handleAdd}  
                    notExpanded={false}
                    rows={this.state.rows}
                    builderList={[...this.state.builderList]}
                    handleDeleteRow={this.handleDeleteRow}
                    handleEdit={this.handleEdit}
                    deleteButton={this.props.deleteButton !== undefined ? this.props.deleteButton  : true}
                    editButton={this.props.editButton !== undefined ? this.props.editButton : true}
                    cleanData={this.state.cleanData}
                    isLoading={this.state.isLoading}
                    cleanReloadRecords={this.handleClose}
                    loadRecords={this.loadRecords} 
                    refreshDetails={this.refreshDetails}  
                    idSave={constants.diaryEntryFilter} 
                    filterOptions={this.state.filterOptions}
                    showFilterFields={this.showFilterFields}
                    fieldsAsDivs={true}
                />
                { this.state.openFileDialog &&
                    this.fileDialog() 
                }
                {this.state.isDeleting &&
                    <Spinner open={true}/>
                }
                
         
            </Fragment>
        )
    };
}

export default withTranslation('common')(DiaryEntriesList);
