import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import *  as customersActions from '../../redux/actions/customersActions';
import *  as contactsActions from '../../redux/actions/contactsActions';
import { bindActionCreators, compose } from 'redux';
import {
    Card,
    CardContent,
    Container,
    Grid,
    Typography,
} from '@material-ui/core';
import UpTextField from '../common/upTextField';
import * as styles from '../common/styles.js';
import "../../index.css";
import Spinner from '../common/CircularSpinner';
import { withTranslation } from 'react-i18next';
import * as constants from "../common/constants";
import initialState from "../../redux/reducers/initialState";
import CancelPromisesHOC   from "../../helpers/cancelPromisesHOC";
import { Cancel } from '@material-ui/icons';
import { Save } from '@material-ui/icons';
import { withRouter } from "react-router-dom";
import { SearchOne } from "../common/SearchOne";
import * as notifications from "../../redux/actions/notificationsActions";
import { Catalogs } from "../common/catalogs";
import *  as catalogsActions from '../../redux/actions/catalogsActions';
import { ContactComponent } from "../common/contactComponent";
import {ScrollableTabs} from '../common/ScrollableTabs';
import { BottomBar } from "../common/BottomBar";
import { ContactList } from "../common/Lists/contactsList";
import { Contact } from "../contacts/contact"

export class Customer  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customerId: this.props.match.params.customerNumber,
            isLoading : false,
            customer : initialState.customer,
            Contact: "",
            ContactId: constants.EmptyGuid,
            contactInfo: initialState.contact,
            showContact: false,
            open: false,
            index: -1,
            loadContactData: false,
            tabs: []
        }
    }

    async componentDidMount() {
        if (this.state.customerId !== constants.New) {
            if (!this.props.isTesting) {
                this.setState({
                    isLoading : true,
                });
            }
         
            await this.props.actions.readCustomer(this.state.customerId)
            .then(() => {
            })
            .catch(() => { })
            .finally(result => {
            })

            if (this.props.customer.contactId  !== constants.EmptyGuid) {
                await this.props.actions.readContact(this.props.customer.contactId )
                .then(() => {
                    this.handleOneSelect(this.props.contact, "contactId");
                })
                .catch(() => { })
                .finally(result => {
                    if (!this.state.isTesting) {
                        this.setState({
                            isLoading: false,
                        });
                    }
                })
            }
            else {
                if (!this.state.isTesting) {
                    this.setState({
                        isLoading: false,
                    });
                }
            }
        }
    }
   
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.customer !== this.props.customer) {
           this.setState({
               customer : this.props.customer,
           });
        }

        if (prevProps.contact !== this.props.contact) {
            if (this.props.contact.state === constants.Added && !this.state.open) {
                this.handleOneSelect(this.props.contact, "contactId");
            }
            else if (this.props.contact.state === constants.Deleted) {
                this.cleanFilter("contactId");
            }
            else if (this.props.contact.id === this.state.ContactId) {
                this.handleOneSelect(this.props.contact, "contactId");
            }
        }
    }

    handleOneSelect = (row, id) => {
        if (row && row.id) {
            if (id === "contactId") {
                this.setState({
                    Contact: row.name,
                    ContactId: row.id,
                    showContact : true,
                    contactInfo : row
                });
            }
 
            this.handleChange({
                target: {
                    id: id,
                    value: row.id
                }
            });
        }
        else {
            if (this.state.ContactId === constants.EmptyGuid) {
                this.setState({
                    showContact : false,
                    contactInfo : initialState.contact
                });
            }
        }

    }

    cleanFilter = (id) => {
        if (id === "contactId") {
            this.setState({
                Contact: "",
                ContactId: constants.EmptyGuid,
                showContact : false,
                contactInfo : initialState.contact
            });
        }
       
        this.handleChange({
            target: {
                id: id,
                value: constants.EmptyGuid
            }
        });

    }
    
    handleChange = (event) => {
      
        const { id, value } = event.target;
        
        this.setState(prevState => ({
            ...prevState,
            customer: {
                ...prevState.customer,
                [id]: value,
            },
        }));

    }

    handleChangePaymentInfoModel = (event) => {
        const { id, value } = event.target;
        
        this.setState(prevState => ({
            ...prevState,
            customer: {
                ...prevState.customer,
                paymentInfoModel : {
                    ...prevState.customer.paymentInfoModel,
                    [id]: value,
                }
            },
        }));

    }

    handleChangeInvoiceAddress = (event) => {
      
        const { id, value } = event.target;
        
        this.setState(prevState => ({
            ...prevState,
            customer: {
                ...prevState.customer,
                invoiceAddress : {
                    ...prevState.customer.invoiceAddress,
                    [id]: value,
                }
            },
        }));

    }

    

    back = () => {
        var path = "/customers";

        if (this.props.isTesting) {
            this.setState({
                backPathTest: path,
            });
        }

        this.props.history.push(path);
    }

    save = async () => {
        if (!this.props.isLoading) {
            this.setState({
                isLoading: true,
            });
        }

        var data = {...this.state.customer};
        if (this.state.customerId === constants.New) {
            data.state = constants.Added;
        }
        else {
            data.state = constants.Modified;
        }

        if (this.props.isTesting) {
            this.setState({
                recordTest: data,
            });
        }
        
        var continueBack = true;
        var id = undefined;
        await this.props.actions.updateCustomer(data)
        .then((result) => {
            if (this.state.customerId === constants.New) {
                id = result;
            }
        })
        .catch(() => {
            continueBack = false;
        })
        .finally(() =>{
            this.setState({
                isLoading: false,
            });
        })

        if (continueBack) {
            if (id !== undefined) {
                this.props.actions.notificationSuccess(this.props.t("CustomerNumber") + ": " + id, true);
            }
            this.back();
        }
    }

    refreshDetails = (rows) => {
        this.setState(prevState => ({
            ...prevState,
            rows: rows
        }));
    } 

    handleCatalogs = (id, value ) => {
        
        this.setState(prevState => ({
            ...prevState,
            customer: {
                ...prevState.customer,
                paymentInfoModel : {
                    ...prevState.customer.paymentInfoModel,
                    [id]: value,
                }
            },
        }));
    }

    handleClose = () => {
        this.setState({
            index:-1
        })
    }

    handleChangeIndex = (index) => {
        if (index === 1) {
            this.setState({
                index: index,
            })
        }
        else {
            this.setState({
                index: index,
            })
            
        }
        this.slider();
    }

    slider = () => {
        const { t } = this.props;
        var tabs = [];
        tabs.push({title: t("GeneralInfo"), item: this.generalInfo()});
        if (this.state.customerId !== constants.New) 
        {
            if (this.state.index !== 1) {
                tabs.push({title: t("Contacts"), item: <div></div>});
            }
            else {
                tabs.push({title: t("Contacts"), item: this.contacts()});
            }
        }

        return (
            <ScrollableTabs
                handleChange={this.handleChangeIndex}
                index={this.state.index}
                tabs={tabs}>
            </ScrollableTabs>
        )
    };

    generalInfo = () => {
        return (
            <Fragment>
                {this.showGeneralInfo()}
                {this.showPaymentInfo()}
                {this.showInvoiceInfo()}
            </Fragment>
        )
    }

    insideTab = (id) => {
        this.setState({
            open : true,
            selectId: id,
        })
    }
  
    handleCloseContactModify = () => {
        this.setState({
            open : false,
        });
    }

    contacts = () => {
        const { customer } = this.state;
        return (
            <div style={{marginTop: "20px", paddingLeft: "32px", paddingRight: "32px"}}>
                <ContactList 
                    {...this.props}
                    addButton={true}
                    backButton={false}
                    onlySelect={false}
                    handleClose={this.handleClose}
                    isToUp={true}
                    multiSelect={false}
                    filterId={customer.id}
                    filterName={customer.name}
                    isReadOnly={true}
                    isAllOptions={true}
                    stayInMainWindowIsAllOptions={true}
                    insideTab={this.insideTab}
                    idSave={constants.contactsCustomerFilter}
                />   
            </div>
        )
    }

    showGeneralInfo = () => {
        const { t } = this.props;
        const { customer } = this.state;
        
        return (
            <Grid container style={styles.ElementWithoutPadding}>
                <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                    <Card style={styles.CardWithoutBoxes}>
                        <CardContent style={styles.ElementWithoutPadding}>
                            <UpTextField
                                disabled={true}
                                style={styles.inputElements}
                                id="customerNumber"
                                label={t("CustomerNumber")}
                                value={customer.customerNumber}
                                onChange={this.handleChange} />
                            <Grid container >
                                <Grid style={{ width: "100%" }}>
                                    <UpTextField
                                        style={styles.inputElements}
                                        id="name"
                                        label={t("Name")}
                                        value={customer.name}
                                        onChange={this.handleChange} />
                                </Grid>
                                <Grid>
                                    {(!customer.name || customer.name.trim() === "") &&
                                        <Typography id="name-helper-text" style={{ color: "rgba(255, 1, 1, 0.54)" }}>
                                            {t("Required")}
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Container>
                <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                    <Card style={styles.CardWithoutBoxes}>
                        <CardContent style={styles.ElementWithoutPadding}>
                            <UpTextField
                                style={styles.inputElements}
                                id="vatCode"
                                label={t("VatCode")}
                                value={customer.vatCode}
                                onChange={this.handleChange} />
                        </CardContent>
                    </Card>
                </Container>
                { (this.state.customerId !== constants.New) &&
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Typography variant="h6">
                            {t("CustomerContact")}
                        </Typography>  
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <SearchOne
                                    {...this.props}
                                    id="contactId"
                                    label={t("CustomerContact")}
                                    valueOne={this.state.Contact}
                                    handleOneSelect={(row) => this.handleOneSelect(row, "contactId")}
                                    cleanFilter={() => this.cleanFilter("contactId")}
                                    typeQuery={constants.contacts}
                                    filterId={customer.id}
                                    filterName={customer.name}
                                    isFullWidth={true}
                                    isFilterDisabled={true}
                                    handleClose={(data) => this.handleOneSelect(data, "contactId")}
                                    idSave={constants.contactsCustomerFilter}
                                />
                                <ContactComponent {...this.props} show={this.state.showContact} 
                                    contact={this.state.contactInfo} cleanFilter={this.cleanFilter}
                                    updateContact={this.handleOneSelect}
                                    stayInMainWindowIsAllOptions={false}
                                >
                                </ContactComponent>
                            </CardContent>
                        </Card>
                    </Container>
                }
            </Grid>
        )
    }

    showPaymentInfo = () => {
        const { t } = this.props;
        const { customer } = this.state;
        
        return (
            <Fragment>
                <Typography variant="h6" >{t("PaymentInfo")}</Typography>
                <Grid container style={styles.ElementWithoutPadding}>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <UpTextField
                                    style={styles.inputElements}
                                    id="eInvoiceAddress"
                                    label={t("EInvoiceAddress")}
                                    value={customer.paymentInfoModel.eInvoiceAddress}
                                    onChange={this.handleChangePaymentInfoModel} />
                                <UpTextField
                                    style={styles.inputElements}
                                    id="eInvoiceOperator"
                                    label={t("EInvoiceOperator")}
                                    value={customer.paymentInfoModel.eInvoiceOperator}
                                    onChange={this.handleChangePaymentInfoModel} />
                                
                            </CardContent>
                        </Card>
                    </Container>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <Catalogs 
                                    {...this.props}
                                    label={t("PaymentType")}
                                    catalogConstant={constants.catalogs.PaymentType}
                                    catalogId={"paymentType"} 
                                    handleCatalogs={this.handleCatalogs}
                                    elementId={customer.paymentInfoModel.paymentType}
                                ></Catalogs>
                                <Catalogs 
                                    {...this.props}
                                    label={t("AccountGroup")}
                                    catalogConstant={constants.catalogs.AccountGroup}
                                    catalogId={"accountGroup"} 
                                    handleCatalogs={this.handleCatalogs}
                                    elementId={customer.paymentInfoModel.accountGroup}
                                ></Catalogs>
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
            </Fragment>
        )
    }

    showInvoiceInfo = () => {
        const { t } = this.props;
        const { customer } = this.state;
        return (
            <Fragment>
                <Typography variant="h6" >{t("InvoiceAddress")}</Typography>
                <Grid container style={{...styles.ElementWithoutPadding, ...{marginBottom: "100px"}}}>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <UpTextField
                                    style={styles.inputElements}
                                    id="address"
                                    label={t("Address")}
                                    value={customer.invoiceAddress.address}
                                    onChange={this.handleChangeInvoiceAddress} />
                                <UpTextField
                                    style={styles.inputElements}
                                    id="addressLine2"
                                    label={t("AddressLine2")}
                                    value={customer.invoiceAddress.addressLine2}
                                    onChange={this.handleChangeInvoiceAddress} />
                                <UpTextField
                                    style={styles.inputElements}
                                    id="postalCode"
                                    label={t("PostalCode")}
                                    value={customer.invoiceAddress.postalCode}
                                    onChange={this.handleChangeInvoiceAddress} />  
                            </CardContent>
                        </Card>
                    </Container>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <UpTextField
                                    style={styles.inputElements}
                                    id="city"
                                    label={t("City")}
                                    value={customer.invoiceAddress.city}
                                    onChange={this.handleChangeInvoiceAddress} />
                                <UpTextField
                                    style={styles.inputElements}
                                    id="country"
                                    label={t("Country")}
                                    value={customer.invoiceAddress.country}
                                    onChange={this.handleChangeInvoiceAddress} />  
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
            </Fragment>
        )
    }


    render() {
        const { t } = this.props;
        const { customer } = this.state;
        return (
            
            <React.Fragment>
                {!this.state.open ? (
                    <Fragment>
                        {this.state.isLoading &&
                            <Spinner open={true}/>
                        }
                        {this.slider()}
                        <BottomBar
                            disabler={!customer.name || customer.name.trim() === ""}
                            title={customer.name}
                            placeholder={t('Unnamed') + " " + t('Customer')}
                            rightButton={{action: this.save, tooltip: t("Save"), icon: <Save />}}
                            leftButton={{action: this.back, tooltip: t("Cancel"), icon: <Cancel/>}}
                        />
                    </Fragment>
                ) :(
                    <Contact {...this.props}
                        selectId={this.state.selectId}
                        handleCloseModify={this.handleCloseContactModify} 
                        handleBackModify={this.handleCloseContactModify} 
                        isAllOptions={true}
                        stayInMainWindowIsAllOptions={true}
                    />
                )
                }
                
            </React.Fragment>
        )
    };
}

function mapStateToProps(state) {
    return {
        notification: state.notifications,
        contact : state.contact,
        contacts : state.contacts,
        customers: state.customers,
        customer: state.customer,
        catalog : state.catalog
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            readCustomer: bindActionCreators(customersActions.readCustomer, dispatch),
            updateCustomer: bindActionCreators(customersActions.updateCustomer, dispatch),
            readContact: bindActionCreators(contactsActions.readContact, dispatch),
            readContacts: bindActionCreators(contactsActions.readContacts, dispatch),
            updateContact: bindActionCreators(contactsActions.updateContact, dispatch),
            notificationSuccess: bindActionCreators(notifications.notificationSuccess, dispatch),
            readCatalog: bindActionCreators(catalogsActions.readCatalog, dispatch),
            updateCatalog: bindActionCreators(catalogsActions.updateCatalog, dispatch),
        
        }
    };
  }

export default compose(withTranslation('common'),
CancelPromisesHOC,
connect(
    mapStateToProps,
    mapDispatchToProps
))(withRouter(Customer));
