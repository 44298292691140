import { USER_LOADED, USER_FOUND, USER_SIGNED_OUT, SESSION_TERMINATED, USER_EXPIRED, LOAD_USER_ERROR } from "redux-oidc";
import { UPDATE_CUSTOM_RESOURCE_ACCESS_SUCCESS } from "../actions/actionTypes";
import initialState from "./initialState";
import { parseToken } from "../../services/accessControl"

export default function resourceAccessReducer(state = initialState.resourceAccess, action) {
    if (action.type === USER_FOUND) {
        const resourceAccess = parseToken(action.payload.access_token).resource_access;
        return { 
            ...resourceAccess,
            custom: state.custom,
            sugarUser: state.sugarUser
        };
    }
    else if (action.type === USER_LOADED) {
        const resourceAccess = parseToken(action.payload.access_token).resource_access;
        return { 
            ...resourceAccess,
            custom: state.custom,
            sugarUser: state.sugarUser
        };
    }
    else if (action.type === UPDATE_CUSTOM_RESOURCE_ACCESS_SUCCESS) {
        return { 
            ...state,
            custom: action.payload.modules,
            sugarUser: action.payload.user
        };
    }
    else if (action.type === USER_EXPIRED) {
        return { 
            ...initialState.resourceAccess,
            custom: state.custom
        };
    }
    else if (action.type === USER_SIGNED_OUT ||
        action.type === SESSION_TERMINATED ||
        action.type === LOAD_USER_ERROR)
    {
        return { 
            ...initialState.resourceAccess,
            custom: state.custom
        };
    }

    return state;
}