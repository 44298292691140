import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import *  as contactsActions from '../../redux/actions/contactsActions';
import *  as customersActions from '../../redux/actions/customersActions';
import { bindActionCreators, compose } from 'redux';
import {
    Card,
    CardContent,
    Container,
    Grid,
    Typography
} from '@material-ui/core';
import UpTextField from '../common/upTextField';
import * as styles from '../common/styles.js';
import "../../index.css";
import Spinner from '../common/CircularSpinner';
import { withTranslation } from 'react-i18next';
import * as constants from "../common/constants";
import initialState from "../../redux/reducers/initialState";
import { Breadcrumb } from "../common/Breadcrumb";
import CancelPromisesHOC   from "../../helpers/cancelPromisesHOC";
import { Cancel } from '@material-ui/icons';
import { Save } from '@material-ui/icons';
import { withRouter } from "react-router-dom";
import { SearchOne } from "../common/SearchOne";
import {ScrollableTabs} from '../common/ScrollableTabs';
import { BottomBar } from "../common/BottomBar";
import *  as catalogsActions from '../../redux/actions/catalogsActions';
import { Catalogs } from "../common/catalogs";

export class Contact  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactId: this.props.selectId ? this.props.selectId : this.props.match.params.contactId,
            isLoading : false,
            contact : initialState.contact,
            Customer: "",
            CustomerId: 0,
            CustomerOriginal: 0,
        }
    }

    async componentDidMount() {
        if (this.props.notNeedsFind) {
            this.setState({
                contact : this.props.contactFound,
            }, function() {
                this.handleOneSelect(this.props.customer, "customerNumber");
            });
        }
        else {
            if (this.state.contactId !== constants.New) {
                if (!this.props.isTesting) {
                    this.setState({
                        isLoading : true,
                    });
                }
            
                await this.props.actions.readContact(this.state.contactId)
                .then(() => {
                })
                .catch(() => { })
                .finally(result => {
                })

                if (parseInt(this.props.contact.customerNumber) !== 0) {
                    this.setState({
                        CustomerOriginal : this.props.contact.customerNumber
                    })
                    await this.props.actions.readCustomer(this.props.contact.customerNumber)
                    .then(() => {
                        this.handleOneSelect(this.props.customer, "customerNumber");
                    })
                    .catch(() => { })
                    .finally(result => {
                        if (!this.state.isTesting) {
                            this.setState({
                                isLoading: false,
                            });
                        }
                    })
                }
                else {
                    if (!this.state.isTesting) {
                        this.setState({
                            isLoading: false,
                        });
                    }
                }
            }
            else if (this.props.isAllOptions) {
                this.handleOneSelect(this.props.customer, "customerNumber");
            }
        }
    }
   
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.contact !== this.props.contact) {
           this.setState({
               contact : this.props.contact,
           });
        }
    }

    handleOneSelect = (row, id) => {
        if (row.id) {
            if (id === "customerNumber") {
                this.setState({
                    Customer: row.name,
                    CustomerId: row.id,
                });
            }
 
            this.handleChange({
                target: {
                    id: id,
                    value: row.id
                }
            });
        }

    }

    cleanFilter = (id) => {
        if (id === "customerNumber") {
            this.setState({
                Customer: "",
                CustomerId: 0,
            });
        }
       
        this.handleChange({
            target: {
                id: id,
                value: 0
            }
        });

    }
    
    handleChange = (event) => {
      
        const { id, value } = event.target;
        this.setState(prevState => ({
            ...prevState,
            contact: {
                ...prevState.contact,
                [id]: value,
            },
        }));

    }

    back = () => {
        if (this.props.isAllOptions) {
            this.props.handleBackModify();
        }
        else {
            var path = "/contacts";

            if (this.props.isTesting) {
                this.setState({
                    backPathTest: path,
                });
            }
    
            this.props.history.push(path);
        }
    }

    save = async () => {
        if (!this.props.isLoading) {
            this.setState({
                isLoading: true,
            });
        }

        var data = {...this.state.contact};
        if (this.state.contactId === constants.New) {
            data.state = constants.Added;
        }
        else {
            data.state = constants.Modified;
        }

        if (this.props.isTesting) {
            this.setState({
                recordTest: data,
            });
        }
        
        if (data.state === constants.Modified) {
            if (parseInt(this.state.CustomerOriginal) !== 0 && parseInt(this.state.CustomerOriginal) !== parseInt(this.state.contact.customerNumber)) {
                this.unLinkCustomer(this.state.CustomerOriginal, data.id);
            }
        }

        var continueBack = true;

        await this.props.actions.updateContact(data)
        .then(() => {
        })
        .catch(() => {
            continueBack = false;
        })
        .finally(() =>{
            this.setState({
                isLoading: false,
            });
        })

        if (continueBack) {
            if (this.props.isAllOptions && !this.props.stayInMainWindowIsAllOptions) {
                this.props.handleCloseModify(data);
            }
            else {
                this.back();
            }
        }
    }

    unLinkCustomer = async (customerId, contactId) => {
        await this.props.actions.readCustomer(customerId)
        .then((result) => {
        })
        .catch(() => { })
        .finally(result => {
        })

        if (this.props.customer && this.props.customer.contactId === contactId) {
            var customerModel = {...this.props.customer};
            customerModel.contactId = constants.EmptyGuid;
            customerModel.state = "Modified";
            await this.props.actions.updateCustomer(customerModel)
            .then(() => {
            })
            .catch(() => { })
            .finally(result => {
            })
        }
    }

    slider = () => {
        const { t } = this.props;
        return (
            <Fragment>
                {this.props.isAllOptions &&
                    <Breadcrumb 
                        title={t("Contact")} 
                        backButton={this.props.isAllOptions ? this.props.isAllOptions : false} 
                        isAllOptions={this.props.isAllOptions}
                    />
                }
                <ScrollableTabs
                    style={this.props.isAllOptions ? {marginTop:'0px'} : undefined}
                    tabs={[
                        {title: t("GeneralInfo"), item: this.generalInfo()},
                    ]}>
                </ScrollableTabs>
            </Fragment>
           
        )
    };

    refreshDetails = (rows) => {
        this.setState(prevState => ({
            ...prevState,
            rows: rows
        }));
    } 

    handleCatalogs = (id, value ) => {
        var event = {
            target : {
                id : id,
                value : value
            }
        }
        this.handleChange(event);

    }


    generalInfo = () => {
        const { t } = this.props;
        const { contact } = this.state;
       
        return (
            <Fragment>
                <Grid container style={styles.ElementWithoutPadding}>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <Grid container >
                                    <Grid style={{ width: "100%" }}>
                                        <UpTextField
                                            style={styles.inputElements}
                                            id="name"
                                            label={t("Name")}
                                            value={contact.name}
                                            onChange={this.handleChange} />
                                    </Grid>
                                    <Grid>
                                        {(!contact.name || contact.name.trim() === "") &&
                                            <Typography id="name-helper-text" style={{ color: "rgba(255, 1, 1, 0.54)" }}>
                                                {t("Required")}
                                            </Typography>
                                        }
                                    </Grid>
                                </Grid>
                                <UpTextField
                                    style={styles.inputElements}
                                    id="email"
                                    label={t("Email")}
                                    value={contact.email}
                                    onChange={this.handleChange} />
                            </CardContent>
                        </Card>
                    </Container>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <UpTextField
                                    style={styles.inputElements}
                                    id="phone"
                                    label={t("Phone")}
                                    value={contact.phone}
                                    onChange={this.handleChange} />
                                <SearchOne
                                    {...this.props}
                                    id="customerId"
                                    label={t("ContactCustomer")}
                                    valueOne={this.state.Customer}
                                    handleOneSelect={(row) => this.handleOneSelect(row, "customerNumber")}
                                    cleanFilter={() => this.cleanFilter("customerNumber")}
                                    typeQuery={constants.customers}
                                    isFullWidth={true}
                                    isReadOnly={this.props.isAllOptions}
                                />
                            </CardContent>
                        </Card>
                    </Container>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <Catalogs
                                    {...this.props}
                                    label={t("ContactTitle")}
                                    catalogConstant={constants.catalogs.Title}
                                    catalogId={"title"} 
                                    handleCatalogs={this.handleCatalogs}
                                    elementId={contact.title}
                                ></Catalogs>
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
            </Fragment>
        )
    }

    render() {
        const { t } = this.props;
        const { contact } = this.state;
        return (
            <React.Fragment>
                {this.state.isLoading &&
                    <Spinner open={true}/>
                }
                {this.slider()}
                <BottomBar
                    disabler={!contact.name || contact.name.trim() === ""}
                    title={contact.name}
                    placeholder={t('Unnamed') + " " + t('Contact')}
                    rightButton={{action: this.save, tooltip: t("Save"), icon: <Save />}}
                    leftButton={{action: this.back, tooltip: t("Cancel"), icon: <Cancel/>}}
                />
            </React.Fragment>
        )
    };
}

function mapStateToProps(state) {
    return {
        notification: state.notifications,
        contact : state.contact,
        customers: state.customers,
        customer: state.customer,
        catalog : state.catalog
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            readContact: bindActionCreators(contactsActions.readContact, dispatch),
            updateContact: bindActionCreators(contactsActions.updateContact, dispatch),
            readCustomers: bindActionCreators(customersActions.readCustomers, dispatch),
            readCustomer: bindActionCreators(customersActions.readCustomer, dispatch),
            readCatalog: bindActionCreators(catalogsActions.readCatalog, dispatch),
            updateCatalog: bindActionCreators(catalogsActions.updateCatalog, dispatch),
            updateCustomer: bindActionCreators(customersActions.updateCustomer, dispatch),
            
        }
    };
  }

export default compose(withTranslation('common'),
CancelPromisesHOC,
connect(
    mapStateToProps,
    mapDispatchToProps
))(withRouter(Contact));
