import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function blobProgressReducer(state = initialState.blobProgress , action) {
    if (action.type === types.UPDATE_BLOB_PROGRESS)
    { 
        return action.blobProgress;
    }
    else {
        return state;
    }
}
