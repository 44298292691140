import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function workCardReducer(state = initialState.workCard , action) {
    if (action.type === types.READ_WORKCARD_SUCCESS)
    {
        return action.workCard
    }
    else {
        return state;
    }
}
