import React, { Component, Fragment } from 'react';
import {
    Dialog,
    Slide,
} from '@material-ui/core';
import "../../../index.css";
import { withTranslation } from 'react-i18next';
import * as constants from "../constants";
import FilterHelper from "../../../helpers/filterHelper"; 
import { ContactList } from "./contactsList";

export class ContactsWorkSiteList  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows : this.props.rows,
            open : false,
            scrollLeft : 0,
            scrollTop : 0,
            filterOptions : [],
            isRowStyle: false,
            counterBasket: 0,
            show: true
        }
    }

    componentDidMount() {
        this.setFilter();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.rows !== this.props.rows) {
            this.setState({
                rows: this.props.rows,
            }, function() {
                this.loadRecords();
            });
        }
    }

    setFilter = () => {
        this.setState({
            idSave : this.props.idSave,
        });

        var options = [
            FilterHelper.createFilterOption("name", true, true, 3, constants.textAlign.left, true, null, "Name"),
            FilterHelper.createFilterOption("email", true, true, 2, constants.textAlign.left, true, null, "Email"),
            FilterHelper.createFilterOption("phone", true, true, 2, constants.textAlign.left, true, null, "Phone"),
            FilterHelper.createFilterOption("customer", true, true, 3, constants.textAlign.left, false),
            FilterHelper.createFilterOption("title", true, true, 3, constants.textAlign.left, false),
        ];

        var id = this.props.idSave !== undefined ? this.props.idSave : constants.contactsWorkSiteFilter;
        
        var filterOptions = FilterHelper.setFilter( id, options, false);
        
        this.setState({
            filterOptions : filterOptions.filter,
            isRowStyle : filterOptions.isRowStyle
        })
    }

    showFilterFields = (row, rowFilter) => {
        return (
            <Fragment>
                {rowFilter.id === "name" &&
                    row.name
                }
                {rowFilter.id === "email" &&
                    row.email
                }
                {rowFilter.id === "phone" &&
                    row.phone
                }
                {rowFilter.id === "customer" &&
                    row.customer
                }
                {rowFilter.id === "title" &&
                    row.title
                }
            </Fragment>
        )
    }

    handleAdd = () => {
        this.setState({
            scrollLeft : window.pageXOffset,
            scrollTop : window.pageYOffset,
            open: true,
        });
    }

    handleClose = () => {
        this.props.cancelPromises();
        
        this.setState({
            open: false,
        }, function() {
            window.scrollTo(this.state.scrollLeft, this.state.scrollTop);
        });
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
   
    refreshDetails = (data) => {
        this.setState(prevState => ({
            ...prevState,
            rows: data,
        }));
    
        this.props.refreshDetails(data);
    } 

    fieldSorter = (fields) => (a, b) => fields.map(o => {
        let dir = 1;
        if (o[0] === '-') { dir = -1; o=o.substring(1); }
        return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
    }).reduce((p, n) => p ? p : n, 0);

    sortAscedent = (data) => {
        var temp = [];
        for (var x = data.length - 1; 0 <= x; x--) {
          temp.push(data[x]);
        }
        return temp;
    }

    loadRecords = () => {

        var data = FilterHelper.getfilterOptions(constants.contactsWorkSiteFilter, this.state.isRowStyle);
        this.setState({
            filterOptions : data.filter,
            isRowStyle: data.isRowStyle
        })

        var rows = [...this.state.rows];
        
        var sortData = data.sort.split(" "); 
        var sortField = [];
        sortField.push(sortData[0]);
        rows = rows.sort(this.fieldSorter(sortField));
        
        if (sortData[1] === "DESC") {
            rows = this.sortAscedent(rows);
        }

        this.setState({
            rows : rows
        });
    }

    setCounterBasket = (counter) => {
        this.setState({
            counterBasket : counter
        })
    }

    showBasket = (show) => {
        this.setState({
            show : show
        })
    }
    
    dialogQuery = () => {
        return (
            <Dialog fullScreen open={this.state.open} onClose={this.handleClose} TransitionComponent={this.Transition}>
                <div style={{paddingLeft: "32px", paddingRight: "32px"}}>
                    <ContactList 
                        {...this.props}
                        addButton={false}
                        backButton={true}
                        onlySelect={true}
                        handleClose={this.handleClose}
                        builderList={this.state.rows}
                        isToUp={true}
                        isModalDialog={true}
                        refreshDetails={this.refreshDetails} 
                        multiSelect={true}
                        searchWithFilter={false}
                        hideSearch={true}
                        dataRowCaptureIdList={constants.contacts}
                        showtitle={true}
                    />
                </div>
            </Dialog>
        )
    }

    render() {
        return (
            <Fragment>
                <ContactList  
                    {...this.props} 
                    title={""} 
                    editButton={false} 
                    onlySelect={false} 
                    backButton={false} 
                    isArray={true} 
                    loadRecords={this.loadRecords} 
                    builderList={this.state.rows} 
                    handleAdd={this.handleAdd}  
                    refreshDetails={this.refreshDetails}  
                    idSave={constants.contactsWorkSiteFilter} 
                    hideFilters={true}
                    dataRowCaptureIdList={constants.ContactsWorkSiteList}
                    filterOptions={this.state.filterOptions}
                    isRowStyle={this.state.isRowStyle}
                    showFilterFields={this.showFilterFields}
                    addButton={this.props.addButton}
                    notExpanded={this.props.notExpanded}
                /> 
                { this.state.open &&
                    this.dialogQuery() 
                } 
            </Fragment>
            
        )
    };
}

export default withTranslation('common')(ContactsWorkSiteList);
