import React, { Component } from 'react';
import { WorkOrderList } from "../common/Lists/workOrderList";
import { connect } from 'react-redux';
import *  as workOrderActions from '../../redux/actions/workOrderActions';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import CancelPromisesHOC   from "../../helpers/cancelPromisesHOC";
import { withRouter } from "react-router-dom";
import *  as navigationActions from '../../redux/actions/navigationActions';
import {setCurrentModule} from "../../utils/navigationUtil";


export class WorkOrders  extends Component {    
    componentDidMount(){
        this.props.actions.setModule(setCurrentModule('/workOrders'));
    }
    render() {
        return (
            <WorkOrderList {...this.props} />
        )
    };
}

function mapStateToProps(state) {
    return {
        workOrders : state.workOrders,
        moduleInfo: state.navigation.module
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            readWorkOrders: bindActionCreators(workOrderActions.readWorkOrders, dispatch),
            updateWorkOrder: bindActionCreators(workOrderActions.updateWorkOrder, dispatch),
            setModule: bindActionCreators(navigationActions.setModule, dispatch)
        }
    };
  }

export default compose(withTranslation('common'),
CancelPromisesHOC,
connect(
    mapStateToProps,
    mapDispatchToProps,
))(withRouter(WorkOrders));