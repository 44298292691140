import { handleResponse, handleError, getApiUrl, serviceKeys } from "./apiUtils";
import getRequestOptions from './authorizationUtils';
import axios from 'axios';

export function readTenant(tenantId) {
    const options = getRequestOptions();
    return axios.get(getApiUrl(serviceKeys.tenants) + `/api/tenants/${tenantId}`, options)
        .then(handleResponse)
        .catch(handleError);
}
export function getAllAvailableTenants() {
    const options = getRequestOptions();
    return axios.get(getApiUrl(serviceKeys.tenants) + `/api/tenants/`, options)
        .then(handleResponse)
        .catch(handleError);
}


