import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from './authorizationUtils';
import axios from 'axios';

// azure functions can not handle ambigous routes
// we need explicit defined those

export function ReadProductUnits(filter, sort, page, cancelToken) {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.products) + `/api/Aggregate/ProductUnit/GetAll?filter=${filter}&sort=${sort}&page=${page}`, options)
        .then(handleResponse)
        .catch(handleError);
}

export function ReadProductUnitsBatch(batch, cancelToken) {
    const options = getRequestOptions(cancelToken);
    return axios.post(getApiUrl(serviceKeys.products) + `/api/Aggregate/ProductUnit/GetBatch`, batch, options)
        .then(handleResponse)
        .catch(handleError);
}

export function ReadProductUnit(id) {
    const options = getRequestOptions();
    return axios.get(getApiUrl(serviceKeys.products) + `/api/Aggregate/ProductUnit/Get/${id}`, options)
        .then(handleResponse)
        .catch(handleError);
}

export function updateProductUnit(productGroup) {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.products) + `/api/Aggregate/ProductUnit/POST`, productGroup, options)
        .then(handleResponse)
        .catch(handleError);
}
