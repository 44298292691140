import React, { Component, Fragment } from 'react';
import "../../../index.css";
import { withTranslation } from 'react-i18next';
import * as constants from "../constants";
import FilterHelper from "../../../helpers/filterHelper"; 
import { SearchList } from "../Lists/searchList";
import axios from 'axios';
import Spinner from './../CircularSpinner';
import StatefulSessionHelper from "../../../helpers/statefulSessionHelper";

var isMounted = false;

export class ProductUnitsList  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows : [],
            filterOptions : [],
            builderList : [],
            isLoading : false,
            isRowStyle : false
        }
    }

    componentWillUnmount() {
        isMounted = false;
        this.props.cancelPromises();
    }

    componentDidMount() {
        this.setFilter();
        var searchValue = StatefulSessionHelper.getSearchValue(constants.productUnitFilter + this.props.user.profile.sub);
        if (searchValue === null) {
            searchValue = "";
        }

        this.setState({
            filter : searchValue
        }, function() {
            this.reloadData();
        })
      
    }

    reloadData = () => {
        var data = FilterHelper.getfilterOptions(constants.productUnitFilter);
        this.loadRecords(this.state.filter, data.sort,1);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.productUnits !== this.props.productUnits) {
            this.setState({
                rows : this.props.productUnits,
            });
        }

    }
    
    setFilter = () => {
        this.setState({
            idSave : this.props.idSave,
        });

        var options = [
            FilterHelper.createFilterOption("itemCode", true, true, 2),
            FilterHelper.createFilterOption("name", true, false, 8),
        ];

        var id = this.props.idSave !== undefined ? this.props.idSave : constants.productUnitFilter;
        
        var filterOptions = FilterHelper.setFilter( id, options, false);
        this.setState({
            filterOptions : filterOptions.filter,
            isRowStyle : filterOptions.isRowStyle
        })
    }

    showFilterFields = (row, rowFilter) => {
        return (
            <Fragment>
                {rowFilter.id === "itemCode" &&
                    row.itemCode
                }
                {rowFilter.id === "name" &&
                    row.name
                }
            </Fragment>
        )
    }

    loadRecords = async (filter, sort, page) => {
        isMounted = true;

        this.props.cancelPromises();

        var isLoaded = false;
        if (!this.props.isArray) {

            
            if (!this.props.isLoading) {
                this.setState({
                    isLoading : true
                })
            }

            var data = FilterHelper.getfilterOptions(constants.productUnitFilter);
            this.setState({
                filterOptions : data.filter,
                isRowStyle: data.isRowStyle 
            })

            const cancelToken = axios.CancelToken.source();

            await this.props.cancellablePromise(
               this.props.actions.readProductUnits(filter, data.sort, page, cancelToken.token), cancelToken
            )
            .then(() => {
                isLoaded = true;
            })
            .catch((error) => {
                if (error.message === constants.cancelHttpRequest || error.message === "[object Object]") {
                    isMounted = false;
                }
            })
            .finally(result =>{
                if (isMounted) {
                    this.setState({
                        isLoading : false
                    })
                }
            })
        }
        else {
            this.props.loadRecords(filter, sort);
        }

        return isLoaded;
    }
    
    handleDeleteRow = async (record) => {
        if (!this.props.isLoading) {
            this.setState({
                isLoading : true
            })
        }

        record.state = constants.Deleted;
        var data = {...record};

        if (this.props.isTesting) {
            this.setState({
                recordTest : data
            });
        }
      
        var isDeleted = false;
        await this.props.actions.updateProductUnit(data)
        .then(() => {
            isDeleted = true;
        })
        .catch(() => {})
        .finally(() =>{
            this.setState({
                isLoading : false
            })
        })
        return isDeleted;
    }

    handleJsonFile = async () => {

    }

    render() {
        const { t } = this.props;
        return (
            <Fragment>
                {this.state.isLoadingMassive &&
                    <Spinner open={true}/>
                }
                <SearchList 
                    {...this.props}
                    rows={this.state.rows}
                    showFilterFields={this.showFilterFields}
                    filterOptions={this.state.filterOptions}
                    isRowStyle={this.state.isRowStyle}
                    handleDeleteRow={this.handleDeleteRow}
                    loadRecords={this.loadRecords}
                    handleJsonFile={this.handleJsonFile}
                    
                    backButton={this.props.backButton !== undefined ? this.props.backButton : false}
                    onlySelect={this.props.onlySelect !== undefined ? this.props.onlySelect :  false}
                    addButton={this.props.addButton !== undefined ? this.props.addButton : true}
                    deleteButton={this.props.deleteButton !== undefined ? this.props.deleteButton  : true}
                    editButton={this.props.editButton !== undefined ? this.props.editButton : true}
                    isToUp={this.props.isToUp !== undefined ? this.props.isToUp : true}
                    title={t(this.props.title !== undefined ? this.props.title : "ProductUnits")} 
                    pathBackFromAddEdit={this.props.pathBackFromAddEdit !== undefined ? this.props.pathBackFromAddEdit : "/productUnitsList/"}
                    pathAddEdit={this.props.pathAddEdit !== undefined ? this.props.pathAddEdit : "/productUnits/"}
                    isLoading={this.state.isLoading}
                    handleOneSelect={this.props.handleOneSelect}
                    handleSearchOneSelect={this.props.handleSearchOneSelect}
                    multiSelect={this.props.multiSelect}
                
                    isArray={this.props.isArray}
                    handleAdd={this.props.handleAdd}
                    refreshDetails={this.props.refreshDetails}
                    builderList={this.state.builderList}
                    idSave={this.props.idSave !== undefined ? this.props.idSave : constants.productUnitFilter  }
                    handleCloseProduct={this.props.handleCloseProduct}
                    history={this.props.history}


                />
            </Fragment>    
        )
    };
}

export default withTranslation('common')(ProductUnitsList);
