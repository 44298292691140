import { LOAD_LOGGED_IN_SUGAR_INSTANCES_SUCCESS, SUGAR_LOGIN_SUCCESS, SUGAR_LOGOUT_SUCCESS } from "./actionTypes";

const LOGGED_IN_SUGAR_INSTANCES_KEY = 'loggedInSugarInstancesPersistenceKey';

function getLoggedInInstances() {
    const str = window.localStorage.getItem(LOGGED_IN_SUGAR_INSTANCES_KEY);
    if (!!str) {
        return JSON.parse(str);
    } else {
        return [];
    }
}

function persistLoggedInInstances(loggedInInstances) {
    window.localStorage.setItem(LOGGED_IN_SUGAR_INSTANCES_KEY, JSON.stringify(loggedInInstances));
}

export function loadloggedInInstances() {
    return function (dispatch) {
        const loggedInInstances = getLoggedInInstances();
        const action = { type: LOAD_LOGGED_IN_SUGAR_INSTANCES_SUCCESS, loggedInInstances };
        dispatch(action);
        return action;
    };
}

export function sugarLoginSuccess(sugarHost) {
    return function (dispatch) {
        const loggedInInstances = getLoggedInInstances();
        if (loggedInInstances.includes(sugarHost)) {
            return;
        }

        loggedInInstances.push(sugarHost);
        persistLoggedInInstances(loggedInInstances);

        const action = { type: SUGAR_LOGIN_SUCCESS, loggedInInstances };
        dispatch(action);
        return action;
    };
}

export function sugarLogoutSuccess(sugarHost) {
    return function (dispatch) {
        const loggedInInstances = getLoggedInInstances();
        if (!loggedInInstances.includes(sugarHost)) {
            return;
        }

        var index = loggedInInstances.indexOf(sugarHost);
        if (index !== -1) {
            loggedInInstances.splice(index, 1);
            persistLoggedInInstances(loggedInInstances);
        }

        const action = { type: SUGAR_LOGOUT_SUCCESS, loggedInInstances };
        dispatch(action);
        return action;
    };
}

