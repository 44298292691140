import * as React from "react";
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute } from "./privateRoute";

import { Callback } from "../components/auth/callback";
import { Logout } from "../components/auth/logout";
import { LogoutCallback } from "../components/auth/logoutCallback";
import { SilentRenew } from "../components/auth/silentRenew";

import WorkCard from '../components/workCards/workCard';
import WorkCardsList from '../components/workCards/workCards';
import ProductsRegister from '../components/productsRegister/productsRegister';
import ProductRegister from '../components/productsRegister/productRegister';
import ProductGroups from '../components/productGroup/productGroups';
import ProductGroup from '../components/productGroup/productGroup';
import ProductUnits from '../components/productUnit/productUnits';
import ProductUnit from '../components/productUnit/productUnit';
import RoutedLegacyIFrame from '../components/legacyIFrame/routedLegacyIFrame';
import SugarLogout from '../components/legacyIFrame/sugarLogout';
import Contacts from "../components/contacts/contacts";
import Contact from "../components/contacts/contact";
import Customers from "../components/customers/customers";
import Customer from "../components/customers/customer";
import WorkSites from "../components/workSite/workSites";
import WorkSite from "../components/workSite/workSite";
import WorkOrders from "../components/workOrder/workOrders";
import WorkOrder from "../components/workOrder/workOrder";
import { LegacyIFrame } from "../components/legacyIFrame/legacyIFrame";
import WorkQueue from "../components/workQueue/workQueue";
import WorkQueuesSettings from "../components/workQueue/WorkQueuesSettings";
import UsersManagement from "../components/userManagement/usersManagement";
import UserManagement from "../components/userManagement/userManagement";
import Roles from"../components/roles/roles";
import Role from"../components/roles/role";

export const routes = (
    <Switch>
        <Route exact={true} path="/signin-oidc" component={Callback} />
        <Route exact={true} path="/logout" component={Logout} />
        <Route exact={true} path="/logout/callback" component={LogoutCallback} />
        <Route exact={true} path="/silentrenew" component={SilentRenew} />

        <Route exact={true} path="/yerp/logout" component={SugarLogout} />

        <PrivateRoute exact={true} path='/' component={RoutedLegacyIFrame} />

        <PrivateRoute exact={false} path='/yerp' component={RoutedLegacyIFrame} />
        <PrivateRoute exact={false} path='/yerp/:location' component={RoutedLegacyIFrame} />

        <PrivateRoute exact={true} path='/workcards' component={WorkCardsList} />
        <PrivateRoute exact={true} path='/workcards/:workCardId' component={WorkCard} />
   
        <PrivateRoute exact={true} path='/productsRegister' component={ProductsRegister} />
        <PrivateRoute exact={true} path='/productsRegister/:productId' component={ProductRegister} />

        <PrivateRoute exact={true} path='/productGroups' component={ProductGroups} />
        <PrivateRoute exact={true} path='/productGroups/:productGroupId' component={ProductGroup} />

        <PrivateRoute exact={true} path='/productUnits' component={ProductUnits} />
        <PrivateRoute exact={true} path='/productUnits/:productUnitId' component={ProductUnit} />

        <PrivateRoute exact={true} path='/contacts/:contactId' component={Contact} />
        <PrivateRoute exact={true} path='/contacts' component={Contacts} />
        
        <PrivateRoute exact={true} path='/customers/:customerNumber' component={Customer} />
        <PrivateRoute exact={true} path='/customers' component={Customers} />

        <PrivateRoute exact={true} path='/worksites' component={WorkSites} />
        <PrivateRoute exact={true} path='/worksites/:workSiteId' component={WorkSite} />
        
        <PrivateRoute exact={true} path='/workOrders' component={WorkOrders} />
        <PrivateRoute exact={true} path='/workOrders/:workOrderId' component={WorkOrder} />

        <PrivateRoute exact={true} path='/workQueuesSettings' component={WorkQueuesSettings} />
        <PrivateRoute exact={true} path='/workQueuesSettings/:workQueueSettingsId' component={WorkQueue} />
      
        <PrivateRoute exact={false} path='/yerp/:location' component={LegacyIFrame} />
        
        <PrivateRoute exact={true} path='/usersManagement' component={UsersManagement} />
        <PrivateRoute exact={true} path='/usersManagement/:userId' component={UserManagement} />
       
        <PrivateRoute exact={true} path='/roles' component={Roles} />
        <PrivateRoute exact={true} path='/roles/:roleId' component={Role} />
       

    </Switch>
);

