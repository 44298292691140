import React, { Component, Fragment } from "react";
import { TextField } from '@material-ui/core';
import { withTranslation   } from 'react-i18next';
import * as styles from './styles';
import { InputAdornment } from '@material-ui/core';


const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;


export class UpTextField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            disabled : this.props.disabled,
            id : this.props.id,
            label : this.props.label,
        };
    }

    timer = {};
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.setState({ value : this.props.value});
        }
        if (prevProps.label !== this.props.label) {
            this.setState({ label : this.props.label});
        }
    }

    componentDidMount() {
        this.timer = null;
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    handleChange = (e) => {
        clearTimeout(this.timer);
        
        this.setState({ 
            value : e.target.value,
            event : {
                target : {
                    id : e.target.id,
                    value : e.target.value,
                    dataset: {
                        record: this.props.inputProps ? this.props.inputProps["data-record"] : ''
                    }
                }
            }
        });
        
        this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
    }

    handleKeyDown = (e) => {
        if (e.keyCode === ENTER_KEY) {
            //this.triggerChange();
        }
    }

    triggerChange = () => {
        const { event } = this.state;
      
        this.props.onChange(event);
    }

    onClick = () => {
        if (this.props.handleSearch) {
            this.props.handleSearch();
        }
        
        if (this.props.showBasket) {
            this.props.showBasket(false);
        }
    }

    render() {
        return (
             <Fragment>
                <TextField
                    disabled={this.props.disabled}
                    style={styles.inputElements}
                    multiline
                    id={this.state.id}
                    label={this.state.label}
                    value={this.state.value}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    onClick={this.onClick}
                    InputProps={
                        {
                            readOnly: this.props.readOnly,
                            startAdornment: 
                                <InputAdornment>
                                    { (this.props.isAdorment) ? (
                                        this.props.Adorment 
                                      ) : (
                                          <Fragment></Fragment>
                                      )
                                    }
                                </InputAdornment>,
                        }
                    }
                />
                {this.props.AdormentRight && this.state.value && this.state.value.trim() !== "" &&
                    <span style={{...styles.endAdormentIput}} >
                    {
                        this.props.AdormentRight 
                    }
                    </span>    
                }
             </Fragment>
             
         );
     }
}

export default withTranslation('common')(UpTextField);
