/* /src/components/auth/logout.js */
import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import BackDrop from '../Backdrop/Backdrop';
import * as sessionActions from '../../redux/actions/sessionActions';

export class LogoutPage extends React.Component {
    componentDidMount() {
        this.props.actions.sessionDeactivated();
        this.props.actions.logout();
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        localStorage.removeItem('tenant_id');
        sessionStorage.removeItem('tenant_id');

        return (
            <div>
                Signing out...
                 <BackDrop />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            sessionDeactivated: bindActionCreators(sessionActions.sessionDeactivated, dispatch),
            logout: bindActionCreators(sessionActions.logout, dispatch),
        }
    };
}

export const Logout = connect(mapStateToProps, mapDispatchToProps)(LogoutPage);
