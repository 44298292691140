/* /src/components/auth/logoutCallback.js */
import React from 'react';
import { connect } from "react-redux";
import BackDrop from '../Backdrop/Backdrop';
import { SignoutCallbackComponent } from "redux-oidc";
import userManager from "../../services/userManager";
import { bindActionCreators } from 'redux';
import *  as sessionActions from '../../redux/actions/sessionActions';

export class LogoutCallbackPage extends React.Component {
    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        return (
            <SignoutCallbackComponent
                userManager={userManager}
                successCallback={() => {
                    this.props.history.push("/yerp/logout");
                }}
                errorCallback={error => {
                    this.props.history.push("/error");
                }}>
                <BackDrop />
            </SignoutCallbackComponent>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            logoutSso: bindActionCreators(sessionActions.logoutSso, dispatch),
        }
    };
}

export const LogoutCallback = connect(mapStateToProps, mapDispatchToProps)(LogoutCallbackPage);