import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from './authorizationUtils';
import axios from 'axios';

// azure functions can not handle ambigous routes
// we need explicit defined those

export function ReadWorkOrderDiaryEntries(sort, page, workOrderId, cancelToken) {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.products) + 
        `/api/Aggregate/WorkOrderDiaryEntry/GetAll?sort=${sort}&page=${page}&workOrderId=${workOrderId}`, options)
        .then(handleResponse)
        .catch(handleError);
}

export function ReadWorkOrderDiaryEntry(id) {
    const options = getRequestOptions();
    return axios.get(getApiUrl(serviceKeys.products) + `/api/Aggregate/WorkOrderDiaryEntry/Get/${id}`, options)
        .then(handleResponse)
        .catch(handleError);
}

export function updateWorkOrderDiaryEntry(diaryentry,cancelToken) {
    const options = getRequestOptions(cancelToken);
    return axios.post(getApiUrl(serviceKeys.products) + `/api/Aggregate/WorkOrderDiaryEntry/POST`, diaryentry, options)
        .then(handleResponse)
        .catch(handleError);
}
