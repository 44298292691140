import React, { Component } from 'react';
import { NumberField  } from "./NumberField";
import { InputAdornment } from '@material-ui/core';
import * as constants from "./constants";
import * as locales from "../../i18n/locales";

export class MoneyField  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang : "",
            symbol : "€",
            start : true
        }
    }

    componentDidMount(prevProps, prevState, snapshot) {
        this.setState({
            symbol : locales.LocaleCurrencySymbol[this.props.lang],
            start : locales.PositionLocaleCurrencySymbol[this.props.lang] === constants.textAlign.left ? true : false
        })
    }

    render() {
    
        return (
            <NumberField
                disabled={this.props.disabled}
                id={this.props.id}
                label={this.props.label}
                value={this.props.value}
                onChange={this.props.onChange}
                lang={this.props.lang}
                InputProps={{
                    startAdornment: 
                    <InputAdornment position="start">
                         {this.state.start &&
                            this.state.symbol
                         }
                    </InputAdornment>,
                }}
                AdormentRight={!this.state.start && this.state.symbol}
            />
        )
    };
}

export default (MoneyField);
