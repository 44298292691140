import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { WorkSiteList } from "../Lists/workSiteList";

export class SearchOneWorkSite extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }
    
    render() {
        return (
            <WorkSiteList 
                {...this.props}
                addButton={false}
                backButton={true}
                onlySelect={true}
                handleClose={this.props.handleClose}
                isToUp={true}
                isModalDialog={true}
                handleSearchOneSelect={this.props.handleSearchOneSelect}
                multiSelect={false}
                filterId={this.props.filterId}
                filterName={this.props.filterName}
            
            />
        )
    };
}

export default withTranslation('common')(SearchOneWorkSite);
