import * as types from "./actionTypes";
import * as workCardApi from "../../api/workCardApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";

export function readWorkCardSuccess(workCard) {
    return { type: types.READ_WORKCARD_SUCCESS, workCard };
}

export function readWorkCard(workCardId) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return workCardApi
        .readWorkCard(workCardId)  
        .then(workCard => {
            dispatch(readWorkCardSuccess(workCard.value));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorloadingworkCard"));
            throw new Error(error);
        });
    };
} 

export function createWorkCardSuccess(workCard) {
    return { type: types.CREATE_WORKCARD_SUCCESS, workCard };
}

export function createWorkCard(workCard) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return workCardApi
        .createWorkCard(workCard)  
        .then(workCard => {
            dispatch(createWorkCardSuccess(workCard));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorsavingworkCard"));
            throw new Error(error);
        });
    };
}

export function updateWorkCardSuccess(workCard) {
    return { type: types.UPDATE_WORKCARD_SUCCESS, workCard };
}

export function updateWorkCard(workCardData) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return workCardApi
        .updateWorkCard(workCardData)  
        .then(workCard => {
            dispatch(updateWorkCardSuccess(workCard));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorupdatingworkCard"));
            throw new Error(error);
        });
    };
} 

export function deleteWorkCardSuccess() {
    return { type: types.DELETE_WORKCARD_SUCCESS };
}

export function deleteWorkCard(workCardId) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return workCardApi
        .deleteWorkCard(workCardId)  
        .then(workCard => {
            dispatch(deleteWorkCardSuccess());
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorloadingworkCard"));
            throw new Error(error);
        });
    };
} 

export function newWorkCardSuccess() {
    return { type: types.NEW_WORKCARD_SUCCESS };
}


export function newWorkCard() {
    return function(dispatch) {
        dispatch(newWorkCardSuccess());
    }
}

