import React from "react";
import Layout from './components/layout';
import { routes } from "./routes/routes";

// TODO separate theming to a component of it's own
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import AutoTenantSelector from './components/tenants/autoTenantSelector';
import AutoLanguageSelector from './components/localization/autoLanguageSelector';
// Realtime initialize signalr service, not remove this services line
//import Realtime from "./services/realTime"; // eslint-disable-line no-unused-vars

const theme = createMuiTheme({
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Open Sans"',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
      },
    palette: {
        primary: {
            light: '#f38a59',
            main: '#f16d30',
            dark: '#a84c21',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ce467b',
            main: '#000',
            dark: '#87103f',
            contrastText: '#000',
        },
    },
});

export class App extends React.Component {

    render = () => {
        return (
            <MuiThemeProvider theme={theme}>
                <Layout children={routes} />
                <AutoTenantSelector />
                <AutoLanguageSelector />
            </MuiThemeProvider>
        );
    }
}

export default App;