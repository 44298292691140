import React, { Component } from 'react';
import { connect } from 'react-redux';
import *  as productUnitActions from '../../redux/actions/productUnitActions';
import { bindActionCreators, compose } from 'redux';
import {
    Card,
    CardContent,
    Container,
    Grid,
    Typography
} from '@material-ui/core';
import UpTextField from '../common/upTextField';
import * as styles from '../common/styles.js';
import "../../index.css";
import Spinner from '../common/CircularSpinner';
import { withTranslation } from 'react-i18next';
import * as constants from "../common/constants";
import initialState from "../../redux/reducers/initialState";
import { Breadcrumb } from "../common/Breadcrumb";
import CancelPromisesHOC   from "../../helpers/cancelPromisesHOC";
import { Cancel } from '@material-ui/icons';
import { Save } from '@material-ui/icons';
import { withRouter } from "react-router-dom";
import {ScrollableTabs} from '../common/ScrollableTabs';
import { BottomBar } from "../common/BottomBar";

export class ProductUnit  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productId: this.props.match.params.productUnitId,
            isLoading : false,
            product : initialState.productUnit,
        }
    }

    componentDidMount() {
        if (this.state.productId !== constants.New) {
            if (!this.props.isTesting) {
                this.setState({
                    isLoading : true,
                });
            }
         
            this.props.actions.readProductUnit(this.state.productId)
            .then(() => {
            })
            .catch(() => {})
            .finally(result =>{
                if (!this.state.isTesting) {
                    this.setState({
                        isLoading : false,
                    });
                }
            })
        }
    }
   
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.product !== this.props.product) {
           this.setState({
               product : this.props.product,
           });
        }
    }
    
    handleChange = (event) => {
      
        const { id, value } = event.target;
        
        this.setState(prevState => ({
            ...prevState,
            product: {
                ...prevState.product,
                [id]: value,
            },
        }));

    }

    back = () => {
        var path = "/productUnits";

        if (this.props.isTesting) {
            this.setState({
                backPathTest: path,
            });
        }

        this.props.history.push(path);
    }

    save = async () => {
        if (!this.props.isLoading) {
            this.setState({
                isLoading: true,
            });
        }

      

        var record = {...this.state.product};
        record.id = record.itemCode;
        if (this.state.productId === constants.New) {
            record.state = constants.Added;
        }
        else {
            record.state = constants.Modified;
        }

        var data = {...record};
       
        if (this.props.isTesting) {
            this.setState({
                recordTest: data,
            });
        }

        var continueBack = true;
        await this.props.actions.updateProductUnit(data)
        .then(() => {
        })
        .catch(() => {
            continueBack = false;
        })
        .finally(() =>{
            this.setState({
                isLoading: false,
            });
        })

        if (continueBack) {
            this.back();
        }
    }

    refreshDetails = (rows) => {
        this.setState(prevState => ({
            ...prevState,
            rows: rows
        }));
    } 

    generalInfo = () => {
        const { t } = this.props;
        const { product } = this.state;
        return (
            <Grid container style={styles.ElementWithoutPadding}>
                <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                    <Card style={styles.CardWithoutBoxes}>
                        <CardContent style={styles.ElementWithoutPadding}>
                            <Grid container >
                                <Grid style={{ width: "100%" }}>
                                    <UpTextField
                                    disabled={this.state.productId !== constants.New}
                                    style={styles.inputElements}
                                    id="itemCode"
                                    label={t("ItemCode")}
                                    value={product.itemCode}
                                    onChange={this.handleChange} />
                                </Grid>
                                <Grid>
                                    {(!product.itemCode || product.itemCode.trim() === "") &&
                                        <Typography id="itemCode-helper-text" style={{ color: "rgba(255, 1, 1, 0.54)" }}>
                                            {t("RequireItemCode")}
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                            <UpTextField
                                style={styles.inputElements}
                                id="name"
                                label={t("Name")}
                                value={product.name}
                                onChange={this.handleChange} />
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
        )
    }

    slider = () => {
        const { t } = this.props;
       
        return (
            <ScrollableTabs
                handleChange={this.handleChangeIndex}
                index={this.state.index}
                tabs={[{title: t("GeneralInfo"), item: this.generalInfo()}]}>
            </ScrollableTabs>
        )
    };

    render() {
        const { t } = this.props;
        const { product } = this.state;
        return (
            
            <React.Fragment>
                {this.state.isLoading &&
                    <Spinner open={true}/>
                }
                <Breadcrumb 
                    title={t("ProductUnit")} 
                    backButton={false} 
                />
                {this.slider()}
                <BottomBar
                    disabler={!product.name || product.name.trim() === ""}
                    title={product.name}
                    placeholder={t('Unnamed') + " " + t('ProductUnit')}
                    rightButton={{action: this.save, tooltip: t("Save"), icon: <Save />}}
                    leftButton={{action: this.back, tooltip: t("Cancel"), icon: <Cancel/>}}
                />

            </React.Fragment>
        )
    };
}

function mapStateToProps(state) {
    return {
        notification: state.notifications,
        product : state.productUnit,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            readProductUnit: bindActionCreators(productUnitActions.readProductUnit, dispatch),
            updateProductUnit: bindActionCreators(productUnitActions.updateProductUnit, dispatch),
        }
    };
  }

export default compose(withTranslation('common'),
CancelPromisesHOC,
connect(
    mapStateToProps,
    mapDispatchToProps
))(withRouter(ProductUnit));
