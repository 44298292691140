import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from './authorizationUtils';
import axios from 'axios';

// azure functions can not handle ambigous routes
// we need explicit defined those

export function ReadWorkQueuesSettings(filter, sort, page, userId, cancelToken) {
    const options = getRequestOptions(cancelToken);
    
    return axios.get(getApiUrl(serviceKeys.products) + 
        `/api/Aggregate/WorkQueueSettings/GetAll?filter=${filter}&sort=${sort}&page=${page}&userId=${userId}`, options)
        .then(handleResponse)
        .catch(handleError);
}

export function ReadWorkQueueSettings(id) {
    const options = getRequestOptions();
    return axios.get(getApiUrl(serviceKeys.products) + `/api/Aggregate/WorkQueueSettings/Get/${id}`, options)
        .then(handleResponse)
        .catch(handleError);
}

export function updateWorkQueueSettings(model) {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.products) + `/api/Aggregate/WorkQueueSettings/POST`, model, options)
        .then(handleResponse)
        .catch(handleError);
}



