import React from 'react';
import SideBarToggle from './SideBarToggle';
import Typography from '@material-ui/core/Typography';
import './TopNavBar.css';
import TopNavMenu from './TopNavMenu';
import { connect } from 'react-redux';
import SearchBar from '../Quicksearch/SearchBar';
import { withTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import *  as unifiedSearchActions from '../../redux/actions/unifiedSearchActions';
import *  as navigationActions from '../../redux/actions/navigationActions';
import *  as tenantActions from '../../redux/actions/tenantActions';


const styles = theme => ({
    navbar: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 100,
        background: '#eeeeee',
        height: props => props.height
    },
    spacer: {
        flex: 1
    },
    moduletext: {
        fontSize: '1.2rem',
        marginTop: '3px',
        marginLeft: '1rem',
        cursor: 'pointer',
        textDecoration: 'unset',

    },
});


export class TopNavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
        }
    }

    render() {
        const { user, t, classes, moduleInfo } = this.props;
        return (
            <header className={classes.navbar}>
                <nav className="topnavbar_nav">
                    <SideBarToggle click={this.props.drawerClickHandler} />
                    <div id="react-module-title" className={classes.moduletext}>
                        {/* get module info from navigation state */}
                        <Typography edge="start"
                            style={{
                                textDecoration: "underline",
                                marginLeft: (this.props.width > 960 ? (this.props.sideNavMinimized ? '2rem' : "11rem") : '1px'),
                                display: (this.props.showModuleTitle ? 'block' : 'none')
                            }}
                            onClick={() => {
                                var original = moduleInfo.url;
                                var locationStr = original;
                                if (original.indexOf('/yerp') !== 0) {
                                    var pathIdx = moduleInfo.url.indexOf('/index.php');
                                    if (pathIdx != -1) {
                                        locationStr = '/yerp' + moduleInfo.url.substring(pathIdx + '/index.php'.length);
                                    }
                                }
                                window.location.href = locationStr;
                            }
                            }
                            color="inherit" aria-label="menu">
                            {t(moduleInfo.name)}
                        </Typography>

                    </div>

                    <div className={classes.spacer} >
                        <div style={{
                            textAlign: "center",
                            padding: (this.props.width < 799 ? '0px' : "10px"),
                            fontSize:(this.props.width < 799 ? 'x-small' : "")
                        }} >{ sessionStorage.getItem("user_name")?? user.profile.name}

                        </div>
                    </div>
                    <div className="topnavbar_nav-items">
                        <ul>
                            <li style={{ marginRight: 14 }}>
                                <SearchBar tenantInfo={this.props.tenantInfo}
                                    actions={this.props.actions}
                                    sugarHistory={this.props.sugarHistory}
                                    searchItems={this.props.searchItems}
                                    width={this.props.width}
                                    unifiedSearchSettings={this.props.unifiedSearchSettings}
                                    unifiedSearchSettingsOpen={this.props.unifiedSearchSettingsOpen}
                                    showModuleTitle={this.props.showModuleTitle}
                                    t={t}>
                                </SearchBar>
                            </li>
                            {/* <li>
                            <SimpleBadge>
                            </SimpleBadge>
                        </li> */}
                            <li >
                                <TopNavMenu
                                    tenantInfo={this.props.tenant}
                                    availableTenants={this.props.availableTenants}
                                    resourceAccess={this.props.resourceAccess}
                                    actions={this.props.actions}
                                    user={this.props.user}
                                    t={t}
                                    history={this.props.history}>
                                </TopNavMenu>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        );
    }
}
function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        tenant: state.tenant,
        availableTenants: state.availableTenants,
        resourceAccess: state.resourceAccess,
        moduleInfo: state.navigation.module,
        showModuleTitle: state.navigation.showModuleTitle,
        sugarHistory: state.navigation.sugarHistory,
        searchItems: state.unifiedSearch.searchItems,
        unifiedSearchSettings: state.unifiedSearch.settings,
        unifiedSearchSettingsOpen: state.unifiedSearch.settingsOpen,

    };

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            setSearchSettingsOpen: bindActionCreators(unifiedSearchActions.setSearchSettingsOpen, dispatch),
            showModuleTitle: bindActionCreators(navigationActions.showModuleTitle, dispatch),
            setModule: bindActionCreators(navigationActions.setModule, dispatch),
            readQuickSearchItems: bindActionCreators(unifiedSearchActions.readQuickSearchItems, dispatch),
            getSugarPageHistory: bindActionCreators(navigationActions.readSugarPageHistory, dispatch),
            postUnifiedSearchSettings: bindActionCreators(unifiedSearchActions.updateUnifiedSearchSettings, dispatch),
            readUnifiedSearchSettings: bindActionCreators(unifiedSearchActions.readUnifiedSearchSettings, dispatch),
            selectTenant: bindActionCreators(tenantActions.selectTenant, dispatch)
        }
    };
}


export default compose(withTranslation('common'), withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(withRouter(TopNavBar));
