import React, { Component, Fragment } from "react";
import {
    Card,
    CardContent,
    Container,
    Grid,
    Typography
} from '@material-ui/core';
import * as styles from './styles.js';
import UpTextField from '../common/upTextField';
import { withTranslation } from 'react-i18next';



export class Address extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            address : this.props.address 
        };
    }

    componentDidMount() {
        this.setState({
            address : this.props.address 
        })
    }
  
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.address !== this.props.address) {
            this.setState({
                address : this.props.address 
            })
        }
    }
    
    render(){
        const { disabled, t } = this.props;
        const { address } = this.state;

        return (
            <Fragment>
                <Typography variant="h6" >{t("Address")}</Typography>
                <Grid container style={{...styles.ElementWithoutPadding, ...{marginBottom: "0px"}}}>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <UpTextField
                                    disabled={disabled}
                                    style={styles.inputElements}
                                    id="address"
                                    label={t("Address")}
                                    value={address.address}
                                />
                                <UpTextField
                                    disabled={disabled}
                                    style={styles.inputElements}
                                    id="addressLine2"
                                    label={t("AddressLine2")}
                                    value={address.addressLine2}
                                />
                                <UpTextField
                                    disabled={disabled}
                                    style={styles.inputElements}
                                    id="postalCode"
                                    label={t("PostalCode")}
                                    value={address.postalCode}
                                />  
                            </CardContent>
                        </Card>
                    </Container>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <UpTextField
                                    disabled={disabled}
                                    style={styles.inputElements}
                                    id="city"
                                    label={t("City")}
                                    value={address.city}
                                />
                                <UpTextField
                                    disabled={disabled}
                                    style={styles.inputElements}
                                    id="country"
                                    label={t("Country")}
                                    value={address.country}
                                />  
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
            </Fragment>
        )
        
    }

}

export default withTranslation('common')(Address);
