import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function realTimeReducer(state = initialState.realTimeMessage , action) {
    if (action.type === types.READ_RALTIME_MESSAGE_SUCCESS)
    {
        return action.realTimeMessage;
    }
    else {
        return state;
    }
}
