import { combineReducers } from "redux";
import tenant from './tenantReducer';
import availableTenants from './availableTenantsReducer';
import workCards from './workCardsReducer';
import workCard from './workCardReducer';
import workCardDocument from './workCardDocumentReducer';
import localization from './localizationReducer';
import notifications from './notificationsReducers';
import resourceAccess from "./resourceAccessReducer";
import persons from "./personsReducer";
import productRegister from "./productRegisterReducer";
import productsRegister from "./productsRegisterReducer";
import productGroup from "./productGroupReducer";
import productGroups from "./productGroupsReducer";
import productUnit from "./productUnitReducer";
import productUnits from "./productUnitsReducer";
import navigation from "./navigationReducer";
import sessionMetadata from "./sessionMetadataReducer";
import sugarSession from "./sugarSessionReducer";
import ui from "./uiReducer";
import unifiedSearch from "./unifiedSearchReducer";
import { reducer as oidcReducer } from 'redux-oidc';
import contact from "./contactReducer";
import contacts from "./contactsReducer";
import customer from "./customerReducer";
import customers from "./customersReducer";
import catalog from "./catalogsReducer";
import workSite from "./workSiteReducer";
import workSites from "./workSitesReducer";
import workOrder from "./workOrderReducer";
import workOrders from "./workOrdersReducer";
import realTimeMessage from "./realTimeReducer";
import workOrderDiaryEntry from "./workOrderDiaryEntryReducer";
import workOrderDiaryEntries from "./workOrderDiaryEntriesReducer";
import blobProgress from "./blobProgressReducer";
import workQueue from "./workQueueReducer";
import workQueues from "./workQueuesReducer";
import workQueueSettings from "./workQueueSettingsReducer";
import workQueuesSettings from "./workQueuesSettingsReducer";
import usersGeneralInfo from "./usersReducer";
import isSessionActive from "./sessionLifeReducer";
import realTimeSendMessage from "./realTimeSenderReducer";
import isConnectionActive from "./realTimeConnectionReducer";
import isConnectionSignalActive from "./realTimeConnectionSignalReducer";
import users from "./usersDataReducer";
import user from "./userDataReducer";
import roles from "./rolesReducer";
import role from "./roleReducer";

const createRootReducer = (history) => combineReducers({
    oidc: oidcReducer,
    resourceAccess,
    blobProgress,
    sessionMetadata,
    sugarSession,
    tenant,
    availableTenants,
    workCards,
    workCard, 
    workCardDocument,
    localization,
    notifications,
    persons,
    productRegister,
    productsRegister,
    productGroup,
    productGroups,
    productUnit,
    productUnits,
    navigation,
    ui,
    contact,
    contacts,
    customer,
    customers,
    catalog,
    unifiedSearch,
    workSite,
    workSites,
    workOrder,
    workOrders,
    realTimeMessage,
    workOrderDiaryEntry,
    workOrderDiaryEntries,
    workQueue,
    workQueues,
    workQueueSettings,
    workQueuesSettings,
    usersGeneralInfo,
    isSessionActive,
    realTimeSendMessage,
    isConnectionActive,
    isConnectionSignalActive,
    users,
    user,
    roles,
    role
});

export default createRootReducer;