import * as types from "./actionTypes";
import * as realTimeApi from "../../api/realTimeApi";

export function realTimeMessageSuccess(realTimeMessage) {
    return { type: types.READ_RALTIME_MESSAGE_SUCCESS, realTimeMessage };
}

export function realTimeConnectionSuccess(isConnectionActive) {
    return { type: types.RALTIME_CONNECTION_ACTIVE, isConnectionActive };
}

export function realTimeConnectionSingalSuccess(isConnectionSignalActive) {
    return { type: types.RALTIME_CONNECTION_SIGNAL_ACTIVE, isConnectionSignalActive };
}

export function sendMessage(message) {
    return function(dispatch) {
        return realTimeApi
        .sendMessage(message)  
        .then(result => {})
        .catch(error => {});
    };
}
