import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import *  as productRegisterActions from '../../redux/actions/productRegisterActions';
import *  as productGroupActions from '../../redux/actions/productGroupActions';
import *  as productUnitActions from '../../redux/actions/productUnitActions';
import { bindActionCreators, compose } from 'redux';
import {
    Card,
    CardContent,
    Container,
    Grid,
    Typography,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import UpTextField from '../common/upTextField';
import * as styles from '../common/styles.js';
import "../../index.css";
import Spinner from '../common/CircularSpinner';
import { withTranslation } from 'react-i18next';
import * as constants from "../common/constants";
import initialState from "../../redux/reducers/initialState";
import { ProductsBuilderList } from "../common/Lists/productsBuilderList";
import { MoneyField } from "../common/MoneyField";
import { UpButton } from "../common/upButton";
import { SearchOne } from "../common/SearchOne";
import { BottomBar } from "../common/BottomBar";
import CancelPromisesHOC from "../../helpers/cancelPromisesHOC";
import { Cancel } from '@material-ui/icons';
import { Save } from '@material-ui/icons';
import { withRouter } from "react-router-dom";
import {ScrollableTabs} from '../common/ScrollableTabs';
import { ProductWorkOrderRow } from "../common/Lists/rows/productWorkOrderRow";


export class ProductRegister extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productId: this.props.match.params.productId,
            isLoading: false,
            product: initialState.productRegister.productRegister,
            rows: [],
            costPrice: 0,
            basicSalePrice: 0,
            ProductGroup: "",
            ProductGroupId: "",
            ProductUnit: "",
            ProductUnitId: "",
            counterBasket: 0,
            numberPage: 1,
            sliderPagination: true,
            firstPage: true,
            LastPage: false,
            numberPages: 2,
            paginations: [
                "GeneralInfo",
                "ProductsBuilder"
            ],
            rowInModal : {},
            row: {
                plannedQuantity : 1,
                actualQuantity: 1,
                installed: false
            }
        }
    }

    async componentDidMount() {
        if (this.state.productId !== constants.New && 
            this.state.productId !== constants.AddProductFromWorkOrder 
            ) {
            if (!this.props.isTesting) {
                this.setState({
                    isLoading: true,
                });
            }

            var id = this.state.productId;
            if (this.state.productId === constants.EditProductFromWorkOrder) 
            {   
                id = this.props.productEditable.id
            }

            await this.props.actions.readProductRegister(id)
                .then(() => {

                    this.setState({
                        costPrice: this.props.product.productRegister.costPrice,
                        basicSalePrice: this.props.product.productRegister.basicSalePrice,
                    });
                })
                .catch(() => { })
                .finally(result => {
                })
 
            var unit =  this.props.product.productRegister.unit;
            var productGroup = this.props.product.productRegister.productGroup;

            if (this.state.productId === constants.EditProductFromWorkOrder) {
                this.setState({
                    costPrice: this.props.productEditable.costPrice,
                    basicSalePrice: this.props.productEditable.basicSalePrice,
                })
                unit = this.props.productEditable.unit;
                productGroup = this.props.productEditable.productGroup;
            }    

            if (unit !== "") {
                await this.props.actions.readProductUnit(unit)
                .then(() => {
                    this.handleOneSelect(this.props.productUnit, "unit");
                })
                .catch(() => { })
                .finally(result => {

                })
            }    
           
            if (productGroup !== "") {
                await this.props.actions.readProductGroup(productGroup)
                .then(() => {
                    this.handleOneSelect(this.props.productGroup, "productGroup");
                })
                .catch(() => { })
                .finally(result => {
                    if (!this.state.isTesting) {
                        this.setState({
                            isLoading: false,
                        });
                    }
                })
            }
            else {
                if (!this.state.isTesting) {
                    this.setState({
                        isLoading: false,
                    });
                }
            }

            if (this.state.productId === constants.EditProductFromWorkOrder) {
                this.setState({
                    product: this.props.productEditable,
                    rows: this.props.product.productsBuilderDetails,
                    counterBasket: this.props.product.productsBuilderDetails.length,
                    row : {
                        plannedQuantity : this.props.productEditable.plannedQuantity,
                        actualQuantity: this.props.productEditable.actualQuantity,
                        installed: this.props.productEditable.installed
                    }
                });
            }
        }
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.product !== this.props.product) {
            this.setState({
                product: this.props.product.productRegister,
                rows: this.props.product.productsBuilderDetails,
                counterBasket: this.props.product.productsBuilderDetails.length
            });
        }
    }

    handleChange = (event) => {

        const { id, value } = event.target;

        this.setState(prevState => ({
            ...prevState,
            product: {
                ...prevState.product,
                [id]: value,
            },
        }));

    }

    back = () => {
        if (this.props.isCreateNewInModal) {
            this.props.handleClose()
        }
        else {
            var path = "/productsRegister";

            if (this.props.isTesting) {
                this.setState({
                    backPathTest: path,
                });
            }
    
            this.props.history.push(path);
    
        }
    }

    save = async () => {
        if (!this.props.isLoading) {
            this.setState({
                isLoading: true,
            });
        }

        var record = { ...this.state.product };
        record.id = record.itemCode;
        if (this.state.productId === constants.New ||
            this.state.productId === constants.AddProductFromWorkOrder) {
            record.state = constants.Added;
        }
        else {
            record.state = constants.Modified;
        }

        record.quantity = 0;
        
        if (isNaN(record.costPrice)) {
            record.costPrice = 0;
        }

        if (isNaN(record.basicSalePrice)) {
            record.basicSalePrice = 0
        }

        var data = { ...initialState.productRegister };
        data.productRegister = { ...record };
        data.productsBuilderDetails = [...this.state.rows];

        if (this.props.isTesting) {
            this.setState({
                recordTest: data,
            });
        }

        var continueBack = true;
        if (this.state.productId !== constants.EditProductFromWorkOrder) {
            await this.props.actions.updateProductRegister(data)
            .then((result) => {
            })
            .catch(() => {
                continueBack = false;
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            })
        }
       
        if (continueBack) {
            var list;
            var temp;
            if (this.state.productId === constants.AddProductFromWorkOrder) {
                list = this.getListProductBuilder();
                data.id = data.itemCode;
                temp = {...data.productRegister, 
                    plannedQuantity : this.state.row.plannedQuantity,
                    actualQuantity: this.state.row.actualQuantity,
                    installed: this.state.row.installed,
                    productsBuilder : list
                }
                this.props.addProduct(temp);
            }
            else if (this.state.productId === constants.EditProductFromWorkOrder) {
                list = this.getListProductBuilder();
                temp = {...data.productRegister,
                    plannedQuantity : this.state.row.plannedQuantity,
                    actualQuantity: this.state.row.actualQuantity,
                    installed: this.state.row.installed,
                    productsBuilder : list
                }
 
                this.props.saveEdit(temp, this.props.idxProduct);
            }
            else {
                this.back();
            }
        }
    }

    getListProductBuilder = () => {
        var list = [];
        for (var x = 0; x < this.state.rows.length; x++) {
            list.push({
                id: this.state.rows[x].id,
                quantity: this.state.rows[x].quantity
            })
        }

        return list;
    }

    refreshDetails = (rows) => {
        this.setState(prevState => ({
            ...prevState,
            rows: rows
        }));
    }

    handleOneSelect = (row, id) => {
        if (row.itemCode) {
            if (id === "productGroup") {
                this.setState({
                    ProductGroup: row.name,
                    ProductGroupId: row.itemCode,
                });
            }

            if (id === "unit") {
                this.setState({
                    ProductUnit: row.name,
                    ProductUnitId: row.itemCode,
                });
            }

            this.handleChange({
                target: {
                    id: id,
                    value: row.itemCode
                }
            });
        }

    }

    cleanFilter = (id) => {
        if (id === "productGroup") {
            this.setState({
                ProductGroup: "",
                ProductGroupId: "",
            });
        }
        if (id === "unit") {
            this.setState({
                ProductUnit: "",
                ProductUnitId: "",
            });
        }

        this.handleChange({
            target: {
                id: id,
                value: ""
            }
        });

    }

    slider = () => {
        const { t } = this.props;
        return (
            <ScrollableTabs
                tabs={[
                    {title: t("ProductInfo"), item: this.generalInfo()},
                    {title: t("ProductsBuilder"), item: this.builderList()},
                ]}>
            </ScrollableTabs>
        )
        /*
      return (
            <Fragment>
                {this.state.numberPage === 1 &&
                    <Fragment>
                       {this.generalInfo()}
                       {this.buttonsBottom()}
                    </Fragment>
                    
                }
                {this.state.numberPage === 2 &&
                    this.builderList()
                }
            </Fragment>
      )   */
    };

    changePage = (number) => {
        let counter = this.state.numberPage + number;
        this.moveTo(counter);

    }

    moveTo = (page) => {
        if (page === this.state.numberPages) {
            this.setState({
                LastPage: true,
                numberPage: page,
                firstPage: false
            })
        }
        else if (page === 1) {
            this.setState({
                firstPage: true,
                numberPage: page,
                LastPage: false
            })
        }
    }

    handleChangeInModal = (value, id) => {
        this.setState(prevState => ({
            ...prevState,
            row: {
                ...prevState.row,
                [id]: value,
            },
        }));
    }
   

    generalInfo = () => {
        const { t, i18n } = this.props;
        const { product } = this.state;
        return (
            <div >

                <Grid container style={styles.ElementWithoutPadding}>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <Grid container >
                                    <Grid style={{ width: "100%" }}>
                                        <UpTextField
                                            disabled={this.state.productId !== constants.New &&
                                                this.state.productId !== constants.AddProductFromWorkOrder
                                            }
                                            style={styles.inputElements}
                                            id="itemCode"
                                            label={t("ProductCode")}
                                            value={product.itemCode}
                                            onChange={this.handleChange} />
                                    </Grid>
                                    <Grid>
                                        {(!product.itemCode || product.itemCode.trim() === "") &&
                                            <Typography id="itemCode-helper-text" style={{ color: "rgba(255, 1, 1, 0.54)" }}>
                                                {t("RequireItemCode")}
                                            </Typography>
                                        }
                                    </Grid>
                                </Grid>
                                <UpTextField
                                    style={styles.inputElements}
                                    id="name"
                                    label={t("Name")}
                                    value={product.name}
                                    onChange={this.handleChange} />
                            </CardContent>
                        </Card>
                    </Container>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <UpTextField
                                    style={styles.inputElements}
                                    id="description"
                                    label={t("Description")}
                                    value={product.description}
                                    onChange={this.handleChange} />
                                <SearchOne
                                    {...this.props}
                                    id="productUnitId"
                                    label={t("Unit")}
                                    valueOne={this.state.ProductUnit}
                                    handleOneSelect={(row) => this.handleOneSelect(row, "unit")}
                                    cleanFilter={() => this.cleanFilter("unit")}
                                    typeQuery={constants.productUnit}
                                    isFullWidth={true}
                                />
                            </CardContent>
                        </Card>
                    </Container>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <MoneyField
                                    id="costPrice"
                                    label={t("CostPrice")}
                                    value={this.state.costPrice}
                                    onChange={this.handleChange}
                                    lang={i18n.language}
                                />
                                <MoneyField
                                    id="basicSalePrice"
                                    label={t("BasicSalePrice")}
                                    value={this.state.basicSalePrice}
                                    onChange={this.handleChange}
                                    lang={i18n.language}
                                />
                            </CardContent>
                        </Card>
                    </Container>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <SearchOne
                                    {...this.props}
                                    id="productGroupId"
                                    label={t("ProductGroup")}
                                    valueOne={this.state.ProductGroup}
                                    handleOneSelect={(row) => this.handleOneSelect(row, "productGroup")}
                                    cleanFilter={() => this.cleanFilter("productGroup")}
                                    typeQuery={constants.productGroup}
                                    isFullWidth={true}
                                />
                            </CardContent>
                        </Card>
                    </Container>
                    {this.props.addType === constants.ProductWorkOrderList &&
                        <ProductWorkOrderRow t={this.props.t} i18n={this.props.i18n} quantityInit={1} 
                            handleRowChange={this.handleChangeInModal} actionType={this.state.productId} row={this.state.row}
                        />
                    }
                </Grid>
            </div>
        )
    }

    builderList = () => {
        return (
            <div style={{marginBottom: "100px"}}>
                <ProductsBuilderList
                    {...this.props}
                    showtitle={false}
                    rows={this.state.rows}
                    refreshDetails={this.refreshDetails}
                    hideSearch={true}
                    counterBasket={this.state.counterBasket}

                />
                {
                    // this.buttonsBottom()
                }
            </div>
            
        )

    }

    handleChangePages = (e) => {
        this.setState({
            sliderPagination: !this.state.sliderPagination
        })
    }
    // altButtonsBottom = () => {
    //     const { t } = this.props;
    //     const { product } = this.state;
    //     return (
    //         <div style={styles.mobilePageSlider}>
    //             <UpButton
    //                 id="cancelId"
    //                 toolTipMessage={t("Cancel")}
    //                 clickAction={this.back}
    //                 iconButton={<Cancel />}
    //                 color="primary"
    //                 circleButton={true}
    //                 style={{ marginBottom: "14px", marginTop: "8px" }}
    //             />
    //             <div style={{ display: 'contents' }}>
    //                 <Typography
    //                     color="primary"
    //                     id="BottomName"
    //                     style={styles.pageSliderName}
    //                 >{product.name ? product.name : t("New")}</Typography>

    //             </div>

    //             <UpButton
    //                 disabled={!product.itemCode || product.itemCode.trim() === ""}
    //                 id="saveId"
    //                 toolTipMessage={t("Save")}
    //                 clickAction={this.save}
    //                 iconButton={<Save />}
    //                 color="primary"
    //                 circleButton={true}
    //                 style={{ marginBottom: "14px", marginTop: "8px" }}
    //             />

    //         </div>
    //     )
    // }
    buttonsBottom = () => {
        const { t } = this.props;
        const { product } = this.state;
        return (
            <Fragment>
                <Grid container style={{ ...styles.ElementWithoutPadding, ...{ marginTop: "20px" } }}>
                    <Grid container direction="row" justify="flex-end">
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.sliderPagination}
                                        onChange={(e) => this.handleChangePages(e)}
                                        color="primary"
                                    />
                                }
                                label={t("Pages")}
                            />
                            <UpButton
                                id="cancelId"
                                toolTipMessage={""}
                                messageContent={t("Cancel")}
                                clickAction={this.back}
                                iconButton={<Cancel style={styles.leftIcon} />}
                                color="primary"
                                circleButton={false}
                            />
                            <UpButton
                                disabled={!product.itemCode || product.itemCode.trim() === ""}
                                id="saveId"
                                toolTipMessage={""}
                                messageContent={t("Save")}
                                clickAction={this.save}
                                iconButton={<Save style={styles.leftIcon} />}
                                color="primary"
                                circleButton={false}
                            />
                        </div>
                    </Grid>
                </Grid>
                <div style={{ marginTop: "20px" }}></div>
            </Fragment>
        )
    }


    render() {
        const { t } = this.props;
        return (

            <React.Fragment>
                {this.props.addType &&
                    <Typography variant="h6" >
                        {t("ProductRegister")}
                    </Typography>
                }
                       
                {this.state.isLoading &&
                    <Spinner open={true} />
                }
                {this.state.sliderPagination ? (
                    <Fragment>
                        {this.slider()}
                    </Fragment>
                ) : (
                        <Fragment>
                            {this.generalInfo()}
                            {this.builderList()}
                        </Fragment>
                    )}
                <BottomBar
                    disabler={(!this.state.product.itemCode || this.state.product.itemCode.trim() === "")}
                    title={this.state.product.name}
                    placeholder={this.props.t('Unnamed') + " " + this.props.t('Product')}
                    rightButton={{action: this.save, tooltip: this.props.t("Save"), icon: <Save />}}
                    leftButton={{action: this.back, tooltip: this.props.t("Cancel"), icon: <Cancel/>}}
                />

                {/* This will show the "pages" checkbox, which shows tabbed content on top of each other */}
               {/* <BottomButtons save={this.save}/> */}
            </React.Fragment>
        )
    };
}

function mapStateToProps(state) {
    return {
        notification: state.notifications,
        product: state.productRegister,
        productsRegister: state.productsRegister,
        productGroups: state.productGroups,
        productGroup: state.productGroup,
        productUnits: state.productUnits,
        productUnit: state.productUnit
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            readProductsRegister: bindActionCreators(productRegisterActions.readProductsRegister, dispatch),
            readProductRegister: bindActionCreators(productRegisterActions.readProductRegister, dispatch),
            updateProductRegister: bindActionCreators(productRegisterActions.updateProductRegister, dispatch),
            readProductGroups: bindActionCreators(productGroupActions.readProductGroups, dispatch),
            readProductGroup: bindActionCreators(productGroupActions.readProductGroup, dispatch),
            readProductUnits: bindActionCreators(productUnitActions.readProductUnits, dispatch),
            readProductUnit: bindActionCreators(productUnitActions.readProductUnit, dispatch),

        }
    };
}

export default compose(withTranslation('common'),
    CancelPromisesHOC,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(withRouter(ProductRegister));
