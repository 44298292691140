import * as types from "./actionTypes";
import * as catalogsApi from "../../api/catalogsApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";
import * as constants from "../../components/common/constants"

export function readCatalogSuccess(catalog) {
    return { type: types.READ_CATALOG_SUCCESS, catalog };
}

export function readCatalog(id) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return catalogsApi
        .ReadCatalog(id)  
        .then(result => {
            dispatch(readCatalogSuccess(result));
            return result;
        })
        .catch(error => {
            if (error !== constants.SystemErrorNotFound) {
                dispatch(apiCallError(error));
                dispatch(notifications.notificationError("ErrorLoadingCatalog"));
            }
            throw new Error(error);
        });
    };
} 

export function updateCatalogSuccess(catalog) {
    return { type: types.UPDATE_CATALOG_SUCCESS, catalog };
}

export function updateCatalog(model) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return catalogsApi
        .updateCatalog(model)  
        .then(result => {
            dispatch(updateCatalogSuccess(result));
            return result;
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorUpdatingCatalog"));
            throw new Error(error);
        });
    };
} 

