import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useSpring, animated } from 'react-spring';
import CreateNewButton from '../Buttons/CreateNewButton';
import AddIcon from "@material-ui/icons/Add";
import Paper from "@material-ui/core/Paper";
import { Typography } from '@material-ui/core';
import * as icons from "@material-ui/icons/";
import ListItemText from "@material-ui/core/ListItemText";
import InputBase from '@material-ui/core/InputBase';
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Icon from '@material-ui/core/Icon';
import red from '@material-ui/core/colors/red';
import createNewItems from './createNewItems';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import hasAccess from '../../services/accessControl';
import { compose } from 'redux';
import env from "../../utils/env";
import { store } from "../../redux/store";


const styles = theme => ({
    gridList: {
        maxWidth: '750px',
        minHeight: '300px',
        columns: 2,
        '@media (max-width:600px)': {
            columns: 1,
        },
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '10px'
    },
    iconHover: {
        height: 'unset',
        width: 'unset',
        overflow: 'auto',
        '&:hover': {
            color: red[800],
        },
    },
    modalcontent: {
        backgroundColor: '#eeeeee',
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        overflow: 'auto',
        padding: theme.spacing(2, 2, 3),
        textAlign: 'center',
        alignItems: 'center',
        maxWidth: '750px',
        maxHeight: '750px',
        color: 'black',
        minWidth: '420px',
        'div::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
            borderRadius: '20px',
            backgroundColor: 'rgba(51, 51, 51, 0.2)',
        },

        '@media (max-width:600px)': {
            minWidth: '250px',
            maxHeight: '400px',
            maxWidth: '300px',
        },
    },
    modalItem: {
        color: 'black',

    },
    paper: {
        display: 'inline-flex',
        textAlign: 'start',
        flexWrap: 'wrap',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        padding: '3px',

        width: '100%',
    },
    filter: {
        display: 'inline-flex'
    },
    input: {

        width: '80%',
    },
    title: {
        fontSize: 18,

    },
    minimizedNewBtn: {
        borderRadius: 50,
        margin: '-10px 8px 20px 8px',
        width: 40,

    },
    newBtn: {
        margin: '0px 42px 20px 42px',
        minWidth: '60%',
    }

});

const Fade = React.forwardRef(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});


export class CreateNewModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            filterString: ""
        }
    }
    handleOpen = () => {
        this.setState({ open: true });
    };
    handleClose = () => {
        this.setState({ open: false });
    };
    handleRoute = (item) => {
        //this.props.history.push(item.link);
        //this.props.actions.setModule({ url: item.Link, name: item.name});
        //this.handleClose();
        //this.props.close();
        //this.props.history.push(location);
        window.location.href = item.link + "&tenant=" + store.getState().tenant.id;
    }
    filterEntries = (item) => {
        if(item.experimental && !env.experimental){
          return false;
        }
        if(!!item.module && !!hasAccess(this.props.resourceAccess, item.module, item.access)){
            return true;
        }else{
          return false
        }
      }
    // Handle the filtering of the list items
    FilteredList = (list, resourceAccess) => {
        const { classes, t } = this.props;
        var result = list.filter(item => this.filterEntries(item)).filter(e =>
            t(e.name).toLowerCase().includes(this.state.filterString.toLowerCase()) &&
            hasAccess(resourceAccess, e.module, e.access)).map(e =>
                <div key={(e.name)}>
                    <ListItem style={{
                        display: 'inline-flex',
                        cursor: 'pointer'
                    }}
                        key={(e.name)}
                        id={"create-new-" + e.name}
                        onClick={() => this.handleRoute(e)}
                        //onClick={() => this.handleRoute(e)}
    //                        onClick="window.open('{(e.link)}');"
                    >
                        <ListItemText
                            key={(e.name)}
                            className={classes.modalItem}
                            primary={t(e.name)}
                        />
                        <Icon className={classes.iconHover} color="primary">
                            {React.createElement(icons["AddCircle"])}</Icon>
                    </ListItem ></div>)
        if (result.length < 1) {
            return <div><h3>{t("NoResults")}</h3><p>{t("FixSearch")}</p></div>
        }
        else {
            return <List className={classes.gridList} id="CreateNewList" spacing={2}>{result}</List>;
        }
    }
    render() {
        const { classes, t, width } = this.props;        
        
        var list3 = createNewItems;
        var menu2 = sessionStorage.getItem("menu2");
        if (menu2) {
            list3 = JSON.parse(menu2);                    
        }



        return (
            <div>
                <CreateNewButton
                    type="button"
                    id="createNewBtn"
                    className={(this.props.minimized ? classes.minimizedNewBtn : classes.newBtn)}
                    onClick={this.handleOpen}>
                    <AddIcon />{(this.props.minimized ? "" : t('CreateNew'))}
                </CreateNewButton>

                <Modal
                    id="CreateNewModal"
                    aria-labelledby="spring-modal-title"
                    aria-describedby="spring-modal-description"
                    className={classes.modal}
                    open={this.state.open}
                    onClose={this.handleClose}
                    closeAfterTransition
                    style={{top: this.props.width < 960 ? 60 : 0}}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.open}>
                        <div className={classes.modalcontent}>
                            <Typography className={classes.title} id="new-modal-title">{t('CreateNewHead')}</Typography>
                            <Paper className={classes.paper} >
                                <Icon>{React.createElement(icons["Sort"])}</Icon>
                                <InputBase
                                    defaultValue=""
                                    autoFocus={width < 1200 ? false : true}
                                    className={classes.input}
                                    id="CreateNewModalFilter"
                                    placeholder={t("Filter")}
                                    onChange={e => this.setState({ filterString: e.target.value })} />
                            </Paper>

                            {this.FilteredList(list3, this.props.resourceAccess)}
                        </div>
                    </Fade>
                </Modal>
            </div >
        );
    }
}



export default compose(withTranslation('common'), withStyles(styles))(CreateNewModal);