import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function workOrderReducer(state = initialState.workOrder , action) {
    if (action.type === types.READ_WORKORDER_SUCCESS)
    {
        return action.workOrder;
    }
    else {
        return state;
    }
}
