import React, { Component, Fragment } from 'react';
import {
    Grid,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Container,
    Card,
    CardContent
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UpTextField from './upTextField';
import * as styles from './styles.js';
import SearchIcon from '@material-ui/icons/Search';
import "../../index.css";
import { withTranslation } from 'react-i18next';
import * as constants from "./constants";
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import UpButton from "./upButton";
import Filters from "./filters";
import ClearTwoToneIcon from '@material-ui/icons/ClearTwoTone';
import DialogAllOptions from "./dialogAllOptions";
import FilterGroups from "./filterGroups/filterGroups";
import StateFulSessionHelper  from "../../helpers/statefulSessionHelper";

let isClickTypeSeachVar = false;
let isClickIcon = false;

export class Search  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter : "",
            sort : this.props.sort,
            filterOpen : false,
            filterOptions : [],
            isRowStyle: false,
            expanded : false,
            isExpanded: true,
            openModify : false,
            id : undefined
        }
    }

    componentDidMount() {
        if(this.props.filter) {
            this.setState({
                filter : this.props.filter,
            });
        }
   
        var searchValue = StateFulSessionHelper.getSearchValue(this.props.idSave + this.props.user.profile.sub);
        if (searchValue !== null) {
            this.setState({
                filter : searchValue
            })
        }
       
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.filterOptions !== this.props.filterOptions) {
            this.setState({
                filterOptions : this.props.filterOptions,
            });
        }
        if(prevProps.isRowStyle !== this.props.isRowStyle) {
            this.setState({
                isRowStyle : this.props.isRowStyle,
            });
        }
        if(prevProps.sort !== this.props.sort) {
            this.setState({
                sort : this.props.sort,
            });
        }

        if(prevProps.expandedSearch !== this.props.expandedSearch) {
            this.setState({
                expanded : this.props.expandedSearch ? 0 : false,
                isExpanded : false,
            });
        }

    }

    handleChangeExpander = (idx, isClickTypeSeach) => {
        isClickTypeSeachVar = isClickTypeSeach;
        if (!isClickTypeSeach) {
            isClickIcon = true;
            this.setState({
                expanded : this.state.isExpanded ? idx : false,
            });
        }
        else {
            isClickIcon = false
        }
        
    };

    handleChangeHeaderExpander = (idx) => (event, isExpanded) => {
        if (isClickTypeSeachVar) {
            if (isExpanded) {
                this.setState({
                    isExpanded : isExpanded,
                });
            } 
        }
        else if (isClickIcon) {
            this.setState({
                isExpanded : !isExpanded,
            });
        } 
       
        isClickIcon = false
    };


    handleSearch = () => { 
        this.props.loadRecords(this.state.filter, this.state.sort, 1);
    }

    handleChange = (event) => {
        const { value } = event.target;
        this.setState({
            filter : value,
        }, function() {
            if (this.props.changeFilter) {
                this.props.changeFilter(value);
            }
            StateFulSessionHelper.setSearchValue(this.props.idSave + this.props.user.profile.sub, this.state.filter);
            this.handleSearch();
        });
    }

    handleCloseModify = () => {
        this.handleBackModify();
        this.props.handleClose();
    }

    handleBackModify = () => {
        this.setState({
            openModify : false
        }, function() {
            if (this.props.stayInMainWindowIsAllOptions) {
                this.props.cleanReloadRecords();
            }
        });
    }
    
    handleAdd = () => {
        if (!this.props.isArray) {
            if (this.props.isAllOptions) {
                if (this.props.insideTab) {
                    this.props.insideTab(constants.New);
                }
                else {
                    this.setState({
                        openModify : true,
                        id : constants.New
                    })
                }
            }
            else {
                if (this.props.isCreateNewInModal) {
                    this.props.handleAddInModal();
                }
                else {
                    var path = this.props.pathAddEdit + constants.New;

                    if (this.props.isTesting) {
                        this.setState({
                            testingpath: path
                        });
                    }
                    this.props.history.push(path)
                }
            }
        }
        else {
            
            if (this.props.isCreateNewInModal) {
                this.props.handleAddInModal();
            }else{
            this.props.handleAdd();
            }
        }
   }
    
    handleFilterOptions = (filterOptions, sort) => {
        if (this.state.filterOpen) {
            this.setState({
                filterOptions : filterOptions,
                sort : sort,
                filterOpen : false
            }, function() {
                this.handleSearch();
            });
        }
        else {
            this.setState({
                filterOpen : true
            });
        }

    }

    cleanFilter = () => {
        this.setState({
            filter : ""
        }, function() {
            StateFulSessionHelper.setSearchValue(this.props.idSave + this.props.user.profile.sub, this.state.filter);
            this.handleSearch();
        })
    }

    onClickSearch = () => {
        this.handleChangeExpander(-1, true);
    }

    searchInput = () => {
        const { t } = this.props;
        return (
            <Grid container style={styles.ElementWithoutPadding}>
                <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                    <Card style={styles.CardWithoutBoxes}>
                        <CardContent style={{...styles.ElementWithoutPadding, ...{padding: "0px"}}}>
                            <UpTextField
                                style={styles.inputElements}
                                id="filterId"
                                label={t("TypeSearch")}
                                value={this.state.filter}
                                onChange={this.handleChange} 
                                isSearchType={true}
                                handleSearch={this.onClickSearch}
                                isAdorment={true}
                                Adorment={<SearchIcon  />}
                                AdormentRight={
                                    <ClearTwoToneIcon  onClick={this.cleanFilter}/>
                                }
                            />
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
        )
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                {this.props.productGroupFilter ? (
                    <ExpansionPanel style={{ backgroundColor: this.props.backgroundColor, margin: "0px"}}
                        expanded={this.state.expanded === 0} onChange={this.handleChangeHeaderExpander(-1)}>
                        <ExpansionPanelSummary expandIcon={<div> <ExpandMoreIcon onClick={() => this.handleChangeExpander(0, false)}/></div>} className="headerSearch" > 
                            { this.searchInput() } 
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{ backgroundColor: "white" }}>
                            {this.props.productGroupFilter}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>    
                ) : (
                    <Fragment>
                        {this.props.hideSearch ? (
                            <Fragment/>
                        ): (
                            this.searchInput() 
                        )}
                    </Fragment>
                )}
                <Grid container style={{...styles.ElementWithoutPadding, ...{marginTop:"20px"}}}>
                    <Grid container direction="row" justify="flex-end">
                        {((this.props.addButton || this.props.isAllOptions) || 
                          (this.props.addButton && this.props.isArray)) &&
                            <UpButton
                                id="addId"
                                toolTipMessage={""}
                                messageContent={(this.props.addButton && this.props.isArray) ?  t("Add") : t("CreateNew")}
                                clickAction={this.handleAdd}
                                iconButton={<AddIcon  style={styles.leftIcon}/>}
                                color="primary" 
                                circleButton={false}
                            />
                        }
                        {this.props.addBasket &&
                            <UpButton
                                id="addId"
                                toolTipMessage={""}
                                messageContent={t(!!this.props.overrideAddButtonLabel ? this.props.overrideAddButtonLabel : "Add") + " (" + this.props.counterBasket + ")"}
                                clickAction={this.handleAdd}
                                iconButton={<AddIcon style={styles.leftIcon}/>}
                                color="primary" 
                                circleButton={false}
                            />
                        }
                        {!this.props.hideFilterOptions &&
                            <UpButton
                                id="filterOptionsId"
                                toolTipMessage={""}
                                messageContent={t("ListSettings")}
                                clickAction={this.handleFilterOptions}
                                iconButton={<FilterListIcon  style={styles.leftIcon}/>}
                                color="primary"  
                                circleButton={false}
                            />
                        }
                    </Grid>
                </Grid>
               { this.state.filterOpen &&
                    <Fragment>
                        {this.props.filterGroups ? (
                            <FilterGroups
                                filterOptions={this.state.filterOptions} 
                                isRowStyle={this.state.isRowStyle}
                                handleFilterOptions={this.handleFilterOptions}
                                open={this.state.filterOpen}
                                idSave={this.props.idSave}
                            >
                            </FilterGroups>
                        ): (
                            <Filters
                                filterOptions={this.state.filterOptions} 
                                isRowStyle={this.state.isRowStyle}
                                handleFilterOptions={this.handleFilterOptions}
                                open={this.state.filterOpen}
                                idSave={this.props.idSave}
                            >
                            </Filters>
                        )
                        }
                    </Fragment>
                }   
                { this.state.openModify &&
                    <DialogAllOptions {...this.props}
                        typeModify={this.props.typeModify}
                        isAllOptions={this.props.isAllOptions}
                        selectId={this.state.id}
                        handleCloseModify={this.handleCloseModify} 
                        handleBackModify={this.handleBackModify} 
                        openModify={this.state.openModify}
                        stayInMainWindowIsAllOptions={this.props.stayInMainWindowIsAllOptions}
                    />
                }     
                {/*
                    <Grid item sm xs={12}>
                        <Grid container direction="row" justify="flex-end">
                            <div>
                                <CircleButton
                                    id="searchId"
                                    toolTipMessage={t("Search")}
                                    clickAction={this.handleSearch}
                                    iconButton={<SearchIcon  />}
                                    color="primary" 
                                />
                                <CircleButton
                                    id="filterOptionsId"
                                    toolTipMessage={t("Filter")}
                                    clickAction={this.handleFilterOptions}
                                    iconButton={<FilterListIcon  />}
                                    color="primary" 
                                />
                            {!this.props.hideFilters &&
                                    <CircleButton
                                        id="jsdonFileId"
                                        toolTipMessage={t("Allrecords")}
                                        clickAction={this.props.handleJsonFile}
                                        iconButton={<DescriptionTwoToneIcon  />}
                                        color="primary" 
                                    />
                                }
                            </div>
                        
                            {this.props.addButton &&
                                <CircleButton
                                    id="addId"
                                    toolTipMessage={t("CreateNew")}
                                    clickAction={this.handleAdd}
                                    iconButton={<AddIcon  />}
                                    color="primary" 
                                />
                            }
                        </Grid>
                    </Grid>
                    { this.state.filterOpen &&
                    <Filters
                            filterOptions={this.state.filterOptions} 
                            handleFilterOptions={this.handleFilterOptions}
                            open={this.state.filterOpen}
                            idSave={this.props.idSave}
                    >
                    </Filters>
                    } 
                    {this.props.productGroupFilter}
                */}
            </React.Fragment>
        )
    };
}

export default withTranslation('common')(Search);
