import React, { Component, Fragment} from 'react';
import { connect } from 'react-redux';
import *  as rolesActions from '../../redux/actions/rolesActions';
import { bindActionCreators, compose } from 'redux';
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    Grid,
    Typography,
    Container,
    Card,
    CardContent,
    ExpansionPanelDetails,
    Checkbox,
    FormControlLabel
} from '@material-ui/core';
import UpTextField from '../common/upTextField';
import * as styles from '../common/styles.js';
import "../../index.css";
import Spinner from '../common/CircularSpinner';
import { withTranslation } from 'react-i18next';
import * as constants from "../common/constants";
import initialState from "../../redux/reducers/initialState";
import { Breadcrumb } from "../common/Breadcrumb";
import CancelPromisesHOC   from "../../helpers/cancelPromisesHOC";
import { Cancel } from '@material-ui/icons';
import { Save } from '@material-ui/icons';
import { withRouter } from "react-router-dom";
import {ScrollableTabs} from '../common/ScrollableTabs';
import { BottomBar } from "../common/BottomBar";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


export class Role  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roleId: this.props.match.params.roleId,
            name: "",
            isLoading : false,
            role : initialState.role,
            rows :  [],
        }
    }

    componentDidMount() {
        if (this.state.roleId !== constants.New) {
            if (!this.props.isTesting) {
                this.setState({
                    isLoading : true,
                });
            }
         
            this.props.actions.readRole(this.state.roleId)
            .then(() => {
            })
            .catch(() => {})
            .finally(result =>{
                if (!this.state.isTesting) {
                    this.setState({
                        isLoading : false,
                    });
                }
            })
        }
        else {
            this.convertToArray(initialState.roles)
        }
        
    }
   
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.role !== this.props.role) {
            this.setState({
                role: this.props.role,
                name: this.props.role.id 
            }, function() {
                this.mergeData({...this.props.permissions});
            });
        }
    }

    convertToArray = (roles) => {
        var temp = [];
        Object.keys(roles).forEach((key) => {
            temp.push({
                id : key,
                permissions: roles[key]
            })
        });

        this.setState({
            rows : temp
        })
        
    }

    convertToMap = () => {
        var temp = {};
        Object.keys(this.state.rows).forEach((idx) => {
            temp[this.state.rows[idx].id] = this.state.rows[idx].permissions
        });

        return temp;
    }

    mergeData = (roles) => {
        var init = initialState.roles;
        Object.keys(init).forEach((key) => {
            if (!roles.hasOwnProperty(key)) {
                roles[key] = init[key];
            }
            else {
                Object.keys(init[key]).forEach((childKey) => {
                    roles[key][childKey] = init[key][childKey];
                });
            }
        });
        this.convertToArray(roles)
    }
    
    handleChange = (event) => {
      
        const { id, value } = event.target;
        this.setState(prevState => ({
            ...prevState,
            [id]: value
        }));

    }

    back = () => {
        var path = "/roles";

        if (this.props.isTesting) {
            this.setState({
                backPathTest: path,
            });
        }

        this.props.history.push(path);
    }

    save = async () => {
        if (!this.props.isLoading) {
            this.setState({
                isLoading: true,
            });
        }

        var record = {...this.state.role};
        if (this.state.roleId === constants.New) {
            record.state = constants.Added;
            record.id = this.state.name;
        }
        else {
            record.state = constants.Modified;
        }

        record.permissions = this.convertToMap();

        var data = {...record};
       
        if (this.props.isTesting) {
            this.setState({
                recordTest: data,
            });
        }

        var continueBack = true;
        await this.props.actions.updateRole({...data})
        .then(() => {
        })
        .catch(() => {
            continueBack = false;
        })
        .finally(() =>{
            this.setState({
                isLoading: false,
            });
        })

        if (continueBack) {
            this.back();
        }
    }

    refreshDetails = (rows) => {
        this.setState(prevState => ({
            ...prevState,
            rows: rows
        }));
    } 

    handleChangeCheckbox = (e, idx, key) => {
        var temp = [...this.state.rows];
        temp[idx].permissions[key] = e.target.checked 

        this.setState({
            rows : [...temp]
        });
    }

    handleChangeRow = (idx) => (event, isExpanded) => {
        this.setState({
            expanded : isExpanded ? idx : false
        });
    };

    generalInfo = () => {
        const { t } = this.props;
        return (
            <Fragment>
                <Grid container style={styles.ElementWithoutPadding}>
                    <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <Grid container >
                                    <Grid style={{ width: "100%" }}>
                                        <UpTextField
                                        disabled={this.state.roleId !== constants.New}
                                        style={styles.inputElements}
                                        id="name"
                                        label={t("Name")}
                                        value={this.state.name}
                                        onChange={this.handleChange} />
                                    </Grid>
                                    <Grid>
                                        {(!this.state.name || this.state.name.trim() === "") &&
                                            <Typography id="role-helper-text" style={{ color: "rgba(255, 1, 1, 0.54)" }}>
                                                {t("Required")}
                                            </Typography>
                                        }
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
                {this.renderList()}
            </Fragment>
            
        )
    }

    slider = () => {
        const { t } = this.props;
       
        return (
            <ScrollableTabs
                handleChange={this.handleChangeIndex}
                index={this.state.index}
                tabs={[{title: t("GeneralInfo"), item: this.generalInfo()}]}>
            </ScrollableTabs>
        )
    };


    headers = () => {
        const { t } = this.props;
        return (
            <ExpansionPanel key={"headers"} expanded={false}
            style={{ backgroundColor: this.props.backgroundColor, marginBottom : "1px"  }}
            >
                <ExpansionPanelSummary expandIcon={null}>
                    <Grid container style={styles.ElementWithoutPadding}>
                        <Grid container style={{...styles.ElementWithoutPadding, 
                            ...{marginButtom: "10px", wordBreak: "break-all", whiteSpace: "normal"}}} >
                            { t("Permissions")}
                        </Grid>  
                    </Grid>
                </ExpansionPanelSummary>
            </ExpansionPanel>
        )
    }

    renderRow = (row, idx) => {
        return (
            <ExpansionPanel key={"expand" + idx} id={"expand" + idx} 
                style={{ backgroundColor: this.props.backgroundColor, marginBottom : "1px"  }}
                expanded={this.state.expanded === idx} onChange={this.handleChangeRow(idx)}
            >
                    {this.renderSummary(row, idx)}
                    {this.renderExpandDataCapture(row, idx)}
            </ExpansionPanel>

       )
    }

    renderExpandDataCapture = (row, idx) => {
        return (
            <ExpansionPanelDetails style={{ backgroundColor: "white" }}>
                <Grid container style={styles.ElementWithoutPadding}>
                    <Grid container direction="row" justify={"flex-start"}>
                        {this.renderDataCapture(row, idx)}
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
        )
    }

    renderDataCapture = (row, idxParent) => {
        return (
            <Fragment>
                  {Object.keys(row.permissions).map((key, idx) => this.captureData(key, idx, idxParent))}
            </Fragment>
        )
    }

    captureData = (key, idx, idxParent) => {
       const { t } = this.props;
        return (
            <Container maxWidth="sm" style={{...styles.ElementWithoutPadding, ...this.props.style}} key={"checkbox" + idx}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.rows[idxParent].permissions[key]}
                            onChange={(e) => this.handleChangeCheckbox(e, idxParent, key)}
                            name={key}
                            color="primary"
                        />
                    }
                    label={t(key)}
                />
            </Container>
        )
    }

    renderList= () => {
        return (
            <Fragment>
                {this.headers()}
                {this.state.rows.map((row, idx) => (
                    this.renderRow(row, idx)
                ))}
            </Fragment>
        )
    }

    renderSummary = (row, idx) => {
        const {t} = this.props
        return (
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container style={styles.ElementWithoutPadding}>
                    <Grid container style={styles.ElementWithoutPadding}>
                        <Grid container style={{...styles.ElementWithoutPadding, 
                            ...{marginButtom: "10px", wordBreak: "break-all", whiteSpace: "normal"}}} 
                        >
                            <Typography style={{flexShrink: 1}}>
                                { t(row.id)}
                            </Typography>
                        </Grid>  
                    </Grid>
                </Grid>
            </ExpansionPanelSummary>
        )
    }


    render() {
        const { t } = this.props;
        return (
            
            <React.Fragment>
                {this.state.isLoading &&
                    <Spinner open={true}/>
                }
                <Breadcrumb 
                    title={t("Role")} 
                    backButton={false} 
                />
                {this.slider()}
                <BottomBar
                    disabler={!this.state.name || this.state.name.trim() === ""}
                    title={this.state.name}
                    placeholder={t('Unnamed') + " " + t('Role')}
                    rightButton={{action: this.save, tooltip: t("Save"), icon: <Save />}}
                    leftButton={{action: this.back, tooltip: t("Cancel"), icon: <Cancel/>}}
                />

            </React.Fragment>
        )
    };
}

function mapStateToProps(state) {
    return {
        notification: state.notifications,
        role : state.role,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            readRole: bindActionCreators(rolesActions.readRole, dispatch),
            updateRole: bindActionCreators(rolesActions.updateRole, dispatch),
        }
    };
  }

export default compose(withTranslation('common'),
CancelPromisesHOC,
connect(
    mapStateToProps,
    mapDispatchToProps
))(withRouter(Role));
