import React, { Component } from 'react';
import { connect } from 'react-redux';
import *  as usersActions from '../../redux/actions/usersActions';
import { bindActionCreators, compose } from 'redux';
import {
    Card,
    CardContent,
    Container,
    Grid,
    Typography
} from '@material-ui/core';
import UpTextField from '../common/upTextField';
import * as styles from '../common/styles.js';
import "../../index.css";
import Spinner from '../common/CircularSpinner';
import { withTranslation } from 'react-i18next';
import * as constants from "../common/constants";
import initialState from "../../redux/reducers/initialState";
import { Breadcrumb } from "../common/Breadcrumb";
import CancelPromisesHOC   from "../../helpers/cancelPromisesHOC";
import { Cancel } from '@material-ui/icons';
import { Save } from '@material-ui/icons';
import { withRouter } from "react-router-dom";
import {ScrollableTabs} from '../common/ScrollableTabs';
import { BottomBar } from "../common/BottomBar";
import * as notifications from "../../redux/actions/notificationsActions";
import UPSwitch from "../common/upSwitch";
import FormControlLabel from '@material-ui/core/FormControlLabel';

export class UserManagement  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: this.props.match.params.userId,
            isLoading : false,
            user : initialState.user,
        }
    }

    componentDidMount() {
        if (this.state.userId !== constants.New) {
            if (!this.props.isTesting) {
                this.setState({
                    isLoading : true,
                });
            }
         
            this.props.actions.getUser(this.state.userId)
            .then(() => {
            })
            .catch(() => {})
            .finally(result =>{
                if (!this.state.isTesting) {
                    this.setState({
                        isLoading : false,
                    });
                }
            })
        }
    }
   
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user !== this.props.user) {
           var temp = {...this.props.user}
           if (temp.email === null) {
               temp.email = "";
           }
           this.setState({
               user : temp,
           });
        }
    }
    
    handleChange = (event) => {
      
        const { id, value } = event.target;
     
        this.setState(prevState => ({
            ...prevState,
            user: {
                ...prevState.user,
                [id]: value,
            },
        }));

    }

    handleChangeSwitch = (event) => {
        
        const { name, checked } = event.target;

        this.setState(prevState => ({
            ...prevState,
            user: {
                ...prevState.user,
                [name]: checked,
            },
        }));

    }

    back = () => {
        var path = "/usersManagement";

        if (this.props.isTesting) {
            this.setState({
                backPathTest: path,
            });
        }

        this.props.history.push(path);
    }

    save = async () => {
        if (!this.props.isLoading) {
            this.setState({
                isLoading: true,
            });
        }

        var record = {...this.state.user};
       
        if (this.state.userId === constants.New) {
            record.state = constants.Added;
        }
        else {
            record.state = constants.Modified;
        }
       
        if (this.props.isTesting) {
            this.setState({
                recordTest: {...record},
            });
        }

        var continueBack = true;
        if (this.state.userId === constants.New) {
            await this.props.actions.checkUsername(record.username)
            .then((result) => {
                if (!result) {
                    this.props.actions.notificationError(this.props.t("UsernameAlreadyexist"), true);
                    continueBack = false;
                }
            })
            .catch(() => {
                continueBack = false;
            })
            .finally(() =>{
            });
            
            if (continueBack) {
                await this.props.actions.createUser({...record})
                .then(() => {
                })
                .catch(() => {
                    continueBack = false;
                })
                .finally(() =>{
                    this.setState({
                        isLoading: false,
                    });
                });
            }
            else {
                this.setState({
                    isLoading: false,
                });
            }
        }
        else {

            await this.props.actions.updateUser(this.state.userId, {...record})
            .then(() => {
            })
            .catch(() => {
                continueBack = false;
            })
            .finally(() =>{
                this.setState({
                    isLoading: false,
                });
            });
        }

        if (continueBack) {
            this.back();
        }
    }

    refreshDetails = (rows) => {
        this.setState(prevState => ({
            ...prevState,
            rows: rows
        }));
    } 

    generalInfo = () => {
        const { t } = this.props;
        const { user } = this.state;
        return (
            <Grid container style={styles.ElementWithoutPadding}>
                <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                    <Card style={styles.CardWithoutBoxes}>
                        <CardContent style={styles.ElementWithoutPadding}>
                            <Grid container >
                                <Grid style={{ width: "100%" }}>
                                    <UpTextField
                                        disabled={this.state.userId !== constants.New}
                                        style={styles.inputElements}
                                        id="username"
                                        label={t("Username")}
                                        value={user.username}
                                        onChange={this.handleChange} />
                                </Grid>
                                <Grid>
                                    {(!user.username || user.username.trim() === "") &&
                                        <Typography id="username-helper-text" style={{ color: "rgba(255, 1, 1, 0.54)" }}>
                                            {t("Required")}
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                            <UpTextField
                                style={styles.inputElements}
                                id="email"
                                label={t("Email")}
                                value={user.email}
                                onChange={this.handleChange} />
                        </CardContent>
                    </Card>
                </Container>
                <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                    <Card style={styles.CardWithoutBoxes}>
                        <CardContent style={styles.ElementWithoutPadding}>
                            <UpTextField
                                style={styles.inputElements}
                                id="firstName"
                                label={t("FirstName")}
                                value={user.firstName}
                                onChange={this.handleChange} />
                            <UpTextField
                                style={styles.inputElements}
                                id="lastName"
                                label={t("LastName")}
                                value={user.lastName}
                                onChange={this.handleChange} />
                        </CardContent>
                    </Card>
                </Container>
                <Container maxWidth="sm" style={styles.ElementWithoutPadding}>
                    <Card style={styles.CardWithoutBoxes}>
                        <CardContent style={styles.ElementWithoutPadding}>
                            <FormControlLabel style={{marginLeft : 0, marginRight :0 }}
                                control={
                                    <UPSwitch checked={user.enabled} onChange={this.handleChangeSwitch} name="enabled" />
                                }
                                label={t("Enabled")}
                            />
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
        )
    }

    slider = () => {
        const { t } = this.props;
       
        return (
            <ScrollableTabs
                handleChange={this.handleChangeIndex}
                index={this.state.index}
                tabs={[{title: t("GeneralInfo"), item: this.generalInfo()}]}>
            </ScrollableTabs>
        )
    };

    render() {
        const { t } = this.props;
        const { user } = this.state;
        return (
            
            <React.Fragment>
                {this.state.isLoading &&
                    <Spinner open={true}/>
                }
                <Breadcrumb 
                    title={t("user")} 
                    backButton={false} 
                />
                {this.slider()}
                <BottomBar
                    disabler={!user.username || user.username.trim() === ""}
                    title={user.username}
                    placeholder={t('Unnamed') + " " + t('user')}
                    rightButton={{action: this.save, tooltip: t("Save"), icon: <Save />}}
                    leftButton={{action: this.back, tooltip: t("Cancel"), icon: <Cancel/>}}
                />

            </React.Fragment>
        )
    };
}

function mapStateToProps(state) {
    return {
        notification: state.notifications,
        user : state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            getUser: bindActionCreators(usersActions.getUser, dispatch),
            createUser: bindActionCreators(usersActions.createUser, dispatch),
            updateUser: bindActionCreators(usersActions.updateUser, dispatch),
            checkUsername: bindActionCreators(usersActions.checkUsername, dispatch),
            notificationError: bindActionCreators(notifications.notificationError, dispatch),
            
        }
    };
  }

export default compose(withTranslation('common'),
CancelPromisesHOC,
connect(
    mapStateToProps,
    mapDispatchToProps
))(withRouter(UserManagement));
