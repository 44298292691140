import React, { Component } from 'react';
import {
    CardContent,
    Grid,
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    Slide,
    Dialog,
    FormControl,
    InputLabel,
    Select,
} from '@material-ui/core';
import * as styles from '../common/styles.js';
import "../../index.css";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { withTranslation } from 'react-i18next';
import FilterHelper from "../../helpers/filterHelper";
import * as constants from "../common/constants";
import { Breadcrumb } from './Breadcrumb.js';
import CircleButton from "./CircleButton";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UpButton from "./upButton";
import Collapse from '@material-ui/core/Collapse';


const marginButton = {
    marginTop : "7px"
}

const WAIT_INTERVAL = 500;

export class Filters  extends Component {

    constructor(props) {
        super(props);
        this.state = {
            widths : [],
            groups : [],
            filterOptions : this.props.filterOptions,
            isRowStyle : this.props.isRowStyle,
            transitionState: [],
            fromIndex: -1,
            toIndex : -1,
            startTransition: false,
            rowPositon: -1,
            startCompleteTransition: false
        }
    }

    componentDidMount() {
        const widths = [];
        const groups = [];
        for (var x = 0; x < 12; x++)
        {
            widths[x] = x+1;
            groups[x] = x+1;
        }
        this.setState({
            widths : widths,
            groups : groups
        })

        this.state.filterOptions.map((row, i) => (
            this.state.transitionState.push(true)
        ));
    }
 
    filterOptionWidth = (e, idx) => {
        this.resetColor();

        const  value = parseInt(e.target.value) + 1
        var row = { ...this.state.filterOptions[idx], sm : value };

        this.setState(prevState => ({
            ...prevState,
            filterOptions : Object.values({
                ...prevState.filterOptions,
                [idx]: row
            })
        }));
    }


    filterOptionGroup  = (e, idx) => {
        this.resetColor();

        const  value = parseInt(e.target.value) + 1;
        var row = { ...this.state.filterOptions[idx], group : value };

        this.setState(prevState => ({
            ...prevState,
            filterOptions : Object.values({
                ...prevState.filterOptions,
                [idx]: row
            })
        }));
    }

    filterOption = (idx, isOn, id) => {

        this.resetColor();
       
        var isOk = true;
        if (!isOn) {
            isOk = this.validateFilter(id);
        }
        
        if (isOk) {
            var row;
            if (id === constants.isVisible && !isOn) {
                row = { ...this.state.filterOptions[idx], [id] : isOn, isSort : false  };
            }
            else {
                row = { ...this.state.filterOptions[idx], [id] : isOn };
            }
            
            this.setState(prevState => ({
                ...prevState,
                filterOptions : Object.values({
                    ...prevState.filterOptions,
                    [idx]: row
                })
            }));
       
        }
    }

    validateFilter = (id) => {
        var isOk = true;

        var countVisible = 0;
        var countSort = 0;
        for (var x = 0; x < this.state.filterOptions.length; x++) {
            if (this.state.filterOptions[x].isVisible) {
                countVisible++;
            }
            if (this.state.filterOptions[x].isSort) {
                countSort++;
            }

        }
        
        if (id === constants.isVisible) {
            if (countVisible ===  1) {
                isOk = false;
            }
        }
        else {
            if (countSort ===  1) {
                isOk = false;
            }
        }

        return isOk;
    }

    moveRow = (fromIndex, isUp) => {

        this.setState({
            rowPositon : fromIndex,
        });

        var toIndex = 0;
        if (isUp) {
            toIndex = fromIndex - 1;
        }
        else {
            toIndex = fromIndex + 1;
        }

        this.setState(prevState => ({
            ...prevState,
            transitionState : Object.values({
                ...prevState.transitionState,
                [fromIndex]: false
            }),
            fromIndex: fromIndex,
            toIndex : toIndex,
            startTransition: true,
            startCompleteTransition: true
        }));
    
    }

    handleFilterOptions = () => {
        var sortField = [];
        sortField.push("group");
        var options = this.state.filterOptions.sort(this.fieldSorter(sortField));

        this.setState({
            filterOptions : options
        }, function() {
            var idx = -1;
            for (var x = 0; x < options.length; x++) {
                if (options[x].isSort === true) {
                    idx = -1;
                    break;
                }
                else if (idx === -1) {
                    idx = 0;
                }
            }
            if (idx === 0) {
                options[idx].isSort = true;
            }

            var sort = FilterHelper.savefilterOptions(this.props.idSave, this.state.filterOptions, this.state.isRowStyle);

            if (this.props.isTesting) {
                this.setState({
                    sortTest : sort,
                });
            }
    
            this.props.handleFilterOptions([...this.state.filterOptions], sort);
    
        })

    }

    fieldSorter = (fields) => (a, b) => fields.map(o => {
        let dir = 1;
        if (o[0] === '-') { dir = -1; o=o.substring(1); }
        return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
    }).reduce((p, n) => p ? p : n, 0);

    clicking = () => {
       this.resetColor();
    }

    resetColor = () => {
        this.setState({
            rowPositon : -1
        });
    }

    rowDetails = (row, idx, len) => {
        const { t } = this.props;
        const { widths } = this.state;
        return (
            <Collapse in={this.state.transitionState[idx]} key={"collapse" + idx}
                addEndListener={this.handleEnd}
                onExited={this.handleExited}>
            <React.Fragment key={"fragment" + idx}>
                <ExpansionPanel expanded={false} 
                    style={idx === this.state.rowPositon ?
                        { backgroundColor: "antiquewhite", marginBottom : "1px"  } :
                        { backgroundColor: this.props.backgroundColor, marginBottom : "1px"  }
                    }
                >
                    <ExpansionPanelSummary >
                        <Grid container style={styles.ElementWithoutPadding} onClick={this.clicking}>
                            <Grid item sm xs={8}>
                                <Typography style={{paddingTop: "17px"}}>
                                    {t(row.label) }
                                </Typography>
                            </Grid>
                            <Grid item sm={9} xs={12}>
                                <Grid container direction="row" justify="flex-end">
                                    <div>
                                        {/*
                                        <FormControl 
                                            variant="outlined" style={{ width : "100px", paddingRight: "10px"}}>
                                            <InputLabel htmlFor="outlined-rowWidth" 
                                                style={{
                                                    background: "white",
                                                    paddingRight: "5px",
                                                    paddingLeft: "5px",
                                                }}
                                                
                                            >
                                                {t("Group")}
                                            </InputLabel>
                                            <Select
                                                disabled={!row.isVisible}
                                                native
                                                value={row.group - 1 }
                                                onChange={(e) => this.filterOptionGroup(e, idx)}
                                                inputProps={{
                                                    name: "rowGroupId",
                                                    id: 'outlined-rowGroup',
                                                }}
                                                onClick={this.clicking}
                                                >
                                                {groups.map((row, idx) => (
                                                    <option key={"option" + idx } value={idx}>{row}</option>
                                                ))}   
                                            </Select>
                                        </FormControl>*/}
                                        <FormControl 
                                            variant="outlined" style={{ width : "100px", paddingRight: "10px"}}>
                                            <InputLabel htmlFor="outlined-rowWidth" 
                                                style={{
                                                    background: "white",
                                                    paddingRight: "5px",
                                                    paddingLeft: "5px",
                                                }}
                                                
                                            >
                                                {t("Width")}
                                            </InputLabel>
                                            <Select
                                                disabled={!row.isVisible}
                                                native
                                                value={row.sm-1}
                                                onChange={(e) => this.filterOptionWidth(e, idx)}
                                                inputProps={{
                                                    name: "rowWidthId",
                                                    id: 'outlined-rowWidth',
                                                }}
                                                onClick={this.clicking}
                                                >
                                                {widths.map((row, idx) => (
                                                    <option key={"option" + idx } value={idx}>{row}</option>
                                                ))}   
                                            </Select>
                                        </FormControl>

                                        {row.isVisible ? (
                                            <UpButton
                                                disabled={this.state.startCompleteTransition}
                                                style={marginButton}
                                                id={"visibilityOnId" + idx}
                                                toolTipMessage={""}
                                                messageContent={t("Hide")}
                                                clickAction={() => this.filterOption(idx, false, constants.isVisible)}
                                                iconButton={<VisibilityOffIcon style={styles.leftIcon} />}
                                                color="primary" 
                                                circleButton={false}
                                            />
                                        ) : (
                                            <UpButton
                                                disabled={this.state.startCompleteTransition}
                                                style={marginButton}
                                                id={"visibilityOffId" + idx}
                                                toolTipMessage={""}
                                                messageContent={t("Show")}
                                                clickAction={() => this.filterOption(idx, true, constants.isVisible)}
                                                iconButton={<VisibilityIcon style={styles.leftIcon} />}
                                                color="primary" 
                                                circleButton={false}
                                            />
                                        )}
                                        {/* row.isSort ? (
                                            <CircleButton
                                                style={marginButton}
                                                id={"sortOnId" + idx}
                                                disabled={!row.isVisible}
                                                toolTipMessage={t("UnSelectSort")}
                                                clickAction={() => this.filterOption(idx, false, constants.isSort)}
                                                iconButton={<CheckBoxIcon style={styles.leftIcon} />}
                                                color="primary" 
                                            />   
                                        ) : (
                                            <CircleButton
                                                style={marginButton}
                                                id={"sortOffId" + idx}
                                                disabled={!row.isVisible}
                                                toolTipMessage={t("SelectSort")}
                                                clickAction={() => this.filterOption(idx, true, constants.isSort)}
                                                iconButton={<CheckBoxOutlineBlankIcon style={styles.leftIcon} />}
                                                color="primary" 
                                            />   
                                        )*/}
                                        <CircleButton
                                            style={marginButton}
                                            id={"updId" + idx}
                                            disabled={idx === 0 || this.state.startCompleteTransition}  
                                            toolTipMessage={t("MoveUp")}
                                            clickAction={() => this.moveRow(idx, true)}
                                            iconButton={<ArrowUpwardIcon style={styles.leftIcon} />}
                                            color="primary" 
                                        />   
                                        <CircleButton
                                            style={marginButton}
                                            id={"downId" + idx}
                                            disabled={(len - 1) === idx || this.state.startCompleteTransition}  
                                            toolTipMessage={t("MoveDown")}
                                            clickAction={() => this.moveRow(idx, false)}
                                            iconButton={<ArrowDownwardIcon style={styles.leftIcon} />}
                                            color="primary" 
                                        />  
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ExpansionPanelSummary>
                </ExpansionPanel>
            </React.Fragment>
            </Collapse>
        );
    }

    headers = () => {
        const { t } = this.props;
        return (
            <ExpansionPanel key={"headers"} expanded={false}
            style={{ backgroundColor: this.props.backgroundColor, marginBottom : "1px"  }}
            >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon style={{ color: "transparent" }}/>}>
                    <Grid container style={styles.ElementWithoutPadding}>
                        <Grid item sm xs={8}>
                            <Typography style={{paddingTop: "17px"}}>
                                {t("Cells")}
                            </Typography>
                        </Grid>
                        {/*
                        <Grid container item sm xs={8} justify="flex-end" alignItems="flex-end">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.isRowStyle}
                                        onChange={(e) => this.handleChange(e, 'isRowStyle')}
                                        color="primary"
                                    />
                                }
                                label={t("RowStyle")}
                            />
                        </Grid>
                        */}
                       
                        
                    </Grid>
                </ExpansionPanelSummary>
            </ExpansionPanel>
        )
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    handleEnd = (node, done) => {
        node.addEventListener("transitionend", this.doNext, false);
    };
    
    doNext = () => {
        if (this.state.startTransition) {
            this.setState({
                rowPositon : -1
            });

            var rows = [...this.state.filterOptions];

            var row = rows[this.state.fromIndex];
    
            rows.splice(this.state.fromIndex, 1);
            rows.splice(this.state.toIndex, 0, row);
            
            this.setState(prevState => ({
                ...prevState,
                transitionState : Object.values({
                    ...prevState.transitionState,
                    [this.state.toIndex]: false
                })
            }), function() {
                this.setState({
                    filterOptions : rows,
                    startTransition : false
                }, function() {
                    this.setState(prevState => ({
                        ...prevState,
                        transitionState : Object.values({
                            ...prevState.transitionState,
                            [this.state.fromIndex]: true
                        })
                    }), function() {
                        setTimeout(this.triggerChange, WAIT_INTERVAL);
                    });
                }); 
            });
        }
    };

    triggerChange = () => {
        this.setState({
            rowPositon : this.state.toIndex
        }, function() {
            this.setState(prevState => ({
                ...prevState,
                transitionState : Object.values({
                    ...prevState.transitionState,
                    [this.state.toIndex]: true
                })
            }), function() {
                this.setState({
                    startCompleteTransition : false
                });
            })
        });
    }

    handleChange = (e, id) => {
        let { checked } = e.target;
       
        this.setState({
            [id] : checked
        });
    }
   

    render() {
        const { t } = this.props;
        return (
            <Dialog fullScreen open={this.props.open} TransitionComponent={this.Transition}>
                <div style={{paddingLeft: "32px", paddingRight: "32px"}}>
                    <Breadcrumb
                        title={t("ListSettings")} 
                        backButton={true}
                        handleClose={this.handleFilterOptions} 
                        backMessage={t("Back")}
                    ></Breadcrumb>
                    <CardContent style={styles.ElementWithoutPadding}>
                        {this.headers()}
                        {this.state.filterOptions.map((row, i) => (
                            this.rowDetails(row, i, this.state.filterOptions.length)
                        ))}
                    </CardContent>
                </div>
            </Dialog>
        )
    };
}

export default withTranslation('common')(Filters);
