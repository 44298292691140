import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from './authorizationUtils';
import axios from 'axios';

// azure functions can not handle ambigous routes
// we need explicit defined those


export function Blobs(blobName, permissions) {
    const options = getRequestOptions();
    return axios.get(getApiUrl(serviceKeys.products) + `/api/Aggregate/Blob/GetSASToken?blobName=${blobName}&permissions=${permissions}`, options)
        .then(handleResponse)
        .catch(handleError);
}
  


