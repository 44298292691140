import React, { Component, Fragment } from "react";
import { TextField } from '@material-ui/core';
import { withTranslation   } from 'react-i18next';
import { LocaleDecimalSeparator, defaultDecimalSeparator }  from '../../i18n/locales'
import * as styles from '../common/styles';

export class NumberField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            disabled : this.props.disabled,
            id : this.props.id,
            label : this.props.label,
        };
    }
    
    componentDidMount() {
        this.setState({ value : this.props.value}, function () {
            this.changeDecimalSeparator(LocaleDecimalSeparator[this.props.lang]);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.setState({ value : this.props.value}, function () {
                this.changeDecimalSeparator(LocaleDecimalSeparator[this.props.lang]);
            });
        }
        if (prevProps.label !== this.props.label) {
            this.setState({ label : this.props.label});
        }
        if (prevProps.lang !== this.props.lang) {
            this.changeDecimalSeparator(LocaleDecimalSeparator[this.props.lang]);
        }
    }

    handleChange = (event) => {
        const { value } = event.target;
        if (this.validData(event)) {
            this.setState({ value : value});
            if (value.toString().trim() !== "" && value.toString().trim() !== "-") {
                var number = parseFloat(this.normalizeNumber(value));
                const event = {
                    target: {
                        id: this.props.id,
                        value: number,
                        dataset: {
                            record: this.props.record || ''
                        }
                    }
                };
                this.props.onChange(event);
            }
        }
        
    }

    changeDecimalSeparator = (separator) => {
        var value = this.state.value;
        
        if (value === undefined) {
            if (this.props.valueInit) {
                value = this.props.valueInit.toString();
            }
            else {
                value = "0";
            }
        }
        else {
            value = value.toString()
        }
        
        var number = "";
        for (var x = 0; x < value.length; x++) {
            if (value[x] !== "-" &&
                value[x] !== '0' && value[x] !== '1' && value[x] !== '2' && value[x] !== '3' && value[x] !== '4' &&
                value[x] !== '5' && value[x] !== '6' && value[x] !== '7' && value[x] !== '8' && value[x] !== '9' 
                ) {
                   number += separator;
            }
            else {
                number += value[x]
            }
        }
        this.setState({
            value : number
        }); 

        /*
        if (this.props.valueInit && number === this.props.valueInit.toString()) {
            this.props.setValueInit(number, this.props.propertyName);
        }*/

        return number;
    }

    normalizeNumber = (value) => {
        var number = "";
        for (var x = 0; x < value.length; x++) {
            if (value[x] !== "-" &&
                value[x] !== '0' && value[x] !== '1' && value[x] !== '2' && value[x] !== '3' && value[x] !== '4' &&
                value[x] !== '5' && value[x] !== '6' && value[x] !== '7' && value[x] !== '8' && value[x] !== '9' 
                ) {
                   number += defaultDecimalSeparator;
            }
            else {
                number += value[x]
            }
        }
        
        return number;
    };
 
    validData = (event) => {
        const { value } = event.target;
        var separator = LocaleDecimalSeparator[this.props.lang];
        var onetineSign = false;
        var onetineSeparator = false;
       
        for (var x = 0; x < value.length; x++) {
            if (value[x] === separator || value[x] === "-" ||
                value[x] === '0' || value[x] === '1' || value[x] === '2' || value[x] === '3' || value[x] === '4' ||
                value[x] === '5' || value[x] === '6' || value[x] === '7' || value[x] === '8' || value[x] === '9' 
                ) {
                if (value[x] === separator) {
                    if (!onetineSeparator) {
                        onetineSeparator = true;
                    }
                    else {
                        return false;
                    }
                }
                else if (value[x] === "-") {
                    if (!onetineSeparator) {
                        if (!onetineSign) {
                            onetineSign = true;
                        }
                        else {
                            return false;
                        }
                    }
                    else {
                        return false;
                    }
                }
            }
            else {
                return false;
            }
        }

        return true;   
    }

    onBlur = () => {
        let value = this.state.value;
        value = this.normalizeNumber(value);
       
        if (isNaN(value) || value.toString().trim() === "") {
            if (this.props.valueInit) {
                value = this.props.valueInit.toString();
            }
            else {
                value = "0";
            }
            
            this.setState({
                value : value
            }, function() {
                this.handleChange(
                    {
                        target: {
                            value: this.state.value,
                        }
                    }
                )
            });
        }
        else {
            const event = {
                target: {
                    id: this.props.id,
                    value: value,
                    dataset: {
                        record: this.props.record || ''
                    }
                }
            };
            this.props.onChange(event);
        }
    }

    render() {
       return (
            <Fragment>
                <TextField
                    disabled={this.state.disabled}
                    style={styles.inputElements}
                    id={this.state.id}
                    label={this.state.label}
                    value={this.state.value}
                    onChange={this.handleChange}
                    InputProps={this.props.InputProps}
                    onBlur={this.onBlur}
                />
                {this.props.AdormentRight &&
                    <span style={{...styles.endAdormentIput}} >
                    {
                        this.props.AdormentRight 
                    }
                    </span>    
                }
            </Fragment>
                
        );
    }
}

export default withTranslation('common')(NumberField);
