
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const CreateNewButton = withStyles({
    root: {
      marginTop: -30,
      minWidth: 'unset',
      borderRadius: 2,
      border: 0,
      color: 'white',
      height: 42,
      padding: '4px 20px',
      fontSize: 18,
      textAlign: 'center',
      background: '#e85a13',
    },
    label: {
      textTransform: 'uppercase',
    },
  })(Button);

export default CreateNewButton;