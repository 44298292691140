import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import IframeResizer from 'iframe-resizer-react';
import Spinner from '../common/CircularSpinner';
import * as notificationActions from "../../redux/actions/notificationsActions";
import * as sugarSessionActions from "../../redux/actions/sugarSessionActions";
import *  as navigationActions from '../../redux/actions/navigationActions';

const frameIdPrimary = 'sugarIFrame0';
const frameIdSecondary = 'sugarIFrame1';

export class LegacyIFrame extends React.Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();
        this.state = {
            isLoading: false,
            error: false,
            frameId: frameIdPrimary,
            path: 'about:blank',
            reportedLocation: '',
            seconds: 10,
        }
    }

    mapYerpUrlToReactRoute = (yerpUrl) => {
        const pathIdx = yerpUrl.indexOf('/index.php');
        const locationStr = '/yerp' + yerpUrl.substring(pathIdx + '/index.php'.length);
        return locationStr;
    }

    handleOnMessage = (event) => {
        const message = event.message;

        // Maintain a list of logged in sugar instances
        if ("type" in message) {
            if (message.type === "logout_success") {
                this.props.actions.sugarLogoutSuccess(message.sugarHost);
            }
        } else if ("sugarHost" in message) {
            if (!this.props.sugarSession.loggedInInstances.includes(message.sugarHost)) {
                this.props.actions.sugarLoginSuccess(message.sugarHost);
            }
        }

        //handle sugar toasters
        if("sugarToaster" in message){
            var toast = message.sugarToaster;
            switch(toast.iconClass){
                case "toast-success":
                    this.props.actions.notificationSuccess(toast.message);
                    break;
                case "toast-info":
                    this.props.actions.notificationInfo(toast.message);
                    break;
                case "toast-error":
                    this.props.actions.notificationError(toast.message);
                    break;
                case "clear":
                    this.props.actions.clearNotifications();
                    break;
                default:
                    break

            }
        }
        //const frame = this.ref.current;

        this.setState({
            isLoading: false,
            seconds: 10
        });
        if (!!message.name && (!!message.moduleUrl || !!message.url)) {
            let user_name = '';
            if (!!message.user_name) {
                user_name = message.user_name;
                sessionStorage.setItem("user_name",user_name);
            }

            let sugar_user_id = '';
            if (!!message.sugar_user_id) {
                sugar_user_id = message.sugar_user_id;
                sessionStorage.setItem("sugar_user_id", sugar_user_id);
            }

            this.onIFrameRouteChange(
                message.name,
                this.mapYerpUrlToReactRoute(message.url),
                this.mapYerpUrlToReactRoute(message.moduleUrl || message.url),
                //this.props.history.push(message.url),
                this.props.actions.setModule({ url: message.url, name: message.name })
            );
        }



        //handle inside iframe spinner

        if ("sugarSpinner" in message) {
            this.setState({
                isLoading: message.sugarSpinner,
                seconds: 10
            });
        }
    }

    initTimer = () => {
        this.myInterval = setInterval(() => {
            this.setState(({ seconds }) => ({
              seconds: seconds > 0 ? seconds - 1 : 0
            }))
          }, 1000)
    }

    healthCheck = () => {
        const transferFailed = () => {
            const message = this.props.t("ErrorLoadingPage");
            //this.props.actions.notificationError(message);
            this.setState({
                isLoading: false,
                error: true,
                //error: false,
            });
        };

        const transferCanceled = () => {
            this.setState({
                isLoading: false,
                error: false,
            });
        };

        const transferSuccess = () => {
            this.setState({
                isLoading: false,
                error: false,
            });
        };


        var oReq = new XMLHttpRequest();
        oReq.open("GET", this.state.path, true);
        oReq.addEventListener("error", transferFailed);
        oReq.addEventListener("abort", transferCanceled);
        oReq.addEventListener("load", transferSuccess);
        oReq.send();
    }

    componentDidMount() {
        const path = "https://" + this.props.sugarHost + "/index.php" + (this.props.location.search || '?m=2');
        this.initTimer();
        this.setState({
            isLoading: true,
            error: false,
            path: path,
            seconds: 10
        });
    }

    componentDidUpdate(prevProps, prevState) {
        var loc1 = (this.props.location.search || "?");
        if (loc1 != "?") {
            loc1 = loc1 + "&";
        }
        loc1 = loc1.replace("&user1id=", "");
        loc1 = loc1.replace(this.props.user.profile.sub, "");
        const path = "https://" + this.props.sugarHost + "/index.php" + loc1 + 'user1id=' + this.props.user.profile.sub;
        const forceRefresh = this.props.location.state?.clickIndicator !==  prevProps.location.state?.clickIndicator;
        const pathChanged = path !== this.state.path;

        if (pathChanged || forceRefresh) {
            this.setState({
                isLoading: true,
                error: false,
                path: path,
                seconds: 10,
                frameId: this.getNewFrameId(),
            });
        }

        if (this.state.isLoading && this.state.seconds === 0 && prevState.seconds > 0) {
            this.healthCheck();
        }
    }

    getNewFrameId = () => {
        return this.state.frameId === frameIdPrimary ? frameIdSecondary : frameIdPrimary;
    }

    onIFrameRouteChange = (title, location, moduleUrl) => {
        if (location !== this.state.reportedLocation) {
            this.setState({
                reportedLocation: location
            });

            this.props.onIFrameRouteChange(title, location, moduleUrl);
        }
    }

    render() {
        return (
            <div style={{margin: "0 -1em", height: this.props.contentHeight}}>
                {!!this.props.sugarHost && (
                    <IframeResizer
                        allow="geolocation"
                        key={this.state.frameId}
                        id={this.state.frameId}
                        src={this.state.path}
                        style={{width: "100%", height: this.props.contentHeight}}
                        sizeHeight={false}
                        scrolling={true}
                        heightCalculationMethod="lowestElement"
                        tolerance="90"
                        checkOrigin={false}
                        frameBorder="0"
                        warningTimeout={10000}
                        onMessage={this.handleOnMessage}
                        forwardRef={this.ref}
                    />
                )}
                <Spinner open={this.state.isLoading} />
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        sugarSession: state.sugarSession,
        user: state.oidc.user,
        contentHeight: state.ui.contentHeight,
        resourceAccess: state.resourceAccess
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            sugarLoginSuccess: bindActionCreators(sugarSessionActions.sugarLoginSuccess, dispatch),
            sugarLogoutSuccess: bindActionCreators(sugarSessionActions.sugarLogoutSuccess, dispatch),
            notificationError: bindActionCreators(notificationActions.notificationError, dispatch),
            notificationInfo: bindActionCreators(notificationActions.notificationInfo, dispatch),
            notificationSuccess: bindActionCreators(notificationActions.notificationSuccess, dispatch),
            notificationWarning: bindActionCreators(notificationActions.notificationWarning, dispatch),
            clearNotifications: bindActionCreators(notificationActions.clearNotifications, dispatch),
            setModule: bindActionCreators(navigationActions.setModule, dispatch)
        }
    };
}

export default compose(withTranslation('common'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(LegacyIFrame);
