import { store } from '../redux/store';
import { forageStore } from '../utils/forageStore';
import { setup } from 'axios-cache-adapter';
import env from '../utils/env';


export const serviceKeys = {
    workCards: 'workcards',
    tenants: 'tenants',
    documents: 'documents',
    documentSharing: 'documentsharing',
    products: 'products',
    translations: 'translations',
    sugar: 'sugar',
    realTime: 'realTime',
    users: 'users'

};

export async function handleResponse1(response) {
    if (response.status === 200) {
        if (response.data.success || typeof(response.data.success) === "undefined") {
            sessionStorage.setItem('menu1', JSON.stringify(response.data));
            return response.data;
        }
        else {
           throw new Error(response.data);
        }
    }
    else if (response.status === 400) {
        throw new Error(response.data);
    }
    else {
        throw new Error("Network response was not ok.");
    }
}

export async function handleResponse2(response) {
    if (response.status === 200) {
        if (response.data.success || typeof (response.data.success) === "undefined") {
            sessionStorage.setItem('menu2', JSON.stringify(response.data));
            return response.data;
        }
        else {
            throw new Error(response.data);
        }
    }
    else if (response.status === 400) {
        throw new Error(response.data);
    }
    else {
        throw new Error("Network response was not ok.");
    }
}

export async function handleResponse(response) {
    if (response.status === 200) {
        if (response.data.success || typeof(response.data.success) === "undefined") {
            return response.data;
        }
        else {
           throw new Error(response.data);
        }
    }
    else if (response.status === 400) {
        throw new Error(response.data);
    }
    else {
        throw new Error("Network response was not ok.");
    }
}

export function handleError(error) {
    var textError = "";
    if (!error.response ) {
        textError = error;
    }
    else if (error.response.data.hasOwnProperty("errors")) {
        textError = error.response.data.errors.message;
    }
    else {
        textError = "SystemError" + error.response.status;
    }
    throw textError;
}

export function handleError1(error) {
    sessionStorage.removeItem("menu1")
    var textError = "";
    if (!error.response ) {
        textError = error;
    }
    else if (error.response.data.hasOwnProperty("errors")) {
        textError = error.response.data.errors.message;
    }
    else {
        textError = "SystemError" + error.response.status;
    }
    throw textError;
}

export function handleError2(error) {
    sessionStorage.removeItem("menu2")
    var textError = "";
    if (!error.response) {
        textError = error;
    }
    else if (error.response.data.hasOwnProperty("errors")) {
        textError = error.response.data.errors.message;
    }
    else {
        textError = "SystemError" + error.response.status;
    }
    throw textError;
}



export function getApiUrl(serviceKey) {
    var url = "";

    if (process.env.REACT_APP_URL_MOCK && !process.env.REACT_APP_USE_MOCK_API_RESOURCE_ACCESS) {
        if (serviceKey === serviceKeys.realTime) {
            url = env.realTime;
        }
        else {
            url = process.env.REACT_APP_URL_MOCK
        }
    } else if (serviceKey === serviceKeys.workCards) {
        url = env.workCardsApiUrl;
    } else if (serviceKey === serviceKeys.documents || serviceKey === serviceKeys.documentSharing) {
        url = env.documentsApiUrl;
    } else if (serviceKey === serviceKeys.tenants) {
        url = env.tenantsApiUrl;
    } else if (serviceKey === serviceKeys.products) {
        url = env.productsApiUrl;
    } else if (serviceKey === serviceKeys.sugar) {
        url = "https://" + store.getState().tenant.host;
    } else if (serviceKey === serviceKeys.users) {
        url = env.usersApiUrl;
    } else if (serviceKey === serviceKeys.realTime) {
        url = env.realTime;
    } else {
        throw new Error(`Invalid service key ${serviceKey}.`);
    }




    return url;
}

export const configureCachedApi = async (url, maxCacheAgeMs) => {
    // Create `axios` instance with pre-configured `axios-cache-adapter` using a `localforage` store
    return setup({
        // `axios` options
        baseURL: url,

        // `axios-cache-adapter` options
        cache: {
            maxAge: maxCacheAgeMs,
            store: forageStore // Pass `localforage` store to `axios-cache-adapter`
        }
    });
}
