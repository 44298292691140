import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from './authorizationUtils';
import axios from 'axios';
import * as constants from "../components/common/constants";
import FilterHelper from "../helpers/filterHelper"; 

// azure functions can not handle ambigous routes
// we need explicit defined those

export function ReadUsersGeneralInfo(filter, sort, page,  cancelToken) {
    const options = getRequestOptions(cancelToken);
    return axios.get(getApiUrl(serviceKeys.users) +  `/api/Aggregate/User/GetAllGeneralInfo?filter=${filter}&sort=${sort}&page=${page}`, options)
        .then(handleResponse)
        .catch(handleError);
}

export function ReadUserBatch(batch, cancelToken) {
    const options = getRequestOptions(cancelToken);
    return axios.post(getApiUrl(serviceKeys.users) + `/api/Aggregate/User/GetBatch`, batch, options)
        .then(handleResponse)
        .catch(handleError);
}

export function ReadUsersGetAll(filter, sort, page, cancelToken) {
    var data = {
        filter : filter,
        sort: sort,
        page: page,
        searchFields: FilterHelper.getSearchfilter(constants.usersManagementFilter)
    }

    const options = getRequestOptions(cancelToken);
    return axios.post(getApiUrl(serviceKeys.users) +  `/api/Aggregate/User/GetAll`, data, options)
    .then(handleResponse)
    .catch(handleError);
}


export function GetUser(id) {
    const options = getRequestOptions();
    return axios.get(getApiUrl(serviceKeys.users) +  `/api/Aggregate/User/Get/${id}`, options)
    .then(handleResponse)
    .catch(handleError);
}

export function CheckUsername(username) {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.users) +  `/api/Aggregate/User/checkusername?username=${username}`, null, options)
    .then(handleResponse)
    .catch(handleError);
}

export function createUser(user) {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.users) +  `/api/Aggregate/User/createuser`, user, options)
    .then(handleResponse)
    .catch(handleError);
}

export function updateUser(id, user) {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.users) +  `/api/Aggregate/User/updateuser/${id}`, user, options)
    .then(handleResponse)
    .catch(handleError);
}

export function deleteUser(id) {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.users) +  `/api/Aggregate/User/deleteuser/${id}`, null , options)
    .then(handleResponse)
    .catch(handleError);
}

export function resetpassword(id, data) {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.users) +  `/api/Aggregate/User/resetpassword/${id}`, data , options)
    .then(handleResponse)
    .catch(handleError);
}
