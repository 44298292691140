import * as blobsApi from "../../api/blobsApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";
import { BlobServiceClient } from "@azure/storage-blob";
import * as constants from "../../components/common/constants";
import * as types from "./actionTypes";

export function updateBlobProgress(blobProgress) {
    return { type: types.UPDATE_BLOB_PROGRESS, blobProgress };
}


export function Blobs(blobName, permission, blob, abortSignal) {
    return async function(dispatch) {
        dispatch(beginApiCall());

        var token = undefined;

        await blobsApi
        .Blobs(blobName, permission)  
        .then(result => {
            token = result;
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorGettingBlobToken"));
            throw new Error(error);
        });
        if (token) {
            var blobServiceClient = new BlobServiceClient(token.uri);
            
            if (token.permission === constants.blobPermissions.Read) 
            {
                const readPromise = Promise.resolve(token.uri);
                return readPromise
                .then((result) => {
                    return result;
                })
                .catch(error => {
                    dispatch(apiCallError(error));
                    dispatch(notifications.notificationError("ErrorReadingBlob"));
                    throw new Error(error);
                });
            } 
            else if (token.permission === constants.blobPermissions.Write && blob) 
            {
                const containerClient = blobServiceClient.getContainerClient(token.container);
                const blockBlobClient = containerClient.getBlockBlobClient(token.blobName);
                await blockBlobClient.upload(blob, blob.size, { onProgress: (ev) => 
                    dispatch(updateBlobProgress({progress:ev.loadedBytes,size:blob.size})),
                    blobHTTPHeaders :{blobContentType:blob.type},
                    abortSignal
                })
                .then(result => {
                    dispatch(updateBlobProgress({progress:0,size:0}));
                    dispatch(notifications.notificationSuccess("BlobOperationSuccessfull"));
                })
                .catch(error => {
                    if (error.name === 'AbortError') {
                    dispatch(notifications.notificationSuccess("BlobUploadCanceled"));
                    }else{
                    dispatch(apiCallError(error));
                    dispatch(notifications.notificationSuccess("ErrorWritingBlob"));
                    }
                    throw new Error(error);
                });
            }
            else if (token.permission === constants.blobPermissions.Delete) {
                const containerClient = blobServiceClient.getContainerClient(token.container);
                const blockBlobClient = containerClient.getBlockBlobClient(token.blobName);
                await blockBlobClient.delete()
                .then(result => {
                    dispatch(notifications.notificationSuccess("BlobOperationSuccessfull"));
                })
                .catch(error => {
                    dispatch(apiCallError(error));
                    dispatch(notifications.notificationError("ErrorDeletingBlob"));
                    throw new Error(error);
                });
            }  
        }

            
    };
} 
