
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { v4 as uuidv4 } from 'uuid';
import logo from './up_logo.png';
import logo_small from './up_logo_small.png';

const useStyles = makeStyles({
  logo: {
    margin: 5,
    width:55,
    height:100,
    objectFit: "scale-down",
    cursor:'pointer',
  },
  bigLogo: {
    margin: 5,
    width: 150,
    height: 100,
    objectFit: "scale-down",
    cursor:'pointer'
  },
});

export function Logo(props) {
  const classes = useStyles();  
  const [wideLogo, setWideLogo] = React.useState(props.minimized);
  var logoClass = (props.minimized ? classes.logo : classes.bigLogo);
  const visiblelogo = (props.minimized ? logo_small : logo);
    useEffect(() => {
        setWideLogo(visiblelogo);
  }, [visiblelogo])

  const handleRoute = (item) => {
    props.actions.setModule({ url: "", name: ""})
    props.history.push('/yerp', {clickIndicator: uuidv4()})
    props.close();
  }
    if (!!props.tenantInfo && !!props.tenantInfo.host) {
        const baseurl = "https://" + (props.tenantInfo != null ? props.tenantInfo.host : window.location.host);
        return (
            <Grid container justify="center" alignItems="center">
                <a href={baseurl}><img alt="Urakointi+" src={wideLogo.toString()} className={logoClass} /></a>
            </Grid>
        );
    } else {
        return (
            <Grid onClick={() => handleRoute()} container justify="center" alignItems="center">
                <img  alt="Urakointi+" src={wideLogo.toString()} className={logoClass} />
            </Grid>
        );
    }
}

export default Logo;

