export default class statefulSessionHelper {

    static setValues = (id, data) => {
        localStorage.setItem(id + "Values", JSON.stringify(data));
    }

    static getValues = (id) => {
        return JSON.parse(localStorage.getItem(id + "Values"));
    }

    static setSearchValue = (id, data) => {
        localStorage.setItem(id + "Search", JSON.stringify(data));
    }

    static getSearchValue = (id) => {
        return JSON.parse(localStorage.getItem(id + "Search"));
    }
}