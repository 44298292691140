import React from "react";
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import LegacyIFrame from './legacyIFrame';
import *  as sugarSessionActions from '../../redux/actions/sugarSessionActions';

export class SugarLogout extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            currentLoggingOutHost: '',
            loggedOut: [],
            location: {
                search: '?module=Users&action=Logout',
                pathname: '/index.php',
            }
        }
    }

    componentDidMount() {
        this.props.actions.loadloggedInInstances();
        this.componentDidUpdate();
    }

    componentDidUpdate(prevProps, prevState) {
        console.log("componentDidUpdate");
        if (!!this.props.sugarSession) {
            const instances = this.props.sugarSession.loggedInInstances;

            if (!this.props.sugarSession.loading && instances.length > 0) {
                const currentHost = this.state.currentLoggingOutHost;
    
                // If the currentLoggingOutHost has disappeared from the loggedInInstances
                // it has been successfully logged out
                if (!instances.includes(currentHost)) {
                    // Take the next instance to log out
                    const nextHost = instances[0];
                    this.setState((prev) => {
                        return {
                            currentLoggingOutHost: nextHost,
                            loggedOut: currentHost !== '' ? 
                                prev.loggedOut.concat([currentHost]) :
                                prev.loggedOut 
                        }
                    });
                }
            } else if (!this.props.sugarSession.loading) {
                this.props.history.push('/');
            }
        }
    }

    render() {
        return !this.props.sugarSession.loading && this.state.currentLoggingOutHost !== ''
            && (
            <LegacyIFrame
                sugarHost={this.state.currentLoggingOutHost} 
                location={this.state.location}
                contentHeight={this.props.contentHeight}
                onIFrameRouteChange={this.onIFrameRouteChange}
                />
        );
    }
}

function mapStateToProps(state) {
    return {
        sugarSession: state.sugarSession,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            loadloggedInInstances: bindActionCreators(sugarSessionActions.loadloggedInInstances, dispatch)
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SugarLogout));