import * as types from "./actionTypes";
import * as usersApi from "../../api/usersApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";
import * as constants from "../../components/common/constants";

export function readUsersGeneralInfoSuccess(usersGeneralInfo) {
    return { type: types.LOAD_USERS_SUCCESS, usersGeneralInfo };
}

export function ReadUsersGeneralInfo(filter, sort, page, cancelToken) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return usersApi
        .ReadUsersGeneralInfo(filter, sort, page, cancelToken)  
        .then(usersGeneralInfo => {
            dispatch(readUsersGeneralInfoSuccess(usersGeneralInfo));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (typeof(error.message) !== "undefined" &&
                error.message !== constants.cancelHttpRequest) {
                dispatch(notifications.notificationError("ErrorLoadingUsers"));
                throw new Error(error);
            }
        });
    };
} 

export function readUsersSuccess(users) {
    return { type: types.LOAD_DATA_USERS_SUCCESS, users };
}

export function ReadUsers(filter, sort, page, cancelToken) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return usersApi
        .ReadUsersGetAll(filter, sort, page, cancelToken)  
        .then(users => {
            dispatch(readUsersSuccess(users));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (typeof(error.message) !== "undefined" &&
                error.message !== constants.cancelHttpRequest) {
                dispatch(notifications.notificationError("ErrorLoadingUsers"));
                throw new Error(error);
            }
        });
    };
} 

export function getUserSuccess(user) {
    return { type: types.READ_USER_SUCCESS, user };
}

export function getUser(id) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return usersApi
        .GetUser(id)  
        .then(result => {
            dispatch(getUserSuccess(result));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (typeof(error.message) !== "undefined" &&
                error.message !== constants.cancelHttpRequest) {
                dispatch(notifications.notificationError("ErrorLoadingUser"));
                throw new Error(error);
            }
        });
    };
} 

export function checkUsernameSuccess(user) {
    return { type: types.CHECK_USER_NAME_SUCCESS, user };
}

export function checkUsername(username) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return usersApi
        .CheckUsername(username)  
        .then(result => {
            //dispatch(checkUsernameSuccess(result));
            return result.value;
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (typeof(error.message) !== "undefined" &&
                error.message !== constants.cancelHttpRequest) {
                dispatch(notifications.notificationError("ErrorChekingUserName"));
                throw new Error(error);
            }
        });
    };
} 

export function createUserSuccess(user) {
    return { type: types.UPDATE_USER_SUCCESS, user };
}

export function createUser(username) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return usersApi
        .createUser(username)  
        .then(result => {
            //dispatch(createUserSuccess(result));
            return result;
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (typeof(error.message) !== "undefined" &&
                error.message !== constants.cancelHttpRequest) {
                dispatch(notifications.notificationError("ErrorCreatingUser"));
                throw new Error(error);
            }
        });
    };
} 

export function updateUserSuccess(user) {
    return { type: types.UPDATE_USER_SUCCESS, user };
}

export function updateUser(id, user) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return usersApi
        .updateUser(id, user)  
        .then(result => {
            //dispatch(updateUserSuccess(result));
            if (result) {
                return true;
            }
            return false;
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (typeof(error.message) !== "undefined" &&
                error.message !== constants.cancelHttpRequest) {
                dispatch(notifications.notificationError("ErrorUpdatingUser"));
                throw new Error(error);
            }
        });
    };
} 

export function deleteUserSuccess(user) {
    return { type: types.UPDATE_USER_SUCCESS, user };
}

export function deleteUser(id) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return usersApi
        .deleteUser(id)  
        .then(result => {
            //dispatch(updateUserSuccess(result));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (typeof(error.message) !== "undefined" &&
                error.message !== constants.cancelHttpRequest) {
                dispatch(notifications.notificationError("ErrorDeletingUser"));
                throw new Error(error);
            }
        });
    };
} 

export function resetpasswordSuccess(user) {
    return { type: types.UPDATE_USER_PASSWORD_SUCCESS, user };
}

export function resetpasswordUser(id, data) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return usersApi
        .resetpassword(id, data)  
        .then(result => {
            //dispatch(updateUserSuccess(result));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (typeof(error.message) !== "undefined" &&
                error.message !== constants.cancelHttpRequest) {
                dispatch(notifications.notificationError("ErrorUpdatingPassword"));
                throw new Error(error);
            }
        });
    };
} 


