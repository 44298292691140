import React,{useEffect } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green, brown, blue } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CodeIcon from '@material-ui/icons/Code';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  debug: CodeIcon
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: blue[700],
  },
  warning: {
    backgroundColor: amber[700],
  },
  debug: {
    backgroundColor: brown[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  close: {
    padding: theme.spacing(0.5),
  },
  margin: {
    margin: theme.spacing(1),
    borderRadius : "5px"
  },
}));

export default function SnackbarContentWrapper(props) {
  const {message, variant } = props;  
  const classes = useStyles1();
    const Icon = variantIcon[variant];
    let timeout = 2000;
    if (variant == "error" || variant == "warning") {
        timeout = 10000;
    }
  useEffect(() => {
    setTimeout(() => {
      handleClose();
    }, timeout)
  });

  
  const handleClose = () => {
    props.onClose();
  };


  return (
    <SnackbarContent
        className={clsx(classes[variant])}
        aria-describedby="client-snackbar"
        message={
        <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
        </span>
        }
        action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon className={classes.icon} />
        </IconButton>,
        ]}
    />
  );
}

