import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container } from '@material-ui/core';
import TopNavBar from './Navbar/TopNavBar';
import SideNavBar from './Navbar/SideNavBar';
import {minimizedWidth, maximizedWidth} from './Navbar/SideNavBar';
import BackDrop from './Backdrop/Backdrop';
import { ProtectedContent } from './auth/protectedContent';
import Notifications from "../components/Notifications";
import *  as uiActions from '../redux/actions/uiActions';
import '../index.css';
import 'normalize.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { RealTimeMessages } from "./realTimeMessages";


// This is the default height of the top navigation bar
const topNavBarHeight = 56;
// This is the expected padding on the Box component
const bottomPadding = 0;

export class Layout extends Component {
  constructor(props) {
    super(props)
    this.props = props;
    this.state = {
      sideDrawerOpen: false,
      minimized: false,
      width: 0,
      topNavBarHeight: topNavBarHeight,
      contentHeight: window.innerHeight - topNavBarHeight - bottomPadding
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  drawerClickHandler = () => {

    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
    this.setState({minimized:false});

  };
  updateWindowDimensions() {
    let contentHeight = window.innerHeight - topNavBarHeight - bottomPadding;
    this.setState({
      width: window.innerWidth,
      contentHeight: contentHeight
    });
    this.props.actions.resizeWindow({
      width: window.innerWidth,
      height: window.innerHeight,
      bodyHeight: (window.innerHeight - topNavBarHeight),
      contentHeight: contentHeight,
    });
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  sideNavMinimizeHandler = () => {
    if (document.getElementsByClassName('MuiCollapse-wrapper')) {
      [].forEach.call(document.getElementsByClassName('MuiCollapse-wrapper'), (el) => {
        var visible = (this.state.minimized ? 'block' : 'none');
        el.style.display = visible;
      });
    }
    this.setState((prevState) => {
      return { minimized: !prevState.minimized };
    });
  };

  render() {
    let backdrop;
    if (this.state.sideDrawerOpen) {
      backdrop = <BackDrop click={this.backdropClickHandler} />
    }

    return (
      <div>
        <ProtectedContent>
          <TopNavBar
          drawerClickHandler={this.drawerClickHandler}
          width={this.state.width}
          sideNavMinimized={this.state.minimized}
          height={this.state.topNavBarHeight} />
          <SideNavBar
            width={this.state.width}
            show={this.state.sideDrawerOpen}
            minimized={this.state.minimized}
            close={this.backdropClickHandler}
            sideNavMinimizeHandler={this.sideNavMinimizeHandler}
          />
        </ProtectedContent>
        {backdrop}
        <Container maxWidth='false'
          style={{ width: 'auto', height:'100vh', overflow: 'hidden', padding: '0 0', paddingTop: topNavBarHeight, marginLeft: (this.state.width > 960 ? (this.state.minimized ? minimizedWidth : maximizedWidth) : "0px") }}
          disableGutters={this.state.width > 960 ? false : true}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className="content-area" style={{height: this.state.contentHeight, overflow: "auto"}}>
                {this.props.children}
              </div>
            </MuiPickersUtilsProvider>
           <Notifications />
           <RealTimeMessages
              isConnectionActive={this.props.isConnectionActive}
              isSessionActive={this.props.isSessionActive}
              tenant={this.props.tenant}
              accessToken={this.props.oidc.user ? this.props.oidc.user.access_token : undefined }
              realTimeSendMessage={this.props.realTimeSendMessage}
              userExpired={this.props.oidc.user ? this.props.oidc.user.expired : undefined }>
            </RealTimeMessages>
         </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ui: state.ui,
    isConnectionActive: state.isConnectionActive,
    isSessionActive : state.isSessionActive,
    tenant: state.tenant,
    oidc: state.oidc,
    realTimeSendMessage : state.realTimeSendMessage
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      resizeWindow: bindActionCreators(uiActions.resizeWindow, dispatch),
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout);
