import * as types from "./actionTypes";
import * as workQueueSettingsApi from "../../api/workQueueSettingsApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as notifications from "./notificationsActions";
import * as constants from "../../components/common/constants";
import * as usersApi from "../../api/usersApi";
import * as WorkOrderApi from "../../api/workOrderApi";
import * as WorkSitesApi from "../../api/workSiteApi";
import * as WorkQueuesApi from "../../api/workQueueApi";
import * as WorkQueueActions from "./workQueueActions";
import initialState from "../reducers/initialState";


export function readworkQueuesSettingsSuccess(workQueuesSettings) {
    return { type: types.LOAD_WORKQUEUESETTINGS_SUCCESS, workQueuesSettings };
}

export function readworkQueuesSettings(filter, sort, page, ownerId, cancelToken) {
    return async function(dispatch) {

        var settings = [];
        await workQueueSettingsApi
        .ReadWorkQueuesSettings(filter, sort, page, ownerId, cancelToken)  
        .then(result => {
            settings = result;
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (error.message !== constants.cancelHttpRequest)
            {
                dispatch(notifications.notificationError("ErrorloadingworkQueuesSettings"));
                throw new Error(error);
            }
        });

        var dict = {};
        settings.map(element => {
            if (element.ownerId !== "") {
                dict[element.ownerId] = "";
            }
            return element;
        })
        var batch = [];
        for(var key in dict) {
            batch.push(key)
        }
        var sendData = {
            batch : batch
        }

        await usersApi
        .ReadUserBatch(sendData, cancelToken)  
        .then(result => {
            for (var x = 0; x < settings.length; x++) {
                var element = findElement(result, settings[x].ownerId);
                if (element) {
                    settings[x].ownerNameTmp = element.name;
                }
                else {
                    settings[x].ownerNameTmp = "";
                }
            }
            dispatch(readworkQueuesSettingsSuccess(settings));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (error.message !== constants.cancelHttpRequest) {
                dispatch(notifications.notificationError("ErrorloadingUsers"));
                throw new Error(error);
            }
        });

    };
} 

function findElement(items, item) {
    return items.find(e => e.id === item);
}

export function readworkQueueSettingsSuccess(workQueueSettings) {
    return { type: types.READ_WORKQUEUESETTINGS_SUCCESS, workQueueSettings };
}

export function readworkQueueSettings(id, cancelToken) {
    return async function(dispatch) {
        dispatch(beginApiCall());
        
        var settings = {}; 
        await workQueueSettingsApi
        .ReadWorkQueueSettings(id)  
        .then(result => {
            settings = result;
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorloadingworkQueueSettings"));
            throw new Error(error);
        });

        let workqueues = [];
            
        var sendData = {
            batch : settings.workQueues
        }

        await WorkQueuesApi
        .ReadWorkQueueBatch(sendData, cancelToken)  
        .then(result => {
            workqueues = result;
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (error.message !== constants.cancelHttpRequest)
            {
                dispatch(notifications.notificationError("ErrorloadingWorkQueues"));
                throw new Error(error);
            }
        });
       
        var batch = [];
      
        workqueues.map(element => {
            element.workOrders.map(order => {
                batch.push(order);
                return order;
            })
            return element;
        })

        sendData = {
            batch : batch
        }
       
        let workorders = [];
        
        await WorkOrderApi
        .ReadWorkOrderBatch(sendData, cancelToken)  
        .then(result => {
            workorders = result;
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (error.message !== constants.cancelHttpRequest)
            {
                dispatch(notifications.notificationError("ErrorloadingWorkOrders"));
                throw new Error(error);
            }
        });

        var dict = {};
        workorders.map(element => {
            if (element.workSiteId !== "") {
                dict[element.workSiteId] = "";
            }
            return element;
        })

        batch = [];
        for(var key in dict) {
            batch.push(key)
        }
        sendData = {
            batch : batch
        }

        
        let  worksites = [];

        await WorkSitesApi
        .ReadWorkSiteBatch(sendData, cancelToken)  
        .then(result => {
            worksites = result;
        })
        .catch(error => {
            dispatch(apiCallError(error));
            if (error.message !== constants.cancelHttpRequest)
            {
                dispatch(notifications.notificationError("ErrorloadingWorkSites"));
                throw new Error(error);
            }
        });
        
        var myMap = {};
        for (var x = 0; x < workqueues.length; x++) {
            var items = [];
            for (var y = 0; y < workqueues[x].workOrders.length; y++) 
            {
                var item = {};

                var workorder = findElement(workorders, workqueues[x].workOrders[y].toString());
                
                item.id = workorder.id.toString();
                item.content = undefined;
                item.show = true;

                delete workorder.Products;
                item.workOrder = workorder;

                var worksite = findElement(worksites, workorder.workSiteId);
                if (worksite) {
                    delete worksite.contacts;
                    item.workSite = worksite;  
                }
                else {
                    item.workSite = initialState.workSite;  
                }
              
                items.push(item);
            }
            
            myMap[workqueues[x].id] = 
            {
                name: workqueues[x].title,
                workQueueSettingsId: workqueues[x].workQueueSettingsId,
                ownerId: workqueues[x].ownerId,
                items: items,
                members: workqueues[x].members,
                cardColor: workqueues[x].cardColor,
                allowCreateCards: workqueues[x].allowCreateCards,
           
            };
        }

        dispatch(WorkQueueActions.readWorkQueuesSuccess(myMap));
        dispatch(readworkQueueSettingsSuccess(settings));
        
    };
} 

export function updateworkQueueSettingsSuccess(workQueueSettings) {
    return { type: types.UPDATE_WORKQUEUESETTINGS_SUCCESS, workQueueSettings };
}

export function updateworkQueueSettings(model) {
    return function(dispatch) {
        dispatch(beginApiCall());
        return workQueueSettingsApi
        .updateWorkQueueSettings(model)  
        .then(result => {
            dispatch(updateworkQueueSettingsSuccess(result));
        })
        .catch(error => {
            dispatch(apiCallError(error));
            dispatch(notifications.notificationError("ErrorupdatingworkQueueSettings"));
            throw new Error(error);
        });
    };
} 

