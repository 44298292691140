import React, { Component } from 'react';
import { ProductUnitsList } from "../common/Lists/productUnitsList";
import { connect } from 'react-redux';
import *  as productUnitActions from '../../redux/actions/productUnitActions';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import CancelPromisesHOC   from "../../helpers/cancelPromisesHOC";
import { withRouter } from "react-router-dom";
import *  as navigationActions from '../../redux/actions/navigationActions';
import {setCurrentModule} from "../../utils/navigationUtil";


export class ProductUnits  extends Component {    
    componentDidMount(){
        this.props.actions.setModule(setCurrentModule('/productUnits'));
    }
    render() {
        return (
            <ProductUnitsList {...this.props} />
        )
    };
}

function mapStateToProps(state) {
    return {
        productUnits : state.productUnits,
        moduleInfo: state.navigation.module
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            readProductUnits: bindActionCreators(productUnitActions.readProductUnits, dispatch),
            updateProductUnit: bindActionCreators(productUnitActions.updateProductUnit, dispatch),
            setModule: bindActionCreators(navigationActions.setModule, dispatch)
        }
    };
  }

export default compose(withTranslation('common'),
CancelPromisesHOC,
connect(
    mapStateToProps,
    mapDispatchToProps,
))(withRouter(ProductUnits));