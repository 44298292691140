import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const MinimizeSideButton = withStyles({
    root: {
      float: 'right',
      border: 0,
      color: 'white',
      padding: '20px 25px',
      fontSize: 22,
      textAlign: 'center',
    },
    label: {
      textTransform: 'capitalize',
    },
  })(Button);

export default MinimizeSideButton;
