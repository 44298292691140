import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import NumberField from "../../NumberField";
import {
    Grid,
    Card,
    CardContent,
    Container,
    Button,
    FormControlLabel,
    Checkbox

} from '@material-ui/core';
import * as styles from "../../styles";
import CheckTwoToneIcon from '@material-ui/icons/CheckTwoTone';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

export class ProductWorkOrderRow  extends Component {

    constructor(props) {
        super(props);
        this.state = {
            row : this.props.row, 
            checked : this.props.row.installed 
        }
    }

    componentDidMount() {
        if (this.props.quantityInit) {
            this.setState({
                row: {...this.state.row, 
                    plannedQuantity : this.props.quantityInit,
                    actualQuantity : this.props.quantityInit,
                },
            });
        }

    }
    
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.row !== this.props.row) {
            this.setState({
                row: this.props.row,
                checked : this.props.row.installed
            });
        }
   }

    handleChange = (e, id) => {
        let { value } = e.target;
        if (id === "installed") {
            value = e.target.checked
            this.setState({
                checked : value
            })
        }

        const { idx } = this.props;

        this.setState(prevState => ({
            ...prevState,
            row : {
                ...prevState.row,
                [id]: value
            },
        }), function() {
            this.props.handleRowChange(value, id, idx, this.props.isBuilder);
        });

       
    }

    setValueInit = (value, id) => {
        this.handleChange({
            target : {
                value : value
            }
        }, id)
    }

    render() {
        const {t, i18n, idx, isBuilder, isListModal} = this.props;
        return (
            <Fragment>
                 <Grid container style={{...styles.ElementWithoutPadding, ...{marginBottom: "0px"}}}>
                    <Container maxWidth="sm" style={{...styles.ElementWithoutPadding, ...this.props.style}}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <NumberField
                                    id={"plannedQuantity" + this.props.idx}
                                    label={t("PlannedQuantity")}
                                    value={this.state.row.plannedQuantity}
                                    onChange={(e) => this.handleChange(e, "plannedQuantity")}
                                    lang={i18n.language}
                                    valueInit={this.props.quantityInit}
                                    setValueInit={this.setValueInit}
                                    propertyName={"plannedQuantity"}
                                    isBuilder={this.props.isBuilder}
                                />  
                            </CardContent>
                        </Card>
                    </Container>
                    <Container maxWidth="sm" style={{...styles.ElementWithoutPadding, ...this.props.style}}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <NumberField
                                    id={"actualQuantity" + this.props.idx}
                                    label={t("ActualQuantity")}
                                    value={this.state.row.actualQuantity}
                                    onChange={(e) => this.handleChange(e, "actualQuantity")}
                                    lang={i18n.language}
                                    valueInit={this.props.quantityInit}
                                    setValueInit={this.setValueInit}
                                    propertyName={"actualQuantity"}
                                    isBuilder={this.props.isBuilder}
                                />
                            </CardContent>
                        </Card>
                    </Container>
                    <Container maxWidth="sm" style={{...styles.ElementWithoutPadding, ...this.props.style}}>
                        <Card style={styles.CardWithoutBoxes}>
                            <CardContent style={styles.ElementWithoutPadding}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.checked}
                                            onChange={(e) => this.handleChange(e, "installed")}
                                            name="installed"
                                            color="primary"
                                        />
                                    }
                                    label={t("Installed")}
                                />
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
                {isListModal &&
                    <Grid container style={{...styles.ElementWithoutPadding, ...{marginBottom: "0px"}}} direction="row" justify={"flex-end"}>
                        {!isBuilder ? (
                            <Button id="selectId" variant="contained" color="primary" className="circleButton" 
                                style={styles.circleButton}
                                onClick={(e) => this.props.handleInsert(e, this.state.row, idx)} 
                                >
                                <CheckTwoToneIcon  style={styles.leftIcon}/>
                            </Button>
                        ) : (
                            <Fragment>
                                <Button id="selectId" variant="contained"  className="circleButton" 
                                    style={styles.circleButton}
                                    onClick={(e) => this.props.handleDelete(e, idx)} 
                                    >
                                    <DeleteIcon style={styles.leftIcon} />
                                </Button>
                                <Button id="selectId" variant="contained" color="primary" className="circleButton" 
                                    style={styles.circleButton}
                                    onClick={(e) => this.props.handleEdit(e, idx)} 
                                    >
                                    <EditIcon  style={styles.leftIcon}/>
                                </Button>
                            </Fragment>
                        ) 
                        }
                    </Grid>
                }
            </Fragment>
        )
    };
}

export default withTranslation('common')(ProductWorkOrderRow);
