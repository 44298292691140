import { handleResponse, handleError, serviceKeys, getApiUrl } from "./apiUtils";
import getRequestOptions from './authorizationUtils';
import axios from 'axios';

export function ReadPersons(projectId) {
  const options = getRequestOptions();
  return axios.get(getApiUrl(serviceKeys.workCards) + `/api/Aggregate/Persons?projectId=${projectId}`, options)
      .then(handleResponse)
      .catch(handleError);
}

export function updatePerson(person) {
    const options = getRequestOptions();
    return axios.post(getApiUrl(serviceKeys.workCards) + `/api/Aggregate/Persons`, person, options)
        .then(handleResponse)
        .catch(handleError);
}


