import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function rolesReducer(state = initialState.roles , action) {
    if (action.type === types.LOAD_ROLES_SUCCESS)
    {
        return action.roles
    }
    else {
        return state;
    }
}
