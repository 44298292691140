import * as types from "./actionTypes";
import { getTranslations } from "../../i18n/external";
import i18next from 'i18next';
import i18n from "../../i18n/i18n";

import * as notifications from "./notificationsActions";
import { Locales, defaultLanguage }  from '../../i18n/locales';


export function loadTranslationsSuccess(value) {
    return { type: types.LOAD_TRANSLATIONS_SUCCESS, translationLoaded : value };
}

export function loadTranslations() {
    return function(dispatch) {
        try {
            const translations = getTranslations();
            i18next.init();
            Object.keys(translations).forEach(function(key) {
                i18next.addResourceBundle(key, 'common', translations[key]);
            });
            dispatch(loadTranslationsSuccess(true));
        } catch (error) {
            dispatch(loadTranslationsSuccess(false));
            dispatch(notifications.notificationError("ErrorloadingTranslations"));
        }
    };
} 
export function selectDefaultLanguage(defaultLocalization) {
    return function(dispatch) {
        try {
            if (window.localStorage) {
                if (!!defaultLocalization) {
                    i18n.changeLanguage(defaultLocalization);
                }
                else {
                    i18n.changeLanguage(Locales[defaultLanguage]);
                }
            }
        } catch (error) {
            dispatch(loadTranslationsSuccess(false));
            dispatch(notifications.notificationError("DefaultLanguage error"));
        }
    };
} 

export function selectLanguage(locale) {
    return function(dispatch) {
        try {
            i18n.changeLanguage(Locales[locale]);
        } catch (error) {
            dispatch(notifications.notificationError("ErrorSelctingLanguage"));
        }
    };
}